import { useContext } from "react";
import { ImportService } from "../../../services/ImportService";
import RequestService from "../../../services/RequestService";
import { formatDateShort, formatDateTimeDigits, formatTimeLong } from "../../../utils/FormatUtils";
import ModalService from "../../shared/bootstrap/Modal";
import ToastContext from "../../shared/bootstrap/Toast";
import { ImportLogListResponse } from "../../shared/entities/ImportLogListResponse";
import FloatingPanelService from "../../shared/FloatingPanel";
import { TranslationService } from "../../../services/TranslationService";
import TableContext from "../../task/TableContext";
import { ImportTableContextValues } from "./ImportLog";
import ImportLogDetail from "./ImportLogDetail";
import CompanyService from "../../../services/CompanyService";

const ImportLogItem = ({ data }: { data: ImportLogListResponse.Item }) => {
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);
    const { lastcompleteimportid, reload } = useContext(TableContext) as ImportTableContextValues;
    const status = (!data.Status && data.Status?.length === 0) ? undefined : TranslationService.getTranslation(data.Status!);
    const importType = ImportService.findImportType(data.Type, data.UpdateStrategy);
    const timezone = CompanyService.getTimezone();
    const lastStatusDate = data.LastStatus && new Date(new Date(data.LastStatus).getTime() - timezone * 60 * 1000);
    const statusTitle = status && status + (lastStatusDate ? (" " + formatDateShort(lastStatusDate) + " " + formatTimeLong(lastStatusDate)) : "");
    const getResult = (data: ImportLogListResponse.Item) => {
        const result = [];
        if (data.IOCount) {
            result.push(data.IOCount + " " + translate.TypeIOList);
        }
        if (data.PersonCount) {
            result.push(data.PersonCount + " " + translate.Clients);
        }
        if (data.TransactionCount) {
            result.push(data.TransactionCount + " " + translate.Transaction);
        }
        if (data.MessageCount) {
            result.push(data.MessageCount + " emails");
        }
        if (data.TagCount) {
            result.push(data.TagCount + " " + translate.Tags);
        }
        if (data.GroupCount) {
            result.push(data.GroupCount + " " + translate.groups);
        }
        return result.join(", ");
    };

    const showImportDetail = () => {
        FloatingPanelService.showPanel({
            title: translate.Import,
            children: <ImportLogDetail data={data} />,
            width: 900,
            height: 700,
        });
    };

    const deleteImport = (id: number) => {
        const onDelete = async () => {
            const result = ImportService.delete(id);
            if (result instanceof Error) {
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
            } else {
                reload();
            }
        };
        ModalService.showDefaultModal({
            title: translate.DeleteImport,
            message: translate.AreYouSureYouWishToDeleteThisImportRecord,
            acceptButtonLabel: translate.Delete,
            acceptButtonClassName: "btn btn-danger",
            onAcceptClick: onDelete,
        });
    };

    const downloadImport = () => {
        RequestService.downloadFile(`/import/download?id=${data.ImportID}`, data.Filename);
    };

    return (
        <tr className="show-child-on-hover">
            <td width={100} >{importType && TranslationService.getTranslation(importType.text)}</td>
            <td width={100} >{(data.Started ?? data.Created) ? formatDateTimeDigits(new Date(new Date(data.Started ?? data.Created).getTime() - timezone * 60 * 1000)) : ""}</td>
            <td width={100} >{lastStatusDate ? formatDateTimeDigits(lastStatusDate) : ""}</td>
            <td title={statusTitle} onClick={showImportDetail} className="pointer">{status}</td>
            <td>{getResult(data)}</td>
            <td>{data.UserName}</td>
            <td >{data.ImportTemplate?.Name}</td>
            <td width={100}>{data.Filename}</td>
            <td>
                <i className="show-when-hovering-parent fa-regular fa-arrow-down-to-line pointer" onClick={downloadImport}></i>
                <i className={"pointer fa-regular fa-search show-when-hovering-parent"} onClick={showImportDetail} />
                {((lastcompleteimportid && data.ImportID >= lastcompleteimportid) || data.New === 0) &&
                    <>
                        {<i className={"pointer fa-regular fa-trash-can text-danger show-when-hovering-parent"} onClick={() => deleteImport(data.ImportID)} />}
                    </>
                }
            </td>
        </tr>);
};
export default ImportLogItem;