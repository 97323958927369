import { SortDirection } from "../../../pages/shared/entities/Sort";
import { TagEntity } from "./TagEntity";

export interface TagListResponse {
    list: TagEntity[]
}

export interface TagReportResponse {
    list: TagReportResponse.Item[]
    itemCount: number
    page: number,
    pageSize: number
    response: string
}

export namespace TagReportResponse {
    export interface Item {
        Name: string,
        TagID: number,
        io: number,
        comment: number,
        mail: number,
        task: number,
        total: number
    }
}

export class TagReportRequest {
    page: number = 0;
    pagesize: number = 20;
    userid?: number | null = null;
    type?: number | null = null;
    dates?: string | null = null;

    pageSize: number = 20;
    datefrom: string = '';
    dateto: string = '';
    filter: any = null;

    sortColumn: string = "amount";
    descending: SortDirection = SortDirection.Descending;
    text: string | null = null;
    impid: null = null;
    actionsentid: null = null;
    reason: null = null;
}

export class RequestTagExport {
    filter: string = '';
}
