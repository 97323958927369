import AuthenticationService from "./AuthenticationService";
import RequestService from "./RequestService";
import { TranslationService } from "./TranslationService";

class CashappService {
    public static lang = () => TranslationService.currentLanguage === "en" ? "en" : "es";
    public static getBaseUrl = () => process.env.REACT_APP_CASHAPP_BASE_URL + CashappService.lang();
    public static async getHeaderItems(): Promise<Error | { Key: string, Value: string }[]> {
        return CashappService.post("/Home/GetMenuTabs", {});
    }
    public static async getClientId(personKey: string): Promise<Error | { clientId: string }> {
        return CashappService.post("/Client/GetId", { clientCode: personKey });
    }
    public static async post<TResponse = {}>(endpoint: string, data: {}) {
        return RequestService.post<TResponse>(endpoint, { ...data, jwt: await AuthenticationService.getAuthenticationToken() }, false, CashappService.getBaseUrl());
    }
}

export default CashappService;