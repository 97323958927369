import CompanyService from "../../../../services/CompanyService";
import { TranslationService } from "../../../../services/TranslationService";
import { EditProps, numEvent } from "../EditAction";
import { TextWithField, Checkbox } from "./FieldHelpers";

export const InvoiceRequirements = ({ propGen }: EditProps) => {
    return (<>
        <TextWithField text={TranslationService.translate.MaxAging} fieldTitle={TranslationService.translate.Days}>
            <input className="form-control" {...numEvent(propGen.num.notRequired("MaxAge"))} />
        </TextWithField>
        <TextWithField text={TranslationService.translate.MinimumAmount} fieldTitle={CompanyService.GetDefaultCurrencySymbol()}>
            <input className="form-control" {...numEvent(propGen.num.notRequired("Mail_MinAmount"))} />
        </TextWithField>
    </>);
}



export const IncludeCustomerSheet = (props: EditProps) =>
    <Checkbox text={TranslationService.translate.IncludeCustomerSheet} defaultValue={props.data.IncludeClientData} onChange={props.setField("IncludeClientData")} />;