import { TranslationService } from "../../../../services/TranslationService";
import Dropdown from "../../../shared/components/Dropdown";
import { EditProps } from "../EditAction";
import { Field } from "./FieldHelpers";

export const LanguageSelector = ({ setField, data }: EditProps) =>
    <div className="row">
        <Field title={TranslationService.translate.Language}>
            <Dropdown onChange={setField("Language")} items={["es-AR", "en-US", "pt-BR"].map(x => ({ text: x, value: x }))} defaultValue={data.Language ?? "es-AR"} />
        </Field>
    </div>;