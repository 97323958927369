import CompanyService from "../../../services/CompanyService";
import { TaskStatus } from "./TaskStatus";

export class TaskEdit {
    clientId?: string;
    groupId?: string;
    userResponsibleId?: string;
    userValidatorId?: string;
    dueDate?: string = (new Date()).toString();
    description?: string;
    statusId: string = TaskStatus.Pending.toString();
    activityTypeId?: number;
    tagsCsv?: string;
    notifyResponsible: boolean = false;
    notifyCompleted: boolean = false;
    emailReminder: boolean = false;
    reminderDay?: string = new Date().toString();
    reminderHour?: string = "0";
    creatorUserId?: string;
    files: string[] = [];
    dateDone?: string = new Date().toString();

    constructor(loggedUserId?: string, personId?: string) {
        this.creatorUserId = loggedUserId;
        this.userResponsibleId = loggedUserId;
        this.clientId = personId;
        this.activityTypeId = CompanyService.getActivityTypes().find(x => x.Task && x.TaskDefault)?.ActivityTypeID;
    }
}