import React from "react";
import { useLocation } from "react-router-dom";

export function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function useIsFirstTime() {
    const [firstTime, setFirstTime] = React.useState<{firstTime: () => boolean}>({firstTime: () => {
        setFirstTime({firstTime: () => false});
        return true;
    }});
    
    return firstTime.firstTime;
}