import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import FilterService from "../../services/FilterService";
import TransactionService from "../../services/TransactionService";
import { Sort, SortDirection } from "../shared/entities/Sort";
import TableContext, { TableContextValues } from "../task/TableContext";
import TranasactionListRequest from "./entities/TransactionListRequest";
import { TransactionListResponse } from "./entities/TransactionListResponse";

export type PaymentListFilters = {
    extraFilters: string[]
};

export class TransactionTableContextValues extends TableContextValues<TransactionListResponse, PaymentListFilters> {
    constructor(public getFilters: () => TranasactionListRequest) {
        super();
    }
}
const TransactionTableProvider = (props: PropsWithChildren<{ defaultFilter?: string[] }>) => {
    const [response, setResponse] = useState<TransactionListResponse>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [currentPage, _setCurrentPage] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(1);
    const [filters, setFilters] = useState<PaymentListFilters>({ extraFilters: props.defaultFilter ?? [] });
    const [sort, _setSort] = useState<Sort>({ sortColumn: "", sortDirection: SortDirection.Descending });

    const getFilters = useCallback((currentPage: number, filters: PaymentListFilters): TranasactionListRequest => {
        const request = {
            page: currentPage,
            sortcolumn: sort.sortColumn,
            descending: sort.sortDirection,
            filter: FilterService.GetExtraFiltersRequestString(filters.extraFilters) ?? "",
        };
        request.page = Math.max(0, currentPage);
        return request;
    }, [sort.sortColumn, sort.sortDirection]);

    const requestData = async () => {
        setLoading(true);
        const request = getFilters(currentPage, filters);
        const result = await TransactionService.getList({ ...request, pagesize: 20 });
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        const totalPages = Math.ceil(result.itemCount / result.pageSize);
        setResponse(result);
        _setCurrentPage(result.page);
        setPageCount(totalPages);
        setLoading(false);
        setError(false);
    };

    const requestDataCallback = useCallback(requestData, [currentPage, _setCurrentPage, filters, getFilters]);

    useEffect(() => {
        requestDataCallback();
    }, [currentPage, sort, requestDataCallback]);

    const setCurrentPage = (page: number) => {
        if (page >= 0 && (page - 1) <= pageCount) {
            _setCurrentPage(page);
        }
    };

    const applyFilters = (filters: PaymentListFilters) => {
        setCurrentPage(0);
        setFilters(x => ({ ...x, ...filters }));
    };

    const setSortColumn = (key: string) => {
        _setSort({
            sortColumn: key,
            sortDirection: sort.sortColumn === key ? (sort.sortDirection === 1 ? 0 : 1) : 0
        });
    };

    const value = new TransactionTableContextValues(() => getFilters(currentPage, filters));
    value.loading = loading;
    value.error = error;
    value.response = response;
    value.currentPage = currentPage;
    value.setCurrentPage = setCurrentPage;
    value.pageCount = pageCount;
    value.reload = requestData;
    value.applyFilters = applyFilters;
    value.sort = sort;
    value.setSortColumn = setSortColumn;

    return (
        <TableContext.Provider value={value}>
            {props.children}
        </TableContext.Provider>
    );
};

export default TransactionTableProvider;
