import { TranslationService } from "../../../services/TranslationService";
import { RrsBaseStructure } from "./RrsBaseStructure";
import Loading from "../../shared/components/Loading";
import { formatCurrency } from "../../../utils/FormatUtils";
import { SeriesLineOptions } from "highcharts";
import { ReportReceivablesResponse } from "../../../entities/reports/ReportReceivablesSummary/ReceivablesResponse";

export const RrsReceivables = ({ filter, receivables }: { filter: string, receivables: ReportReceivablesResponse | undefined }) => {
    if (receivables === undefined) {
        return <Loading height={420} />;
    }
    const LeftSide = () =>
        <div className="dashboard__sales d-flex flex-column gap-3">
            <div className="dashboard__total card p-3">
                <div className="card-body px-2">
                    <div className="card-header mb-1">
                        <p>{TranslationService.translate.Receivables}</p>
                    </div>
                    <h2 className="fw-bolder">{formatCurrency(receivables.due + receivables.nodue)}</h2>
                </div>
            </div>
            <div className="dashboard__expired card p-3">
                <div className="card-body px-2">
                    <div className="card-header mb-1">
                        <p>{TranslationService.translate.InvoiceExpired}</p>
                        <span>{receivables.duePerc}%</span>
                    </div>
                    <h2 className="text-danger">{formatCurrency(receivables.due)}</h2>
                </div>
            </div>
            <div className="dashboard__notExpired card p-3">
                <div className="card-body px-2">
                    <div className="card-header mb-1">
                        <p>{TranslationService.translate.NoDue}</p>
                        <span>{receivables.noduePerc}%</span>
                    </div>
                    <h2>{formatCurrency(receivables.nodue)}</h2>
                </div>
            </div>
        </div>

    const PreGraphic = () =>
        <h5 className="d-flex justify-content-center h5">
            {TranslationService.translate.ReceivablesAging}
        </h5>;
    return (<RrsBaseStructure UnderTitle={LeftSide} PreGraphic={PreGraphic} highchartsOptions={drawChart(receivables.chartData, receivables.total)} />);
}

function drawChart(data: [string[], number[], string[]], charttotal: number) {
    const options: Highcharts.Options = {
        colors: ['#1647b5', '#f4861a'],
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen", "printChart", 'separator', 'downloadPNG', 'downloadPDF']
                }
            }
        },
        legend: {
            enabled: false,
            floating: true,
            borderWidth: 0,
            itemMarginTop: 0,
            itemMarginBottom: 0
        },
        credits: { enabled: false },
        title: {
            text: ''
        },
        plotOptions: {
            series: {
                // pointWidth: 40,
                shadow: false
            },
            column: {
                stacking: 'normal',
                dataLabels: {
                    verticalAlign: 'top',
                    y: -15,
                    enabled: true,
                    color: 'black',
                    style: {
                        fontSize: "10px"
                    },
                    formatter: function () {
                        let s = '';
                        if (this.y !== 0) {
                            s = '<div><p style="text-align:center;width:100%;">' + formatCurrency(this.y!) + '</p><br />';
                            s += '<p style="text-align:center;width:100%;">' + Math.round(this.y! / charttotal * 100) + '%</p></div>';
                            //s = '<div><p style="text-align:center;width:100%;">' + formatCurrency(this.y, currencySymbol, false) + '</p><br />';
                            //s += '<p style="text-align:center;width:100%;">' + Math.round(this.y / charttotal * 100) + '%</p></div>';
                        }
                        return s;
                    }
                }
            }
        },
        xAxis: {
            categories: data[0],
            labels: {
                style: {
                    fontSize: '9px'
                }
            }
        },
        yAxis: {
            title: { text: "" },
            gridLineColor: '#e3e3e3',
            gridLineWidth: 0,
            min: 0,
            labels: {
                enabled: false,
                formatter: function () {
                    return formatCurrency(parseFloat(this.value.toString())) ?? "";
                }
            }
        },

        tooltip: {
            formatter: function () {
                let s;
                s = formatCurrency(this.y!);
                s += '<br />' + Math.round(this.y! / charttotal * 100) + '%';
                return s;
            }
        },
        series: [{
            type: 'column',
            name: TranslationService.translate.Sales,
            data: [],
            events: {
                click: function (event) {
                    // clickPoint(event, '');
                }
            }
        }],
        chart: { height: 343 },
    };


    const series = options.series! as SeriesLineOptions[];
    series[0].data = series[0].data ?? [];
    let ix = 0;
    for (; ix < data[0].length - 1; ix++) {
        if (!data[2][ix])
            series[0].data!.push({ y: data[1][ix], color: '#FF0000' });
        else
            series[0].data!.push({ y: data[1][ix], color: '#1f53c4' });
    }
    series[0].data.push({ y: data[1][ix] })
    return options;
}