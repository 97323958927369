import { useState, useEffect } from "react";
import { TranslationService } from "../../services/TranslationService";

export interface ValidationFunctions<T = unknown> {
	onValidation: (value: T) => boolean; /// Injects validation on change.
	isValid: () => boolean; /// Shows message if invalid. returns if it is valid.
}

export type ValidationFunc<T = unknown> = {
	(value?: T): void;
	validationFunctions: ValidationFunctions<T> | undefined;
};
export class RequiredManager {
	private validations: { [index: string]: ValidationFunctions | undefined } & (
		| ValidationFunctions
		| undefined
	)[] = [] as any;
	makeRequired<T>(changeFunction: (value: T) => void): ValidationFunc<T> {
		let index = this.validations.push({ onValidation: () => true, isValid: () => true }) - 1;
		return this.injectValidationFunctions(
			changeFunction,
			this.validations[index]!
		);
	}
	makeRequiredWithId<T>(changeFunction: (value: T) => void, id: string) {
		this.validations[id] = this.validations[id] ?? {
			onValidation: () => true,
			isValid: () => true,
		};
		return this.injectValidationFunctions(
			changeFunction,
			this.validations[id]!
		);
	}
	makeRequiredIf<T>(condition: boolean, changeFunction: (value: T) => void, id?: string) {
		if (condition){
			if (id !== undefined) {
				return this.makeRequiredWithId(changeFunction, id);
			} else {
				return this.makeRequired(changeFunction);
			}
		}
		return changeFunction;
	}
	getValidation(id: string) {
		return (this.validations[id] = this.validations[id] ?? {
			onValidation: () => true,
			isValid: () => true,
		});
	}
	private injectValidationFunctions<T>(
		changeFunction: (value: T) => void,
		validationFunctions: ValidationFunctions<T>
	) {
		let onChange = ((value: Parameters<typeof changeFunction>[0]) => {
			changeFunction(value);
			const validation = validationFunctions?.onValidation;
			validation && validation(value);
		}) as ValidationFunc<T>;
		onChange.validationFunctions = validationFunctions;
		return onChange;
	}
	/**
	 * @returns True if all validations are valid
	 */
	validate() {
		return Object.values(this.validations).reduce(
			(prev, curr) => curr!.isValid() && prev,
			true
		);
	}
}

export interface ValidationMessageProps<T> {
	onChange: ((value: T) => void) | ValidationFunctions<T>;
	message?: string;
	validationMethod?: (value: T | undefined) => boolean;
	defaultValue?: T | undefined;
	className?: string;
}

export const ValidationMessage = <T,>({
	onChange,
	message = undefined,
	className = "text-danger",
	validationMethod = (value: T | undefined) => !!value,
	defaultValue = undefined,
}: ValidationMessageProps<T>) => {
	const { translate } = TranslationService;
	const [valid, setValid] = useState(validationMethod(defaultValue));
	const [showMessage, setShowMessage] = useState(false);
	useEffect(() => {
		let validationFunctions = isValidationFunc(onChange)
			? onChange
			: (onChange as ValidationFunc<T>)?.validationFunctions;
		if (validationFunctions === undefined) {
			return;
		}
		const onValidation = (value: T) => {
			const isValid = validationMethod(value);
			setValid(isValid);
			return isValid;
		};
		const isValid = () => {
			setShowMessage(true);
			return valid;
		};
		validationFunctions.isValid = isValid;
		validationFunctions.onValidation = onValidation;
		setValid(validationMethod(defaultValue));
	}, [defaultValue, message, onChange, valid, validationMethod]);
	return (
		<>
			{showMessage && !valid && (
				<p className={className}>{message ?? translate.RequiredField}</p>
			)}
		</>
	);
};

const nameof = <T,>(name: Extract<keyof T, string>): string => name;
function isValidationFunc<T>(
	onchange?: ((value: T) => void) | ValidationFunctions<T>
): onchange is ValidationFunctions<T> {
	return (
		onchange !== undefined &&
		nameof<ValidationFunctions>("isValid") in onchange &&
		nameof<ValidationFunctions>("onValidation") in onchange
	);
}
