import React, { PropsWithChildren, useState } from "react";
import { InvoiceListFilters } from "./invoice/InvoiceTableProvider";

export const ClientDetailProvider = ({ children,  reloadExtraData = undefined }: PropsWithChildren<{ reloadExtraData?: () => void }>) => {
    const [invoiceIds, setInvoiceIds] = useState<number[]>([]);
    const [invoiceAll, setInvoiceAll] = useState(false);
    const [invoiceCount, setInvoiceCount] = useState<number>(0);
    const [invoiceFilters, setInvoiceFilters] = useState<InvoiceListFilters>({ quickfilter: "", extraFilters: [], quickExtraFilter: "", iotree: "" });

    return (<ClientDetailContext.Provider value={{
        invoiceIds,
        setInvoiceIds,
        invoiceAll,
        setInvoiceAll,
        invoiceCount,
        setInvoiceCount,
        invoiceFilters,
        setInvoiceFilters,
        reloadExtraData: reloadExtraData ?? (() => undefined),
    }}>
        {children}
    </ClientDetailContext.Provider>);
};

export class ClientDetailContextValues {
    invoiceIds: number[] = [];
    invoiceAll = false;
    invoiceCount = 0;
    invoiceFilters: InvoiceListFilters = { quickfilter: "", extraFilters: [], quickExtraFilter: "", iotree: "" }

    setInvoiceIds: (ids: number[]) => void = () => { throw Error("error"); };
    setInvoiceAll: (all: boolean) => void = () => { throw Error("error"); };
    setInvoiceCount: (count: number) => void = () => { throw Error("error"); };
    setInvoiceFilters: (filters: InvoiceListFilters) => void = () => { throw Error("error"); };
    reloadExtraData: () => void = () => { throw Error("error"); };
}
const ClientDetailContext = React.createContext<ClientDetailContextValues>(new ClientDetailContextValues());
export default ClientDetailContext;    