import { SortDirection } from "../../shared/entities/Sort";

export class ClientHistoryListRequest {
    page: number = 0
    pageSize: number = 20;
    datefrom: string = '';
    dateto: string = '';
    filter: any = null;

    sortColumn: string = "amount";
    descending: SortDirection = SortDirection.Descending;
    text: string | null = null;
    impid: null = null;
    actionsentid: null = null;
    reason: null = null;
    pagesize: number = 20;
}