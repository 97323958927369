import { TranslationService } from "../../../../services/TranslationService";
import Dropdown from "../../../shared/components/Dropdown";
import { EditProps, strEvent } from "../EditAction";
import { Field } from "./FieldHelpers";

export const LetterFrom = ({ setField, data, propGen }: EditProps) =>
    <div className="row">
        <Field title={TranslationService.translate.From}>
            <Dropdown items={[{ text: TranslationService.translate.EmailAddress, value: 0 }, { text: TranslationService.translate.TheUser, value: 1 }]} onChange={setField("Mail_FromType")} defaultValue={data.Mail_FromType} />
        </Field>
        {(data.Mail_FromType ?? 0) === 0 &&
            <>
                <Field title={TranslationService.translate.Name}>
                    <input className="form-control" {...strEvent(propGen.str.notRequired("Mail_FromName"))} />
                </Field>
                <Field title={TranslationService.translate.Email}>
                    <input className="form-control" {...strEvent(propGen.str.notRequired("Mail_From"))} />
                </Field>
            </>
        }
    </div>;
