import { ImportTemplateListResponse } from "../../../shared/entities/ImportTemplateListResponse";
import { TranslationService } from "../../../../services/TranslationService";
import ImportTemplateEditBase, { ImportTemplateComponentProps } from "./ImportTemplateEditBase";
import EditItem from "./Components/EditItem";
import { TextEditor } from "../../../shared/components/Editors";

const ImportTemplateEditInvoiceAutomatic = ({ data, reload, entityId = undefined }: { data?: ImportTemplateListResponse.Item, reload: () => void, importsList?: ImportTemplateListResponse, entityId?: number }) => {
    const { translate } = TranslationService;

    const Header = () => {
        return (<div className="mb-3 mt-4">
            <h5 className="mb-4">{translate.DictionaryImportSettings}</h5>
            <details className="mt-3">
                <summary className="ps-1"><h6 style={{ display: "unset" }}>{translate.SeeTheRequirementsOfTheFileToImport}</h6></summary>
                {translate.RequirementsOfTheFileToImport.split("\n").map((x, i) => <div key={i} className="text-muted ps-3">{x}</div>)}
            </details>
        </div>);
    };

    const AdvancedOptions = ({ data, updateModel }: ImportTemplateComponentProps) =>
        <EditItem label={<>{translate.SendResultsTo}</>} ><TextEditor onChange={updateModel("EmailReturn")} defaultValue={data?.EmailReturn} /></EditItem>;
    return (
        <>
            <ImportTemplateEditBase entityId={entityId} Header={Header} reload={reload} requiredFieldIds={[]} data={data} AdvancedOptions={AdvancedOptions} />
        </>

    );
};

export default ImportTemplateEditInvoiceAutomatic;