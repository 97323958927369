import { SortDirection } from "../../shared/entities/Sort";


export class IoActivityListRequest {
    page: number = 0;
    pagesize: number = 20;
    "filter": any = '';
    "quickfilter": any = '';
    currencies:
        [
            {
                "CurrencyId": number;
                "Name": string;
                "Symbol": string;
                "Rate": number;
            }
        ] | [] = [];
    "users": [
        {
            "Id": string;
            "Value": string
        }
    ];
    "iostatus": [
        {
            "IOStatusDependencies": [
                {
                    "IOStatusDependencyID": number;
                    "IOStatusID": number;
                    "AdditionalDefinitionID": number;
                    "Mandatory": true;
                    "Public": true
                }
            ];
            "IOStatusID": number;
            "Value": string;
            "Exclude": true;
            "Public": true;
            "PublicValue": string
        }
    ];
    sortColumn: string = "amount";
    descending: SortDirection = SortDirection.Descending;
    text: string | null = null;
}