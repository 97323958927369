import React, { useState } from 'react';
import TranslationsJson from '../../assets/translations/translations.json';
import { Language, TranslationService, TranslationsKey } from '../../services/TranslationService';

export type ILanguageContext = {
    currentLanguage: Language,
    setCurrentLanguage: (lang: Language) => void,
    translate:(page: string, key: string, params?: any) => string,
}

const defaultLanguageContext: ILanguageContext = {
    currentLanguage: 'es',
    setCurrentLanguage: () => 'es',
    translate: (page, key, params) => {
        console.error('No LanguageContext defined')
        return `${page}.${String(key)}.${params}`;
    }
}

function postProcess(l: string, params?: any): string {
    if (params && typeof params === 'object' && !Array.isArray(params)) {
        let result: string = l;
        const keys: string[] = Object.keys(params);
        keys.forEach(key => {
            result = result.replace(`{${key}}`, params[key]);
        });
        return result;
    }
    return l;
}

const defaultProdString = '';
const defaultDevString = 'Missing translation: ';
const LanguageContext = React.createContext<ILanguageContext>(defaultLanguageContext);

const LanguageProvider = (props: any) => {

    const [currentLanguage, _setCurrentLanguage] = useState<Language>(defaultLanguageContext.currentLanguage);

    const setCurrentLanguage = (lang: Language) => {
        TranslationService.setCurrentLanguage(lang);
        _setCurrentLanguage(lang);
    }

    function _translate(page: string, key: string, lang: string, params?: any): string {
        let stringKey = String(key);
        const Translations = TranslationsJson as unknown as { [t in TranslationsKey]: { [key in Language]: string } };
        if (Translations) {
            if (Translations) {
                if (Translations[key as TranslationsKey]) {
                    // @ts-ignore
                    const l: string = Translations[key][lang];
                    if (l) {
                        // Existe traduccion
                        return postProcess(l, params);
                    } else {
                        // No hay traduccion de la key especifica en el lenguaje actual, si no es defaultLang fallback, sino default prod string
                        if (lang !== defaultLanguageContext.currentLanguage) {
                            console.debug('Translation fallback: ' + stringKey);
                            return _translate(page, key, defaultLanguageContext.currentLanguage, params);
                        } else {
                            //console.debug('Default translation: ' + key);
                            return postProcess(defaultProdString, params);
                        }
                    }
                } else {
                    // No traduccion de la pagina especificada en el lenguaje actual, si no es defaultLang fallback, sino default prod string
                    if (lang !== defaultLanguageContext.currentLanguage) {
                        console.debug('Translation fallback: ' + stringKey);
                        return _translate(page, key, defaultLanguageContext.currentLanguage, params);
                    } else {
                        console.debug('Default translation: ' + stringKey);
                        return postProcess(defaultProdString, params);
                    }
                }
            } else {
                // No hay pagina especificada, fallar si es produccion, default dev string si es dev y warning
                if (process.env.NODE_ENV === 'production') {
                    throw new Error("No page translation");
                } else {
                    console.error('No page translation! Not suitable for production: ' + stringKey);
                    return defaultDevString + stringKey;
                }
            }
        } else {
            // No esta cargado  translations, fallar si es produccion, default dev string si es dev y warning
            if (process.env.NODE_ENV === 'production') {
                throw new Error("No page translation: " + stringKey);
            } else {
                console.error('No page translation! Not suitable for production: ' + stringKey);
                return defaultDevString + stringKey;
            }
        }
    }

    function translate(page: string, key: string, params?: any): string {
        return _translate(page, key, currentLanguage, params);
    }

    const value: ILanguageContext = {
        currentLanguage,
        setCurrentLanguage,
        translate,
    }

    return (
        <LanguageContext.Provider value={value}>
            {props.children}
        </LanguageContext.Provider>
    )
}

export { LanguageProvider };
export default LanguageContext;