import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TranslationService } from '../../../services/TranslationService';
import ButtonPrimary from '../../account/components/ButtonPrimary';
import * as yup from "yup";
import CompanyService from '../../../services/CompanyService';
import { TitleConfig } from './components/TitleConfig';

type PriorityFormulaWeightsType = {
    amountRankPercentValue: string,
    dueRankPercentValue: string,
    lastActivityRankPercentValue: string,
    lastTaskRankPercentValue: string,
    fullSize?: boolean
}

export const PriorityFormulaWeights = ({
    amountRankPercentValue = '0',
    dueRankPercentValue = '0',
    lastActivityRankPercentValue = '0',
    lastTaskRankPercentValue = '0',
    fullSize = false
}: PriorityFormulaWeightsType) => {
    const [error, setError] = useState('')
    const [saveData, setSaveData] = useState(false)
    const [formData, setFormData] = useState({
        amountRankPercent: parseFloat(amountRankPercentValue) * 100,
        dueRankPercent: parseFloat(dueRankPercentValue) * 100,
        lastActivityRankPercent: parseFloat(lastActivityRankPercentValue) * 100,
        lastTaskRankPercent: parseFloat(lastTaskRankPercentValue) * 100,
    })

    const { translate } = TranslationService;

    const formDataSchema = yup.object({
        amountRankPercent: yup.number().required().typeError(`${translate.ValueRequiredFor} ${translate.AmountDue}`),
        dueRankPercent: yup.number().required().typeError(`${translate.ValueRequiredFor} ${translate.DebtAging}`),
        lastActivityRankPercent: yup.number().required().typeError(`${translate.ValueRequiredFor} ${translate.LastActivity}`),
        lastTaskRankPercent: yup.number().required().typeError(`${translate.ValueRequiredFor} ${translate.PendingTasks}`),
    });

    const sumTotalValues = useMemo(() => Object.values(formData).reduce((total, value) => total + (typeof value === 'string' ? parseFloat(value) : value), 0), [formData])

    const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevState) => ({ ...prevState, [name]: value }));
        setSaveData(true)
    }


    const saveValues = useCallback(() => {
        setSaveData(false)
        CompanyService.setConfigCompany({
            amountRankPercent: (formData.amountRankPercent / 100).toString(),
            dueRankPercent: (formData.dueRankPercent / 100).toString(),
            lastActivityRankPercent: (formData.lastActivityRankPercent / 100).toString(),
            lastTaskRankPercent: (formData.lastTaskRankPercent / 100).toString(),
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    useEffect(() => {
        if (sumTotalValues !== 100) {
            setError(translate.PercentageSumToSaveChanges)
            return
        } else { setError('') }

        formDataSchema.validate(formData)
            .then(() => {
                setError('')
                setSaveData(true)
            })
            .catch((err) => setError(err.errors[0]))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    return (
        <div className={`${fullSize ? '' : 'col-md-8'} mx-3`}>
            <TitleConfig title={translate.PriorityFormulaWeights} />
            <div className="col">
                <div className="col-sm-6">
                    <div className="input-group mb-3">
                        <span className="input-group-text col-md-8" id="basic-addon1">{translate.AmountDue}</span>
                        <input
                            name='amountRankPercent'
                            defaultValue={formData.amountRankPercent}
                            type="number"
                            onChange={handleInputChange}
                            className="form-control col-md-2"
                        />
                        <span className="input-group-text col-md-2" id="basic-addon1">%</span>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="input-group mb-3">
                        <span className="input-group-text col-md-8" id="basic-addon1">{translate.DebtAging}</span>
                        <input
                            name='dueRankPercent'
                            defaultValue={formData.dueRankPercent}
                            type="number"
                            onChange={handleInputChange}
                            className="form-control col-md-2"
                        />
                        <span className="input-group-text col-md-2" id="basic-addon1">%</span>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="input-group mb-3">
                        <span className="input-group-text col-md-8" id="basic-addon1">{translate.LastActivity}</span>
                        <input
                            name='lastActivityRankPercent'
                            defaultValue={formData.lastActivityRankPercent}
                            type="number"
                            onChange={handleInputChange}
                            className="form-control col-md-2"
                        />
                        <span className="input-group-text col-md-2" id="basic-addon1">%</span>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="input-group mb-3">
                        <span className="input-group-text col-md-8" id="basic-addon1">{translate.PendingTasks}</span>
                        <input
                            name='lastTaskRankPercent'
                            defaultValue={formData.lastTaskRankPercent}
                            type="number"
                            onChange={handleInputChange}
                            className="form-control col-md-2"
                        />
                        <span className="input-group-text col-md-2" id="basic-addon1">%</span>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
                Total: {sumTotalValues || 'Error'}%
                <div>
                    <div id="errorMsj" className="incorrect" style={{ color: "red", fontSize: "14px", marginTop: "10px" }}>{error}</div>
                </div>
                {saveData && !error && <ButtonPrimary onClick={saveValues}>{translate.Save}</ButtonPrimary>}
            </div>
        </div>
    )
}
