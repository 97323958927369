import React, { PropsWithChildren, useEffect, useCallback } from "react";
import ErrorBoundary from "./ErrorBoundary";

import "./FloatingPanel.css";
import { Cast } from "../../utils/Utils";
import { onSectionChangeObservable } from "./TabPositionContext";
import { AdvancedFiltersProvider } from "./components/AdvancedFilters";
const nextZIndex = () => {
    let maxZ = 10;
    for (const w of document.querySelectorAll<HTMLSpanElement>(".window-container")) {
        const z = parseInt(w.style.zIndex);
        maxZ = Math.max(isNaN(z) ? 0 : z, maxZ);
    }
    return maxZ + 1;
};

//const minimizeComponent = () => <i className="fas fa-window-minimize" />;
const maximizeComponent = () => <i className="fas fa-window-maximize" />;
const restoreComponent = () => <i className="fas fa-window-restore" />;
interface WindowProps {
    id: string;
    children?: any;
    height: number;
    width: number;
    top?: number;
    left?: number;
    resizable?: boolean;
    titleBar?: {
        icon?: string | HTMLImageElement;
        title?: string;
        buttons?: {
            minimize?: boolean;
            maximize?: boolean;
            close?: boolean;
        };
    };
}
let showPanelInternal = (params: FloatingPanelPanelArgs) => { };
let setFloatingPanelTitle = (title: string, className?: string) => { };

export interface FloatingPanelPanelArgs {
    title: string;
    children: React.ReactChild;
    width?: number;
    height?: number;
    resizable?: boolean;
    position?: "center" | "bottom-right",
    onBeforeClose?: () => void;
    close?: boolean;
    showBackdrop?: boolean
}

const onCloseSubs: (() => void)[] = [];

function callAllOnCloseSubs() {
    const subscribers = onCloseSubs.splice(0);
    subscribers.forEach(x => x());
}
class FloatingPanelService {
    static OnBeforeClose?: () => boolean;
    public static hidePanel(): void {
        showPanelInternal({
            title: "",
            children: <></>,
            close: true
        });
    }
    public static showPanel(params: FloatingPanelPanelArgs) {
        this.setTitle("", "");
        showPanelInternal(params);
    }
    public static subscribeEventOnClose(func: () => void) {
        onCloseSubs.push(func);
    }

    public static setTitle(title: string, className?: string) {
        setFloatingPanelTitle(title, className);
    }
}

export const FloatingPanel = (props: PropsWithChildren<WindowProps>) => {
    const properties = Object.assign({
        id: props.id && props.id.length ? props.id : Date.now().toString(),
        children: null,
        height: 0,
        width: 0,
        top: 0,
        left: 0,
        resizable: false,
        titleBar: Object.assign({
            icon: " ",
            title: "Untitled window",
            buttons: Object.assign({
                minimize: false,
                maximize: true,
                close: true,
            }, (props.titleBar && props.titleBar.buttons) || {}),
        }, props.titleBar),
    }, props);
    if (!properties.id) {
        properties.id = Date.now().toString();
    }
    const [width, setWidth] = React.useState(properties.width);
    const [height, setHeight] = React.useState(properties.height);
    const [widthRestored, setWidthRestored] = React.useState(properties.width);
    const [heightRestored, setHeightRestored] = React.useState(properties.height);
    const [top, setTop] = React.useState(properties.top || 0);
    const [left, setLeft] = React.useState(properties.left || 0);
    const [xOffset, setXOffset] = React.useState(0);
    const [yOffset, setYOffset] = React.useState(0);
    const [minimized, setMinimized] = React.useState(false);
    const [maximized, setMaximized] = React.useState(false);
    //const [minimizeIcon, setMinimizeIcon] = React.useState(minimizeComponent);
    const [maximizeIcon, setMaximizeIcon] = React.useState(maximizeComponent);
    const [contentDisplay, setContentDisplay] = React.useState(true);
    const [windowTransition, setWindowTransition] = React.useState("");
    const [level, setLevel] = React.useState(nextZIndex());
    const [visibility, setWindowVisibility] = React.useState(1.0);
    const [show, setShow] = React.useState(false);
    const [children, setChildren] = React.useState<React.ReactChild>();
    const [resizeable, setResizeable] = React.useState(true);
    const [title, setTitle] = React.useState("");
    const [className, setClassName] = React.useState("");
    const [showBackdrop, setShowBackdrop] = React.useState<boolean>(false)
    const container = React.useRef<HTMLDivElement | null>(null);
    const windowTitle = React.useRef(null);
    const animationDuration = 500;

    useEffect(() => {
        showPanelInternal = (params: FloatingPanelPanelArgs) => {
            callAllOnCloseSubs();
            const metadata = Cast<{ type: { [metadataSymbol]: FloatingPanelPanelArgs } }>(params.children)?.type[metadataSymbol] ?? {};
            Object.keys(metadata).forEach(x => {
                params[x as "title"] ??= metadata[x as "title"];
            });
            if (params.close) {
                let close = true;
                if (FloatingPanelService.OnBeforeClose) {
                    close = FloatingPanelService.OnBeforeClose();
                }
                if (close) {
                    FloatingPanelService.OnBeforeClose = undefined;
                    setShow(false);
                }
                return;
            }
            setChildren(params.children);
            params.width && setWidth(Math.min(params.width, window.innerWidth));
            params.height && setHeight(Math.min(params.height, window.innerHeight));
            setResizeable(params.resizable === undefined || params.resizable);
            setMinimized(false);
            setMaximized(false);
            setTitle(params.title);
            setShowBackdrop(params?.showBackdrop || false)
            if (!show) {
                setShow(true);
            }
            if (!show || params.position !== undefined) {
                const { newLeft, newTop } = getPosition(params.position, params.width ?? width, params.height ?? height);
                setLeft(newLeft);
                setTop(newTop);
                setXOffset(newLeft);
                setYOffset(newTop);
            }
            if (container.current) {
                container.current.querySelector(".content")!.scroll(0, 0);
            }
        };
    });

    useEffect(() => {
        setFloatingPanelTitle = (title: string, className?: string) => {
            setTitle(title);
            setClassName(className ?? "");
        };
    });
    useEffect(() => onSectionChangeObservable.subscribe(() => FloatingPanelService.hidePanel()).unsubscribe, []);
    const onResize = useCallback((e?: React.DragEvent<HTMLSpanElement>) => {
        if ((left + width) > window.innerWidth || left < 0 || (e !== undefined && e.clientX < 0)) {
            let newLeft = window.innerWidth - width;
            if (left < 0 || newLeft < 0 || (e !== undefined && e.clientX < 0)) {
                newLeft = 0;
            }
            setLeft(newLeft);
        }
        if ((top + height) > window.innerHeight || top < 0 || (e !== undefined && e.clientY < 0)) {
            let newTop = window.innerHeight - height;
            if (top < 0 || newTop < 0 || (e !== undefined && e.clientY < 0)) {
                newTop = 0;
            }
            setTop(newTop);
        }

        if (!maximized && !minimized) {
            setWidthRestored(container.current?.clientWidth!);
            setHeightRestored(container.current?.clientHeight!);
        }
    }, [height, left, top, width, minimized, maximized, setTop, setLeft, setWidthRestored, setHeightRestored]);

    useEffect(() => {
        onResize();
    }, [setWidth, setHeight, onResize]);

    useEffect(() => {
        const listener = () => onResize();
        window.addEventListener("resize", listener);
        return () => {
            window.removeEventListener("resize", listener);
        };
    }, [onResize]);

    const handleDragStart = (e: React.DragEvent<HTMLSpanElement>) => {
        setXOffset(e.clientX - left);
        setYOffset(e.clientY - top);
        setLevel(nextZIndex());
        setWindowVisibility(0.5);
    };
    const handleDrag = (e: React.DragEvent<HTMLSpanElement>) => {
        setLeft((e.clientX || e.screenX || left + xOffset) - xOffset);
        setTop((e.clientY || e.screenY || top + yOffset) - yOffset);
    };
    const handleDragEnd = (e: React.DragEvent<HTMLSpanElement>) => {
        setLeft((e.clientX || e.screenX) - xOffset);
        setTop((e.clientY || e.screenY) - yOffset);
        setXOffset((e.clientX || e.screenX) - xOffset);
        setYOffset((e.clientY || e.screenY) - yOffset);
        setWindowVisibility(1.0);
        onResize(e);
    };
    /*const minimize = () => {
        setWindowTransition(`${animationDuration}ms ease-in-out`);
        if (minimized) {
            setContentDisplay(true);
            setHeight(heightRestored);
            setWidth(widthRestored);
            setLeft(xOffset);
            setTop(yOffset);
            setMinimized(false);
            //setMinimizeIcon(minimizeComponent);
            setMaximized(false);
        }
        else {
            const newWidth = 300;
            const newHeight = 45;
            setContentDisplay(false);
            if (!maximized && !minimized) {
                setWidthRestored(container.current?.clientWidth!);
                setHeightRestored(container.current?.clientHeight!);
            }
            setHeight(newHeight);
            // let _b;
            // const parent = (_b = document.getElementById(properties.id)) === null || _b === undefined ? undefined : _b.parentElement;
            setWidth(newWidth);
            let topPosition = (window.innerHeight) -
                newHeight -
                4;
            let leftPosition = (window.innerWidth) - newWidth - 4;
            const minimizedWindow = document.elementFromPoint(leftPosition + newWidth / 2, topPosition + newHeight / 2);
            if (minimizedWindow &&
                ["window-container", "windowTitle"].includes((minimizedWindow === null || minimizedWindow === undefined ? undefined : minimizedWindow.className) || "")) {
                topPosition -= (minimizedWindow === null || minimizedWindow === undefined ? 0 : minimizedWindow.clientHeight) + 4;
            }
            setTop(topPosition);
            setLeft(leftPosition);
            setMinimized(true);
            //setMinimizeIcon(restoreComponent);
            setMaximized(false);
            setMaximizeIcon(maximizeComponent);
        }
        setLevel(nextZIndex());
        setTimeout(setWindowTransition, animationDuration + 1, "");
    };*/
    const maximize = () => {
        // const modalParams = new ModalParams();
        // modalParams.children = children;
        // modalParams.size = "modal-lg";
        // ModalService.showModal(modalParams);
        setWindowTransition(`${animationDuration}ms ease-in-out`);
        if (maximized) {
            setContentDisplay(true);
            setHeight(heightRestored);
            setWidth(widthRestored);
            setLeft(xOffset);
            setTop(yOffset);
            setMaximized(false);
            setMaximizeIcon(maximizeComponent);
            setMinimized(false);
            //setMinimizeIcon(minimizeComponent);
        }
        else {
            if (!maximized && !minimized) {
                setWidthRestored(container.current?.clientWidth!);
                setHeightRestored(container.current?.clientHeight!);
            }
            setContentDisplay(true);
            setHeight((window.innerHeight) - 64);
            setWidth((window.innerWidth) - 64);
            setTop((0) + 32);
            setLeft((0) + 32);
            setMaximized(true);
            setMaximizeIcon(restoreComponent);
            setMinimized(false);
            //setMinimizeIcon(minimizeComponent);
        }
        setLevel(nextZIndex());
        setTimeout(setWindowTransition, animationDuration + 1, "");
    };
    if (!show) {
        return (<></>);
    }
    return (
        <>
            {showBackdrop && <div className="backdrop-modal"></div>}

            <div className={"floating-window-overlay" + (maximized ? " fadeIn" : "")} /* style={{ width: window.innerWidth, height: window.innerHeight }} */></div>
            <div id={properties.id}
                className={"window-container"}
                style={{
                    height: height,
                    width: width,
                    top,
                    left,
                    resize: (resizeable && !maximized) ? "both" : "none",
                    transition: windowTransition,
                    zIndex: level,
                    opacity: visibility,
                    marginRight: "20px"
                }} ref={container} onClick={() => { setLevel(nextZIndex()); }}>
                {properties.titleBar &&
                    <div className={"title-bar " + className} data-parent={properties.id} style={{ opacity: visibility }}>
                        <span className="windowTitle" draggable={!maximized} onDragStart={handleDragStart}
                            onDrag={handleDrag} onDragEnd={handleDragEnd}
                            style={{ opacity: Math.floor(visibility) }} ref={windowTitle}>
                            {title}
                        </span>
                        {properties.titleBar.buttons &&
                            <span className="buttonContainer">
                                {/*{properties.titleBar.buttons.minimize && <span className="windowButton" onClick={minimize}>{minimizeIcon}</span>}*/}
                                {properties.titleBar.buttons.maximize && <span className="windowButton" onClick={maximize}>{maximizeIcon}</span>}
                                <span className="windowButton" onClick={() => FloatingPanelService.hidePanel()}><i className="fas fa-window-close" /></span>
                            </span>
                        }
                    </div>
                }

                <div className="content" draggable={"false"} style={{ height: contentDisplay ? "auto" : 0, opacity: visibility,/* borderBottom: "1px solid lightgray"  */ }}>
                    <ErrorBoundary location="">
                        <AdvancedFiltersProvider>
                            {children}
                        </AdvancedFiltersProvider>
                    </ErrorBoundary>
                </div>
            </div>
        </>);
};
function getPosition(position: string | undefined, width: number, height: number): { newLeft: any; newTop: any; } {
    if (position === "center") {
        const newLeft = window.innerWidth / 2 - (width) / 2;
        const newTop = window.innerHeight / 2 - (height) / 2;
        return { newLeft, newTop };
    }
    //bottom-right
    const newLeft = window.innerWidth - 45 - (width);
    const newTop = window.innerHeight - 62 - (height);
    return { newLeft, newTop };
}

const metadataSymbol = Symbol.for("FloatingPanelMetadataSymbol");
export const FloatingPanelMetadata = <T extends (...params: any) => JSX.Element,>(metadata: Partial<FloatingPanelPanelArgs>, Component: T) => {
    Cast<{ [metadataSymbol]: Partial<FloatingPanelPanelArgs> }>(Component)[metadataSymbol] = metadata;
    return Component;
};
export default FloatingPanelService;