import CompanyService from "../../../services/CompanyService";
import { TranslationService } from "../../../services/TranslationService";
import { InvoiceData } from "./InvoiceData";
import { SubscriptionData } from "./SubscriptionData";

export const DataPlan = () => {
    return (
        <div className="main-layout-container mx-3">
            <div className="main-panel">
                <div className="card">
                    <h2 className="my-3">{TranslationService.translate.DataPlan}</h2>
                    {CompanyService.getCompanyToken()?.userid === CompanyService.getCompanyToken()?.ownerId &&
                        <>
                            <h2 className="my-3">{TranslationService.translate.CustomDataPlan}</h2>
                            <SubscriptionData />
                            <hr />
                        </>
                    }
                    <div className="card-body">
                        <InvoiceData />
                    </div>
                </div>
            </div>
        </div>);
};