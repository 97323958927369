import React from "react";

export const Label = ({ children, label, className = "mb-3" }: React.PropsWithChildren<{
    label?: string | JSX.Element,
    className?: string
}>) => {
    return (
        <div className={className}>
            <div className="d-flex input-column">
                <label className="form-label">{label}&nbsp;</label>
                {children}
            </div>
        </div>)
}

export default Label;