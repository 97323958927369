import { SortDirection } from "../../../pages/shared/entities/Sort";

export interface ReportLogListRequest {
  page?: number,
  pagesize?: number,
  userid?: number,
  type?: number,
  dates?: string,
}

export class ReportLogListClassRequest {
  page: number = 0;
  pagesize: number = 30;
  userid?: number | null = null;
  type: number | null = null;
  to?: string;
  from?: string;

  dates?: string;
  pageSize: number = 30;
  filter: any = null;
  sortColumn: string = "amount";
  descending: SortDirection = SortDirection.Descending;
  text: string | null = null;
  impid: null = null;
  actionsentid: null = null;
  reason: null = null;
}

export class RequestLogExport {
  type?: number | null;
  from?: string;
  to?: string;
  userid?: string;
}
