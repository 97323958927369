import { useCallback, useEffect, useState } from "react";
import { TranslationService } from "../../../services/TranslationService";
import AccountService from "../../../services/AccountService";
import { SubscriptionResponse } from "../../account/entities/AccountResponse";
import { capitalize } from "../../../utils/FormatUtils";
import FloatingPanelService from "../../shared/FloatingPanel";
import { UserListGetResponse } from "../../user/entities/UserListGetResponse";
import CompanyService from "../../../services/CompanyService";

export const SubscriptionData = () => {
    const [data, setData] = useState<SubscriptionResponse>();
    const [usersData, setUsersData] = useState<UserListGetResponse.User[]>();

    const loadData = async () => {
        const result = await AccountService.getSubscription();
        if (result instanceof Error) {
            return;
        }
        setData(result);
    };

    const loadDataUsers = async () => {
        const result = await AccountService.getUsersByPlan();
        if (result instanceof Error) {
            return;
        }
        setUsersData(result.users);
    };

    const showUsersByPlan = useCallback(() => {
        if (usersData) {
            FloatingPanelService.showPanel({
                children: <UsersByPlan users={usersData} />,
                title: TranslationService.translate.Users + " " + (usersData.length || 0),
                width: 1200,
                height: usersData.length === 0 ? 150 : 650,
                position: "center",
            });
        }
    }, [usersData]);


    useEffect(() => {
        loadData();
        loadDataUsers();
    }, []);


    return (
        <div>
            <div className='d-flex mb-2'>
                <label className="col-3 me-1">{TranslationService.translate.PlanLimit}:</label>
                {TranslationService.translate.Clients}: {data?.clientlimit || capitalize(TranslationService.translate.NoLimit)}s, {TranslationService.translate.Users}: {data?.userlimit || capitalize(TranslationService.translate.NoLimit)}s
            </div>
            <div className='d-flex mb-2'>
                <label className="col-3 me-1">{TranslationService.translate.PlanUse}:</label>
                <span> {TranslationService.translate.Clients}: {data?.clientcount}, <span onClick={showUsersByPlan} className="text-info" style={{ cursor: "pointer" }}>{TranslationService.translate.Users}: {usersData?.length}</span></span>
            </div>
        </div>
    );
};

const UsersByPlan = ({ users }: { users: UserListGetResponse.User[] }) => {

    const roleProfile = (user: UserListGetResponse.User) => {
        let userRoleText = "";
        const ownerId = CompanyService.getCompanyToken()?.ownerId;
        if (ownerId === user.UserID.toString()) {
            userRoleText = TranslationService.translate.Creator;
        } else if (user.ReadOnly) {
            userRoleText = TranslationService.translate.UserRoleReadonly;
        } else if (user.Admin) {
            userRoleText = TranslationService.translate.AdminUser;
        } else {
            userRoleText = TranslationService.translate.Guest;
        }
        return userRoleText + ` ${TranslationService.translate.In} ` + user.CompanyName;
    };

    if (users?.length === 0) {
        return <div className='d-flex mb-2'>
            <h5 className="col m-4">{TranslationService.translate.NotifyUsers}</h5>
        </div>;
    }

    return <div className="floatingBody p-4">
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">{TranslationService.translate.Name}</th>
                    <th scope="col">{TranslationService.translate.User}</th>
                    <th scope="col">{TranslationService.translate.Company}: {TranslationService.translate.Profile}</th>
                </tr>
            </thead>
            <tbody>
                {users
                    .sort((a, b) => (a.UserName.toLowerCase() < b.UserName.toLowerCase()) ? -1 : 1)
                    .map((el, i) =>
                        <tr key={i}>
                            <th scope="row">{i + 1}</th>
                            <td>{el.UserName}</td>
                            <td>{el.UserEmail}</td>
                            <td>{roleProfile(el)}</td>
                        </tr>)}
                <tr />
            </tbody>
        </table>
    </div>;
};
