import CompanyService, { Entities } from '../../../services/CompanyService'
import { TranslationService } from '../../../services/TranslationService';
import { DropdownSelectWrapper } from './OtherConfigurationItems/DropdownSelectWrapper'

type DefaultFieldForSMSType = {
    initialValue?: number;
}

export const DefaultFieldForSMS = ({ initialValue }: DefaultFieldForSMSType) => {
    const listItems = CompanyService.getAdditionalDefinitions()
        .filter(x => x.typeName === 'phone' && x.Entity === Entities.Client)
        .map(x => ({ text: x.Name, value: x.AdditionalDefinitionID }))

    return listItems ? (
        <DropdownSelectWrapper
            initialValue={initialValue}
            items={[...listItems, { text: TranslationService.translate.Phone, value: -1 }]}
            title="DefaultFieldForSMS"
            action="defaultsmsfield" />
    ) : null
}
