import { Link, useLocation } from "react-router-dom";
import intizaLogoPng from "../../assets/img/intizaLogo.png";
import { TranslationService } from "../../services/TranslationService";
import React, { useContext, useMemo } from "react";
import CompanyService from "../../services/CompanyService";
import TabPositionContext from "./TabPositionContext";
import RequestService from "../../services/RequestService";
import { CashappLayoutHeader } from "./layout/CashappLayoutHeader";
import FloatingPanelService from "./FloatingPanel";
import { NewCompany } from "./NewCompany";
import { Counter } from "../inbox/InboxTabContent";
import iconHelp from '../../assets/img/help-svg.svg';

const LayoutHeader: React.FC = () => {
    const { currentTabPosition, inboxCount } = useContext(TabPositionContext);
    const [fName, ...lName] = CompanyService.getUsers().find(x => x.Id === CompanyService.getUserid())?.Value.split(',') || []
    const userCount = CompanyService.getCompanyAuth()?.userCount ?? 0;
    const location = useLocation();

    const linkToV1 = useMemo(() => {
        const baseUrl = `https://www1.intiza.com/account/logontoken?token=${CompanyService.getCompanyAuth()?.companyToken}`
        // process.env.REACT_APP_BASE_SITE_v1 + `account/logontoken?token=${CompanyService.getCompanyAuth()?.companyToken}`

        const returnUrl = location.pathname + location.search;
        return returnUrl ? baseUrl + '&' + returnUrl : baseUrl;
    }, [location.pathname, location.search])
    return (<>
        <header>
            <nav className="navbar navbar-expand-sm bg-white">
                <div className="container-fluid d-flex flex-row flex-nowrap">
                    <Link to={"/"} className="navbar-brand">
                        <img src={CompanyService.getCompanyAuth()?.logoUrl ?? intizaLogoPng} alt="Logo de Intiza" /></Link>
                    {/* 
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button> 
                    */}
                    <div className="navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-0">
                            <NavItem link="company" name={TranslationService.translate.Dashboard} />
                            <NavItem link="client" name={TranslationService.translate.Clients} />
                            <NavItem link="inbox" name={TranslationService.translate.Inbox} counter={inboxCount?.count} />
                            <NavItem link="task" name={TranslationService.translate.Tasks} />
                            <NavItem link="segment" name={TranslationService.translate.Segments} />
                            {CompanyService.getIsAdmin() && <NavItem link="action" name={TranslationService.translate.Automatic} />}
                            <CashappLayoutHeader />
                            {CompanyService.canDo("viewreport") &&
                                <li className={"nav-item dropdown" + (currentTabPosition === "Reports" ? " active" : "")}>
                                    <button className="btn nav-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <p>{TranslationService.translate.Reports} <i className="ms-2 fas fa-chevron-down"></i></p>
                                        <hr />
                                    </button>
                                    <div className="dropdown-menu">
                                        <ul>
                                            <DropdownTitle>{TranslationService.translate.DebtAnalysis}</DropdownTitle>
                                            <DropdownItem link="report/ReportReceivablesSummary" name={TranslationService.translate.CollectionAnalisis} />
                                            <DropdownItem link="report/collection" name={TranslationService.translate.RecoveryAnalysis} />
                                            <DropdownItem link="report/reportaginggroup" name={TranslationService.translate.AgingAnalysis} />
                                        </ul>
                                        <ul>
                                            <DropdownTitle>{TranslationService.translate.Clients}</DropdownTitle>
                                            <DropdownItem link="report/client" name={TranslationService.translate.CustomerAnalysis} />
                                            <DropdownItem link="report/persongroup" name={TranslationService.translate.ClientSummary} />
                                            <DropdownItem link="report/reportclientmap" name={TranslationService.translate.ClientsMap} />
                                            <DropdownItem link="report/credit" name={TranslationService.translate.CreditAnalysis} />
                                        </ul>
                                        <ul>
                                            <DropdownTitle>{TranslationService.translate.Invoices} {TranslationService.translate.And.toLocaleLowerCase()} {TranslationService.translate.Payments}</DropdownTitle>
                                            <DropdownItem link="report/iolist" name={TranslationService.translate.HeaderInvoices} />
                                            <DropdownItem link="report/invoicegroup" name={TranslationService.translate.InvoiceSummary} />
                                            {CompanyService.getSetting("messageiolink") &&
                                                <DropdownItem link="report/ioactivity" name={TranslationService.translate.ReportInvociesActivities} />}
                                            <DropdownItem link="report/payment" name={TranslationService.translate.Transaction} />
                                            <DropdownItem link="report/paymentgroup" name={TranslationService.translate.PaymentSummary} />
                                            <DropdownItem link="report/reportpaymentanalysis" name={TranslationService.translate.PaymentAnalysis} />
                                        </ul>
                                        <ul>
                                            <DropdownTitle>{TranslationService.translate.Activities}</DropdownTitle>
                                            <DropdownItem link="report/reportactivity" name={TranslationService.translate.Activities} />
                                            <DropdownItem link="report/reportactivitysummary" name={TranslationService.translate.ActivitySummary} />
                                            <DropdownItem link="report/activitytypesummary" name={TranslationService.translate.ActivitiesTypes} />
                                            {Boolean(CompanyService.getTags()?.length) && <DropdownItem link="report/taglist" name={TranslationService.translate.Tags} />}
                                        </ul>
                                        <ul>
                                            <DropdownTitle>{TranslationService.translate.Other}</DropdownTitle>
                                            <DropdownItem link="report/reportexport" name={TranslationService.translate.Templates} />
                                            <DropdownItem link="report/scheduled" name={TranslationService.translate.ScheduledReports} />
                                            {TranslationService.getCurrentLanguage() === "es" &&
                                                <DropdownItem link="report/log" name={"Registro"} />}
                                        </ul>
                                    </div>
                                </li>
                            }
                        </ul>
                        <div className="d-flex align-items-center">
                            <a href={'https://ayuda.intiza.com/'} target="_blank" rel='noreferrer'>
                                <img src={iconHelp} alt="help" />
                            </a>
                            <ul className="company-dropdown">
                                <li className="nav-item dropdown">
                                    <button className="btn nav-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <p>{CompanyService.getCompanyAuth()?.companyName}<i className="ms-2 fas fa-chevron-down"></i></p>
                                    </button>
                                    <div className="dropdown-menu">
                                        <ul>
                                            <li>
                                                <div className="companyDropdown__setup">
                                                    <div className="companyDropdown__setup-info">
                                                        <h5 className="companyDropdown__setup-name">{CompanyService.getCompanyAuth()?.companyName}</h5>
                                                        {CompanyService.getIsAdmin()
                                                            &&
                                                            <Link to={`/${TranslationService.currentLanguage}/user`}>
                                                                <div className="companyDropdown__setup-users"><i className="far fa-user-friends me-2"></i><span className="me-1">{userCount}</span><p>{userCount > 0 ? TranslationService.translate.Users : TranslationService.translate.User}</p></div>
                                                            </Link>}
                                                    </div>
                                                    {CompanyService.getIsAdmin() /*&& !CompanyService.getIsConsolidated()*/ && <Link to={`/${TranslationService.currentLanguage}/edit`}><button className="btn-outline-light" type="button"><i className="fal fa-cog"></i><p>{TranslationService.translate.CompanySettings}</p></button></Link>}

                                                </div>
                                            </li>
                                            <li>
                                                {CompanyService.getCompanies().filter(x => x.Id !== CompanyService.getCompanyId()).map(x => <CompanyItem {...x} key={x.Id} />)}
                                            </li>
                                            {CompanyService.canDo("newcompany") && <li>
                                                <Link to={"#"} className="addCompany" href="#" onClick={() => FloatingPanelService.showPanel({
                                                    children: <NewCompany />,
                                                    title: TranslationService.translate.NewCompany,
                                                    height: 300,
                                                    width: 600,
                                                    position: "center",
                                                })}>
                                                    <h5><i className="fas fa-plus me-2"></i>{TranslationService.translate.AddCompany}</h5>
                                                </Link>
                                            </li>}
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                            <ul className="userProfile-dropdown">
                                <li className="nav-item dropdown">
                                    <button type="button" className="btn nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <p>{lName + ' ' + fName}<i className="ms-2 fas fa-chevron-down"></i></p>
                                    </button>
                                    <div className="dropdown-menu">
                                        <ul>
                                            {!!CompanyService.getCompanyId() && <li>
                                                <Link to={`/${TranslationService.currentLanguage}/account/edit`} className="userProfile-dropdown-account" href="#">
                                                    <i className="far fa-user me-2"></i>
                                                    <p>{TranslationService.translate.MyAccount}</p>
                                                </Link>
                                            </li>}

                                            {CompanyService.getVersionAllowed() === 3 &&
                                                <a
                                                    href={linkToV1}
                                                    className="userProfile-dropdown-account" >
                                                    <i className="far fa-clock me-2"></i>
                                                    <p>{TranslationService.translate.BackToV1}</p>
                                                </a>}

                                            {CompanyService.getIsInvited() && CompanyService.getHasSegments() && CompanyService.getSetting("allowsegmentfiltertoggle") && <li>
                                                <div className="userProfile-dropdown-account" onClick={async e => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    await RequestService.togglefilteruseridpaused(!CompanyService.getFilterUserIdPaused());
                                                    window.location.reload();
                                                }}>
                                                    <div className="form-check pl-0">
                                                        <label className="form-check-label font-weight-normal">
                                                            <input className="form-check-input me-2" type="checkbox" defaultChecked={CompanyService.getFilterUserIdPaused()} /><p>{TranslationService.translate.ViewAllSegments}</p>
                                                        </label>
                                                    </div>
                                                </div>
                                            </li>}
                                            <li>
                                                <Link to={`/${TranslationService.currentLanguage}/logout`} className="userProfile-dropdown-exit" href="#">
                                                    <i className="far fa-sign-out me-2"></i>
                                                    <p>{TranslationService.translate.Logout}</p>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header >
    </>);
};

function getLinkTo(link: string) {
    return "/" + TranslationService.currentLanguage + "/" + link;
}

const NavItem = ({ link, name, counter }: { link: string, name: string, counter?: number }) => {
    const { currentTabPosition } = useContext(TabPositionContext);
    const current = currentTabPosition === link;

    return (
        <li className={"nav-item" + (current ? " active" : "")}>
            <Link to={getLinkTo(link)} className="nav-link" aria-current="page" >
                {counter ?
                    <>
                        <div className="link-content container-counter-tab ">
                            <p id='pCounter'>{name}</p>
                            <Counter counter={counter} />
                        </div>
                        <hr id='hrCounter' />
                    </>
                    :
                    <>
                        <p>{name}</p>
                        <hr />
                    </>
                }
            </Link>
        </li>
    )
};

const CompanyItem = ({ Id, Value }: { Id: string, Value: string }) => {
    return (
        <Link to={`/${TranslationService.currentLanguage}/switchcompany?to=${Id}`} className="changeCompany">
            <div className="changeCompany__name">
                <h5>{Value}</h5>
                {false && CompanyService.getIsAdmin() &&
                    <p><span className="me-1">51</span>{TranslationService.translate.Users}</p>}
            </div>
        </Link>);
};

export const LayoutHeaderDropdownItem = ({ link, name }: { link: string, name: string }) =>
(<li><Link to={getLinkTo(link)} onClick={(e) => {
    if (link === "") {
        alert("not implemented");
        e.preventDefault();
        e.stopPropagation();
        return false;
    }
}} className="dropdown-item">{name}</Link></li>);

const DropdownItem = LayoutHeaderDropdownItem;

export const LayoutHeaderDropdownTitle = (props: React.PropsWithChildren<unknown>) =>
    (<li><h6 className="dropdown-item">{props.children}</h6></li>);

const DropdownTitle = LayoutHeaderDropdownTitle;

export default LayoutHeader;