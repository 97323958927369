import { CSSProperties, useState } from "react";
import { TranslationService } from "../../../services/TranslationService";

const SearchInput = ({ onSearch, style, autosearch = true, placeholder = undefined, divClass = "" }: { onSearch: (value: string) => void, style?: CSSProperties, autosearch?: boolean, placeholder?: string, divClass?: string }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const search = (value?: string) => {
        onSearch(value ?? searchTerm);
    };
    const onChange = (value: string) => {
        setSearchTerm(value);
        if (autosearch) {
            search(value);
        }
    };
    return (
        <div className={"search-input input-group " + divClass} >
            <button className="btn btn-outline-secondary" type="button" onClick={() => search()}><i className="far fa-search"></i></button>
            <input type="text" style={style} className="form-control " placeholder={placeholder ?? TranslationService.translate.ApplySearch} onKeyPress={event => event.key === "Enter" && search()} onChange={(e) => onChange(e.target.value)}></input>
        </div>
    );
};

export default SearchInput;