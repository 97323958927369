import ActivityService from "../../../../services/ActivityService";
import { TranslationService } from '../../../../services/TranslationService';
import { ActivityItemTemplate } from "../ActivityHistory";
import { ActivityListResponse } from "../entities/AcitivityListResponse";
import { ActivityHistoryType } from "../../../shared/enums";
import { ToastService } from "../../../shared/bootstrap/Toast";
import ModalService from "../../../shared/bootstrap/Modal";

export const ActivityWhatsapp = ({ data, reload }: { data: ActivityListResponse.Item, reload: () => void }) => {
    const { translate } = TranslationService;
    const onReadMoreClicked = async (id: number) => {
        const result = await ActivityService.getEmailContent({ id: id.toString(), noheader: "0" });
        if (result instanceof Error) {
            ToastService.showToast(translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
        ModalService.showDefaultModal({
            message: () => <div style={{ overflow: "auto" }} dangerouslySetInnerHTML={{ __html: result.content }}></div>,
            title: translate.Email,
            size: "modal-xl",
        });
    }

    const content = <div>
        <div style={{ overflow: "auto" }} dangerouslySetInnerHTML={{ __html: data.Content }}></div>
        {data.HasMoreContent && <button className="btn btn-intiza ps-0 mt-1" onClick={() => onReadMoreClicked(data.ID)}>{translate.ReadMore}</button>}
    </div>

    return (
        <ActivityItemTemplate data={data} type={ActivityHistoryType.whatsapp} date={new Date(data.date)} creator={data?.From || data.Username} content={content} subject={data.Subject} actions={[]} to={data.To} />
    )
}