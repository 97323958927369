import CompanyService from "../../../services/CompanyService";
import { formatDateTimeDigits } from "../../../utils/FormatUtils";
import { TranslationService } from "../../../services/TranslationService";
import { ActivityListResponse } from "../activity/entities/AcitivityListResponse";
import { FloatingPanelFooter } from "../../shared/components/FloatingPanelFooter";
import { restTimezone } from "../../../utils/ParseUtils";

export const SMSView = ({ data }: { data: ActivityListResponse.Item }) => {
    const { translate } = TranslationService;
    return (
        <>
            <div className="floatingBody p-4">
                <div className="d-flex input-row mb-3">
                    <label className="form-label">
                        {CompanyService.getGroupName()}
                    </label>
                    <p className="form-label-detail">
                        {data.groupName ?? "-"}
                    </p>
                </div>
                <div className="d-flex input-row mb-3">
                    <label className="form-label">{translate.Date}</label>
                    <p className="form-label-detail">
                        {formatDateTimeDigits(restTimezone(new Date(data.date)))}
                    </p>
                </div>
                <div className="d-flex input-row mb-3">
                    <label className="form-label">{translate.Telephone}</label>
                    <p className="form-label-detail">
                        {data.smsphone}
                    </p>
                </div>
                {data.Tags && data.Tags.length > 0 &&
                    <div className="d-flex input-row mb-3">
                        <label className="form-label">{translate.Tags}</label>
                        <p className="form-label-detail">
                            <input type="text" readOnly className="form-control-plaintext" value={data.Tags?.map(x => x.Name).join(", ")} />
                        </p>
                    </div>}
                <div className="d-flex input-row mb-3">
                    <label className="form-label">{translate.Message}</label>
                    <p className="form-label-detail">
                        {data.Content}
                    </p>
                </div>
            </div>
            <FloatingPanelFooter>
            </FloatingPanelFooter>
        </>
    );
};