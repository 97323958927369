import { InvoiceGetResponse } from "./InvoiceGetResponse";

export class InvoiceEditStatus {
    additionals: AdditionalFieldValue[] = [];
    statusId?: number;
    userId?: string;

    constructor(loggedUserId?: string, statusId?: number, invoice?: InvoiceGetResponse.Item) {
        this.userId = loggedUserId;
        this.statusId = statusId;
        this.additionals = invoice?.additionals.map(x => ({ Id: parseInt(x.Id), Value: x.Value })) ?? [];
    }
}

class AdditionalFieldValue {
    Id: number = 0;
    Value: string = '';
}