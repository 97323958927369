import { ConfigItem } from "../pages/shared/entities/Company";
import { throttleCached } from "../utils/FuncUtils";
import { ForceAssing } from "../utils/TypeUtils";
import RequestService from "./RequestService";

class FieldConfigurationService {
    public static getAdditionalDefinition(id: number): Promise<AdditionalDefinitionsGetResponse | Error> {
        return RequestService.post("/AdditionalDefinition/get", { id });
    }
    public static getAdditionalDefinitionList(): Promise<AdditionalDefinitionsListResponse | Error> {
        return throttleCached(FieldConfigurationService.getAdditionalDefinitionListImpl);
    }
    private static getAdditionalDefinitionListImpl(): Promise<AdditionalDefinitionsListResponse | Error> {
        return RequestService.post("/AdditionalDefinition/list", {});
    }
    public static setAdditionalDefinition(definition: AdditionalDefinitionsListResponse.Item): Promise<AdditionalDefinitionsGetResponse | Error> {
        return RequestService.post("/AdditionalDefinition/set", { response: "ok", item: definition });
    }
    public static deleteAdditionalDefinition(id: number): Promise<unknown | Error> {
        return RequestService.post("/AdditionalDefinition/delete", { id });
    }
    public static setFieldsOrder(sortedFields: number[], entity: number) {
        return RequestService.post("/company/updatesorting", { entity, sortedFields });
    }
    public static setFixedDefinition(definition: ConfigItem) {
        const Uncapitalize = (text: string) => text.slice(0, 1).toLowerCase() + text.slice(1);
        Object.keys(definition).forEach(key => ForceAssing(definition, Uncapitalize(key), definition[key as keyof ConfigItem]));
        return RequestService.post("/field/set", { response: "ok", item: definition });
    }
}
export default FieldConfigurationService;

export interface AdditionalDefinitionsListResponse {
    Response: string
    list: AdditionalDefinitionsListResponse.Item[]
}

export interface AdditionalDefinitionsGetResponse {
    Response: string
    item: AdditionalDefinitionsListResponse.Item
}

export namespace AdditionalDefinitionsListResponse {
    export interface Item {
        IsPK: boolean
        typeName: string
        type: number
        TypeName: string
        entity: number
        AdditionalDefinitionID: number
        CreatorUserID: number
        Created: string
        Type: number
        Name: string
        Field: number
        Entity: number
        LinkText?: string
        IncludeInList: boolean
        ShowToClient: boolean
        ShowAlways?: boolean
        IncludeInClientExport: boolean
        Width?: number
        ManualEdit?: boolean
        HasIODependency: boolean
        ExcludeInFilters?: boolean
        PublicName?: string
        LanguageNames?: string
        AutoPopulate?: boolean
        AdditionalDefinitionID_ref: any
        Hidden?: boolean
        Whatsapp?: boolean
        Summarize?: boolean
        Right?: boolean
        IncludeInCustomerPortal?: boolean
        IOStatusDependencies: IostatusDependency[]
        AdditionalDefinitionItems: AdditionalDefinitionItem[]
    }

    export interface AdditionalDefinitionItem {
        AdditionalDefinitionItemID: number
        AdditionalDefinitionID: number
        Value: string
        Default?: boolean
        ImportID?: number
        AdditionalDefinitionItemID_ref: any
    }

    export interface IostatusDependency {
        IOStatusDependencyID: number
        IOStatusID: number
        AdditionalDefinitionID: number
        Mandatory: boolean
        Public: boolean
        IOStatusDependencyID_ref: any
    }

}
