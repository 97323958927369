import { TranslationService } from '../../../services/TranslationService';

function ErrorMessage(props: any): JSX.Element {
    const { translate } = TranslationService;
    return (<>
        <div className={props.className + " h-50 d-flex align-items-center justify-content-center"}>
            <div className="messageSwichContent text-center">
                <p className="mb-2"><i className="fal fa-exclamation-square fa-3x"></i></p>
                <p className="lead">{props.message}</p>
                <p style={{ display: 'flex', justifyContent: 'center' }}><button className="btn btn-primary" onClick={(e) => {
                    e.preventDefault();
                    if (props.onRefresh) {
                        props.onRefresh();
                    }
                }}>{translate.Retry}</button></p>
            </div>
        </div >
    </>);
}

export default ErrorMessage;