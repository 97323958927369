import { SortDirection } from "../../shared/entities/Sort";

export class CustomerAnalysisListRequest {
    page: number = 0
    descending: SortDirection = SortDirection.Descending;
    sortColumn: string = "amount";
    pageSize: number = 15;

    dateFrom: string = '20231201';
    dateTo: string = '20231201';
    filter: any = null;
    text: string | null = null;
    impid: null = null;
    actionsentid: null = null;
    reason: null = null;
    pagesize: number = 15;
}