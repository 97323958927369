import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Dropdown from "../../../../shared/components/Dropdown";
import { ImportTemplateListResponse } from "../../../../shared/entities/ImportTemplateListResponse";
import { TranslationService } from "../../../../../services/TranslationService";
import { sheetColumns } from "../ImportTemplateEditBase";
import DictionaryService from "../../../../../services/DictionaryService";
import { DictionaryListResponse } from "../../../../../entities/dictionary/DictionaryListResponse";

const Dictionaries = ({ data, model, setModel }: { data?: ImportTemplateListResponse.Item, model: Partial<ImportTemplateListResponse.Item>, setModel: Dispatch<SetStateAction<Partial<ImportTemplateListResponse.Item>>> }) => {
    const { translate } = TranslationService;
    const [dictionaries, setDictionaries] = useState<DictionaryListResponse.Dictionary[]>([]);
    const [dictionaryToAdd, setDictionaryToAdd] = useState<ImportTemplateListResponse.ImportTemplateDictionary>({ ImportTemplateID: data?.ImportTemplateID } as ImportTemplateListResponse.ImportTemplateDictionary);

    useEffect(() => {
        const getDictionaries = async () => {
            const result = await DictionaryService.getList();
            if (result instanceof Error) {
                return;
            }
            setDictionaries(result.list);
        }
        getDictionaries();
    }, []);
    const notFoundActions = [{ value: 0, text: translate.OnlyIncludeFoundRows }, { value: 2, text: translate.IncludeMissingRows }, { value: 1, text: translate.OnlyIncludeRowsNotFound },];

    const addCurrentDictionary = () => {
        if (!dictionaryToAdd.ImportDictionary) {
            return;
        }
        dictionaryToAdd.Column = dictionaryToAdd.Column ?? sheetColumns[0].value;
        dictionaryToAdd.NotFoundAction = dictionaryToAdd.NotFoundAction ?? notFoundActions[0].value;
        dictionaryToAdd.notFoundAction = dictionaryToAdd.NotFoundAction;
        const ImportTemplateDictionaries = model.ImportTemplateDictionaries ? [...model.ImportTemplateDictionaries] : [];
        ImportTemplateDictionaries.push({ ...dictionaryToAdd });
        setModel(x => ({ ...x, ImportTemplateDictionaries }));
    };


    return (<>
        <h5>{translate.Dictionaries}</h5>
        <table className="table shadow-hover">
            <thead>
                <tr>
                    <th>{translate.Dictionary}</th>
                    <th>{translate.Column}</th>
                    <th>{translate.Action}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {model?.ImportTemplateDictionaries?.map((x, i) => (
                    <tr key={i} className="show-child-on-hover">
                        <td>{x.ImportDictionary.Name}</td>
                        <td>{sheetColumns.find(y => y.value === x.Column)?.text}</td>
                        <td>{notFoundActions.find(y => y.value === x.NotFoundAction)?.text}</td>
                        <td className="show-when-hovering-parent"><i className={"pointer fas fa-fw fa-trash text-danger"} onClick={() => setModel(x => ({ ...x, ImportTemplateDictionaries: x.ImportTemplateDictionaries?.filter((x, index) => index !== i) }))} /></td>
                    </tr>
                ))}
                <tr className="bb-0 dont-shadow" >
                    <td className="border-bottom-0"><Dropdown onChange={(value) => setDictionaryToAdd(x => ({ ...x, ...value, ImportDictionary: value! }))} items={dictionaries.map(x => ({ text: x.Name, value: x, key: x.ImportDictionaryID }))} optionLabel={TranslationService.translate.Select} /></td>
                    <td className="border-bottom-0"><Dropdown onChange={(value: number) => setDictionaryToAdd(x => ({ ...x, Column: value }))} items={sheetColumns} /></td>
                    <td className="border-bottom-0"><Dropdown onChange={(value: number) => setDictionaryToAdd(x => ({ ...x, NotFoundAction: value }))} items={notFoundActions} /></td>
                    <td className="border-bottom-0"><button className="btn btn-link" onClick={addCurrentDictionary}>{translate.Add}</button></td>
                </tr>
            </tbody>
        </table>
    </>)
}

export default Dictionaries;