import { useContext, useEffect, useState } from "react";
import CompanyService from "../../../services/CompanyService";
import { isNullOrWhitespace } from "../../../utils/Utils";
import TabPositionContext from "../TabPositionContext";
import { TranslationService } from "../../../services/TranslationService";
import { LayoutHeaderDropdownItem } from "../LayoutHeader";
import CashappService from "../../../services/CashappService";

export const CashappLayoutHeader = () => {
    const { currentTabPosition } = useContext(TabPositionContext);
    const [items, setItems] = useState<{ Key: string, Value: string }[] | null>([]);
    const showHeader = !isNullOrWhitespace(CompanyService.getCompanyToken()?.cashappid);
    useEffect(() => {
        if (!showHeader) {
            return;
        }
        const getData = async () => {
            const result = await CashappService.getHeaderItems();
            if (result instanceof Error) {
                setItems(null);
            } else {
                setItems(result);
            }
        };
        getData();
    }, [showHeader]);
    if (!showHeader || items === null) {
        return <></>;
    }
    return (
        <li className={"nav-item dropdown" + (currentTabPosition === "cashapp" ? " active" : "")}>
            <button className="btn nav-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <p>{TranslationService.translate.Reconciliations} <i className="ms-2 fas fa-chevron-down"></i></p>
                <hr />
            </button>
            <div className="dropdown-menu" id="dropdown-menu-conciliaciones">
                <ul>
                    {items.map(x => <LayoutHeaderDropdownItem key={x.Value} link={"cashapp?url=" + x.Value} name={x.Key} />)}
                </ul>
            </div>
        </li>
    );
};