import { useContext, useState } from "react";
import { TranslationService } from '../../../services/TranslationService';
import ToastContext from "../../shared/bootstrap/Toast";
import FloatingPanelService from "../../shared/FloatingPanel";
import ModalService from "../../shared/bootstrap/Modal";
import DashboardPanelService from "../../../services/DashboardPanelService";
import { DashboardPanelEntity } from "../../../entities/company/DashboardPanel/DashboardPanelEntity";
import { TextEditor } from "../../shared/components/Editors";
import { RequiredManager, ValidationMessage } from "../../shared/RequieredManager";
import { parseIntOrDefault } from "../../../utils/ParseUtils";
import { FloatingPanelFooter } from "../../shared/components/FloatingPanelFooter";


const DashboardPanelEdit = ({ reload, current = undefined }: { reload: () => void, current?: DashboardPanelEntity }) => {
    const { translate } = TranslationService;
    const [submiting, setSubmiting] = useState("");
    const { showToast } = useContext(ToastContext);
    const [name, setName] = useState(current?.Name ?? "");

    const requiredManager = new RequiredManager();

    const onNameChanged = requiredManager.makeRequired(setName);

    const onSubmit = async () => {
        if (submiting !== "") {
            return;
        }

        if (!requiredManager.validate()) {
            showToast(translate.MissingRequiredFields);
            return;
        }

        setSubmiting("set");
        const result = await DashboardPanelService.set({
            DashboardPanelID: current?.DashboardPanelID ?? 0,
            Name: name
        });
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            setSubmiting("");
            return;
        }
        setSubmiting("");
        FloatingPanelService.hidePanel();
        showToast(translate.DashboardPanelSaved, undefined, "success");
        reload();
    }

    const onDelete = () => {
        if (submiting !== "") {
            return;
        }
        const deleteDashboardPanel = async () => {

            setSubmiting("del");
            const result = await DashboardPanelService.delete(current?.DashboardPanelID!);
            if (result instanceof Error) {
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
                setSubmiting("");
                return;
            }
            setSubmiting("");
            FloatingPanelService.hidePanel();
            showToast(translate.DashboardPanelDeleted, undefined, "success");
            reload();
        }
        ModalService.showDefaultModal({
            acceptButtonLabel: translate.Delete,
            message: translate.AreYouSureDeletePanel,
            onAcceptClick: deleteDashboardPanel,
        })
    };

    const onClone = () => {
        const cloned = async () => {
            FloatingPanelService.hidePanel();
            ModalService.hideModal();
            showToast(translate.DashboardPanelCloned, undefined, "success");
            reload();
        }
        const cloneError = () => {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
        }
        ModalService.showModal(<ModalClone dashboardPanelId={current!.DashboardPanelID} onCloned={cloned} onCloneError={cloneError}></ModalClone>);
    }

    return (
        <>
            <div className="floatingBody p-4">
                <div className="card-body">
                    <div className="form-group mb-3">
                        <label>{translate.Name}</label>
                        <TextEditor onChange={onNameChanged} defaultValue={name} />
                        {<ValidationMessage onChange={onNameChanged} defaultValue={name} />}
                    </div>
                </div>
            </div>

            <FloatingPanelFooter>
                {current && <button type="button" className="btn btn-danger btn-sm" onClick={onDelete}>
                    {translate.Delete}
                    {submiting === "del" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>}

                {current &&
                    <button type="button" className="btn btn-primary btn-sm" onClick={onClone}>{translate.Clone}</button>
                }

                <button className="btn btn-primary btn-sm" onClick={onSubmit}>
                    {translate.Save}
                    {submiting === "set" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </FloatingPanelFooter>
        </>
    );
}

export default DashboardPanelEdit;

const ModalClone = ({ dashboardPanelId, onCloned, onCloneError }: { dashboardPanelId: number, onCloned: () => void, onCloneError: () => void }) => {
    const { translate } = TranslationService;
    const [submiting, setSubmiting] = useState(false);
    const [qty, setQty] = useState(1);

    const clone = async () => {

        if (submiting) {
            return;
        }
        setSubmiting(true);
        const result = await DashboardPanelService.clone(dashboardPanelId, qty);
        setSubmiting(false);
        if (result instanceof Error) {
            onCloneError();
            return;
        }
        onCloned();
    }

    return (
        <>
            <div className="modal-header">
                <h5 className="modal-title">{translate.Clone}</h5>
            </div>
            <div className="modal-body">
                <div className="row mb-1">
                    <label className="col-4 font-weight-normal col-form-label">{translate.QtyToClone}:</label>
                    <div className="col-3">
                        <input type="text" className="form-control" value={qty} onChange={(e) => setQty(parseIntOrDefault(e.target.value, 1))}></input>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                <button type="button" className="btn btn-primary" onClick={clone}>
                    {submiting && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                    {translate.Clone}
                </button>
            </div>
        </>
    )
}
