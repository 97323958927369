import { ReactElement, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { TranslationService } from '../../services/TranslationService';
import AuthenticationContext from './AuthenticationContext';
import { Subject } from 'rxjs';

export const onClearCacheSubject = new Subject();
export const onClearCache = onClearCacheSubject.asObservable();
function Logout(props: { lang: string }): ReactElement {
    const { doLogout } = useContext(AuthenticationContext);
    const history = useHistory();
    useEffect(() => {
        doLogout();
        onClearCacheSubject.next(false);
        history.replace(`/${TranslationService.currentLanguage}/logon`);
    })
    return (
        <></>
    );
}

export default Logout;