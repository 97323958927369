import { useContext } from "react";
import ClientService from "../../../services/ClientService";
import CompanyService from "../../../services/CompanyService";
import { TranslationService } from "../../../services/TranslationService";
import { DefaultModalProps } from "../../shared/bootstrap/DefaultModal";
import ModalService from "../../shared/bootstrap/Modal";
import TableContext from "../../task/TableContext";
import { ClientTableContextValues } from "../providers/ClientTableProvider";
import ProcessService from "../../../services/ProcessService";

export const DeleteAllClients = () => {
    const { loading, request, response, error } = useContext(TableContext) as ClientTableContextValues;
    const { translate } = TranslationService;
    const deleteClients = () => {
        const message = (response?.itemCount === 1 ? translate.AreYouSureDeleteClient : translate.AreYouSureDeleteClients).replaceAll("{0}", response?.itemCount?.toString() ?? "0");
        const modalProps: DefaultModalProps = {
            message: () => <> {message.split("\n").map(x => (
                <><span>{x}</span><br /></>
            ))}</>,
            acceptButtonLabel: translate.Accept,
            onAcceptClick: async () => {
                ProcessService.showResultToast(ClientService.deleteList(request.text, request.segmentid, request.filter));
            }
        }
        ModalService.showDefaultModal(modalProps);
    }
    if (response === undefined || loading || error ||
        !CompanyService.canDo("deleteperson") ||
        CompanyService.getSetting("hidedeletepersonlist") ||
        (!request?.filter?.length && !request?.text?.length)) {
        return <></>;
    }
    return (
        <div className="row">
            <div className="col-auto">
                <button type="button" className="btn btn-intiza" onClick={deleteClients}>{translate.DeleteClientsInList}</button>
            </div>
        </div>
    );
}