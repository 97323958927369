import { TranslationService } from "../../../../services/TranslationService";
import { getEnumArray } from "../../../../utils/EnumUtils";
import Dropdown from "../../../shared/components/Dropdown";
import { EditProps } from "../EditAction";
import { LetterFormatOptions } from "./EmailComponents";
import { Field } from "./FieldHelpers";

export const LetterFormat = (props: EditProps) => {
    const { propGen } = props;
    const items = getEnumArray(LetterFormatOptions);

    return (<>
        <div className="row">
            <Field title={TranslationService.translate.Format}>
                <Dropdown items={items} {...propGen.num.notRequired("Letter_Format")} />
            </Field>
        </div>
    </>); 
}
