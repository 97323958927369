import { useState } from "react";
import Dropdown from "../../../../shared/components/Dropdown";
import { TextEditor } from "../../../../shared/components/Editors";
import { ImportTemplateListResponse } from "../../../../shared/entities/ImportTemplateListResponse";
import { TranslationService } from "../../../../../services/TranslationService";
import { UpdateModelFunc } from "../ImportTemplateEditBase";
import EditItem from "./EditItem";

const SeparatorWithFixedColumns = ({ data, updateModel, model }: { data?: ImportTemplateListResponse.Item, updateModel: UpdateModelFunc, model: Partial<ImportTemplateListResponse.Item> }) => {
    const { translate } = TranslationService;
    const [fixedColumns, setFixedColumns] = useState(!!data?.FixedColumns);
    return (
        <div className="row flex-nowrap">
            <div className="col">
                <EditItem label={<>{translate.Separator} <i className="fal fa-question-circle px-2" title={translate.ThisConfigurationAppliesOnlyToPlainTextFiles} /></>} ><Dropdown onChange={(val) => {setFixedColumns(x => !x); updateModel(val === "1" ? "Separator" :"FixedColumns")("")}} defaultValue={fixedColumns ? "1" : "0"} items={[{ value: "0", text: translate.SeparatorCharacter }, { value: "1", text: translate.FixedWidthColumns },]} /></EditItem>
            </div>
            <div className="col">
                <EditItem label={fixedColumns ? translate.FixedWidthColumns : translate.Separator} >
                    <div className={!fixedColumns ? "row" : undefined}>
                        <div className={!fixedColumns ? "col-6" : undefined}>
                            <TextEditor onChange={fixedColumns ? updateModel("FixedColumns") : updateModel("Separator")} defaultValue={fixedColumns ? data?.FixedColumns : data?.Separator} />
                        </div>
                        <div className={!fixedColumns ? "col-6 col-form-label" : "d-none"}>
                            Tab = "T"
                        </div>
                    </div>
                </EditItem>
            </div>
        </div>
    )
}

export default SeparatorWithFixedColumns;