import { useCallback, useEffect, useState } from "react";
import { DashboardEntity } from "../../../entities/company/Dashboard/DashboardEntity";
import DashboardService from "../../../services/DashboardService";
import Loading from "../../shared/components/Loading";
import { TranslationService } from '../../../services/TranslationService';
import { DashboardDebt, DashboardDebtResponse } from "../../../entities/company/Dashboard/DashboardDebtResponse"
import { formatInteger } from "../../../utils/FormatUtils";
import { FilterRequest } from "../../../entities/Filter/FilterRequest";

const CardChart = ({ dashboard, dashboardFilters }: { dashboard: DashboardEntity, dashboardFilters: FilterRequest[] }) => {
    const { translate } = TranslationService;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [response, setResponse] = useState<DashboardDebt>();

    const requestData = async () => {
        setLoading(true);
        const result = await DashboardService.calculate(dashboard.DashboardID, dashboardFilters);
        setLoading(false);
        if (result instanceof Error) {
            setError(true);
            return;
        }
        setError(false);
        const card = result as DashboardDebtResponse;
        if (card.list && card.list.length > 0) {
            setResponse(card.list[0]);
        }
    }
    const requestDataCallback = useCallback(requestData, [dashboard.DashboardID, dashboardFilters]);

    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);


    if (loading) {
        return (<Loading height={100} />)
    }

    if (error || !response) {
        return (<></>)
    }

    return (
        <div className="card">
            <div className="card-body">
                <h2>{response.name}: <strong>{formatInteger(response.y)}</strong> {translate.Days}</h2>
            </div>
        </div>
    )
}
export default CardChart;

