import React, { useState } from "react";
import { SegmentModel } from "../EditSegment";

export enum Operator {
    Equal = 0,
    Lower,
    Greater,
    Contains,
    DoesntContain,
    NotEqual,
    " value missing ",
    Empty,
    NotEmpty,
}

export type CriterionModel = {
    key: string,
    highlight: boolean,
    Field: number,
    Operator: Operator,
    Value: string,
    IDs?: string,
    Data: number,
    ConditionOR: boolean,
    ActivityTypes: string,
    amount?: string,
};

export enum CriterionType {
    Client = -1,
    Invoice = -2,
    ActivityType = -3,
    Number_Amount = 3,
    Date = 2,
    List = 1,
}

type ICriteriaContext = {
    criteria: CriterionModel[],
    addCriterion: (criterion: CriterionModel) => void,
    updateCriterion: <T extends keyof CriterionModel,>(id: string, key: T, value: CriterionModel[T]) => void,
    deleteCriterion: (id: string) => void
}

const CriteriaContext = React.createContext({} as ICriteriaContext);

const CriteriaProvider = ({ children, data, setData }: React.PropsWithChildren<{ data: SegmentModel, setData: React.Dispatch<React.SetStateAction<SegmentModel>> }>) => {
    const [criteria, setCriteria] = useState<CriterionModel[]>(data.Criteria ? data.Criteria : [])

    const updateAllCriteria = (newCriteria: CriterionModel[]) => {
        setData(x => ({ ...x, Criteria: newCriteria }));
        setCriteria(newCriteria);
    }

    const addCriterion = (criterion: CriterionModel) => {
        updateAllCriteria(criteria.concat(criterion));
    }

    const updateCriterion = <T extends keyof CriterionModel,>(id: string, key: T, value: CriterionModel[T]) => {
        const newCriteria = [...criteria];
        newCriteria.forEach((element: any) => {
            if (element.key === id) {
                element[key] = value
            }
        });
        updateAllCriteria(newCriteria);
    }

    const deleteCriterion = (id: string) => {
        const newCriteria = criteria.filter((item: any) => item.key !== id)
        if (newCriteria.length === 1) {
            newCriteria[0].ConditionOR = false
        }
        updateAllCriteria(newCriteria);
    }
    const value: ICriteriaContext = { criteria, addCriterion, updateCriterion, deleteCriterion };
    return (
        <CriteriaContext.Provider value={value}>{children}</CriteriaContext.Provider>
    )
}

export { CriteriaProvider };
export default CriteriaContext