import React, { useState, useEffect, useRef, ChangeEvent, Dispatch, SetStateAction, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { TranslationService } from "../../../services/TranslationService";
import ErrorMessage from "../../shared/components/Error";
import CompanyService from "../../../services/CompanyService";
import { endpointRequest, useRequestAborter } from "../../../utils/RequestUtils";
import ActionService, { ActionType } from "../../../services/ActionService";
import { ActionGetResponse, ActionRun } from "../entities/ActionGetResponse";
import { Cast, MapIf, OptionalMap, PickByValue, Switch } from "../../../utils/Utils";
import Breadcrumb from "../../shared/Breadcrumb";
import { useQuery } from "../../../utils/ReactUtils";
import {
    DefaultSections, CustomEmailSections, InvoiceEmailSections, TaskSections, SmsSections,
    ClientListSections, InvoiceListSections, LetterSections, WhatsAppSections
} from "./SectionsData";
import { ListSections } from "./ListSections";
import { ListTitles } from "./ListTitles";
import { RequiredManager, ValidationMessage } from "../../shared/RequieredManager";
import { AdditionalDefinitions } from "./components/AdditionalDefinitions";
import Loading from "../../shared/components/Loading";
import ModalService, { ModalParams } from "../../shared/bootstrap/Modal";
import TooltipComponent from "../../shared/TooltipComponent";
import { parseIntOrDefault, validateMailOrEmpty } from "../../../utils/ParseUtils";
import ProcessService from "../../../services/ProcessService";

export type FieldKey<T> = keyof PickByValue<ActionGetResponse["item"], T | undefined>

export type defaultProps<T extends string | number> = {
    defaultValue: T,
    onChange: (value: T | undefined) => void,
}

type propGenerator<T extends string | number> = (key: FieldKey<T>) => defaultProps<T>;

const defaultGenerator = <TType extends string | number>(
    data: Partial<ActionGetResponse.Action>,
    setField: (field: FieldKey<TType>) => (value: TType | undefined) => void) =>
    (key: FieldKey<TType>): defaultProps<TType> => {
        return {
            defaultValue: data[key] as TType,
            onChange: setField(key),
        };
    };

const defaultValidatedGenerator = <TType extends string | number>(
    data: Partial<ActionGetResponse.Action>,
    setField: (field: FieldKey<TType>) => (value: TType | undefined) => void,
    requiredManager: RequiredManager) =>
    (key: FieldKey<TType>) => {
        const props = defaultGenerator(data, setField)(key);
        props.onChange = requiredManager.makeRequiredWithId(props.onChange, key);
        return { ...props, validationMethod: (x: TType) => ![undefined, null, NaN, ""].includes(x) };
    };

export const strEvent = (props: {
    onChange: (value: string) => void,
    defaultValue: string,
}) => {
    return {
        defaultValue: props.defaultValue,
        onChange: (event: ChangeEvent<HTMLInputElement>) => {
            props.onChange(event.target.value);
        }
    };
};

export const numEvent = (props: {
    onChange: (value: number) => void,
    defaultValue: number,
}) => {
    return {
        defaultValue: props.defaultValue,
        onChange: (event: ChangeEvent<HTMLInputElement>) => {
            props.onChange(event.target.valueAsNumber);
        },
        type: "number",
    };
};

type requiredOrNot<T extends string | number> = {
    required: propGenerator<T>,
    notRequired: propGenerator<T>,
}
type PropsGenerators = {
    str: requiredOrNot<string>,
    num: requiredOrNot<number>,
}

export type EditProps = {
    data: Partial<ActionGetResponse.Action>;
    setField: <T extends keyof ActionGetResponse.Action>(field: T) =>
        (value: Partial<ActionGetResponse.Action>[T]) => void;
    setData: Dispatch<SetStateAction<Partial<ActionGetResponse.Action>>>;
    propGen: PropsGenerators;
    requiredManager: RequiredManager;
    whatsappSectionEmail?: boolean;
}

export type SectionsData = {
    name: string | undefined;
    Component: (props: EditProps) => JSX.Element;
}[];

export type TitleRefs = {
    headings: HTMLHeadElement[],
    listItems: HTMLLIElement[],
}

const EditAction: React.FC = () => {
    const { translate, getTranslation } = TranslationService;
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Partial<ActionGetResponse["item"]>>({} as Partial<ActionGetResponse["item"]>);
    const titleRefs = useRef<TitleRefs>({ headings: [], listItems: [] });
    const requiredManager = new RequiredManager();
    const [cloneCount, setCloneCount] = useState(1);
    const { showModal } = ModalService;

    const sections = useMemo(() => (Switch(
        [data.Type === undefined, DefaultSections],
        [ActionType.MailSend === data.Type && data.when === 0, CustomEmailSections],
        [ActionType.MailSend === data.Type && data.when !== undefined, InvoiceEmailSections],
        [ActionType.ActionTask === data.Type, TaskSections],
        [ActionType.ActionSMSSend === data.Type, SmsSections],
        [ActionType.ActionSendWhatsApp === data.Type, WhatsAppSections],
        [ActionType.ActionSendList === data.Type, ClientListSections],
        [ActionType.ActionSendIOList === data.Type, InvoiceListSections],
        [ActionType.ActionSendLetter === data.Type, LetterSections],
    ) ?? DefaultSections)
        .map(x => ({ name: OptionalMap(x.name, getTranslation), Component: x.Component }))
        , [data.Type, data.when, getTranslation]);

    const setField = <T extends keyof ActionGetResponse["item"],>(field: T) =>
        (value: Partial<ActionGetResponse.Action>[T]) =>
            setData(old => ({ ...old, [field]: MapIf(value, Number.isNaN, undefined) }));

    const requestAborter = useRequestAborter();
    const history = useHistory();
    const queryParams = useQuery();

    useEffect(() => {
        const id = Number(queryParams.get("id"));
        if (id) {
            endpointRequest(() => ActionService.get(id), x => setData(x.item),
                setLoading, setError, requestAborter);
        }
        else {
            const userName = CompanyService.getUsers()
                .find(x => x.Id === CompanyService.getUserid())!.Value;
            setData(Cast<ActionGetResponse["item"]>({
                Type: undefined,
                Name: "",
                Mail_Saturday: false,
                Mail_Sunday: false,
                Mail_Period: 0,
                Mail_IncludeLink: false,
                Mail_ReportExportID: null,
                MailFrameID: null,
                Mail_FromName: userName,
                Disabled: false,
                Mail_From: CompanyService.getUserEmail(),
            }));
            setLoading(false);
        }
    }, [queryParams, requestAborter]);

    const handleSave = () => requiredManager?.validate() && endpointRequest(
        () => ActionService.set(data as ActionGetResponse["item"]),
        () => { history.goBack(); }, setLoading, setError, requestAborter);

    const sendNowModal = new ModalParams();
    sendNowModal.children = <SendNow actionId={data.ActionID!}></SendNow>;
    sendNowModal.closeClickingOutside = true;
    sendNowModal.title = translate.SendNow;

    const handleSendNow = () => {
        showModal(sendNowModal);
    };

    const handleDelete = () => ModalService.showDefaultModal({
        onAcceptClick: () => endpointRequest(
            () => ActionService.delete(data.ActionID!),
            () => { history.goBack(); }, setLoading, setError, requestAborter),
        acceptButtonLabel: TranslationService.translate.Delete,
        acceptButtonClassName: "btn btn-danger",
        message: TranslationService.translate.SureDelete,
        title: TranslationService.translate.Delete,
    });

    const count = MapIf(cloneCount, x => !Number.isInteger(x), 1);
    const handleClone = () => ModalService.showDefaultModal({
        onAcceptClick: () => endpointRequest(
            () => ActionService.clone(data.ActionID!, count),
            () => { history.goBack(); },
            setLoading, setError, requestAborter),
        acceptButtonLabel: TranslationService.translate.Clone,
        acceptButtonClassName: "btn btn-primary",
        message: count > 1 ?
            TranslationService.translate.SureCloneManyAA
                .replace("{cant}", count.toString()) :
            TranslationService.translate.SureCloneAA,
        title: TranslationService.translate.Clone,
    });

    if (loading) {
        return <Loading />;
    }
    if (error) {
        return <ErrorMessage />;
    }

    const props: PropsGenerators = {
        str: {
            required: defaultValidatedGenerator<string>(data, setField, requiredManager),
            notRequired: defaultGenerator<string>(data, setField),
        },
        num: {
            required: defaultValidatedGenerator<number>(data, setField, requiredManager),
            notRequired: defaultGenerator<number>(data, setField),
        },
    };

    const editProps: EditProps = {
        data,
        setField,
        setData,
        propGen: props,
        requiredManager,
    };
    const id = Number(queryParams.get("id"));
    const title = id ? translate.EditAction : translate.NewAction;

    return (<>
        <div className="d-flex">
            <div className="col-2 p-0" style={{ backgroundColor: "#FFFFFF" }}>
                <div className="bullet-point-connected">
                    <ListTitles sections={sections} titleRefs={titleRefs} />
                </div>
            </div>
            <div className="col-10">
                <div className="container-fluid ps-4 pe-0 mb-4">
                    <div className="mt-3">
                        <Breadcrumb paths={[{ name: translate.Automatic, link: "action" }, { name: title }]} />
                    </div>
                    <div className="card me-4">
                        <ListSections sections={sections} titleRefs={titleRefs}
                            editProps={editProps} />
                        <hr />
                        <AdditionalDefinitions {...editProps} />
                        <div className="row mt-3">
                            <div className="col d-flex">
                                {data.ActionID && <>
                                    <button className="btn btn-secondary me-2"
                                        onClick={() => history.goBack()}>{translate.GoBack}</button>
                                    <button className="btn btn-danger me-2"
                                        onClick={handleDelete}>{translate.Delete}</button>
                                    <button className="btn btn-secondary show-child-on-hover d-flex me-2"
                                        onClick={handleClone}>{translate.Clone}
                                        <input
                                            style={{ width: 30, height: 20 }} defaultValue={cloneCount}
                                            onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
                                            onChange={(e) => { setCloneCount(parseInt(e.target.value)); }}
                                            className="mx-1 px-1 display-when-hovering-parent" />
                                    </button>
                                </>}
                            </div>
                            <div className="col-auto d-flex">
                                {data.ActionID &&
                                    <button className="btn btn-secondary me-2"
                                        onClick={handleSendNow}>{translate.SendNow}</button>
                                }
                                <button className="btn btn-primary btn-keyword-filter"
                                    onClick={handleSave}>{translate.Save}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
};

const SendNow = ({ actionId }: { actionId: number }) => {
    const [isSaving, setIsSaving] = useState(false);
    const [actionRun, setActionRun] = useState(new ActionRun(actionId));

    const requiredManager = new RequiredManager();

    const send = async () => {
        if (!requiredManager.validate()) {
            return;
        }

        if (isSaving) { return; }
        setIsSaving(true);
        if (await ProcessService.showResultToast(ActionService.run(actionRun))) {
            ModalService.hideModal();
        } else {
            setIsSaving(false);
        }
    };

    const onChange = requiredManager.makeRequired((value: string) => {
        setActionRun({ ...actionRun, overrideto: value });
    });

    return (
        <>
            <div className="modal-body">
                <div className="d-flex input-column mb-3">
                    <p className="mb-3">{TranslationService.translate.ExecuteActionNow}</p>
                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <div className="d-flex input-column">
                                    <label className="form-label">{TranslationService.translate.MaxEmailsQty}</label>
                                    <div className="input-group" style={{ width: 125 }}>
                                        <input className="form-control" type="number" onChange={(e) => setActionRun({ ...actionRun, maxcount: parseIntOrDefault(e.target.value) })}></input>
                                        <span className="input-group-text">
                                            <TooltipComponent title={TranslationService.translate.MaxEmailsToSend}><i className="fa fa-question"></i></TooltipComponent>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <div className="d-flex input-column">
                                    <label className="form-label">{TranslationService.translate.SendTo}</label>
                                    <div className="input-group">
                                        <input className="form-control" onChange={(e) => onChange(e.target.value)}></input>
                                        <span className="input-group-text">
                                            <TooltipComponent title={TranslationService.translate.EmailOverrideTo}><i className="fa fa-question"></i></TooltipComponent>
                                        </span>
                                    </div>
                                    <ValidationMessage onChange={onChange} message={TranslationService.translate.EmailInvalid} validationMethod={validateMailOrEmpty} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => ModalService.hideModal()}>{TranslationService.translate.Close}</button>
                <button className="btn btn-primary" onClick={send}>
                    {TranslationService.translate.Send}
                    {isSaving && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </div>
        </>
    );
};

export default EditAction;