import { useCallback, useContext, useEffect, useState } from "react";
import ErrorMessage from "../../shared/components/Error";
import Loading from "../../shared/components/Loading";
import FloatingPanelService from "../../shared/FloatingPanel";
import { TranslationService } from "../../../services/TranslationService";
// import ToastContext from "../../shared/bootstrap/Toast";
// import ModalService from "../../shared/bootstrap/Modal";
import { RequiredManager, ValidationMessage } from "../../shared/RequieredManager";
import { CheckBoxEditor, TextEditor } from "../../shared/components/Editors";
import { ActivityTypeListResponse } from "../../../entities/company/ActivityType/ActivityTypeListResponse";
import ActivityTypeService from "../../../services/ActivityTypeService";
import { ActivityTypeEntity } from "../../../entities/company/ActivityType/ActivityTypeEntity";
import { FloatingPanelFooter } from "../../shared/components/FloatingPanelFooter";
import ModalService from "../../shared/bootstrap/Modal";
import ToastContext from "../../shared/bootstrap/Toast";


const ActivityType = () => {
    const { translate } = TranslationService;
    const [response, setResponse] = useState<ActivityTypeListResponse>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);


    const requestData = async () => {
        setLoading(true);
        const result = await ActivityTypeService.getList();
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        setResponse(result);
        setLoading(false);
        setError(false);
    };

    const requestDataCallback = useCallback(requestData, []);

    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    if (loading) {
        return (<Loading />);
    }

    if (error) {
        return (<ErrorMessage onRefresh={requestDataCallback} message={translate.ErrorLoadingList} />);
    }

    const showActivityTypeEdit = (activityType?: ActivityTypeEntity) => {
        FloatingPanelService.showPanel({
            children: <ActivityTypeEdit reload={requestDataCallback} activityType={activityType} />,
            title: activityType ? translate.EditActivityType : translate.NewActivityType,
            width: 600,
            height: 500,
            position: "center",
        });
    };

    return (
        <div className="card px-5">
            <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center my-4">
                <h2>{TranslationService.translate.ActivityTypes}</h2>
                <div className="d-flex align-items-center">
                    <button className="btn btn-primary" onClick={() => showActivityTypeEdit(undefined)}><i className="fal fa-plus" /> {translate.NewActivityType}</button>
                </div>
            </div>

            <table className="table">
                <thead>
                    <tr>
                        <th>{translate.Value}</th>
                        <th className="text-center">{translate.EfectiveActivity}</th>
                        <th className="text-center">{translate.Mails}</th>
                        <th className="text-center">{translate.DefaultOption}</th>
                        <th className="text-center">{translate.Comments}</th>
                        <th className="text-center">{translate.DefaultOption}</th>
                        <th className="text-center">{translate.Tasks}</th>
                        <th className="text-center">{translate.DefaultOption}</th>
                        <th className="text-center">{translate.SMS}</th>
                        <th className="text-center">{translate.DefaultOption}</th>
                    </tr>
                </thead>
                <tbody>
                    {response?.list.map(x => (
                        <tr key={x.ActivityTypeID} className="pointer" onClick={(event) => {
                            showActivityTypeEdit(x); event.stopPropagation();
                        }}>
                            <td className="text-start">
                                {x.ActivityTypeName}
                            </td>
                            <td className="text-center">
                                {x.Effective && <i className="fa fa-check"></i>}
                            </td>
                            <td className="text-center">
                                {x.Mail && <i className="fa fa-check"></i>}
                            </td>
                            <td className="text-center">
                                {x.MailDefault && <i className="fa fa-check"></i>}
                            </td>
                            <td className="text-center">
                                {x.Comment && <i className="fa fa-check"></i>}
                            </td>
                            <td className="text-center">
                                {x.CommentDefault && <i className="fa fa-check"></i>}
                            </td>
                            <td className="text-center">
                                {x.Task && <i className="fa fa-check"></i>}
                            </td>
                            <td className="text-center">
                                {x.TaskDefault && <i className="fa fa-check"></i>}
                            </td>
                            <td className="text-center">
                                {x.SMS && <i className="fa fa-check"></i>}
                            </td>
                            <td className="text-center">
                                {x.SMSDefault && <i className="fa fa-check"></i>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

        </div>

    );
};


const ActivityTypeEdit = ({ reload, activityType }: { reload: () => void, activityType?: ActivityTypeEntity }) => {

    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);
    const [submiting, setSubmiting] = useState<string | null>(null);
    const [activityTypeModel, setActivityTypeModel] = useState<ActivityTypeEntity>({
        ...activityType ?? {
            ActivityTypeID: 0,
            ActivityTypeName: "",
            Comment: false,
            CommentDefault: false,
            Effective: false,
            Mail: false,
            MailDefault: false,
            SMS: false,
            SMSDefault: false,
            Task: false,
            TaskDefault: false
        }
    });
    const [name, setName] = useState(activityType?.ActivityTypeName ?? "");

    const requiredManager = new RequiredManager();
    const onValueChanged = requiredManager.makeRequired(setName);

    const onSubmit = async () => {
        if (submiting !== null) {
            return;
        }
        if (!requiredManager.validate()) {
            showToast(translate.MissingRequiredFields);
            return;
        }
        setSubmiting("set");
        activityTypeModel.ActivityTypeName = name;
        const result = await ActivityTypeService.set(activityTypeModel);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            setSubmiting(null);
            return;
        }
        setSubmiting(null);
        reload();
        FloatingPanelService.hidePanel();
        showToast(translate.ActivityTypeSaved, undefined, "success");
    };

    const onDelete = () => {
        const deleteTransactionType = async () => {
            if (submiting !== null) {
                return;
            }
            setSubmiting("del");
            const result = await ActivityTypeService.delete(activityType?.ActivityTypeID!);
            if (result instanceof Error) {
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
                setSubmiting(null);
                return;
            }
            setSubmiting(null);
            reload();
            FloatingPanelService.hidePanel();
            showToast(translate.ActivityTypeDeleted, undefined, "success");
        };
        ModalService.showDefaultModal({
            acceptButtonLabel: translate.Delete,
            message: () => <>{translate.AreYouSureDeleteActivityType.split("\n").map(x => (
                <><span>{x}</span><br /></>
            ))}</>,
            onAcceptClick: deleteTransactionType,
        });
    };


    return (
        <>
            <div className="floatingBody p-4">
                <div className="card-body">
                    <div className="d-flex flex-column">
                        <div className="row mb-3">
                            <label className="col-3 font-weight-normal col-form-label">{translate.Name}:</label>
                            <div className="col-8">
                                <TextEditor onChange={onValueChanged} defaultValue={name} />
                                {<ValidationMessage onChange={onValueChanged} defaultValue={name} />}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <CheckBoxEditor onChange={(x) => setActivityTypeModel({ ...activityTypeModel, Effective: x })} defaultValue={activityType?.Effective} message={translate.EfectiveActivity}></CheckBoxEditor>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="row">
                            <div className="col-4">
                                <CheckBoxEditor onChange={(x) => setActivityTypeModel({ ...activityTypeModel, Mail: x, MailDefault: !x ? false : activityTypeModel.MailDefault })} value={activityTypeModel.Mail} message={translate.Mails}></CheckBoxEditor>
                            </div>
                            <div className="col-6">
                                <CheckBoxEditor onChange={(x) => setActivityTypeModel({ ...activityTypeModel, MailDefault: x })} value={activityTypeModel.MailDefault} message={translate.DefaultOption} disabled={!activityTypeModel.Mail}></CheckBoxEditor>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <CheckBoxEditor onChange={(x) => setActivityTypeModel({ ...activityTypeModel, Comment: x, CommentDefault: !x ? false : activityTypeModel.CommentDefault })} value={activityTypeModel.Comment} message={translate.Comments}></CheckBoxEditor>
                            </div>
                            <div className="col-6">
                                <CheckBoxEditor onChange={(x) => setActivityTypeModel({ ...activityTypeModel, CommentDefault: x })} value={activityTypeModel.CommentDefault} message={translate.DefaultOption} disabled={!activityTypeModel.Comment}></CheckBoxEditor>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <CheckBoxEditor onChange={(x) => setActivityTypeModel({ ...activityTypeModel, Task: x, TaskDefault: !x ? false : activityTypeModel.TaskDefault })} value={activityTypeModel.Task} message={translate.Tasks}></CheckBoxEditor>
                            </div>
                            <div className="col-6">
                                <CheckBoxEditor onChange={(x) => setActivityTypeModel({ ...activityTypeModel, TaskDefault: x })} value={activityTypeModel.TaskDefault} message={translate.DefaultOption} disabled={!activityTypeModel.Task}></CheckBoxEditor>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <CheckBoxEditor onChange={(x) => setActivityTypeModel({ ...activityTypeModel, SMS: x, SMSDefault: !x ? false : activityTypeModel.SMSDefault })} value={activityTypeModel.SMS} message={translate.SMS}></CheckBoxEditor>
                            </div>
                            <div className="col-6">
                                <CheckBoxEditor onChange={(x) => setActivityTypeModel({ ...activityTypeModel, SMSDefault: x })} value={activityTypeModel.SMSDefault} message={translate.DefaultOption} disabled={!activityTypeModel.SMS}></CheckBoxEditor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FloatingPanelFooter>

                {activityType &&
                    <button type="button" className="btn btn-danger btn-sm" onClick={onDelete}>
                        {translate.Delete}
                        {submiting === "del" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                    </button>}

                <button className="btn btn-primary btn-sm" onClick={onSubmit}>
                    {translate.Save}
                    {submiting === "set" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </FloatingPanelFooter>
        </>
    );

};


export default ActivityType;
