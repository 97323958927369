import { TranslationService } from "../../../../services/TranslationService";
import { ValidationMessage } from "../../../shared/RequieredManager";
import { EditProps } from "../EditAction";
import { Field } from "./FieldHelpers";

export const WhatsAppTemplateContent = (propsEdit: EditProps) => {
    const { propGen: props, data } = propsEdit;
    let contentData = data.Content;
    const partes = contentData?.split('---');
    const primeraParte = partes ? partes[0] : '';
    const numeroPrimeraParte = parseFloat(primeraParte);
    const esNumero = !isNaN(numeroPrimeraParte) && isFinite(numeroPrimeraParte);
    if (esNumero && partes) {
        const [, rest] = partes
        contentData = rest;
    }

    return (
        <div className="row">
            {contentData &&
                <Field title={TranslationService.translate.template} colClass="col-8">
                    <textarea
                        disabled
                        className="form-control"
                        value={contentData}
                        onChange={e => props.str.required("Content").onChange(e.target.value)}
                        style={{ resize: "none" }}
                        rows={6} />
                    <ValidationMessage {...props.str.required("Content")}
                        message={TranslationService.translate.EnterBodyWhatsApp} />
                </Field>
            }
        </div>)
}