import TabPosition from "../shared/TabPosition";
import { InboxListProvider } from "./InboxListContext";
import { InboxTabContent } from "./InboxTabContent";
import InboxTableProvider from "./InboxTableProvider";

export const InboxListContainer = () => {
    return (
        <TabPosition module="inbox">
            <InboxListProvider>
                <InboxTableProvider>
                    <InboxTabContent />
                </InboxTableProvider>
            </InboxListProvider>
        </TabPosition>
    )
}
