import React from "react";
import { TranslationService } from "../../../services/TranslationService";

const ReportsCardContainer = ({ background, title, tooltip = true, children }: React.PropsWithChildren<{
    background?: "blue" | "red", title: string, tooltip?: boolean
}>) => {

    return (
        <div className={"card " + (background)}>
            <div className="row">
                <div className="col">
                    {title}
                </div>
                {tooltip &&
                    <div className="col-auto">
                        <td className='popover__wrapper' >
                            <div>
                                <i className="far fa-info-circle information-icon"></i>
                            </div>
                            <div className="popover__content right" style={{ width: 500, }}>
                                <p style={{ fontWeight: 400 }}>{TranslationService.translate.ReferencesCollectionReportTooltip_title}</p>
                                <p style={{ fontWeight: 400 }}>{TranslationService.translate.ReferencesCollectionReportTooltip_customers}</p>
                                <p style={{ fontWeight: 400 }}>{TranslationService.translate.ReferencesCollectionReportTooltip_initialDebt}</p>
                                <p style={{ fontWeight: 400 }}>{TranslationService.translate.ReferencesCollectionReportTooltip_collected}</p>
                                <p style={{ fontWeight: 400 }}>{TranslationService.translate.ReferencesCollectionReportTooltip_pending}</p>
                            </div>
                        </td>
                    </div>}
            </div>
            {children}
        </div>
    )

}

export default ReportsCardContainer;