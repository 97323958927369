import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TranslationService } from '../../services/TranslationService';
import { ToastService } from '../shared/bootstrap/Toast';
import FileService from '../../services/FileService';
import Loading from '../shared/components/Loading';

export const FileDownloadReport = () => {
    const history = useHistory();
    const fileId = new URLSearchParams(history.location.search).get("id") || '';
    const currentLanguage = TranslationService.currentLanguage;

    useEffect(() => {
        FileService.downloadReport(fileId)
            .then((response) => {
                if (response instanceof Error) {
                    ToastService.showToast('Error al descargar archivo', undefined, "danger");
                    return response;
                }
                ToastService.showToast('Descarga completa', undefined, "success");
                return response;
            })
            .finally(() => {
                const newPath = `/${currentLanguage}/company`;
                history.replace(newPath);
            });
    }, [fileId, history, currentLanguage]);

    return <div>
        <Loading />
    </div>;
};
