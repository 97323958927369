import { useContext, useEffect } from 'react';
import TabPositionContext from './TabPositionContext';

const TabPosition: any = (props: { module: string, children: any }) => {
    const { setCurrentTabPosition } = useContext(TabPositionContext);
    useEffect(() => {
        if (props.module) {
            setCurrentTabPosition(props.module);
        }
    })

    return (props.children)
}

export default TabPosition;