import TabPosition from "../shared/TabPosition";

const GoalList: React.FC = () => {

    return (
        <TabPosition module="Goals">
            <p>Goals</p>
        </TabPosition>
    )
}

export default GoalList;