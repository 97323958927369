export function Switch<T>(...expressions: [boolean | (() => boolean), T | (() => T)][]) {
    for (let index = 0; index < expressions.length; index++) {
        const element = expressions[index];
        if (getValueOrValueResult(element[0])) {
            return getValueOrValueResult(element[1]);
        }
    }
    return undefined;
}
export function Choose<T>(...expressions: (T | (() => T))[]) {
    for (let index = 0; index < expressions.length; index++) {
        const element = expressions[index];
        const val = getValueOrValueResult(element);
        if (val !== null && val !== undefined) {
            return val;
        }
    }
    return undefined;
}

// type ValueOrFuncOfValue<T> = T | (() => T);

/**
 * If value is a function, returns the result of calling the function. Otherwise, returns the value.
 * @param value The value to check.
 * @param args The arguments to pass to the function.
 * @returns The value or the result of the function.
 */
export function getValueOrValueResult<T, K>(value: T, ...args: K[]): T extends (...args: K[]) => infer J ? J : T {
    return typeof value === "function" ? value.apply(undefined, args) : value;
}

export function MapNotUndefined<T, U>(value: (T | undefined) | (T | null), map: U | ((value: T) => U)) {
    if (value === null) {
        return null;
    }
    if (value === undefined) {
        return undefined;
    }
    return getValueOrValueResult(map, value);
}

export function MapIf<T, U>(value: T, condition: boolean | ((value: T) => boolean), map: U | ((value: T) => U)) {
    if (getValueOrValueResult(condition, value)) {
        return getValueOrValueResult(map, value);
    }
    return value;
}

export function MapIfEqual<T>(value: T, otherValue: T, map: T | ((value: T) => T)) {
    if (value === otherValue) {
        return getValueOrValueResult(map, value);
    }
    return value;
}

/// Maps a value to a new value if the value is not undefined.
export function OptionalMap<T, U>(value: T | undefined, map: (val: T) => U) {
    return value === undefined ? undefined : map(value);
}

/// Maps a value to a new value if the value is not null.
export function NotNullMap<T, U>(value: T | null, map: (val: T) => NonNullable<U>) {
    return value === null ? null : map(value);
}

export function GroupBy<T>(list: T[], getKey: (value: T) => string | number): { [index: string | number]: T[] };
export function GroupBy<T, U>(list: T[], getKey: (value: T) => string | number, getValue: (value: T) => U): { [index: string | number]: U[] };

export function GroupBy(list: any[], getKey: (value: any) => string | number, getValue?: (value: any) => any): { [index: string | number]: any[] } {
    if (getValue === undefined) {
        getValue = (x: any) => x;
    }
    return list.reduce((prev, curr) => {
        const key = getKey(curr);
        const array = prev[key] === undefined ? [] : prev[key];
        array.push(getValue!(curr));
        prev[key] = array;
        return prev;
    }, {} as { [index: string | number]: unknown[] });
}

export function isNullOrWhitespace(value: string | null | undefined): value is null | undefined | "" {
    return value === null || value === undefined || value.trim().length === 0;
}

export function sumList<T>(list: T[], getValue: (value: T) => number) {
    return list.reduce((prev, curr) => prev + getValue(curr), 0);
}

export type PickByValue<T, ValueType> = Pick<T, { [Key in keyof T]-?: T[Key] extends ValueType ? Key : never }[keyof T]>;

export function Cast<T>(toCast: unknown) { return toCast as T; }

export function BadlySpelled<TOrig extends object, TKey extends keyof TOrig>(orig: TOrig, key: TKey, badSpelling: string) {
    if (key in orig) {
        return orig[key];
    }
    const casted = Cast<{ [badSpelling: string]: TOrig[TKey] }>(orig);
    return casted[badSpelling];
}

export function BadlySpelled2<TKey>(key: TKey, badSpelling: string) {
    return Cast<TKey>(badSpelling);
}

export const daysOfTheWeek: Readonly<string[]> = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export function Tee<T>(value: T, callback: (value: T) => void = console["log"]) {
    callback(value);
    return value;
}

type AnyFunction = (...args: any[]) => any;

export function debounce<F extends AnyFunction>(func: F, delay: number) {
    let timeoutId: ReturnType<typeof setTimeout>;

    return function (this: any, ...args: Parameters<F>) {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => { func.apply(this, args) }, delay);
    };
}

export function addIf<T>(condition: boolean, item: T): T[] {
    if (!condition) {
        return [];
    }
    return [item];
}

// Encode Filters for Reports
export const encodeFilters = (filters: string[]) => {
    let i = 0
    return filters.map((value) => {
        let aux = "filter" + (i) + "=" + encodeURIComponent(value)
        i++
        return aux
    }).join("&");
}

export const hasValuesLength = (values?: any[]): boolean => {
    return Boolean(values && (values?.length > 0))
}