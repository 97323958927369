import { TranslationService } from "../../../services/TranslationService";

const Empty = ({message = undefined, className = ""}: {message?: string, className?: string}) => {
    return (<>
        <div className={className + "d-flex align-items-center justify-content-center mt-2"}>
            <div className="messageSwichContent text-center">
                <p className="mb-2"><i className="fal fa-file-search fa-3x"></i></p>
                <p className="lead">{message ?? TranslationService.translate.NoElementsWereFound}</p>
            </div>
        </div>
    </>);
};

export default Empty;