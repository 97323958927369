import { TranslationService } from "../../../../services/TranslationService";
import { getEnumArray } from "../../../../utils/EnumUtils";
import TooltipComponent from "../../../shared/TooltipComponent";
import { EditProps } from "../EditAction";
import { EmailFrom, EmailBccButton, EmailBccInput, SendToOptions } from "./EmailComponents";
import { EmailInput } from "./EmailInput";
import { Field } from "./FieldHelpers";

export const EmailToClient = (props: EditProps) => {
    const { data, setField, propGen } = props;
    return (<>
        <EmailFrom {...props} />
        <div className="row">
            <EmailInput {...props} field="To" items={getEnumArray(SendToOptions)}
                title={<TooltipComponent placement="right"
                    title={TranslationService.translate.TipSendTo}>
                    {TranslationService.translate.SendTo}
                    <i className="fal fa-question-circle ms-2" />
                </TooltipComponent>} />
            <Field>
                <div className="d-flex py-1" style={{ height: 40 }}>
                    <EmailBccButton data={data} setField={setField} dataKey={"Cc"} />
                    <EmailBccButton data={data} setField={setField} dataKey={"Bcc"} />
                </div>
            </Field>
            <EmailBccInput data={data} setField={setField} propGen={propGen}
                dataKey={"cc"} />
            <EmailBccInput data={data} setField={setField} propGen={propGen}
                dataKey={"bcc"} />
        </div>
    </>);
}