import React, { useEffect, useState } from "react";
import CompanyService, { Entities } from "../../../../services/CompanyService";
import { TranslationService } from "../../../../services/TranslationService";
import Dropdown from "../../../shared/components/Dropdown";
import { EntityField, sortEntityFields } from "../../../shared/entities/EntityField";
import { EditProps } from "../EditAction";
import { Field } from "./FieldHelpers";
import { endpointRequest } from "../../../../utils/RequestUtils";
import UserService from "../../../../services/UserService";

export const InsertFields = (props: EditProps) => {
    const fixedFields = React.useMemo(() => {
        const clientFieldOptions = [...CompanyService.getConfigItemsFiltered(Entities.Client).map(x => new EntityField(x)),
        ...CompanyService.getAdditionalDefinitionsFiltered(Entities.Client).map(x => new EntityField(x)),
        ].sort(sortEntityFields(CompanyService.getPersonSortedFields().split(","))).map(x => ({ value: `[${x.title}]`, text: x.title ?? '' }));
        const fixedInsertFieldOptions = ['Monto', 'Monto vencido', 'Cliente', "Código Cliente", " Monto no vencido"].map(x => ({ value: `[${x}]`, text: x }));
        fixedInsertFieldOptions.push(
            { value: '[Portal] ', text: TranslationService.translate.CustomerPortal });
        const insertFieldOptions = [
            { text: TranslationService.translate.EmailInsertField, value: "" },
            ...clientFieldOptions,
            ...fixedInsertFieldOptions,
            ...(props.data.Mail_Include ? [{ value: '[Facturas] ', text: TranslationService.translate.InvoiceList }] : [])
        ];
        return insertFieldOptions;
    }, [props.data.Mail_Include]);
    const insertFieldOptions = React.useMemo(() => {
        // if (props.data.Mail_Include) {
        //     return [...fixedFields, { value: '[Facturas] ', text: TranslationService.translate.InvoiceList }];
        // }
        return fixedFields;
    }, [fixedFields]);
    const onChange = (value: string) => (value?.length > 0) && props.setData(old => ({ ...old, Content: (old.Content ?? "") + value }));
    return (
        <Field title={TranslationService.translate.AddMoreFields}>
            <Dropdown onChange={onChange} items={insertFieldOptions} value={""} />
        </Field>);
}

export const InsertFieldsOnly = (props: EditProps) =>
    <div className="row">
        <InsertFields {...props} />
    </div>


export const InsertFieldsSignature = (props: EditProps) => {
    const [signatures, setSignatures] = useState<{ value: number, text: string }[]>([]);
    useEffect(() => {
        endpointRequest(() => UserService.getSignatures(),
            x => setSignatures(x.map(y => ({ value: y.SignatureID, text: UserService.getUserName(y.UserID) ?? "" })))
        )
    }, []);
    return (
        <div className="row">
            <InsertFields {...props} />
            {signatures.length > 0 && <Field title={TranslationService.translate.Signature}>
                <Dropdown onChange={props.propGen.num.notRequired("Mail_SignatureID").onChange} items={signatures} optionLabel={TranslationService.translate.None} value={props.data.Mail_SignatureID} />
            </Field>}
        </div>
    )
}