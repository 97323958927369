import { useCallback, useEffect, useMemo, useState } from "react";
import { TranslationService } from "../../../services/TranslationService";
import { Client } from "../entities/ClientGetResponse";
import { ToastService } from "../../shared/bootstrap/Toast";
import { WhatsAppRequestBody } from "../activity/entities/AcitivityPendingResponse";
import { WhatsappChat } from "../../shared/components/whatsappChat/WhatsappChat";
import { WhatsappInputSend } from "../../shared/components/whatsappChat/WhatsappInputSend";
import { EntityField, sortEntityFields } from "../../shared/entities/EntityField";
import WhatsappService, { WhatsappTemplateItemType, WhatsappTemplateListType } from "../../../services/WhatsappService";
import CompanyService, { Entities } from "../../../services/CompanyService";
import ActivityService from "../../../services/ActivityService";
import Dropdown from "../../shared/components/Dropdown";
import Loading from "../../shared/components/Loading";
import { ClientFields, getClientFieldTitle } from "../../../services/ClientService";

class SMSEdit {
    groupId?: string;
    telephone?: string;
    message?: string;
    activityTypeId?: number;
    tags?: string;

    constructor(person: Client, activityTypeId: number | undefined) {
        this.activityTypeId = activityTypeId ?? CompanyService.getActivityTypes().find(x => x.SMS && x.SMSDefault)?.ActivityTypeID;
        const field = CompanyService.getCompanyAuth()?.DefaultWhatsappField;
        if (field) {
            this.telephone = field === -1 ? person.Phone : person.additionals.find(x => x.id === CompanyService.getCompanyAuth()!.DefaultWhatsappField)?.value;
        }
    }
}

export const WhatsappCompose = ({ person, personId, onSubmit, getCounterInbox }: { person: Client, personId: string, onSubmit?: () => void, getCounterInbox: () => void }) => {
    const [whatsAppTemplate, setWhatsAppTemplate] = useState<any>(null)
    const [whatsAppTemplateSelected, setIdWhatsAppTemplateSelected] = useState<WhatsappTemplateItemType | any>('0')
    const [changeSMSSender, setChangeSMSSender] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const [whatsappMsgList, setWhatsappMsgList] = useState<any>();
    const [openchat, setOpenchat] = useState<boolean>(false)
    const [matrizResults, setMatrizResults] = useState([[]])
    const [SMS, setSMS] = useState(new SMSEdit(person, CompanyService.getActivityTypes().find(x => x.SMS && x.SMSDefault)?.ActivityTypeID));
    const [page, setPage] = useState(0);
    const [noMoreResults, setNoMoreResults] = useState(!(personId && SMS.telephone))
    const [cleanFreeText, setCleanFreeText] = useState(false)
    const activityTypeOptions = CompanyService.getActivityTypes().filter(x => x.SMS).map(x => ({ value: x.ActivityTypeID, text: x.ActivityTypeName }));
    const { translate } = TranslationService;
    const [sendingWhatsApp, setSendingWhatsApp] = useState(false)

    const isFreeText = useMemo(() => (openchat === true && whatsAppTemplateSelected === '0'), [whatsAppTemplateSelected, openchat])

    const setSMSField = useCallback((property: string) => (value: number | string | undefined) => {
        setSMS(SMS => ({ ...SMS, [property]: value }));
    }, [setSMS]);

    const handleTemplateWhatsappChange = (value: string) => {
        setWhatsAppTemplate(value)
    }

    const handleCreateWhatsAppMessage = async () => {
        setSendingWhatsApp(true)
        if (personId && SMS.telephone) {
            const freeTextCompleted = (isFreeText && whatsAppTemplate?.length > 0);
            const watemplateidAux = freeTextCompleted ? '0' : whatsAppTemplateSelected?.WATemplateID;

            if (whatsAppTemplate !== null && watemplateidAux) {
                const dataBodyWhatsApp: WhatsAppRequestBody = {
                    personid: parseInt(personId),
                    content: freeTextCompleted ? whatsAppTemplate : whatsAppTemplateSelected.Structure,
                    phone: SMS.telephone,
                    activitytypeid: null,
                    groupid: null,
                    watemplateid: watemplateidAux
                }

                const result = await ActivityService.setWhatsApp(dataBodyWhatsApp);

                if (result instanceof Error) {
                    ToastService.showToast(TranslationService.translate.WhatsAppErrorSent, '', 'danger');
                    setSendingWhatsApp(false);
                    return;
                } else {
                    if (result.Response === 'Ok') {
                        setCleanFreeText(true)
                        setWhatsAppTemplate(null)
                        setIdWhatsAppTemplateSelected('0')
                        onSubmit && onSubmit();
                        setSendingWhatsApp(false);
                    } else {
                        ToastService.showToast(TranslationService.translate.WhatsAppErrorSent, '', 'danger');
                        setSendingWhatsApp(false);
                        return;
                    }
                }


            } else {
                ToastService.showToast('Seleccione un template y complete los campos obligatorios', '', 'info');
                setSendingWhatsApp(false);
            }
        } else {
            ToastService.showToast('Seleccione un template y complete los campos obligatorios', '', 'info');
            setSendingWhatsApp(false);
        }
    }

    const getWhatsappList = useCallback(async (pageResult = page) => {
        try {
            setIsFetching(true);
            if (personId && SMS.telephone) {
                const result = await ActivityService.getListWhatsappMessages({
                    personid: parseInt(personId),
                    phone: SMS.telephone,
                    filter: '',
                    page: pageResult.toString(),
                    users: []
                })

                if (result instanceof Error) {
                    setIsFetching(false);
                    return;
                }

                if (result.list.length === 0) {
                    setNoMoreResults(true);
                } else {
                    const matriz = matrizResults;
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    matriz[pageResult] = result.list;
                    setMatrizResults(matriz);
                    setWhatsappMsgList(result.list);
                    setOpenchat(result.openchat)
                    setNoMoreResults(false);
                    setPage(pageResult);
                    getCounterInbox();
                }
            }
            setIsFetching(false);
        } catch (error) {
            setIsFetching(false);
            return;
        }
    }, [SMS.telephone, matrizResults, page, personId, getCounterInbox]);

    const getMoreResults = () => {
        const pageQuery = page + 1;
        getWhatsappList(pageQuery)
    }

    const handleFreeTextChange = (val: string) => {
        setWhatsAppTemplate(null)
        setIdWhatsAppTemplateSelected('0')
        if (whatsAppTemplateSelected === '0') {
            setWhatsAppTemplate(val)
        }
    }

    useEffect(() => {
        setChangeSMSSender(true)
        setWhatsAppTemplate(null)
        setIdWhatsAppTemplateSelected(null)
        const timeoutId = setTimeout(() => {
            getWhatsappList().finally(() => setChangeSMSSender(false))
        }, 800);

        return () => {
            clearTimeout(timeoutId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SMS.telephone, personId]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            getWhatsappList();
        }, openchat ? 5000 : 15000);

        return () => {
            clearTimeout(timeoutId);
        }
    }, [getWhatsappList, whatsappMsgList, openchat])


    return (
        <div className="floatingBody" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <div className="row">
                <div className="col-4">
                    <div className="d-flex input-column mb-3">
                        <label className="form-label">{translate.Telephone}</label>
                        <input type="text" className="form-control border" disabled value={SMS.telephone} />
                    </div>
                </div>
                <div className="col-8">
                    <div className="d-flex input-column mb-3">
                        <label className="form-label">{translate.ActivityType}</label>
                        <Dropdown
                            onChange={() => setSMSField("activityTypeId")}
                            items={activityTypeOptions}
                            optionLabel={translate.None}
                            defaultValue={SMS.activityTypeId}
                        />
                    </div>
                </div>
            </div>
            <WhatsappChat
                noMoreResults={noMoreResults}
                matrizResults={matrizResults}
                getMoreMessages={getMoreResults}
                isFetching={isFetching} />
            {!changeSMSSender ?
                <div className="my-2">
                    <div className="d-flex col-8 mb-3 align-items-center nowrap important">
                        <div className="col">
                            <ChangeWhatsAppTemplate
                                onChange={handleTemplateWhatsappChange}
                                onSelectTemplateId={setIdWhatsAppTemplateSelected}
                                whatsAppTemplateSelected={whatsAppTemplateSelected}
                                openchat={openchat}
                                defaultFreeText={isFreeText}
                                cleanFreeText={cleanFreeText}
                                setCleanFreeText={setCleanFreeText}
                            />
                        </div>
                    </div>

                    <WhatsappInputSend
                        handleSend={handleCreateWhatsAppMessage}
                        whatsAppTemplateText={whatsAppTemplate}
                        setFreeTextChanged={handleFreeTextChange}
                        disabledInput={!isFreeText}
                        cleanFreeText={cleanFreeText}
                        setCleanFreeText={setCleanFreeText}
                        loading={sendingWhatsApp}
                    />
                </div> : <Loading customStyle={{ width: 75, height: 75 }} height={230} />}
        </div>
    );
};

const ChangeWhatsAppTemplate = ({ onSelectTemplateId, onChange, openchat, cleanFreeText }: any) => {
    const [data, setData] = useState<WhatsappTemplateListType>();

    const getWhatsappTemplateList = async () => {
        const result = await WhatsappService.getList()
        if (result instanceof Error) {
            ToastService.showToast('Error al obtener templates', undefined, "danger");
            return;
        } else {
            setData(result);
        }
    }

    const fixedFieldsCustomer = useMemo(() => {

        const clientFieldOptions = [
            ...CompanyService.getAdditionalDefinitionsFiltered(Entities.Client).map(x => new EntityField(x)),
        ]
            .sort(sortEntityFields(CompanyService.getPersonSortedFields().split(",")))
            .map(x => ({ value: x.id.toString(), text: x?.title ?? '' }));

        const insertFieldOptions = [
            { text: TranslationService.translate.InsertField, value: "" },
            ...WhatsappService.getConfigItemsByWhatsApp().map(cl => ({ value: cl.toString(), text: getClientFieldTitle(cl as ClientFields) })),
            ...clientFieldOptions,
        ];
        return insertFieldOptions;
    }, []);

    const handleSelectedChange = (value: any) => {
        const valueSelected = value.split('-')
        const item = data?.list && data.list.find(el => el.WATemplateID.toString() === valueSelected[0])
        const dataParameters = item?.Parameters;
        const elements = Array.from({ length: dataParameters || 0 }, (_, index) => index + 1);

        let texto = valueSelected[1] || '';

        if (item?.FieldList) {
            (item?.FieldList?.split(',').map((f, index) => ({ param: index + 1, data: `${f}`, valueText: `${f}` })) ||
                elements.map((elIndex) => ({ param: elIndex, data: `{{${elIndex}}}`, valueText: `{{${elIndex}}}` }))
            ).forEach(({ param, valueText }) => {
                texto = texto.replace(`{{${param}}}`, `{{${fixedFieldsCustomer.find(f => f.value === valueText)?.text || valueText}}}`)
            });
        }

        if (value === '0') {
            onSelectTemplateId('0')
        } else {
            onSelectTemplateId(item)
        }
        onChange(texto)
    }

    useEffect(() => {
        openchat && onSelectTemplateId('0')
        getWhatsappTemplateList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (cleanFreeText) {
            handleSelectedChange('0')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cleanFreeText])


    const fixedFields = useMemo(() => {
        const templateFieldOptions = data?.list.filter(f => f.FieldList).map(x => ({ value: `${x.WATemplateID}-${x.Structure}`, text: x.Name ?? '' }));
        return templateFieldOptions || [];
    }, [data?.list]);

    const insertFieldOptions = useMemo(() => {
        let itemsFields = [...fixedFields]
        if (openchat) {
            itemsFields = [{ value: '-1', text: TranslationService.translate.Select }, { value: '0', text: TranslationService.translate.Freetext }, ...itemsFields]
        } else {
            itemsFields = [{ value: '0', text: TranslationService.translate.Select }, ...itemsFields]
        }
        return itemsFields;
    }, [fixedFields, openchat]);

    if (cleanFreeText) {
        return <Loading />
    }

    return (
        <Dropdown
            onChange={handleSelectedChange}
            placeholder={TranslationService.translate.Select + ' ' + TranslationService.translate.WhatsAppTemplate}
            items={insertFieldOptions}
            defaultValue="0"
        />
    )
}
