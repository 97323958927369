import React, { useCallback, useState } from "react";
import ClientService from "../../../services/ClientService";
import { ClientHistoryListRequest } from "../entities/ClientHistoryListRequest";
import { ClientHistoryListResponse } from "../entities/ClientHistoryListResponse";
import TableContext, { TableContextValues } from "../../task/TableContext";
import { applyFiltersHelper, calculatePageCount, setCurrentPageHelper, sortHelper, useEndpointData } from "../../../utils/TableUtils";

export class ClientHistoryTableContextValues extends TableContextValues<ClientHistoryListResponse, { extraFilters: string[] }> {
    // constructor(public getFilters: () => ClientHistoryListRequest) {
    //     super();
    // }
    max: number = 0;
    request: ClientHistoryListRequest = new ClientHistoryListRequest();
    setRequest: (request: ClientHistoryListRequest) => void = () => { };
}

const ReportHistoryClientListTableProvider = ({ children, prevRequest = undefined }: React.PropsWithChildren<{ prevRequest?: ClientHistoryListRequest }>) => {
    const [request, setRequest] = useState(prevRequest ?? new ClientHistoryListRequest());
    const endpoint = useCallback(() => ClientService.getClientsHistoryList(request), [request]);
    const { loading, error, response, reload } = useEndpointData(endpoint);
    const pageCount = calculatePageCount(response);
    const { sort, setSortColumn } = sortHelper(request, setRequest);
    const applySearch = (keyword: any) => {
        setRequest({ ...request, text: keyword })
    };
    const value: ClientHistoryTableContextValues =
    {
        loading,
        error,
        response,
        setSortColumn,
        currentPage: request.page,
        setCurrentPage: setCurrentPageHelper(setRequest, pageCount),
        pageCount: pageCount,
        applyFilters: applyFiltersHelper(setRequest),
        applySearch,
        reload,
        sort,
        max: 0, //adaptar
        pageSize: request.pageSize,
        // setPageSize: setPageSizeHelper(setRequest),
        request,
        setRequest,
    }

    return (
        <TableContext.Provider value={value}>
            {children}
        </TableContext.Provider>
    )
}

export default ReportHistoryClientListTableProvider; 