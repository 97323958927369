import bracketsIMG from "../../../assets/img/brackets.svg";

const ReportsBrackets = ({ revert = false }: { revert?: boolean }) => {

    return (
        <>
            <div className="brackets">
                <img src={bracketsIMG} alt="brackets" className={`${revert ? 'imgRevert' : ''}`}></img>
            </div>
        </>
    )

}

export default ReportsBrackets;