import { PropsWithChildren, useMemo } from "react";
import { TranslationService } from "../../../services/TranslationService";
import { ImportPreviewResponse } from "../../../entities/import/ImportPreview";
import React from "react";
import { v4 } from "uuid";
import CompanyService, { Entities } from "../../../services/CompanyService";
import { formatCurrency, formatDateDigits, formatDateShort } from "../../../utils/FormatUtils";
import { BadlySpelled, MapNotUndefined, OptionalMap } from "../../../utils/Utils";
import { ImportTemplateListResponse } from "../entities/ImportTemplateListResponse";
import ImportTemplateService from "../../../services/ImportTemplateService";
import GroupService from "../../../services/GroupService";
import { AwaitValue } from "./AwaitValue";

const ImportStatus = ({ preview, submit, back, template }: { preview: ImportPreviewResponse, submit: () => void, back: () => void, template: ImportTemplateListResponse.Item }) => {
    const canImport = useMemo(() =>
        (preview.newcount + (preview.updatecount ?? 0) + (preview.partialcount ?? 0) + (preview.updatecount ?? 0) + (preview.recoveredcount ?? 0) + (preview.fullcount ?? 0)), [preview.fullcount, preview.newcount, preview.partialcount, preview.recoveredcount, preview.updatecount]);

    const replaceNum = (translation: string, num: number) => {
        if (translation.includes('proveedor')) {
            return translation.replace("{num}", num.toString())
                .replaceAll("(s)", num > 1 ? "es" : "")
                .replace("(n)", num > 1 ? "n" : "")
        }
        return translation.replace("{num}", num.toString())
            .replaceAll("(s)", num > 1 ? "s" : "")
            .replace("(n)", num > 1 ? "n" : "")
    };

    const hasErrors = useMemo(() => Boolean(preview?.errorcount > 0 && (preview?.errors || preview?.errorrecords)), [preview?.errorcount, preview?.errorrecords, preview?.errors]);

    return (
        <section className="importContainer py-3">
            <h2 className="h2 mt-3 mb-0">{TranslationService.translate.ImportResult}:</h2>
            <div className="accordion" id="accordionExample">
                {!preview.validdates &&
                    <h2 className="accordion-title">
                        {TranslationService.translate.ImportManualInvalidDate}
                    </h2>}
                {canImport ?
                    <>
                        {template.entity === 1 && preview?.newcount === 0 &&
                            <h2 className="accordion-title">
                                {TranslationService.translate.NoNewCustomerToImportInThisFile}
                            </h2>
                        }
                        {Boolean(preview?.newcount) && preview?.newios && <AccordionItem title={replaceNum(TranslationService.translate.InvoicesWillBeCreated, preview.newcount)}>
                            <StatusTable items={preview.newios} includeAdditionals={true} includeIndex={true} />
                        </AccordionItem>}
                        {Boolean(preview?.newcount) && preview?.newRecords &&
                            <AccordionItem title={replaceNum(TranslationService.translate.PaymentsWillBeCreated, preview.newcount)}>
                                <StatusTablePayment items={preview.newRecords} includeIndex={false} />
                            </AccordionItem>}
                        {Boolean(preview?.newcount) && preview?.newrecords &&
                            <AccordionItem title={replaceNum(TranslationService.translate.CustomersWillBeCreated, preview.newcount)}>
                                <StatusTableClient items={preview.newrecords} includeAdditionals={true} includeIndex={true} />
                            </AccordionItem>}
                        {Boolean(preview?.newcount) && preview?.items?.length && <AccordionItem title={replaceNum(TranslationService.translate.CommentsWillBeCreated, preview.newcount)}>
                            <StatusTableComment items={preview.items!} />
                        </AccordionItem>}
                        {Boolean(preview.updatecount) && preview.updates?.length && <AccordionItem title={replaceNum(TranslationService.translate.DataForInvoicesWillBeModified, preview.updatecount)}>
                            <StatusTable items={preview.updates} includeAdditionals={false} includeIndex={false} />
                        </AccordionItem>}
                        {(Boolean(preview?.updatecount) && preview?.updaterecords?.length) && <AccordionItem title={replaceNum(TranslationService.translate.CustomersDataWillBeModified, preview.updatecount)}>
                            <StatusTableClient items={preview.updaterecords} includeAdditionals={true} includeIndex={true} />
                        </AccordionItem>}
                        {Boolean(preview?.partialcount) && <AccordionItem title={replaceNum(TranslationService.translate.PartialPaymentsWillBeCreatedForInvoices, preview.partialcount)}>
                            <StatusTable items={preview.partials} includeAdditionals={false} includeIndex={false} />
                        </AccordionItem>}
                        {Boolean(preview?.recoveredcount) && <AccordionItem title={replaceNum(TranslationService.translate.InvoicesWillBeMarkedAsNotPaid, preview.recoveredcount)}>
                            <StatusTable items={BadlySpelled(preview, "recoveredios", "recoveredos")} includeAdditionals={false} includeIndex={false} />
                        </AccordionItem>}
                        {Boolean(preview?.fullcount) && <AccordionItem title={replaceNum(TranslationService.translate.InvoicesWillBeMarkedAsFullPaid, preview.fullcount)}>
                            <StatusTable items={preview.fullios} includeAdditionals={false} includeIndex={false} />
                        </AccordionItem>}
                        {Boolean(preview?.personcount) && <AccordionItem title={replaceNum(TranslationService.translate.CustomersWillBeCreated, preview.personcount)}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="white-space-nowrap">{TranslationService.translate.Client}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {preview.persons.map(x => <tr key={x}><td>{x}</td></tr>)}
                                </tbody>
                            </table>
                        </AccordionItem>}
                        {hasErrors &&
                            <AccordionItem title={replaceNum(TranslationService.translate.RowsWillBeOmittedForContainingInconsistentInformation, preview?.errorcount)}>
                                <ColumnsWithErrors preview={preview} template={template} />
                            </AccordionItem>}
                        {Boolean(preview.additionalitemcount) && <h5 className="mt-4 ms-4">{replaceNum(TranslationService.translate.NewListItemWillBeCreated, preview.additionalitemcount)}</h5>}
                    </> : null}
            </div>
            {
                false && <div className="alert alert-primary" role="alert">
                    <i className="me-2 fa-regular fa-circle-info"></i>{TranslationService.translate.OnceThisFileIsImporteTheTotalDebtWillBe.replace("{companyName}", CompanyService.getCompanyName())} <strong>U$D 129.100.345.396</strong>
                </div>
            }
            <div className="actions">
                <button className="btn btn-primary" onClick={back}>{TranslationService.translate.Back}</button>
                {canImport ?
                    <button className="btn btn-primary ms-3" onClick={submit}>
                        {TranslationService.translate.FinishImport}
                    </button> : null}
            </div>
        </section >
    );
};

const AccordionItemImpl = (props: PropsWithChildren<{ title: string }>) => {
    const idHeader = "accordion-" + v4();
    const idContent = "accordion-" + v4();

    return (<div className="accordion-item">
        <h2 className="accordion-header" id={idHeader}>
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#" + idContent} aria-expanded="false" aria-controls={idContent}>
                {props.title}
            </button>
        </h2>
        <div id={idContent} className="accordion-collapse collapse" aria-labelledby={idHeader}>
            <div className="accordion-body">
                {props.children}
            </div>
        </div>
    </div>);
};

const AccordionItem = React.memo(AccordionItemImpl);

const StatusTable = ({ items, includeIndex, includeAdditionals }: { includeIndex: boolean, includeAdditionals: boolean, items: ImportPreviewResponse.Newio[] }) => {
    if (!items) {
        return <></>;
    }
    return (
        <div className="table-container">
            <table className="table">
                <thead>
                    <tr>
                        {includeIndex && <th></th>}
                        <th className="white-space-nowrap">{TranslationService.translate.Client}</th>
                        <th className="white-space-nowrap">{TranslationService.translate.IssueDate}</th>
                        <th className="white-space-nowrap">{TranslationService.translate.AmountButPendingSometimes}</th>
                        <th className="white-space-nowrap">{TranslationService.translate.Currency}</th>
                        <th className="white-space-nowrap">{TranslationService.translate.DueDate}</th>
                        <th className="white-space-nowrap">{TranslationService.translate.InvoiceNumber}</th>
                        <th className="white-space-nowrap">{TranslationService.translate.Comments}</th>
                        <th className="white-space-nowrap">{CompanyService.getGroupName()}</th>
                        {includeAdditionals && CompanyService.getAdditionalDefinitionsFiltered(Entities.Invoice).map(x => <th key={x.AdditionalDefinitionID} className="white-space-nowrap">{x.Name}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {items.map(x => <tr key={x.Index + x.IOID} className="pointer">
                        {includeIndex && <td className="ellipsis-oneline w-200px">{x.Index}</td>}
                        <td className="ellipsis-oneline w-200px">{x.PersonKey}</td>
                        <td className="ellipsis-oneline w-200px">{formatDateDigits(new Date(x.emittedDate))}</td>
                        <td className="ellipsis-oneline w-200px">{formatCurrency(x.amount, x.CurrencyID)}</td>
                        <td className="ellipsis-oneline w-200px">{x.CurrencySymbol}</td>
                        <td className="ellipsis-oneline w-200px">{MapNotUndefined(x.dueDate, date => formatDateDigits(new Date(date)))}</td>
                        <td className="ellipsis-oneline w-200px">{x.referenceNumber}</td>
                        <td className="ellipsis-oneline w-200px">{x.description}</td>
                        <td className="ellipsis-oneline w-200px">{x.group}</td>
                        {includeAdditionals && x.values.slice(20).map(val => <td key={val} className="ellipsis-oneline w-200px">{val}</td>)}
                    </tr>)}
                </tbody>
            </table>
        </div>);
};

const StatusTableClient = ({ items, includeIndex, includeAdditionals }: { includeIndex: boolean, includeAdditionals: boolean, items: ImportPreviewResponse.NewClient[] }) => {
    return (
        <div className="table-container">
            <table className="table">
                <thead>
                    <tr>
                        {includeIndex && <th></th>}
                        <th className="white-space-nowrap">{TranslationService.translate.Name}</th>
                        <th className="white-space-nowrap">{TranslationService.translate.Contact}</th>
                        <th className="white-space-nowrap">{TranslationService.translate.Email}</th>
                        <th className="white-space-nowrap">{TranslationService.translate.Phone}</th>
                        <th className="white-space-nowrap">{TranslationService.translate.Address}</th>
                        <th className="white-space-nowrap">{TranslationService.translate.Comments}</th>
                        <th className="white-space-nowrap">{CompanyService.getGroupName()}</th>
                        {includeAdditionals && CompanyService.getAdditionalDefinitionsFiltered(Entities.Client).map(x => <th key={x.AdditionalDefinitionID} className="white-space-nowrap">{x.Name}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {items.map(x => <tr key={x.Index + x.Name} className="pointer">
                        {includeIndex && <td className="ellipsis-oneline w-200px">{x.Index}</td>}
                        <td className="ellipsis-oneline w-200px">{x.Name}</td>
                        <td className="ellipsis-oneline w-200px">{x.Contact}</td>
                        <td className="ellipsis-oneline w-200px">{x.Email}</td>
                        <td className="ellipsis-oneline w-200px">{x.Phone}</td>
                        <td className="ellipsis-oneline w-200px">{x.Address}</td>
                        <td className="ellipsis-oneline w-200px">{x.Comments}</td>
                        <td className="ellipsis-oneline w-200px">{/* Group is missing */}</td>
                        {includeAdditionals && CompanyService.getAdditionalDefinitionsFiltered(Entities.Client)
                            .map(add => x.additionalList.find(y => y.Id === add.AdditionalDefinitionID))
                            .map(val => !val ? undefined : <td key={val.AdditionalDefinition} className="ellipsis-oneline w-200px">{val.value}</td>)}
                    </tr>)}
                </tbody>
            </table>
        </div>);
};

const StatusTablePayment = ({ items, includeIndex }: { items: ImportPreviewResponse.NewPayment[], includeIndex: boolean }) => {
    return (
        <div className="table-container">
            <table className="table">
                <thead>
                    <tr>
                        {includeIndex && <th></th>}
                        <th className="white-space-nowrap">{TranslationService.translate.Client}</th>
                        <th className="white-space-nowrap">{TranslationService.translate.InvoiceNumber}</th>
                        <th className="white-space-nowrap">{TranslationService.translate.Date}</th>
                        <th className="white-space-nowrap">{TranslationService.translate.Amount}</th>
                        <th className="white-space-nowrap">{TranslationService.translate.Notes}</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map(x =>
                        <tr key={x.Index + x.Index} className="pointer">
                            {includeIndex && <td className="ellipsis-oneline w-200px">{x.Index}</td>}
                            <td className="ellipsis-oneline w-200px">{x.personName}</td>
                            <td className="ellipsis-oneline w-200px">{x.io.referenceNumber}</td>
                            <td className="ellipsis-oneline w-200px">{MapNotUndefined(x.Date, date => formatDateDigits(new Date(date)))}</td>
                            <td className="ellipsis-oneline w-200px">{formatCurrency(x.Amount || 0)}</td>
                            <td className="ellipsis-oneline w-200px">{x.Notes}</td>
                        </tr>)}
                </tbody>
            </table>
        </div>);
};
const StatusTableComment = ({ items }: { items: ImportPreviewResponse.Item[] }) => {
    return (
        <div className="table-container">
            <table className="table">
                <thead>
                    <tr>
                        <th className="w-40px"></th>
                        <th className="white-space-nowrap">{TranslationService.translate.Date}</th>
                        <th className="white-space-nowrap">{TranslationService.translate.User}</th>
                        <th className="white-space-nowrap">{TranslationService.translate.Client}</th>
                        <th className="white-space-nowrap">{TranslationService.translate.Content}</th>
                        <th className="white-space-nowrap">{TranslationService.translate.Tags}</th>
                        <th className="white-space-nowrap">{TranslationService.translate.ActivityType}</th>
                        <th className="white-space-nowrap">{CompanyService.getGroupName()}</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map(x =>
                        <tr key={x.Index + x.Index} className="pointer">
                            <td className="ellipsis-oneline w-40px">{x.Index}</td>
                            <td className="ellipsis-oneline w-200px">{MapNotUndefined(x.Date, date => formatDateShort(new Date(date)))}</td>
                            <td className="ellipsis-oneline w-200px">{x.user.Fullname}</td>
                            <td className="ellipsis-oneline w-200px">{x.person.Name}</td>
                            <td className="ellipsis-oneline w-200px">{x.content}</td>
                            <td className="ellipsis-oneline w-200px">{x.tagnames}</td>
                            <td className="ellipsis-oneline w-200px">{CompanyService.getActivityTypes().find(y => y.ActivityTypeID === x.ActivityTypeID)?.ActivityTypeName}</td>
                            <td className="ellipsis-oneline w-200px">{x.groupId && <AwaitValue awaitable={GroupService.getGroupName(x.groupId, x.person.PersonId)} />}</td>
                        </tr>)}
                </tbody>
            </table>
        </div>);
};

function indexToExcelLetter(index: number) {
    return OptionalMap(index.toString(26).split(""), split => split.map((num, i) => num.charCodeAt(0) - split.length + 1 + i).map(x => ((x < 58) ? 17 : 10) + x).map((y, i) => String.fromCharCode(y).toUpperCase()).join(""))!;
}

const ColumnsWithErrors = ({ preview, template, showColumnErrors = false }: { preview: ImportPreviewResponse, template: ImportTemplateListResponse.Item, showColumnErrors?: boolean }) => {
    const templateFields = useMemo(() => ImportTemplateService.getTemplateFields(template.Entity), [template.Entity]);
    const errors = useMemo(() => (preview?.errorcount > 0 && (preview?.errors || preview?.errorrecords)) || [], [preview?.errorcount, preview?.errorrecords, preview?.errors]);

    const errorCountPerColumnPerErrorType = useMemo(() => {
        const result: Record<string, Record<number, number>> = {};
        errors && errors?.forEach(x => {
            x.columns.forEach((col, i) => {
                if (col === "") {
                    return;
                }
                result[col] = (result[col] ?? {});
                result[col][x.types[i]] = (result[col][x.types[i]] ?? 0) + 1;
            });
        });
        return result;
    }, [errors]);

    const errorMessages = useMemo(() => [
        "El campo está vacío",
        "El valor no pudo ser interpretado como fecha",
    ], []);

    return (
        <div>
            {showColumnErrors && Object.keys(errorCountPerColumnPerErrorType).map(x => <div key={x}>
                <div><b>{TranslationService.translate.Column} {indexToExcelLetter(parseInt(x) - 1)}</b></div>
                {/* eslint-disable-next-line react/no-unescaped-entities*/}
                {Object.keys(errorCountPerColumnPerErrorType[x]).map(err => <div key={err}>{errorCountPerColumnPerErrorType[x][parseInt(err)]} filas con el error '{errorMessages[parseInt(err)]}'</div>)}
            </div>)}
            <hr />
            {(errors.length > 0) ?
                <div className="table-container">
                    <table className="table">
                        <thead>
                            <tr>
                                <th></th>
                                {errors[0].values.slice(0, -1).map((x, i) => <th key={i} className="white-space-nowrap text-center">
                                    {templateFields.find(x => x.fieldId === template.Columns?.find(x => parseInt(x.ix) === (i + 1))?.name)?.label()}
                                </th>)}
                            </tr>
                            <tr>
                                <th></th>
                                {errors[0].values.map((_, i) => indexToExcelLetter(i)).map(x => <th key={x} className="mw-100 text-center">{x}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {errors.map(x => <tr key={x.cell} className="pointer">
                                {x.values.map((val, i) => <td key={x.cell.toString() + "&" + i.toString()} className={"ellipsis-oneline w-200px" + (x.columns.includes((i).toString()) ? " bg-danger-light" : "")}>{val}</td>)}
                            </tr>)}
                        </tbody>
                    </table>
                </div> : null}
        </div>
    );
};

export default ImportStatus;