import { TransactionTypeEntity } from "../entities/company/TransactionType/TransactionTypeEntity";
import { TransactionTypeListResponse } from "../entities/company/TransactionType/TransactionTypeListResponse";
import RequestService from "./RequestService";

class TransactionTypeService {

    public static get(id: number): Promise<Error | TransactionTypeEntity> {
        return RequestService.post("/transactiontype/get", { id });
    }

    public static set(transactionType: TransactionTypeEntity): Promise<Error | unknown> {
        return RequestService.post("/transactiontype/set", {
            item: {
                TransactionTypeID: transactionType.TransactionTypeID,
                TypeName: transactionType.TypeName
            }
        });
    }

    public static delete(id: number): Promise<Error | unknown> {
        return RequestService.post("/transactiontype/delete", { id });
    }

    public static getList(): Promise<Error | TransactionTypeListResponse> {
        return RequestService.post("/transactiontype/list", {});
    }
}


export default TransactionTypeService;