type SubmitButtonType = {
  text: string;
  onClick?: () => void;
  loading: boolean;
};

const SubmitButton = ({ text, onClick, loading = false }: SubmitButtonType) => {
  return (
    <button
      type="button"
      onClick={() => !loading && onClick && onClick()}
      className="botondemo"
    >
      {text}
      {loading && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
    </button>
  );
};

export default SubmitButton;
