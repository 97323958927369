import React from "react";
import { Link } from "react-router-dom";
import { TranslationService } from "../../services/TranslationService";
import { Cast, Switch } from "../../utils/Utils";

export interface Path {
    name: string;
    link?: (() => void) | string;
}

const Breadcrumb = ({ paths, children }: React.PropsWithChildren<{ paths: Path[] }>) => {
    const { currentLanguage } = TranslationService;
    return (
        <nav className="breadcrumb-divider">
            <ol className="breadcrumb align-items-center">
                {paths.map((path, i) =>
                    <li key={path.name} className={"breadcrumb-item" + ((i === paths.length - 1) ? " active" : "")}>
                        {Switch(
                            [!path.link || (i === paths.length -1), () => <span>{path.name}</span>],
                            [() => typeof path.link === "string", () => <Link to={"/" + currentLanguage + "/" + path.link}>{path.name}</Link>],
                            [() => typeof path.link === "function", () => <span onClick={() => Cast<Function>(path.link)()} className="pointer">{path.name}</span>],
                        )}
                    </li>
                )}
                {children}
            </ol>
        </nav>
    );
}


export default Breadcrumb;