import { useEffect, useMemo, useRef, useState } from "react";
import { Autocomplete } from "./Autocomplete";
import { Subject, concatMap, distinctUntilChanged, throttleTime } from "rxjs";
import { mapIfSuccess } from "../../../utils/RequestUtils";

export const AutocompleteAsync = <T,>({ loadItems, onChange, defaultValue }: {
    loadItems: (val: string) => Promise<{ text: string, value: T }[] | Error>,
    onChange: (value: string) => void,
    defaultValue: string
}) => {
    const [items, setItems] = useState<{ text: string, value: T }[]>([]);
    const autocompleteRecord = useMemo(() => Object.fromEntries(items.map(y => [y.text, y.text])), [items]);
    const onChangeSubject = useRef(new Subject<string>());
    useEffect(() => {
        const subs = onChangeSubject.current.pipe(
            throttleTime(500, undefined, { trailing: true, leading: false }),
            distinctUntilChanged(),
            concatMap(x => loadItems(x)),
        ).subscribe((x) => mapIfSuccess(x, setItems));
        return () => subs.unsubscribe();
    }, [loadItems]);
    return <Autocomplete items={autocompleteRecord} defaultValue={defaultValue}
        onChange={(x) => { onChangeSubject.current.next(x); onChange(x); }} />;
};