import { useState } from "react";
import { TranslationService } from "../../services/TranslationService";
import { FloatingPanelFooter } from "./components/FloatingPanelFooter";
import CompanyService from "../../services/CompanyService";
import { RequiredManager, ValidationMessage } from "./RequieredManager";
import { NewCompanyRequest } from "../../entities/company/NewCompanyRequest";
import { handleErrorWithToast } from "../../utils/RequestUtils";

export const NewCompany = () => {
    const [isSaving, setIsSaving] = useState(false);
    const [company, setCompany] = useState<Partial<NewCompanyRequest>>({ currencySymbol: "$"});
    const requiredManager = new RequiredManager();
    const submit = async () => {
        if (!requiredManager.validate()) {
            return;
        }
        setIsSaving(true);
        await handleErrorWithToast(CompanyService.newCompany(company as NewCompanyRequest), () => setIsSaving(false));
        const companyId = CompanyService.getCompanies().find(x => x.Value === company.name)?.Id;
        
        window.location.href = `/${TranslationService.currentLanguage}/switchcompany?to=${companyId}`;
    };
    const setField = <T extends keyof NewCompanyRequest,>(keyName: T) => (val: NewCompanyRequest[T]) => setCompany(x => ({...x, [keyName]: val}));
    const nameChange = requiredManager.makeRequired(setField("name"));
    const symbolChange = requiredManager.makeRequired(setField("currencySymbol"));
    return <>
        <div className="floatingBody p-4">
            <div className="row mb-2">
                <div className="col-5 col-form-label font-weight-bold">
                    {TranslationService.translate.CompanyName}:
                </div>
                <div className="col-7">
                    <input className="form-control" type="text" defaultValue={company.name} onChange={e => nameChange(e.target.value)}/>
                    <ValidationMessage onChange={nameChange} defaultValue={company.name}/>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-5 font-weight-bold">
                    {TranslationService.translate.MainCurrencySymbol}:
                </div>
                <div className="col-7">
                    <input className="form-control" type="text" onChange={e => symbolChange(e.target.value)} defaultValue={company.currencySymbol} />
                    <ValidationMessage onChange={symbolChange} defaultValue={company.currencySymbol}/>
                </div>
            </div>
        </div>
        <FloatingPanelFooter>
            <button className='btn btn-primary' onClick={submit}>
                {TranslationService.translate.Save}
                {isSaving && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
            </button>
        </FloatingPanelFooter>
    </>;
};