import { useCallback, useEffect, useMemo, useState } from "react";
import ErrorMessage from "../../shared/components/Error";
import Loading from "../../shared/components/Loading";
import FloatingPanelService from "../../shared/FloatingPanel";
import { TranslationService } from '../../../services/TranslationService';
import WhatsappTemplateEdit from "./WhatsappTemplateEdit";
import WhatsappService, { WhatsappTemplateItemType, WhatsappTemplateListType } from "../../../services/WhatsappService";
import { ToastService } from "../../shared/bootstrap/Toast";
import CompanyService, { Entities } from "../../../services/CompanyService";
import { EntityField, sortEntityFields } from "../../shared/entities/EntityField";
import { ClientFields, getClientFieldTitle } from "../../../services/ClientService";

const WhatsappTemplateConfiguration = () => {
    const { translate } = TranslationService;
    const [response, setResponse] = useState<WhatsappTemplateListType>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [synchronizing, setSynchronizing] = useState(false)

    const syncState = synchronizing ? 'rotate' : '';

    const getWhatsappTemplateList = async () => {
        const result = await WhatsappService.getList()
        if (result instanceof Error) {
            ToastService.showToast('Error al obtener templates', undefined, "danger");
            setLoading(false);
            setError(true);
            return;
        } else {
            setResponse(result);
        }
        setLoading(false);
        setError(false);
    }

    const requestDataCallback = useCallback(getWhatsappTemplateList, []);

    const fixedFields = useMemo(() => {

        const clientFieldOptions = [
            ...CompanyService.getAdditionalDefinitionsFiltered(Entities.Client).map(x => new EntityField(x)),
        ]
            .sort(sortEntityFields(CompanyService.getPersonSortedFields().split(",")))
            .map(x => ({ value: x.id.toString(), text: x?.title ?? '' }));

        const insertFieldOptions = [
            { text: TranslationService.translate.InsertField, value: "" },
            ...WhatsappService.getConfigItemsByWhatsApp().map(cl => ({ value: cl.toString(), text: getClientFieldTitle(cl as ClientFields) })),
            ...clientFieldOptions,
        ];
        return insertFieldOptions;
    }, []);

    const templateFormated = (template: WhatsappTemplateItemType) => {
        const dataParameters = template.Parameters;
        const elements = Array.from({ length: dataParameters || 0 }, (_, index) => index + 1);
        const data = elements.map((elIndex) => ({ param: elIndex, data: `{{${elIndex}}}`, valueText: `{{${elIndex}}}` }))

        let texto = template.Structure;

        if (template.FieldList) {
            data.forEach(({ param, valueText }) => {
                texto = texto.replace(`{{${param}}}`,
                    (valueText !== `{{${param}}}` ? `{{${valueText}}}` : false) ||
                    (`{{${fixedFields.find(e => e.value === template.FieldList.split(',')[param - 1])?.text}}}`) ||
                    (`{{${param}}}`)
                );
            });
        }

        return texto
    }

    const showWhatsappTemplateEditModal = (template: WhatsappTemplateItemType) => {
        FloatingPanelService.showPanel({
            children: <WhatsappTemplateEdit template={template} reload={requestDataCallback} />,
            title: translate.WhatsappTemplates,
            width: 900,
            height: 420,
            position: "bottom-right",
        })
    }

    const syncWhatsappTemplate = async () => {
        setSynchronizing(true)
        const result = await WhatsappService.sincroImportTemplates()
            .finally(() => setSynchronizing(false))

        if (result instanceof Error) {
            ToastService.showToast('Error de Sincronización', undefined, "danger");
            return;
        }
        getWhatsappTemplateList()
        ToastService.showToast('Sincronización exitosa', undefined, "success");
    }

    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    if (loading) {
        return (<Loading />)
    }

    if (error) {
        return (<ErrorMessage onRefresh={requestDataCallback} message={translate.ErrorLoadingList} />)
    }

    return (
        <div className="card px-5">
            <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center my-4">
                <h2>{TranslationService.translate.WhatsappTemplates}</h2>
                <div className="d-flex align-items-center">
                    <button className="btn btn-primary" onClick={syncWhatsappTemplate}>
                        <i className={`fal fa-sync ${syncState}`} />
                        {translate.SyncWhatsAppTemplates}
                    </button>
                </div>
            </div>
            <table className="table shadow-hover">
                <thead>
                    <tr>
                        <th>{translate.Template}</th>
                        <th>{translate.Subject}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>

                    {!synchronizing ?
                        response?.list.map(x => (
                            <tr key={x.waid} className="pointer" onClick={() => showWhatsappTemplateEditModal(x)}>
                                <td>{x.Name}</td>
                                <td>{!x?.FieldList ? <><i style={{ color: 'red' }} className="far fa-warning"></i>{' '}</> : null}{templateFormated(x)}</td>
                                <td width={200}><button className="edit"><i className="far fa-pen"></i></button></td>
                            </tr>)) :
                        <Loading />}
                </tbody>
            </table>
        </div>
    )
}
export default WhatsappTemplateConfiguration;