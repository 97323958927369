import { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import AuthenticationContext from "./AuthenticationContext";
import { TranslationService } from "../../services/TranslationService";
import Loading from "./components/Loading";
import { useRequestAborter } from "../../utils/RequestUtils";
import AuthenticationService from "../../services/AuthenticationService";

const SwitchCompany: React.FC<any> = () => {
    const [error, setError] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const { translate, currentLanguage } = TranslationService;
    const { setCurrentAuthentication } = useContext(AuthenticationContext);
    const requestAborter = useRequestAborter();
    useEffect(() => {
        const queryData = new URLSearchParams(location.search);
        setError(false);
        const switchCompany = async () => {
            const result = await requestAborter.next(AuthenticationService.switchCompany(queryData.get("to")!));
            if (result instanceof Error) {
                setError(true);
                return;
            }
            setCurrentAuthentication(result);
            history.replace(`/${currentLanguage}/company`);
        };
        switchCompany()
            .finally(() => {
                history.replace(`/${currentLanguage}/company`);
                window.location.reload();
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //Only once

    return (
        <>
            <div className="messageSwich">
                <div className="messageSwichContent">
                    {error ? (<>
                        <i className="fal fa-exclamation-circle fa-3x mb-3"></i>
                        <p className="lead">{translate.ErrorSwitchingCompany}</p>
                        <p><Link className="btn btn-primary" to={`/${currentLanguage}/logon`}>{translate.GoToLoginButton}</Link></p>
                    </>) : <>
                        <p className="h4">{translate.SwitchingCompany}</p>
                        <Loading />
                        <div className="messageSwichAnim">
                            <div className="messageSwichAnimDot"></div>
                        </div>
                    </>}
                </div>
            </div>
        </>
    )
}

export default SwitchCompany;