import { useMemo } from "react";
import CompanyService from "../../../../services/CompanyService";
import { TranslationService } from "../../../../services/TranslationService";
import Dropdown from "../../../shared/components/Dropdown";
import { ValidationMessage } from "../../../shared/RequieredManager";
import { EditProps, numEvent } from "../EditAction";
import { Field } from "./FieldHelpers";

export const TaskDescription = ({ propGen, data }: EditProps) =>
    <div className="row">
        <Field title={TranslationService.translate.Description} colClass="col-9">
            <textarea className="form-control"
                onChange={e => propGen.str.required("Content").onChange(e.target.value)}
                value={data.Content} style={{ resize: "none" }} rows={6} />
            <ValidationMessage {...propGen.str.required("Content")}
                message={TranslationService.translate.EnterTask} />
        </Field>
    </div>;

export const TaskDue = ({ propGen }: EditProps) =>
    <div className="row">
        <Field title={TranslationService.translate.Expiration}>
            <div className="d-flex align-items-center gap-3">
                <input className="form-control" {...numEvent(propGen.num.required("Task_Due"))} />
                <label>{TranslationService.translate.Days}</label>
            </div>
            <ValidationMessage {...propGen.num.required("Task_Due")}
                message={TranslationService.translate.EnterDue} />
        </Field>
    </div>;

export const TaskResponsableValidator = ({ propGen }: EditProps) => {
    const users = useMemo(() => CompanyService.getUsers().map(x => ({ text: x.Value, value: parseInt(x.Id) })), []);
    return (
        <div className="row">
            <Field title={TranslationService.translate.Responsible}>
                <Dropdown items={users} {...propGen.num.notRequired("Task_ResponsibleUserID")} />
            </Field>
            <Field title={TranslationService.translate.Validator}>
                <Dropdown items={users} {...propGen.num.notRequired("Task_ValidatorUserID")} />
            </Field>
        </div>);
}