import { TextEditor } from "../../../../shared/components/Editors";
import { ImportTemplateListResponse } from "../../../../shared/entities/ImportTemplateListResponse";
import { TranslationService } from "../../../../../services/TranslationService";
import { UpdateModelFunc } from "../ImportTemplateEditBase";
import EditItem from "./EditItem";

const SeparatorSimple = ({ data, updateModel }: { data?: ImportTemplateListResponse.Item, updateModel: UpdateModelFunc }) => {
    const { translate } = TranslationService;
    return (
        <>
            <EditItem label={<>{translate.Separator}</>} ><TextEditor onChange={updateModel("Separator")} defaultValue={data?.Separator} /></EditItem>
        </>
    )
}

export default SeparatorSimple;