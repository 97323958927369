import { useContext } from 'react'
import { InboxContext } from './InboxTableProvider'
import { InboxList } from './InboxList'
import { TranslationService } from '../../services/TranslationService'
import TabPositionContext from '../shared/TabPositionContext'
import CompanyService from '../../services/CompanyService'
import InboxListContext from './InboxListContext'

export enum QUICK_TABS {
    WHATSAPP = 7,
    EMAIL = 8
}

export const InboxTabContent = () => {
    const { inboxCount } = useContext(TabPositionContext);
    const { setRequest, request, loading } = useContext(InboxContext);
    const { setMessageAll, setMessageIds } = useContext(InboxListContext);

    const handleRequest = (quickfilter: number) => {
        setRequest({ ...request, quickfilter })
        setMessageIds([]);
        setMessageAll(false);
    }

    return (
        <>
            <div className="container-fluid padding mb-0 pb-0">
                <section className="">
                    <ul className="nav nav-pills nav-pills_inbox" id="activity-tab" role="tablist">
                        <li className="nav-item nav-item_inbox inbox-tab-container" role="presentation">
                            <button
                                className="nav-link active bg-transparent"
                                onClick={() => handleRequest(QUICK_TABS.EMAIL)}
                                data-bs-toggle="pill" data-bs-target="#activity-tab-pane" type="button" role="tab">
                                <div className='tab_inbox'>
                                    <i className="far fa-envelope me-2"></i> {TranslationService.translate.Email}
                                    {inboxCount?.countMail ? <Counter counter={inboxCount.countMail} /> : null}
                                </div>
                                <span className="rounded"></span>
                            </button>
                        </li>

                        {(CompanyService.canDo("newwhatsapp") && CompanyService.canShowWhatsApp()) &&
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link bg-transparent"
                                    onClick={() => handleRequest(QUICK_TABS.WHATSAPP)}
                                    data-bs-toggle="pill" data-bs-target="#activity-tab-pane" type="button" role="tab">
                                    <div className='tab_inbox'>
                                        <i className="fa-brands fa-whatsapp me-2"></i> {TranslationService.translate.WhatsApp}
                                        {inboxCount?.countWA ? <Counter counter={inboxCount.countWA} /> : null}
                                    </div>
                                    <span className="rounded"></span>
                                </button>
                            </li>
                        }
                    </ul>
                </section>
            </div>
            <InboxList quickfilter={request.quickfilter} loading={loading} />
        </>
    )
}

export const Counter = ({ counter }: { counter: number }) => {
    return (
        <div className="counter-container">
            {counter}
        </div>
    );
};