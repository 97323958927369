import { useCallback, useEffect, useMemo, useState } from 'react'
import { TranslationService } from '../../../services/TranslationService';
import ButtonPrimary from '../../account/components/ButtonPrimary';
import * as yup from "yup";
import CompanyService from '../../../services/CompanyService';
import { TitleConfig } from './components/TitleConfig';

type ReceivablesAgingGraphScaleType = {
    initialValue: string
    fullSize?: boolean
}

export const ReceivablesAgingGraphScale = ({ initialValue = '', fullSize = false }: ReceivablesAgingGraphScaleType) => {
    const [error, setError] = useState('')
    const [saveData, setSaveData] = useState(false)
    const [scale, setScale] = useState(initialValue.split(',').map(parseFloat))

    const formData = useMemo(() => scale.length && {
        days1: scale[0],
        days2: scale[1],
        days3: scale[2],
        days4: scale[3],
        days5: scale[4],
        days6: scale[5],
        days7: scale[6],
    }, [scale])

    const { translate } = TranslationService;

    const formDataSchema = yup.object({
        days1: yup.number().required(translate.EnterAtLeastOnePositiveOneNegativeOneZero).typeError(translate.EnterAtLeastOnePositiveOneNegativeOneZero),
        days2: yup.number().required(translate.EnterAtLeastOnePositiveOneNegativeOneZero).typeError(translate.EnterAtLeastOnePositiveOneNegativeOneZero),
        days3: yup.number().required(translate.EnterAtLeastOnePositiveOneNegativeOneZero).typeError(translate.EnterAtLeastOnePositiveOneNegativeOneZero),
        days4: yup.number().required(translate.EnterAtLeastOnePositiveOneNegativeOneZero).typeError(translate.EnterAtLeastOnePositiveOneNegativeOneZero),
        days5: yup.number().required(translate.EnterAtLeastOnePositiveOneNegativeOneZero).typeError(translate.EnterAtLeastOnePositiveOneNegativeOneZero),
        days6: yup.number().required(translate.EnterAtLeastOnePositiveOneNegativeOneZero).typeError(translate.EnterAtLeastOnePositiveOneNegativeOneZero),
        days7: yup.number().required(translate.EnterAtLeastOnePositiveOneNegativeOneZero).typeError(translate.EnterAtLeastOnePositiveOneNegativeOneZero),
    });

    const handleScaleInputChange = (newValue: any, index: number) => {
        setScale(prevArray => {
            const newArray = [...prevArray];
            newArray[index] = newValue;
            return newArray;
        });
        setSaveData(true)
    }

    const validateValuesRequired = useCallback(() => {
        const auxScale = scale.map(el => el.toString()).map(parseFloat)
        const min = Math.min(...auxScale) < 0
        const max = Math.max(...auxScale) > 0
        const zero = auxScale.includes(0)
        const ascen = auxScale.every((num, index, array) => index === 0 || num >= array[index - 1]);
        return !(min && max && zero && ascen)
    }, [scale])

    const saveValues = useCallback(() => {
        setSaveData(false)
        CompanyService.setConfigCompany({ graphscales: scale.join(', ') })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scale])


    useEffect(() => {
        if (validateValuesRequired()) {
            setError(translate.EnterAtLeastOnePositiveOneNegativeOneZero)
            return
        } else { setError('') }

        formDataSchema.validate(formData)
            .then(() => {
                setError('')
                setSaveData(true)
            })
            .catch((err) => setError(err.errors[0]))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])


    return (
        <div className={`${fullSize ? '' : 'col-md-8'} mx-3`}>
            <TitleConfig title={translate.ReceivablesAgingGraphScale} />
            <div className="row align-items-center">
                {scale.map((scaleItem, index) =>
                    <div key={index} className="col">
                        <input type="number"
                            className="form-control"
                            defaultValue={scaleItem}
                            onChange={({ target: { value } }) => handleScaleInputChange(value, index)} />
                    </div>)}
                <div className="col">{translate.Days}</div>
            </div>
            <div className='d-flex justify-content-between align-items-center my-2'>
                <div id="errorMsj" className="incorrect" style={{ color: "red", fontSize: "14px", margin: "10px", }}>{error}</div>
                {saveData && !error && <ButtonPrimary className='ml-auto p-2' onClick={saveValues}>{translate.Save}</ButtonPrimary>}
            </div>
        </div>
    )
}
