import React, { useCallback, useEffect, useState } from 'react'
import { TranslationService } from '../../../services/TranslationService';
import ButtonPrimary from '../../account/components/ButtonPrimary';
import CompanyService from '../../../services/CompanyService';
import * as yup from "yup";
import { TitleConfig } from './components/TitleConfig';

type CustomerPortalLinkExpirationTimeType = {
    initialValue?: number
    fullSize?: boolean
}

export const CustomerPortalLinkExpirationTime = ({ initialValue = 0, fullSize = false }: CustomerPortalLinkExpirationTimeType) => {
    const [error, setError] = useState('')
    const [saveData, setSaveData] = useState(false)
    const [formData, setFormData] = useState({ customersiteexpiredays: initialValue })

    const { translate } = TranslationService;

    const formDataSchema = yup.object({
        customersiteexpiredays: yup.number()
            .min(0, translate.MinimumValueGreaterThanZero)
            .required(translate.MinimumValueGreaterThanZero)
            .typeError(translate.MinimumValueGreaterThanZero),

    });

    const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevState) => ({ ...prevState, [name]: value }));
        setSaveData(true)
    }

    const saveValues = useCallback(() => {
        setSaveData(false)
        CompanyService.setConfigCompany({ customersiteexpiredays: formData.customersiteexpiredays })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    useEffect(() => {
        formDataSchema.validate(formData)
            .then(() => {
                setError('')
                setSaveData(true)
            })
            .catch((err) => setError(err.errors[0]))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])


    return (
        <div className={`${fullSize ? '' : 'col-md-8'} mx-3`}>
            <div className='d-flex col-md-6 justify-content-between align-items-center'>
                <TitleConfig title={translate.CustomerPortalLinkExpirationTime} className='col-md-9' />
                <div className="col-md-2">
                    <input
                        name='customersiteexpiredays'
                        type="number"
                        className="form-control"
                        defaultValue={formData.customersiteexpiredays}
                        onChange={handleInputChange} />
                </div>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
                <div id="errorMsj" className="incorrect" style={{ color: "red", fontSize: "14px", marginTop: "10px" }}>{error}</div>
                {saveData && !error && <ButtonPrimary onClick={saveValues}>{translate.Save}</ButtonPrimary>}
            </div>
        </div >
    )
}
