import { DataType } from "../../../services/CompanyService";
import { unbreak } from "../../../utils/FormatUtils";

export class TableHeader {
	public label: () => JSX.Element;
	constructor(
		public key: string,
		labelElement: string | (() => JSX.Element),
		public numeric: boolean = false,
		public sorteable: boolean = true,
		public className: string = "",
		public tooltip: string = ""
	) {
		this.label = (typeof labelElement === 'string') ? () => <>{unbreak(labelElement)}</> : labelElement;
	}

}

export class TableHeaderWithFieldId extends TableHeader {
	constructor(
		key: string,
		labelElement: string | (() => JSX.Element),
		numeric: boolean = false,
		sorteable: boolean = true,
		public fieldId: string,
		public type: DataType,
		public className: string = "",
		public tooltip: string = ""
	) {
		super(key, labelElement, numeric, sorteable, className);
	}
}
