import { useMemo } from "react";
import CompanyService, { Entities, DataType } from "../../../../services/CompanyService";
import { TranslationService } from "../../../../services/TranslationService";
import Dropdown from "../../../shared/components/Dropdown";
import { EditProps } from "../EditAction";
import { Field } from "./FieldHelpers";

export const SMSNumberChoose = ({ setField, data }: EditProps) => {
    const items = useMemo(() =>
        [{ text: TranslationService.translate.Phone, value: -1 },
        ...CompanyService.getAdditionalDefinitions()
            .filter(x => x.Entity === Entities.Client && x.Type === DataType.Phone)
            .map(x => ({ text: x.Name, value: x.AdditionalDefinitionID }))], []);
    return (
        <Field title={TranslationService.translate.SendTo}>
            <Dropdown items={items} onChange={setField("SMS_ToTypeField")} defaultValue={data.SMS_ToTypeField ?? -1} />
        </Field>);
};