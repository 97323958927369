
export enum TaskStatus {
    Pending = 0,
    Fulfilled = 1,
    Canceled = 2,
    // Expired = 3,
    Validated = 4,
}

export const getStatusTranslateKey = (status: TaskStatus) => {
    switch (status) {
        case TaskStatus.Pending: return "StatusPending";
        case TaskStatus.Fulfilled: return "StatusFulfilled";
        case TaskStatus.Canceled: return "StatusCanceled";
        // case TaskStatus.Expired: return "StatusExpired";
        case TaskStatus.Validated: return "StatusValidated";
    }
};

export const getStatusList = (): Array<{ status: TaskStatus, translateKey: string }> => {
    const statusList: Array<{ status: TaskStatus, translateKey: string }> = [];
    for (const status of Object.values(TaskStatus)) {
        if (typeof status === "string") {
            continue;
        }
        statusList.push({ status: status, translateKey: getStatusTranslateKey(status) });
    }
    return statusList;
};

export const getStatusListPlural = (): Array<{ status: TaskStatus, translateKey: string }> => {
    const statusList: Array<{ status: TaskStatus, translateKey: string }> = [];
    for (const status of Object.values(TaskStatus)) {
        if (typeof status === "string") {
            continue;
        }
        statusList.push({
            status: status, translateKey: getStatusTranslateKey(status) + "Plural"
        });
    }
    return statusList;
};