import { CompanyAuth } from "../../../../entities/login/CompanyAuth";
import { Currency } from "../../../shared/entities/Company";
import { GroupType } from "../InvoiceGroupWithChildren";

export interface InvoiceListRequest {
  personid?: string,
  quickfilter?: string,
  page?: number,
  pagesize?: number,
  sortcolumn?: string,
  descending?: number,
  groupid?: number,
  filter?: string,
  itemCount?: number,
  includeadditional?: boolean,
  includelastlog?: boolean,
  includeperson?: boolean,
  additionaldefinitions?: CompanyAuth.AdditionalDefinition[],
  currencies?: Currency[],
  iotree?: string,
  text?: string,
}
export class InvoiceListClassRequest {
  personid: string = '-1'
  quickfilter?: | null = null;
  page?: number | null = null;
  pagesize?: number | null = null;
  sortcolumn?: string | null = null;
  descending?: number | null = null;
  groupid?: number | null = null;
  filter?: string | null = null;
  itemCount?: number | null = null;
  includeadditional?: boolean | null = null;
  includelastlog?: boolean | null = null;
  includeperson?: boolean | null = null;
  additionaldefinitions?: CompanyAuth.AdditionalDefinition[] | null = null;
  currencies?: Currency[] | null = null;
  iotree?: string | null = null;
}

export class InvoiceGroupListResponse {
  route: string = '';
  tree: GroupType[] = [];
  maxDEbt: number = 0;
  currencyTotals?: any;
}


export class InvoiceGroupCheckboxSelect {
  stateValue: boolean = false;
  tree: GroupType[] | [] = [];
  Children?: InvoiceGroupCheckboxSelect[];
  maxDEbt: number = 0;
}
