
import { useCallback, useMemo, useState } from "react";
import { calculatePageCount, setCurrentPageHelper, sortHelper, useEndpointData } from "../../utils/TableUtils";
import Table, { TableHeader } from "../shared/Table";
import TableContext from "../task/TableContext";
import { CreditReportListRequest, CreditReportService } from "../../services/CreditReportService";
import { TranslationService } from "../../services/TranslationService";
import FilterService, { FilterCollection, FilterDefinition } from "../../services/FilterService";
import AdvancedFilters, { AdvancedFiltersButton } from "../shared/components/AdvancedFilters";
import CompanyService, { DataType, Entities } from "../../services/CompanyService";
import { CreditReportResponse } from "./entities/CreditReportResponse";
import { formatCurrency, formatInteger } from "../../utils/FormatUtils";
import { useHistory } from "react-router-dom";
import { ButtonTooltipIcon } from "../shared/components/ButtonTooltipIcon";

export const ReportCredit = () => {
    const [request, setRequest] = useState<CreditReportListRequest>({ descending: 1, filter: "", page: 0, pagesize: 10, sortColumn: "credit" });
    const endpoint = useCallback(() => CreditReportService.getList(request), [request]);
    const context = useEndpointData(endpoint);
    context.pageCount = calculatePageCount(context.response);
    context.setCurrentPage = setCurrentPageHelper(setRequest, context.pageCount);
    context.currentPage = context.response?.page ?? 0;
    const sortStatus = sortHelper(request, setRequest);
    context.sort = sortStatus.sort;
    context.setSortColumn = sortStatus.setSortColumn;

    const tableHead = useMemo(() => [
        new TableHeader("name", TranslationService.translate.Client, false, true, "w-200px"),
        new TableHeader("averagePaymentDays", TranslationService.translate.ReportAveragePaymentDays, true, true),
        new TableHeader("averageAgingDays", TranslationService.translate.ReportAverageAgingDays, true, true),
        new TableHeader("credit", TranslationService.translate.Credit, true, true),
        new TableHeader("creditLimit", TranslationService.translate.CreditLimit, true, true),
        new TableHeader("creditPercent", TranslationService.translate.CreditLimitPercent, true, true),
        new TableHeader("bar", "", false, false, "w-200px"),
    ], []);

    const filterOptions = useMemo(() => {
        const filtersDefinitions: FilterDefinition[] = [
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: TranslationService.translate.Name,
                Field: "0",
                Type: DataType.Text,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: TranslationService.translate.Credit,
                Field: "2",
                Type: DataType.Currency,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: TranslationService.translate.CreditLimit,
                Field: "1",
                Type: DataType.Currency,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: TranslationService.translate.CreditPercent,
                Field: "3",
                Type: DataType.Percentage,
            },
            {
                AdditionalDefinitionItems: CompanyService.getSegments().map(x => ({ Label: x.Value, Value: x.Id })),
                Entity: Entities.Client,
                Name: TranslationService.translate.Segment,
                Field: "4",
                Type: DataType.List,
            },
        ];
        return {
            title: TranslationService.translate.FilterData.replace("{EntityName}", TranslationService.translate.Credit),
            entity: Entities.Client,
            definitions: filtersDefinitions,
        };
    }, []);

    return (
        <div className="container-fluid padding">
            <div className="d-flex justify-content-between">
                <h2 className="mb-3">{TranslationService.translate.CreditAnalysis}</h2>
            </div>
            <div className="card mh-100 p-0">
                <div className="genericHeader">
                    <div className="searcherFilterHeader actionButtonsHeader">
                        {CompanyService.canDo("export") &&
                            <ButtonTooltipIcon
                                title={TranslationService.translate.Export}
                                icon="fa-light fa-arrow-down-to-line text-granite-gray"
                                onClick={() => CreditReportService.export(request, context.pageSize * (context.pageCount ?? 1))}
                            />
                        }
                        <AdvancedFiltersButton/>
                    </div>
                </div>
                <div className="pt-0">
                    <AdvancedFilters page={FilterCollection.ReportInvoice} onFilterApply={(filters) => setRequest(x => ({
                        ...x, filter: FilterService.GetExtraFiltersRequestString(filters) ?? ""
                    }))} filterOptions={[filterOptions]} />
                </div>
                <div className="p-4">
                    <TableContext.Provider value={context}>
                        <Table headers={tableHead} item={ReportCreditItem} limitSize={true} />
                    </TableContext.Provider>
                </div>
            </div>
        </div>
    );
};

const ReportCreditItem = ({ data }: { data: CreditReportResponse.Item }) => {
    const difCredit = data.credit - data.CreditLimit;
    const perc = (difCredit > 0 ? (data.CreditLimit / data.credit) : (1 - difCredit / -data.CreditLimit)) * 100;
    const history = useHistory();
    return (<tr className="pointer" onClick={() => history.push(`/${TranslationService.currentLanguage}/client/details?id=${data.PersonId}`)}>
        <td>{data.Name}</td>
        <td className="text-end">{formatInteger(data.averagePaymentDays ?? 0)}</td>
        <td className="text-end">{formatInteger(data.averageAgingDays ?? 0)}</td>
        <td className="text-end">{formatCurrency(data.credit)}</td>
        <td className="text-end">{formatCurrency(data.CreditLimit ?? 0)}</td>
        <td className="text-end">{formatInteger(data.credit / data.CreditLimit * 100)}%</td>
        <td className='popover__wrapper pe-3'>
            <div className="progress progress-amount" style={{ marginBottom: "12px" }}>
                <div className="progress-bar bg-primary bg-gradient progress-bar-stripped bg-opacity-75" style={{ width: perc + "%" }} />
                <div className="progress-bar bg-danger bg-gradient bg-opacity-75" style={{ width: (difCredit > 0 ? (100 - perc) : 0) + "%" }} />
            </div>
            <div className="popover__content">
                <p className="popover__message" style={{ color: "blue" }}>{TranslationService.translate.Credit}: {formatCurrency(data.credit, CompanyService.getDefaultCurrencyId())} {difCredit <= 0 && `(${formatInteger(100 - (difCredit / data.CreditLimit * -100))}%)`}</p>
                {difCredit > 0 ? <p className="popover__message" style={{ color: "red" }}>{TranslationService.translate.CreditExceeded}: {formatCurrency(difCredit, CompanyService.getDefaultCurrencyId())} ({formatInteger(data.credit / data.CreditLimit * 100 - 100)}%)</p>
                    : <p className="popover__message">{TranslationService.translate.CreditAvailable}: {formatCurrency(Math.abs(difCredit), CompanyService.getDefaultCurrencyId())} ({formatInteger(difCredit / data.CreditLimit * -100)}%)</p>}
            </div>
        </td>
    </tr>);
};
