import React, { useState } from "react";
import TableContext from "../task/TableContext";
import { useEndpointData } from "../../utils/TableUtils";
import UserService, { UserGetListRequest } from "../../services/UserService";

const UserListEndpoint = async () => {
    const result = await UserService.getList(new UserGetListRequest());
    if (result instanceof Error) {
        return result;
    }
    return { list: result.users, result }
}

const UserTableProvider = (props: React.PropsWithChildren<{}>) => {
    const value = useEndpointData(UserListEndpoint);
    //const [list, setList] = useState(value.response?.list);
    const [profile, setProfile] = useState("");

    value.applyFilters = (newProfile: string) => {
        if (!value.response) {
            return;
        }

        if (!!newProfile) {
            setProfile(newProfile);
            //setList(value.response.result.users.filter(x => UserService.getRole(x) === profile));
        }
        else {
            setProfile("");
            //setList(value.response.result.users);
        }
    }

    if (value.response) {
        value.response.list = profile === "" ? value.response.result.users : value.response.result.users.filter(x => UserService.getRole(x) === profile);
    }
    //useEffect(() => { value.applyFilters("") }, [value.loading, value.error, value]);

    return (
        <TableContext.Provider value={value}>
            {props.children}
        </TableContext.Provider>
    )
}

export default UserTableProvider;