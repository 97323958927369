//import { TranslationService } from "../../../services/TranslationService";

const StaticHeader: React.FC<{ lang: string }> = (props: { lang: string }) => {

  //const { translate } = TranslationService;

  return (
    <div id="offmenu">
      <header>
        <div className="container">
          <div className="row">
            <div className="col-md-4 col1">
              <a href="https://www.intiza.com/cobranzas">
                <img alt="intiza"
                  src="https://cdn.intiza.com/content/v5/img/intiza-logo.svg"
                  width={145}
                />
              </a>
            </div>
{/*                
            <div className="col-md-4 col2">
              <ul className="mb-0 pl-0">
                <li>
                  <a href="https://www.intiza.com/cobranzas">{translate.Solution}</a>
                </li>
                <li>
                  <a href="https://www.intiza.com/es/home/us">{translate.AboutUs}</a>
                </li>
                <li>
                  <a href="https://www.intiza.com/es/home/contact">{translate.Contact}</a>
                </li>
                {props.lang !== "en" &&
                  <li>
                    <a href="https://www.intiza.com/es/blog">{translate.Blog}</a>
                  </li>}

              </ul>
            </div>
            <div className="col-md-4 col3">
              <a href="/es/logon" className="ingresar">
                {translate.LoginButton.toUpperCase()}
              </a>
            </div>
*/}            
          </div>
        </div>
      </header>
    </div>

  )
}

export default StaticHeader;