const ImportTemplateEditCurrencies = () => {
    return (
        <>
        Inputs a definir
        </>
        
    )

}

export default ImportTemplateEditCurrencies;