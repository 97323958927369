import { useCallback, useEffect, useMemo, useState } from "react";
import Switch from "react-switch";
import { TranslationService } from "../../../services/TranslationService";
import AccountService, { AccountConfigsResponse, AccountSettings } from "../../../services/AccountService";

export const EmailsNotificationsData = () => {
    const [formValues, setFormValues] = useState<AccountConfigsResponse>();

    const keyValueChecked = useMemo(() => formValues && formValues.configs?.length > 0 ?
        formValues.configs.map(({ name, value }) => ({ [name]: value }))
            .reduce((obj: any, item: any) => {
                obj = { ...obj, ...item };
                return obj;
            }, {}) : undefined, [formValues]);


    const loadData = useCallback(async () => {
        const result = await AccountService.getAccountConfig();
        if (result instanceof Error) {
            return;
        }
        setFormValues(result);
    }, []);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="card-body">
            {formValues ? <>
                <ItemCheck
                    defaultValue={Boolean(parseInt(keyValueChecked["dailymailtaskscheck"]))}
                    action='dailymailtaskscheck'
                    loadData={loadData}
                    translate='SendPendingTasks'
                />
                <ItemCheck
                    defaultValue={Boolean(parseInt(keyValueChecked["dailymailduescheck"]))}
                    action='dailymailduescheck'
                    loadData={loadData}
                    translate='SendInvoicesDueToday'
                />
                <ItemCheck
                    defaultValue={Boolean(parseInt(keyValueChecked["weekendcheck"]))}
                    action='weekendcheck'
                    loadData={loadData}
                    translate='SendOnWeekendToo'
                />
            </> : null}
        </div>
    );
};

const ItemCheck = ({
    defaultValue,
    action,
    loadData,
    translate
}: {
    defaultValue: boolean,
    action: keyof AccountSettings,
    loadData: () => void,
    translate: string
}) => {
    const [valueChecked, setValueChecked] = useState(defaultValue);

    const handleChangeValue = useCallback(
        (valueChecked: boolean) => {
            setValueChecked(valueChecked);
            AccountService.setAccountConfig({ "Key": action, "Value": valueChecked })
                .finally(loadData);
        }, [action, loadData]);

    return <div className={"d-flex justify-content-between align-items-center"}>
        <td className="col-auto py-0"><div className="configuration-display-item">{TranslationService.getTranslation(translate)}</div></td>
        <td className="py-0"><Switch name='dailymailtaskscheck' checked={valueChecked} onChange={handleChangeValue} height={24} width={50} /></td>
    </div>;
};