import { TranslationService } from "../../services/TranslationService";
import { useMemo, useState } from "react";
import { FloatingPanelFooter } from "../shared/components/FloatingPanelFooter";
import Label from "../shared/form/Label";
import { ScheduledListEntities, ScheduledRequestItem } from "./entities/ScheduledListResponse";
import Dropdown from "../shared/components/Dropdown";
import { addTimezone, restTimezone } from "../../utils/ParseUtils";
import { Checkbox } from "../action/Edit/components/FieldHelpers";
import CompanyService from "../../services/CompanyService";
import { Cast, OptionalMap } from "../../utils/Utils";
import { ScheduledService } from "../../services/ScheduledService";
import { handleErrorWithToast } from "../../utils/RequestUtils";
import FloatingPanelService, { FloatingPanelMetadata } from "../shared/FloatingPanel";
import { ToastService } from "../shared/bootstrap/Toast";
import ModalService from "../shared/bootstrap/Modal";
import { Link } from "react-router-dom";
import { capitalize, uncapitalize } from "../../utils/FormatUtils";

export const ReportScheduledEditItem = FloatingPanelMetadata({
    width: 725,
    height: 800,
    position: "center",
}, function ReportScheduledEditItemImpl({ data, defaultReportId, reload }: { data?: ScheduledListEntities.ListItem, defaultReportId?: number, reload?: () => void }) {
    const [report, setReport] = useState(data ?? Cast<ScheduledListEntities.ListItem>({ AddSufix: true, Destination: 0, Frequency: 0, Period: 0, Hour: new Date(), report: defaultReportId, Report: defaultReportId, }));
    const [isSaving, setIsSaving] = useState<false | "saving" | "deleting" | "sending">(false);
    const reportNames = useMemo(() => [
        TranslationService.translate.Activity,
        "",
        "",
        TranslationService.translate.ReportInvociesActivities,
        TranslationService.translate.AgingAnalysis,
    ], []);
    const reportLinks = useMemo(() => [
        "report/reportactivity",
        "",
        "",
        "report/ioactivity",
        "report/reportaginggroup",
    ].map(x => `/${TranslationService.currentLanguage}/${x}`), []);

    const getLink = () => {
        const getAgingFilter = (filter: string) => {
            const urlSearchParams = new URLSearchParams(filter);
            let fields = urlSearchParams.get("field") ?? "";
            urlSearchParams.delete("field");
            fields = fields.endsWith(",") ? fields.substring(0, fields.length - 1) : fields;
            return "?filter=" + encodeURIComponent(urlSearchParams.toString()) + "&field=" + fields.replaceAll(/\w*additional-/g, "");
        };
        const query = report.report !== 4 ? ("?filter=" + encodeURIComponent(report.Filter)) :
            getAgingFilter(report.Filter);

        return reportLinks[report.report] + query;
    };

    const frecuencyItems = useMemo(() => [
        { text: TranslationService.translate.Manually, value: 0 },
        { text: TranslationService.translate.Daily, value: 1 },
        { text: TranslationService.translate.Weekly, value: 2 },
        { text: TranslationService.translate.Monthly, value: 3 },
    ], []);
    const hours = useMemo(() => Array.from({ length: 24 }).map((_, i) => ({ text: i.toString().padStart(2, "0") + ":00", value: i })), []);

    const daysOfWeek = useMemo(() => {
        const days = [TranslationService.translate.Sunday, TranslationService.translate.Monday, TranslationService.translate.Tuesday, TranslationService.translate.Wednesday, TranslationService.translate.Thursday, TranslationService.translate.Friday, TranslationService.translate.Saturday,]
            .map((x, i) => ({ name: x, value: 1 << i }));
        days.push(days.shift()!);
        return days;
    }, []);

    const templates = useMemo(() => {
        const templateList = CompanyService.getReportExports()
            .filter(x => x.Report === report.Report)
            .map(x => ({ text: x.Name ?? TranslationService.translate.Default, value: x.ReportExportID }));
        templateList.unshift({ text: TranslationService.translate.Default, value: 0 });
        return templateList;
    }, [report.Report]);

    const setField = <TKey extends keyof ScheduledListEntities.ListItem,>(key: TKey) => (value: ScheduledListEntities.ListItem[TKey]) =>
        setReport(x => ({ ...x, [capitalize(key)]: value, [uncapitalize(key)]: value }));

    const onDelete = () => {
        const deleteSchedule = async () => {
            setIsSaving("deleting");
            await handleErrorWithToast(ScheduledService.deleteScheduledReport(report.ScheduledReportID), () => setIsSaving(false));
            FloatingPanelService.hidePanel();
            reload && reload();
            ToastService.showToast(TranslationService.translate.DeletedSuccessfully, undefined, "success");
        };
        ModalService.showDefaultModal({
            title: TranslationService.translate.Delete,
            message: TranslationService.translate.AreYouSureYouWantToDeleteThisScheduledReportNow,
            onAcceptClick: deleteSchedule,
            acceptButtonClassName: "btn btn-danger",
            acceptButtonLabel: TranslationService.translate.Delete,
        });
    };

    const onRunNow = async () => {
        const runNow = async () => {
            setIsSaving("sending");
            await handleErrorWithToast(ScheduledService.processScheduledReport(report.ScheduledReportID), () => setIsSaving(false));
            FloatingPanelService.hidePanel();
            ToastService.showToast(TranslationService.translate.ProcessRunning);
        };
        ModalService.showDefaultModal({
            title: TranslationService.translate.RunNow,
            message: TranslationService.translate.AreYouSureYouWantToGenerateThisReportNow,
            onAcceptClick: runNow,
            acceptButtonLabel: TranslationService.translate.RunNow,
        });
    };

    const onSave = async () => {
        setIsSaving("saving");
        let dayFrecuency = report.Frequency;
        if (OptionalMap(new Date(report.Hour), x => x.getDate() !== restTimezone(x).getDate())) {
            dayFrecuency = ((dayFrecuency >> 1) & 63) | (dayFrecuency << 6);
        }
        const request: ScheduledRequestItem = {
            addsufix: report.AddSufix,
            day0: Boolean(dayFrecuency & 1),
            day1: Boolean(dayFrecuency & 2),
            day2: Boolean(dayFrecuency & 4),
            day3: Boolean(dayFrecuency & 8),
            day4: Boolean(dayFrecuency & 16),
            day5: Boolean(dayFrecuency & 32),
            day6: Boolean(dayFrecuency & 64),
            destination: report.Destination,
            destinationemail: report.DestinationEmail,
            destinationftp: report.DestinationFTPFolder,
            fileNaming: report.Naming,
            filter: report.Filter,
            frequency: report.Frequency,
            hour: restTimezone(new Date(report.Hour)).getHours(),
            id: report.ScheduledReportID,
            name: report.Name,
            period: report.Period,
            report: report.Report,
            reportexportid: report.ReportExportID,
            reportType: report.Report,
            zipfile: !report.NoZip,
        };
        await handleErrorWithToast(ScheduledService.setScheduledReport(request), () => {
            setIsSaving(false);
            return TranslationService.translate.ErrorProcessingRequest;
        });
        FloatingPanelService.hidePanel();
        reload && reload();
    };

    return (<>
        <div className="floatingBody p-4">
            <Label label={TranslationService.translate.Type}>
                <div>
                    <strong className="btn fw-bold ps-0">{reportNames[report.report]}</strong>
                    {report.ScheduledReportID && <Link to={getLink()} >
                        <button type="button" className="btn btn-link" onClick={() => FloatingPanelService.hidePanel()}>
                            {TranslationService.translate.ViewReport}
                        </button>
                    </Link>}
                </div>
            </Label >
            <Label label={TranslationService.translate.Name}>
                <input className="form-control" onChange={x => setField("Name")(x.target.value)} defaultValue={report.Name} />
            </Label>
            <Label label={TranslationService.translate.Frequency}>
                <Dropdown onChange={setField("Period")} items={frecuencyItems} defaultValue={report.Period} />
            </Label>
            {report.period > 0 && <>
                {report.period === 2 &&
                    <Label label={TranslationService.translate.SpecificDays}>
                        <div className="weekday-buttons-list">
                            {daysOfWeek.map(x =>
                                <span key={x.value} className={(report.Frequency & x.value) === x.value ? "active" : ""}
                                    onClick={() => setReport(y => ({ ...y, Frequency: (y.Frequency ?? 0) ^ x.value }))}>{x.name}
                                </span>)}
                        </div>
                    </Label>}
                {report.period === 3 &&
                    <Label label={TranslationService.translate.DayOfMonth}>
                        <input className="form-control text-start" type="number" onChange={x => setField("Frequency")(x.target.valueAsNumber)} defaultValue={report.Frequency} />
                    </Label>
                }
                <Label label={TranslationService.translate.Hour}>
                    <Dropdown onChange={(value: number) => setReport(x => ({ ...x, Hour: addTimezone(new Date(2024, 10, 10, value, 0, 0, 0)).toString() }))} items={hours} defaultValue={restTimezone(new Date(report.Hour)).getHours()} />
                </Label>
            </>}
            <Label label={TranslationService.translate.Destination}>
                <div className="row">
                    <div className="col-auto">
                        <Dropdown items={[{ text: "Email", value: 0 }, { text: "FTP", value: 1 }]} onChange={setField("Destination")} defaultValue={report.Destination} />
                    </div>
                    <div className="col">
                        <input className="form-control" onChange={x => setField(report.Destination ? "DestinationFTPFolder" : "DestinationEmail")(x.target.value)} defaultValue={report.Destination ? report.DestinationFTPFolder : report.DestinationEmail} />
                    </div>
                </div>
            </Label>
            <Label label={TranslationService.translate.FileName}>
                <input className="form-control" onChange={x => setField("Naming")(x.target.value)} defaultValue={report.Naming} />
            </Label>
            {templates.length > 1 &&
                <Label label={TranslationService.translate.Template}>
                    <Dropdown items={templates} onChange={setField("ReportExportID")} defaultValue={report.ReportExportID} />
                </Label>
            }
            <Checkbox text={TranslationService.translate.CompressFile} defaultValue={!report.NoZip} onChange={x => setField("NoZip")(!x)} />
            <Checkbox text={TranslationService.translate.AddDateFile} defaultValue={report.AddSufix ?? true} onChange={setField("AddSufix")} />
        </div >
        <FloatingPanelFooter>
            {report.ScheduledReportID > 0 && <button className='btn btn-danger' onClick={onDelete} disabled={Boolean(isSaving)}>
                {TranslationService.translate.Delete}
                {isSaving === "deleting" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
            </button>}
            {report.ScheduledReportID > 0 && <button className='btn btn-secondary' onClick={onRunNow} disabled={Boolean(isSaving)}>
                {TranslationService.translate.SendNow}
                {isSaving === "sending" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
            </button>}
            <button className='btn btn-primary me-3' onClick={onSave} disabled={Boolean(isSaving)}>
                {TranslationService.translate.Save}
                {isSaving === "saving" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
            </button>
        </FloatingPanelFooter>
    </>);
});
