import { ChangeEvent, useContext, useMemo, useState } from "react";
import CompanyService from "../../../services/CompanyService";
import SegmentService from "../../../services/SegmentService";
import { TranslationService } from "../../../services/TranslationService";
import ModalService from "../../shared/bootstrap/Modal";
import Dropdown from "../../shared/components/Dropdown";
import { MultiselectEditor } from "../../shared/components/Editors";
import TooltipComponent from "../../shared/TooltipComponent";
import CriteriaContext, { CriterionModel, CriterionType, Operator } from "../providers/CriteriaContext";
import ButtonLink from "./form/ButtonLink";
import Input from "./form/Input";

export type CriterionDefinition = { id: number, name: string, type: CriterionType, tooltip: string | null };
export type AdditionalDefinitionItems = {
    AdditionalDefinitionItemID: number;
    Value: string;
}[];
export type AdditionalDefinition =
    {
        AdditionalDefinitionID: number;
        Name: string;
        Field: number;
        type: number;
        AdditionalDefinitionItems: AdditionalDefinitionItems;
    };
export type ExtraDefinitions = {
    criterionList: CriterionDefinition[],
    clientDefs: AdditionalDefinition[],
    invoiceDefs: AdditionalDefinition[],
}

const Criterion = ({ first, data, extraDefinitions }: { first: boolean, data: CriterionModel, extraDefinitions: ExtraDefinitions }) => {
    const { translate } = TranslationService;
    const { updateCriterion, deleteCriterion } = useContext(CriteriaContext);
    const { clientDefs, criterionList, invoiceDefs } = extraDefinitions;
    const [criterion, setCriterion] = useState(criterionList.find((item) => item.id === data.Field)!);
    const [activityTypes, setActivityTypes] = useState(data.ActivityTypes || "");
    const criterionIsNumber = [6, 19, 7, 20].includes(criterion?.id);
    const handleCriterion = (criterion?: CriterionDefinition) => {
        if (criterion === undefined) {
            updateCriterion(data.key, "Field", 0);
            return;
        }
        if ([CriterionType.Number_Amount, CriterionType.Date, CriterionType.List].includes(criterion.type)) {
            updateCriterion(data.key, "Operator", 0);
        }
        else if ([CriterionType.Invoice, CriterionType.Client].includes(criterion.type)) {
            updateCriterion(data.key, "Data", 0);
            updateCriterion(data.key, "Operator", 3);
            handleDefinition((criterion?.type === CriterionType.Client ? clientDefs : invoiceDefs)[0]);
        }
        updateCriterion(data.key, "Field", criterion.id);
        setCriterion(criterion);
    };

    const handleDefinition = (definition: typeof invoiceDefs[number]) => {
        if ([CriterionType.Number_Amount, CriterionType.Date, CriterionType.List].includes(definition.type)) {
            updateCriterion(data.key, "Operator", 0);
        }
        updateCriterion(data.key, "Data", definition.AdditionalDefinitionID);
    };

    const andOr = [
        { value: false, text: translate.And },
        { value: true, text: translate.Or }
    ];
    const { dateOperators, textOperators, amountOperators, listOperators } = useMemo(() => SegmentService.getOperators(translate), [translate]);

    const currencySymbol = useMemo(() => CompanyService.getCurrencies().find(x => x.CurrencyId === CompanyService.getDefaultCurrencyId())!.Symbol, []);
    const definitions = useMemo(() => (criterion?.type === CriterionType.Client ? clientDefs : invoiceDefs).map(x => ({ text: x.Name, value: x as typeof invoiceDefs[number] })), [clientDefs, criterion?.type, invoiceDefs]);
    const definition = useMemo(() => definitions.find(x => x.value.AdditionalDefinitionID === data.Data)?.value, [definitions, data.Data]);
    const activities = useMemo(() => CompanyService.getActivityTypes().map(x => ({ label: x.ActivityTypeName, value: x.ActivityTypeID.toString() })), []);

    const handleOperator = (value: number) => {
        updateCriterion(data.key, "Operator", value);
    };

    const handleValue = (e: ChangeEvent<HTMLInputElement>) => {
        updateCriterion(data.key, "Value", e.target.value);
    };

    const handleNumberAmount = (e: ChangeEvent<HTMLInputElement>) => {
        if (e) {
            updateCriterion(data.key, "amount", e.target.value);
        }
    };

    const handleAndOr = (ConditionOR: boolean) => {
        updateCriterion(data.key, "ConditionOR", ConditionOR);
    };

    const handleList = (value: string[] | undefined) => {
        if (!value) {
            updateCriterion(data.key, "IDs", "");
            return;
        }
        updateCriterion(data.key, "IDs", value.join(","));
    };

    const handleActivityOperator = (value: number) => {
        let formated = (activityTypes || "").replace("!", "");
        if (value === 5) {
            formated = "!" + formated;
        }
        updateCriterion(data.key, "ActivityTypes", formated);
        setActivityTypes(formated);
        //setListOperator(value);
    };

    const handleActivity = (options: string[] | undefined) => {
        let value = options ? options.join(",") : "";
        if (activityTypes.indexOf("!") > -1) {
            value = "!" + value;
        }
        updateCriterion(data.key, "ActivityTypes", value);
        setActivityTypes(value);
    };

    const onDeleteCriterion = (key: string) => {
        ModalService.showDefaultModal({
            onAcceptClick: () => deleteCriterion(key),
            acceptButtonLabel: TranslationService.translate.Delete,
            acceptButtonClassName: "btn btn-danger",
            message: TranslationService.translate.SureDeleteCriteria,
            title: TranslationService.translate.Delete,
        });
    };

    const definitionFind: (value: string) => AdditionalDefinitionItems[number] | undefined = data.IDs ?
        x => definition?.AdditionalDefinitionItems.find(y => y.AdditionalDefinitionItemID.toString() === x) :
        x => definition?.AdditionalDefinitionItems.find(y => y.Value === x);
    const listValue: { value: string, label: string }[] = definition?.type !== 1 ? [] :
        (data.IDs ?? data.Value).split(",")
            .filter(x => x?.length > 0)
            .map(definitionFind)
            .filterFalsey()
            .map(x => ({ label: x.Value, value: x.AdditionalDefinitionItemID.toString() }));

    return (<>
        <div className={"col-12 mb-0 pt-3 criterion " + (data.highlight ? "rowHighlight" : "")}>
            <div className="d-flex align-items-center">
                <div className="form-row d-flex">
                    <div style={{ width: 84 }}>{!first &&
                        <Dropdown onChange={handleAndOr} defaultValue={data.ConditionOR} items={andOr} />}
                    </div>
                    <Dropdown selectClasses="ps-2 pe-1" onChange={handleCriterion} optionLabel={translate.Select} defaultValue={criterionList.find(x => x.id === data.Field)} items={criterionList.map(x => ({ text: x.name, value: x }))} />
                    <div className="form-group d-flex align-items-center justify-content-center w-25px">
                        {criterion?.tooltip &&
                            <TooltipComponent title={criterion.tooltip}>
                                <i className="fal fa-question-square px-1" />
                            </TooltipComponent>}
                    </div>
                    {CriterionType.Number_Amount === criterion?.type && <>
                        <Dropdown selectClasses="px-1" onChange={handleOperator} items={amountOperators} defaultValue={data.Operator} />
                        <Input className="form-group px-1" type="number" item={data} filterKey="Value" callback={handleValue} withBox={!criterionIsNumber} boxText={criterion.id === 31 ? "%" : currencySymbol} />
                    </>}
                    {CriterionType.Date === criterion?.type && <>
                        <Dropdown selectClasses="px-1" onChange={handleOperator} items={dateOperators} defaultValue={data.Operator} />
                        <Input className="form-group px-1" type="number" item={data} filterKey="Value" callback={handleValue} withBox={true} boxText={TranslationService.translate.Days} />
                    </>}
                    {[CriterionType.Client, CriterionType.Invoice].includes(criterion?.type) && <>
                        <Dropdown selectClasses="px-1" onChange={handleDefinition} items={definitions} value={definition} />
                        {/* text */definition?.type === 0 && <>
                            <Dropdown selectClasses="px-1" onChange={handleOperator} items={textOperators} value={data.Operator} />
                            {![Operator.Empty, Operator.NotEmpty].includes(data.Operator) && <Input className="form-group px-1" type="text" callback={handleValue} item={data} filterKey="Value" />}
                        </>}
                        {/* list */definition?.type === 1 && <>
                            <Dropdown selectClasses="px-1" onChange={handleOperator} items={listOperators} value={data.Operator} />
                            {![Operator.Empty, Operator.NotEmpty].includes(data.Operator) &&
                                <MultiselectEditor placeholder={translate.Select} callback={handleList} items={definition.AdditionalDefinitionItems.map(x => ({ label: x.Value, value: x.AdditionalDefinitionItemID.toString() }))} value={listValue} />
                            }
                        </>}
                        {/* date */definition?.type === 2 && <>
                            <Dropdown selectClasses="px-1" onChange={handleOperator} items={dateOperators} value={data.Operator} />
                            {![Operator.Empty, Operator.NotEmpty].includes(data.Operator) && <Input className="form-group px-1" type="number" callback={handleValue} item={data} filterKey="Value" withBox={true} boxText="dias" />}
                        </>}
                        {/* amount */definition?.type === 3 && <>
                            <Dropdown selectClasses="px-1" onChange={handleOperator} items={dateOperators} value={data.Operator} />
                            {![Operator.Empty, Operator.NotEmpty].includes(data.Operator) && <Input className="form-group px-1" type="number" callback={handleNumberAmount} item={data} filterKey="Value" withBox={true} boxText={currencySymbol} />}
                        </>}
                    </>}
                    {CriterionType.ActivityType === criterion?.type && <>
                        <Dropdown selectClasses="px-1" onChange={handleOperator} items={dateOperators} defaultValue={data.Operator} />
                        <Input className="form-group px-1" type="number" callback={handleValue} item={data} filterKey="Value" withBox={true} boxText={translate.Days} />
                        <Dropdown selectClasses="px-1" onChange={handleActivityOperator} items={listOperators.slice(0, 2)} defaultValue={activityTypes.indexOf("!") > -1 ? 5 : 0} />
                        <MultiselectEditor items={activities} placeholder={translate.SelectActivityType} callback={handleActivity} defaultValue={activities.filter(x => data.ActivityTypes.split(",").includes(x.value))} className="mw-250" />
                    </>}
                </div>
                <div className="ms-auto">
                    <TooltipComponent title={TranslationService.translate.Delete}>
                        <ButtonLink className="a-button btn btn-link" onClick={() => onDeleteCriterion(data.key)} >
                            <i className="fa-solid fa-trash text-danger" />
                        </ButtonLink>
                    </TooltipComponent>
                </div>
            </div>
        </div>
    </>);
};

export default Criterion;
