import CompanyService from '../../../services/CompanyService';
import { TranslationService } from '../../../services/TranslationService';
import { DropdownSelectWrapper } from './OtherConfigurationItems/DropdownSelectWrapper';
import { ToggleEnableActionWrapper, convertBool } from './OtherConfigurationItems/ToggleEnableActionWrapper';

type CreateActivityWhenInvoiceStatusChangeType = {
    iostatuscomment: 'False' | 'True',
    iostatuscommentactivitytype: number,
}

export const CreateActivityWhenInvoiceStatusChange = ({ iostatuscomment, iostatuscommentactivitytype }: CreateActivityWhenInvoiceStatusChangeType) => {
    const listItemsFields = CompanyService.getActivityTypes().map(x => ({ text: x.ActivityTypeName, value: x.ActivityTypeID }))

    return (
        <div className='col justify-content-between align-items-center'>
            <ToggleEnableActionWrapper
                title="CreateActivityWhenInvoiceStatusChange"
                action="iostatuscomment"
                initialValue={iostatuscomment} />

            <div className='my-3'>
                {convertBool[iostatuscomment] &&
                    <DropdownSelectWrapper
                        title="ActivityType"
                        action="iostatuscommentactivitytype"
                        initialValue={iostatuscommentactivitytype}
                        items={[{ value: 0, text: TranslationService.translate.None }, ...listItemsFields]}
                    />}
            </div>
        </div>
    )
}
