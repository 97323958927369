import { EntityField } from "../../shared/entities/EntityField";
import { Client } from "./ClientGetResponse";

export class ClientEdit {
    additionals: AdditionalFieldValue[] = [];
    Name?: string;
    Contact?: string;
    Phone?: string;
    Address?: string;
    Comments?: string;
    customerSite?: string;
    Email?: string;
    CreditLimit?: number;
    Created?: string;
    CreatorUserID?: number;
    PersonId?: number;
    groupedperson?: GroupPersona[];
    groupedpersonids?: string;

    constructor(client?: Client, loggedUserId?: number, clientFields?: EntityField[]) {
        this.additionals = client?.additionals.map(x => ({ Id: x.id, Value: x.value })) ?? clientFields?.filter(x => !x.isDefault()).map(x => ({ Id: x.id, Value: "" })) ?? [];
        this.Name = client?.Name;
        this.Contact = client?.Contact;
        this.Phone = client?.Phone;
        this.Address = client?.Address;
        this.Comments = client?.Comments;
        this.customerSite = client?.customerSite;
        this.PersonId = client?.PersonId;
        this.Created = client?.Created ?? new Date().toJSON();
        this.CreatorUserID = client?.CreatorUserID ?? loggedUserId;
        this.groupedperson = client?.GroupedPerson?.map(x => ({ PersonId: x.PersonId })) ?? [];
        this.Email = client?.Email;
        this.CreditLimit = client?.CreditLimit;
    }
}

class AdditionalFieldValue {
    Id: number = 0;
    Value: string = '';
}

class GroupPersona {
    PersonId: number = 0;
}