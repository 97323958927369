import { useMemo, useState } from "react";
import CompanyService, { DataType, Entities } from "../../../services/CompanyService";
import InvoiceService from "../../../services/InvoiceService";
import { ToastService } from "../../shared/bootstrap/Toast";
import Dropdown from "../../shared/components/Dropdown";
import { EntityField } from "../../shared/entities/EntityField";
import { TranslationService } from "../../../services/TranslationService";
import FieldEdit from "./InvoiceMassiveChangesFieldEdit";
import ModalService from "../../shared/bootstrap/Modal";
import { InvoiceListRequest } from "./entities/InvoiceListRequest";
import { ClientDetailContextValues } from "../ClientDetailContext";
import { formatDateDigitsMin } from "../../../utils/FormatUtils";

class RowParams {
    constructor(
        public id: number,
        public removeRow: (id: number) => void,
        public onChange: (rowId: number, field: EntityField | undefined, value: string | undefined) => void,
        public personId: string,
    ) { }
}

const InvoiceMassiveEditFieldsRow = ({ id, removeRow, onChange, personId }: RowParams) => {
    const { translate } = TranslationService;
    const [field, setField] = useState<EntityField>();
    const fields = useMemo(() => [
        ...CompanyService.getConfigItemsFiltered(Entities.Invoice)
            .filter(x => x.ManualEdit).map(x => new EntityField(x)),
        ...CompanyService.getAdditionalDefinitionsFiltered(Entities.Invoice, undefined, true, undefined).map(x => new EntityField(x)),
    ].filter(x => ![-9, -4, -1].includes(x.id)), []);
    // Filtrados cliente, identificador y estado

    const onFieldChange = (fieldId?: number) => {
        const selectedField = fields.find(x => x.id === fieldId);
        setField(selectedField);
        onChange(id, selectedField, undefined);
    };

    return (
        <div className="filter-row  mb-3">
            <div className="row gap-3 me-2">
                <div className="col">
                    <Dropdown optionLabel={translate.Select} onChange={onFieldChange} items={fields.map(x => ({ value: x.id, text: x.title ?? "" }))} />
                </div>
                <div className="col">
                    {field && <FieldEdit field={field} onChange={(value) => onChange(id, field, value)} onlyEditor={true} personId={personId} />}
                </div>
            </div>
            <div className="delete">
                <button className="btn" onClick={() => removeRow(id)}><i className="fas fa-times"></i></button>
            </div>
        </div>
    );
};
export const InvoiceMassiveEditFields = ({ personId, clientDetailContext, onSubmit, getFilters }: { personId: string, clientDetailContext: ClientDetailContextValues, onSubmit: () => void, getFilters: () => InvoiceListRequest }) => {
    const { translate } = TranslationService;
    const { invoiceAll, invoiceIds, setInvoiceAll, setInvoiceIds } = clientDetailContext;
    const rowId = () => new Date().getTime();
    const [isSaving, setIsSaving] = useState(false);
    const [rowList, setRowList] = useState<{ rowId: number, fieldId?: number, value?: string }[]>([{ rowId: rowId() }]);


    const addRow = () => setRowList([...rowList, { rowId: rowId() }]);
    const removeRow = (id: number) => setRowList([...rowList.filter(x => x.rowId !== id)]);
    const rowOnChange = (rowId: number, field: EntityField | undefined, value: string | undefined) => {
        const row = rowList.find(x => x.rowId === rowId);
        if (!row) { throw new Error(); }
        if (row.fieldId !== field?.id) { row.value = undefined; }
        row.fieldId = field?.id;
        if (field === undefined) { return; }
        if (value && field.type === DataType.Date) {
            value = formatDateDigitsMin(value);
        }
        row.value = value;
        setRowList(rowList);
    };

    const submit = async () => {
        if (isSaving) { return; }
        setIsSaving(true);
        const result = await InvoiceService.editMultiple({
            invoiceIds: invoiceIds,
            all: invoiceAll,
            filters: getFilters().filter,
            personId,
            fields: rowList.filter(x => x.fieldId !== undefined).map(x => ({ field: x.fieldId?.toString() ?? "", value: x.value ?? "" }))
        });
        if (result instanceof Error) {
            ToastService.showToast(translate.ErrorProcessingRequest, undefined, "danger");
        }
        else {
            ToastService.showToast(translate.SavedChanges, undefined, "success");
            setInvoiceAll(false);
            setInvoiceIds([]);
            onSubmit && onSubmit();
            setRowList([{ rowId: rowId() }]);
        }
        setIsSaving(false);
    };

    return (
        <>
            <div className="modal-body">
                <p className="mb-4">{translate.MultiInvoiceEditSubtitle}</p>
                {rowList.map(x => <InvoiceMassiveEditFieldsRow removeRow={removeRow} key={x.rowId} id={x.rowId} onChange={rowOnChange} personId={personId} />)}
                <button type="button" className="btn btn-intiza ps-0" onClick={addRow}>
                    <i className="far fa-plus me-2"></i>
                    {translate.Add}
                </button>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={ModalService.hideModal}>{translate.Close}</button>
                <button className='btn btn-primary me-3' onClick={() => submit()}>
                    {translate.Save}
                </button>
            </div>
        </>
    );
};