import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { TranslationService } from "../../services/TranslationService";
import Breadcrumb, { Path } from "./Breadcrumb";
import { NavigationHistoryContext } from "./NavigationHistory";

export interface PathHistory {
    pathHistory?: Path[];
}

const BreadcrumbHistory = ({ defaultPrevious, current, children }: React.PropsWithChildren<{ defaultPrevious: Path, current: Path }>) => {
    const navHistory = useContext(NavigationHistoryContext);
    const paths = navHistory.pathHistory ? [...navHistory.pathHistory] : [defaultPrevious];
    paths.push(current);
    navHistory.setCurrentPath(current);
    return (
        <Breadcrumb paths={paths}>
            {children}
        </Breadcrumb>
    );
}

export const LinkWithHistory = ({ to, children }: React.PropsWithChildren<{ to: string | (() => void) }>) => {
    const history = useHistory<PathHistory>();
    const { conserveHistory } = useContext(NavigationHistoryContext);
    const go = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        conserveHistory();
        if (typeof to === "string") {
            history.push(`/${TranslationService.currentLanguage}/${to}`);
        } else {
            to();
        }
    }
    return (<span onClick={go} className="pointer">{children}</span>);
}

export default BreadcrumbHistory;