import { useEffect, useMemo, useState } from "react";
import CompanyService from "../../../../services/CompanyService";
import { ToastService } from "../../../shared/bootstrap/Toast";
import { ImportTemplateListResponse } from "../../../shared/entities/ImportTemplateListResponse";
import { TranslationService } from '../../../../services/TranslationService';
import { TemplateField, sheetColumns } from "./ImportTemplateEditBase";

import ImportTemplateService from "../../../../services/ImportTemplateService";
import FloatingPanelService from "../../../shared/FloatingPanel";
import { RequiredManager, ValidationMessage } from "../../../shared/RequieredManager";
import { FloatingPanelFooter } from "../../../shared/components/FloatingPanelFooter";
import { HorizontalItem, ReferenceItem, getAvailableSheetColumns } from "./Components/FieldList";
import InvoiceService from "../../../../services/InvoiceService";
import Dropdown, { DropdownItems } from "../../../shared/components/Dropdown";
import ClientService from "../../../../services/ClientService";
import Loading from "../../../shared/components/Loading";
import { handleErrorWithToast } from "../../../../utils/RequestUtils";
import { Cast } from "../../../../utils/Utils";

const ImportTemplateEditClientExportTemplate = ({ data, reload, importsList = undefined, entityId = undefined }: { data?: ImportTemplateListResponse.Item, reload: () => void, importsList?: ImportTemplateListResponse, entityId?: number }) => {
    const { translate } = TranslationService;
    const [model, setModel] = useState<Parameters<typeof ImportTemplateService.setExportTemplate>[1]>([]);
    const [loading, setLoading] = useState(true);
    const [submiting, setSubmiting] = useState(false);
    const requiredManager = useMemo(() => new RequiredManager(), []);

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const result = await handleErrorWithToast(ImportTemplateService.getExportTemplate(data!.ImportTemplateID));
            setModel(result.list);
            setLoading(false);
        };
        getData();
    }, [data])

    const onSubmit = async () => {
        if (submiting) {
            return;
        }
        setSubmiting(true);
        const result = await ImportTemplateService.setExportTemplate(data!.ImportTemplateID, model);
        if (result instanceof Error) {
            ToastService.showToast(translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
        ToastService.showToast(translate.ImportTemplateSaved, undefined, "success");
        setSubmiting(false);
        reload();
        FloatingPanelService.hidePanel();
    }
    const findValueOrDefault = (field: string) => 
        model.find(y => y.Field?.toString() === field)?.Position.toString() ?? "";

    const requiredIds = ["contact"];
    const invoiceFields: TemplateField[] = InvoiceService.getTableHeaders(false).map(x => (
        {
            fieldId: x.fieldId,
            key: x.key,
            label: x.label,
            value: findValueOrDefault(x.fieldId)
        }));

    let createdDate = invoiceFields.find(x => x.fieldId === "emittedDate")!;
    createdDate.fieldId = "createdDate";
    createdDate.key = "createdDate";

    const amountField = invoiceFields.find(x => x.fieldId === "amount")!;
    amountField.label = () => <>{TranslationService.translate.AmountButPendingSometimes}</>;

    const descriptionField = invoiceFields.find(x => x.fieldId === "description")!;
    descriptionField.key = "description";

    const pendingIndex = invoiceFields.findIndex(x => x.key === "pending");
    if (pendingIndex >= 0) {
        invoiceFields.splice(pendingIndex, 1);
    }

    invoiceFields.unshift({
        fieldId: "group",
        key: "group",
        label: () => <>{CompanyService.getGroupName()}</>,
    }, {
        fieldId: "contact",
        key: "contact",
        label: () => <>CUIT</>,
    }, {
        fieldId: "currency",
        key: "currency",
        label: () => <>{translate.Currency}</>,
    });

    const clientFields: TemplateField[] = ClientService.getClientHeaders().map(x => (
        {
            fieldId: x.fieldId,
            key: x.key,
            label: x.label,
            value: findValueOrDefault(x.fieldId),
        }));

    const updateColumn = (data: TemplateField) => (value?: string | number) => {
        const name = data.fieldId;
        const column = {
            Position: value,
            Field: name,
            Entity: "",
        }

        setModel(x => {
            let Columns = x.filter(x => x.Field?.toString() !== name) ?? [];
            if (column && column.Position !== "") {
                Columns.push(Cast<(typeof x)[number]>(column));
            }
            return Columns;
        });
    }
    
    const availableSheetColumns = useMemo(() => getAvailableSheetColumns([], sheetColumns), []);
    function addOwnValue(availableSheetColumns: DropdownItems<string> | { Value: string; Id: string; }[], value: string | undefined): DropdownItems<string> | { Value: string; Id: string; }[] {
        const sheets = availableSheetColumns as { value: string; text: string; }[];
        if (value === undefined || sheets.find(x => x.value === value)) {
            return sheets;
        }
        const valueNum = parseInt(value);
        if (Number.isNaN(valueNum)) {
            return sheets;
        }
        const insertIndex = sheets.findIndex(x => parseInt(x.value) > valueNum);
        const sheetValue = sheetColumns.find(x => x.value === valueNum)!;
        return [...sheets.slice(0, insertIndex), { text: sheetValue.text, value: sheetValue.value.toString() }, ...sheets.slice(insertIndex)];
    }
    const renderFieldData = (fieldData: TemplateField[]) =>
        fieldData.map(x => {
            const value = findValueOrDefault(x.fieldId);
            return (
                x.fieldId === "referenceNumber" ? <ReferenceItem key={x.key} data={x} onChange={requiredManager.makeRequiredWithId(updateColumn(x), x.fieldId)} value={value?.toString()} /> :
                    requiredIds.includes(x.fieldId) ?
                        <HorizontalItem key={x.key} label={x.label()} >
                            <Dropdown onChange={requiredManager.makeRequiredWithId(updateColumn(x), x.fieldId)} items={addOwnValue(availableSheetColumns, value)} optionLabel={translate.Select} selectClasses={"w-sm"} defaultValue={value?.toString()} />
                            <ValidationMessage onChange={requiredManager.getValidation(x.fieldId)!} defaultValue={value} />
                        </HorizontalItem>
                        :
                        <HorizontalItem key={x.key} label={x.label()} ><Dropdown onChange={updateColumn(x)} items={addOwnValue(availableSheetColumns, value)} optionLabel={translate.Ignore} selectClasses={"w-sm"} defaultValue={value?.toString()} /></HorizontalItem>
            )
        })
    if (loading) {
        return <Loading />
    }
    return (
        <>
            <div className="floatingBody p-4">
                <div className="mb-3 mt-4">
                    <h5 className="mb-4">{translate.ConfigureAutomaticClientUpdateProcess} </h5>
                </div>
                <div className="mb-3 mt-4">
                    <h4 className="mb-4">{translate.InvoiceFields} </h4>
                </div>
                {renderFieldData(invoiceFields)}
                <div className="mb-3 mt-4">
                    <h4 className="mb-4">{translate.ClientFields} </h4>
                </div>
                {renderFieldData(clientFields)}
            </div>
            <FloatingPanelFooter>
                <button className='btn btn-primary' onClick={onSubmit}>
                    {translate.Save}
                    {submiting && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </FloatingPanelFooter>
        </>
    )
}
export default ImportTemplateEditClientExportTemplate;
