import { ReactElement, useContext, useState } from "react";
import axios from "axios";
import * as yup from "yup";

import ErrorContext from "../shared/ErrorContext";
import { TranslationService } from "../../services/TranslationService";
import SubmitButton from "./components/SubmitButton";
import Form from "./components/Form";
import { BackButton } from "./components/BackButton";

function RecoverPassword(): ReactElement {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const { translate } = TranslationService;
  const { setCurrentError } = useContext(ErrorContext);

  const formDataSchema = yup.object({
    email: yup
      .string()
      .email(translate.LoginEmailIncorrect)
      .required(translate.LoginEmailMissing),
  });

  async function handleRecoverPassword(
    event: React.FormEvent<HTMLFormElement>
  ) {
    event.preventDefault();
    setCurrentError(undefined);
    formDataSchema
      .validate({ email })
      .then(async () => {
        setLoading(true);
        await axios
          .post(process.env.REACT_APP_INTIZA_AUTH + "/auth/recover", { email })
          .then((res) => res.data.response === "Ok" && setShowMessage(true))
          .catch(console.error)
          .finally(() => setLoading(false));
      })
      .catch(({ errors }) => setCurrentError(errors[0]));
  }

  const handleInputChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => setEmail(value);

  if (showMessage) {
    return (
      <Form>
        <h5 style={{ textAlign: "justify" }}>
          {translate.InstructionsRecoverPassword}
        </h5>
        <p style={{ marginBottom: 15 }}></p>
      </Form>
    );
  }

  return (
    <form onSubmit={handleRecoverPassword} noValidate>
      <div className="box">
        <h1>{translate.RecoverPassword}</h1>
        <p style={{ marginBottom: 10 }}>
          {translate.Subtitle1}.
          <br />
          {translate.Subtitle2}.
        </p>
        <div className="input-group">
          <input
            type="email"
            className="email"
            placeholder="E-mail"
            name="email"
            onChange={handleInputChange}
          />
          <i className="fas fa-user icono-input"></i>
        </div>
        <div className="login-btn" id="recover">
          {loading ? (
            <SubmitButton
              text={translate.Send.toUpperCase()}
              loading={loading}
            />
          ) : (
            <input type="submit" value={translate.Send.toUpperCase()} />
          )}
        </div>
        <BackButton />
      </div>
    </form>
  );
}

export default RecoverPassword;
