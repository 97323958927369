import { useContext, useEffect } from "react";
import { TranslationService } from "../../../services/TranslationService";
import Dropdown from "../../shared/components/Dropdown";
import TableContext from "../../task/TableContext";
import CompanyService, { DataType, Entities } from "../../../services/CompanyService";
import InvoiceService from "../../../services/InvoiceService";
import { InvoiceTableContextValues } from "./InvoiceTableProvider";
import InvoiceNew from "./InvoiceNew";
import FloatingPanelService from "../../shared/FloatingPanel";
import { NewReciept } from "../components/NewReceipt";
import AdvancedFilterService from "../../../services/AdvancedFilterService";
import ClientDetailContext from "../ClientDetailContext";
import MenuButton from "../../shared/components/MenuButton";
import { addIf } from "../../../utils/Utils";
import { AdvancedFiltersButton } from "../../shared/components/AdvancedFilters";

const InvoiceTableHeader = ({ client, ioTreeFilters, reload }: { client: { PersonId: number, Name: string }, ioTreeFilters: string, reload: () => void }) => {
    const { translate } = TranslationService;
    const tableContext = useContext(TableContext);
    const clientDetailContext = useContext(ClientDetailContext);
    const { applyFilters } = tableContext;

    const exportInvoices = async (reportId: number | null) => {
        const { getFilters, response } = tableContext as InvoiceTableContextValues;
        InvoiceService.export({ ...getFilters(), reid: reportId }, response!.itemCount);
    };

    const fixedFiltersChange = (value: number) => {
        applyFilters({ quickfilter: value, quickExtraFilter: "" });
    };

    const fixedFilterChildChange = (value: number, parentTitle: string) => {
        if (parentTitle === translate.ByStatus) {
            const definition = {
                AdditionalDefinitionItems: CompanyService.getIoStatus().map(x => ({ Label: x.Value, Value: x.IOStatusID.toString() })),
                Entity: Entities.Invoice,
                Name: translate.IOStatus,
                Field: "iostatus",
                Type: DataType.List
            };

            const filter = AdvancedFilterService.filterToString({
                kind: DataType.List,
                definition: definition,
                value: value.toString(),
                operator: 0
            });
            applyFilters({ quickfilter: "2", quickExtraFilter: filter });
        }
    };

    const statusList = CompanyService.getIoStatus();

    const invoiceFilters = [
        { value: 1, text: translate.PendingInvoices },
        { value: 0, text: translate.DueInvoices },
        { value: 4, text: translate.ClaimableInvoices },
        { value: 2, text: translate.AllInvoices },
        {
            text: translate.ByStatus, childItems: statusList.map(x => {
                return { text: x.Value, value: x.IOStatusID };
            })
        }
    ];

    const showNewInvoiceModal = () => {
        FloatingPanelService.showPanel({
            children: <InvoiceNew reload={reload} client={client} />,
            title: translate.NewInvoice,
            height: 800,
            width: 750,
        });
    };

    const showNewRecieptModal = () => {
        FloatingPanelService.showPanel({
            children: <NewReciept personId={client.PersonId} invoiceIds={clientDetailContext.invoiceIds} />,
            title: translate.NewReciept,
            height: 800,
            width: 750,
        });
    };

    const exportItems = [
        {
            key: translate.Default,
            onClick: () => exportInvoices(null),
        },
        ...CompanyService.getReportExports()
            .filter(x => x.Report === 1)
            .map(x => ({ key: x.Name ?? TranslationService.translate.Default, onClick: () => exportInvoices(x.ReportExportID) })),
    ].map((x, i) => ({ text: x.key, action: x.onClick }));

    useEffect(() => {
        applyFilters({ iotree: ioTreeFilters });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ioTreeFilters]);

    const newActions = [
        ...addIf(CompanyService.canDo("newio") && !CompanyService.getSetting("hidecreateio"), { text: TranslationService.translate.AddInvoice, action: showNewInvoiceModal }),
        ...addIf(CompanyService.canDo("hasReceipt"), { text: TranslationService.translate.NewReciept, action: showNewRecieptModal }),
    ];

    return (
        <div className="genericHeader" id="invoice-table-header">
            <div className="d-flex gap-3 align-items-center">
                <MenuButton extraClassName="select-export" text={TranslationService.translate.New} actions={newActions} icon="fa-light fa-plus" />

                {CompanyService.canDo("export") &&
                    <MenuButton text={translate.ExportToXls} actions={exportItems} extraClassName="select-export text-granite-gray" icon="fa-light fa-arrow-down-to-line" />
                }
                <AdvancedFiltersButton />
                <Dropdown quickFilter onChange={fixedFiltersChange} onChildItemChange={fixedFilterChildChange} items={invoiceFilters} />
            </div>
        </div>
    );
};

export default InvoiceTableHeader;