import { SectionsData, TitleRefs } from "./EditAction";

export const ListTitles = ({ sections, titleRefs }: { sections: SectionsData, titleRefs: React.MutableRefObject<TitleRefs> }) => {
    const { headings, listItems } = titleRefs.current;
    const setupSectionsRef = (index: number) => (li: HTMLLIElement | null) => {
        if (li === null) {
            return;
        }
        listItems[index] = li;
        if (listItems.length === 1) {
            li.classList.add("highlight");
        }
    }
    return (
        <ul>
            {sections.filter(x => x.name !== undefined).map((x, i) => 
            <li key={i} ref={setupSectionsRef(i)} onClick={() => {
                headings[i].parentElement!.scrollIntoView();
                listItems.forEach(section => {
                    section.classList.remove("highlight");
                });
                listItems[i].classList.add("highlight");
            }}>{x.name}</li>)}
        </ul>);
}