import { useHistory } from "react-router-dom";
import ClientService from "../../services/ClientService";
import { TranslationService } from "../../services/TranslationService";
import { RequestAborter } from "../../utils/RequestUtils";
import { ClientListRequest } from "./entities/ClientListRequest";
import { ClientEntities, ClientListResponse } from "./entities/ClientListResponse";
type History = ReturnType<typeof useHistory>;
export interface ListIterator {
    goNext: (history: History) => Promise<void>;
    goPrev: (history: History) => Promise<void>;
    hasNext: () => boolean;
    hasPrev: () => boolean;
}

export class ClientIterator implements ListIterator {
    requestAborter: RequestAborter = new RequestAborter();
    items: ClientEntities.ListItem[][] = [];
    constructor(private request: ClientListRequest,
        private response: ClientListResponse,
        private index: number,
        items?: ClientEntities.ListItem[][]) {
        if (items === undefined) {
            this.items[this.response.page] = this.response.list;
        } else {
            this.items = items;
        }
    }
    private async go(newIndex: number, history: History) {
        this.request.page = this.request.page + Number(newIndex >= this.request.pagesize) - Number(newIndex < 0);
        newIndex = (newIndex >= this.request.pagesize) ? 0 : (newIndex < 0) ? (this.request.pagesize - 1) : newIndex;
        if (this.items[this.request.page] === undefined) {
            const result = await this.requestAborter.next(ClientService.getList(this.request));
            if (result instanceof Error) {
                return;
            }
            this.items[this.request.page] = result.list;
        }
        const id = this.items[this.request.page][newIndex]?.PersonId;
        if (id === undefined) {
            return;
        }
        const url = `/${TranslationService.currentLanguage}/client/details?id=${id}`;
        history.push(url, { clientIterator: new ClientIterator(this.request, this.response, newIndex, this.items), prevRequest: this.request });
    }
    async goNext(history: History) {
        this.hasNext() && await this.go(this.index + 1, history);
    }
    async goPrev(history: History) {
        this.hasPrev() && await this.go(this.index - 1, history);
    }
    hasNext() {
        return (this.index +  this.request.page * this.request.pagesize) < this.response.itemCount - 1 && this.response.itemCount > 1;
    }
    hasPrev() {
        return (this.index +  this.request.page * this.request.pagesize) > 0 && this.response.itemCount > 1;
    }

}