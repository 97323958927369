import { SortDirection } from "../../../shared/entities/Sort";

export class InvoiceHistoryListRequest {
    page = 0
    pageSize = 40;
    dateFrom = "";
    dateTo = "";

    filter: string | null = null;

    sortColumn = "amount";
    descending: SortDirection = SortDirection.Descending;
    text: string | null = null;
    // impid: null = null;
    // actionsentid: null = null;
    // reason: null = null;
    pagesize: number = 40;
}