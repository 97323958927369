import React from "react";

const Section = React.forwardRef<HTMLHeadingElement, { name: string | undefined, children: React.ReactNode }>((props, ref) => 
    props.name === undefined ? <>{props.children}</> : (
    <div className="pt-4">
        <h3 ref={ref} className="section-subtitle mb-4 line-right text-nowrap">{props.name}</h3>
        <div className="form-row">
            {props.children}
        </div>
    </div>
));

export default Section;