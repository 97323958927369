import { ClientFields, getClientFieldDisplayValue } from "../../../services/ClientService";
import CompanyService, { DataType } from "../../../services/CompanyService";
import { TranslationService } from "../../../services/TranslationService";
import Dropdown from "../../shared/components/Dropdown";
import { DatePickerEditor, PhoneEditor, TextAreaBigEditor, TextEditor, TextEditorNumber } from "../../shared/components/Editors";
import { EntityField } from "../../shared/entities/EntityField";
import { RequiredManager, ValidationMessage } from "../../shared/RequieredManager";
import { Client } from "../entities/ClientGetResponse";

export class ClientFieldEditProps {
    constructor(
        public client: Client | undefined,
        public field: EntityField,
        public onChange: (value?: string) => void,
        public requiredManager: RequiredManager,
        public fieldsToValidate: number[]
    ) { }
}

export const ClientFieldEdit = ({ client, field, onChange, requiredManager, fieldsToValidate }: ClientFieldEditProps) => {
    const title = field.title;
    const displayValue = client ? getClientFieldDisplayValue(field, client) : undefined;
    let editor = undefined;

    if (fieldsToValidate.includes(field.id)) {
        onChange = requiredManager.makeRequired(onChange);
    }
    switch (field.type) {
        case DataType.List:
            {
                const drpItems = field.listItems.map(x => ({ value: x.id.toString(), text: x.value, default: x.Default }));
                const selectedValue = drpItems.find(x => x.text === displayValue)?.value ?? drpItems.find(x => x.default)?.value;
                editor = <Dropdown onChange={onChange} items={drpItems} defaultValue={selectedValue} optionLabel={TranslationService.translate.Select} />;
            }
            break;
        case DataType.Date:
            editor = <DatePickerEditor onChange={onChange} defaultValue={displayValue} />;
            break;
        case DataType.Phone:
            editor = <PhoneEditor onChange={onChange} defaultValue={displayValue} />;
            break;
        case DataType.Number:
            editor = <TextEditorNumber onChange={onChange} defaultValue={displayValue} />;
            break;
        case DataType.Currency:
            editor = <div className="input-group">
                <TextEditorNumber onChange={onChange} defaultValue={displayValue} />
                <div className="input-group-prepend">
                    <div className="input-group-text border-left-0">{CompanyService.GetDefaultCurrencySymbol()}</div>
                </div>
            </div>;
            break;
        case DataType.Text:
        case DataType.Link:
        default:
            if (field.id === ClientFields.Comments) {
                editor = <TextAreaBigEditor onChange={onChange} defaultValue={displayValue} />;
            }
            else {
                editor = <TextEditor onChange={onChange} defaultValue={displayValue} />;
            }
            break;
    }

    //const col = field.id === ClientFields.Name || field.id === ClientFields.Comments ? "col-12" : "col-6";
    const col = "col-12";
    return (
        <div className={col}>
            <div className="row mb-1">
                <label className="col-sm-3 col-form-label">{title}</label>
                <div className="col-sm-9">
                {field.ManualEdit ? editor : <input type="text" readOnly className="form-control-plaintext" value={displayValue} />}
                <ValidationMessage onChange={onChange} defaultValue={displayValue} />
                </div>
            </div>
        </div>
    );
};

export default ClientFieldEdit;