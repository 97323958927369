import { useEffect, useRef, FC } from "react";
//@ts-expect-error
import AutocompletLib from "bootstrap5-autocomplete";

type AutocompleteInstance = {
    setData: (val: Record<string, string>) => void,
    _showSuggestions: () => void,
};
type AutocompleteProps = {
    items: Record<string, string>,
    onChange?: (value: string) => void,
    defaultValue?: string
};
export const Autocomplete: FC<AutocompleteProps> = ({ items, defaultValue, onChange = undefined }) => {
    const autocompleteRef = useRef<AutocompleteInstance | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const setupAutocomplete = (x: HTMLInputElement | null) => {
        if (!x || autocompleteRef.current) {
            return;
        }
        inputRef.current = x;
        autocompleteRef.current = AutocompletLib.getOrCreateInstance(x, {
            fullWidth: true,
            activeClasses: [],
            itemClass: "autocomplete-item",
            onSelectItem: (x: { label: string }) => { onChange && onChange(x.label); inputRef.current?.blur() },
        });
        autocompleteRef.current!.setData(items);
    }
    useEffect(() => {
        if (!autocompleteRef.current) {
            return;
        }
        autocompleteRef.current.setData(items);
        autocompleteRef.current._showSuggestions();
    }, [items]);
    return (
        <input ref={setupAutocomplete} className="form-control" defaultValue={defaultValue}
            onChange={(e) => onChange && onChange(e.target.value)} />
    );
};
