import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { parseIntOrDefault } from "../../../../../utils/ParseUtils";
import Dropdown, { DropdownProps } from "../../../../shared/components/Dropdown";
import { TextEditor } from "../../../../shared/components/Editors";
import { ImportTemplateListResponse } from "../../../../shared/entities/ImportTemplateListResponse";
import { TranslationService } from "../../../../../services/TranslationService";
import { RequiredManager, ValidationMessage } from "../../../../shared/RequieredManager";
import { TemplateField, sheetColumns, UpdateModelFunc } from "../ImportTemplateEditBase";
import { OptionalMap } from "../../../../../utils/Utils";

type Props = {
    data?: ImportTemplateListResponse.Item
    updateModel: UpdateModelFunc
    model: Partial<ImportTemplateListResponse.Item>
    setModel: Dispatch<SetStateAction<Partial<ImportTemplateListResponse.Item>>>
    fields: TemplateField[]
    requiredFieldIds: string[]
    requiredManager: RequiredManager
}

const FieldList = ({ data, updateModel, model, setModel, fields, requiredFieldIds, requiredManager }: Props) => {
    const { translate } = TranslationService;

    const inTheSheetSelect: DropdownProps<string>["items"] = [
        { text: translate.Number, value: "num" },
        { text: translate.Named, value: "nam" },
    ];
    const updateColumn = (data: TemplateField) => (value?: string | number) => {
        const name = data.key.includes("additional") ? "additional-" + data.fieldId : data.key;
        const column = {
            ix: value,
            name,
            pattern: "",
        };
        setModel(x => {
            const Columns = x.Columns?.filter(x => x.name !== name) ?? [];
            if (column && column.ix !== "") {
                Columns.push(column as ImportTemplateListResponse.Column);
            }
            return { ...x, Columns, ColumnData: JSON.stringify(Columns) };
        });
    };

    const availableSheetColumns = useMemo(() => getAvailableSheetColumns(model?.Columns, sheetColumns), [model?.Columns]);
    const inTheSheetSelectNumbers: DropdownProps<number>["items"] = Array.from({ length: 15 }, (x, i) => i + 1).map(x => ({ text: x.toString(), value: x }));
    function addOwnValue(availableSheetColumns: import("../../../../shared/components/Dropdown").DropdownItems<string> | { Value: string; Id: string; }[], value: string | undefined): import("../../../../shared/components/Dropdown").DropdownItems<string> | { Value: string; Id: string; }[] {
        const sheets = availableSheetColumns as { value: string; text: string; }[];
        if (value === undefined || sheets.find(x => x.value === value)) {
            return sheets;
        }
        const valueNum = parseInt(value);
        if (Number.isNaN(valueNum)) {
            return sheets;
        }
        const insertIndex = sheets.findIndex(x => parseInt(x.value) > valueNum);
        const sheetValue = sheetColumns.find(x => x.value === valueNum)!;
        return [...sheets.slice(0, insertIndex), { text: sheetValue.text, value: sheetValue.value.toString() }, ...sheets.slice(insertIndex)];
    }

    return (
        <>
            <ImportTemplateName updateModel={updateModel} data={model?.Name} requiredManager={requiredManager} />
            <h6 className="mb-3">{translate.InWhichSheetIsTheInformationToBeImported}</h6>
            <div className="mb-5">
                <label className="col-auto w-xl col-form-label">{translate.InTheSheet}</label>
                <div className="row">
                    <div className="col">
                        <Dropdown onChange={(value: string) => { setModel(x => ({ ...x, Sheet: value + "-1" })); }} items={inTheSheetSelect} defaultValue={data?.Sheet.substring(0, 3)} />
                    </div>
                    {model.Sheet?.startsWith("num") && <div className="col-4"><Dropdown onChange={(value: number) => { setModel(x => ({ ...x, Sheet: "num-" + value })); }} items={inTheSheetSelectNumbers} defaultValue={parseIntOrDefault(data?.Sheet.substring(4))} /></div>}
                    {model.Sheet?.startsWith("nam") && <div className="col-4"><TextEditor onChange={(value: string) => { setModel(x => ({ ...x, Sheet: "nam-" + value })); }} defaultValue={data?.Sheet.substring(4)} /></div>}
                </div>
            </div>
            <hr />
            <h6 className="pt-4 mb-4">{translate.IdentityWhichColumnCorrespondsToEachSpecificDataFieldToImport}</h6>
            {fields.map(x => {
                const value = model?.Columns?.find(y => y.name.replace("additional-", "") === x.fieldId)?.ix ?? model?.Columns?.find(y => y.name.replace("additional-", "") === x.key)?.ix ?? undefined;
                return (
                    x.fieldId === "referenceNumber" ? <ReferenceItem key={x.key} data={x} onChange={requiredManager.makeRequiredWithId(updateColumn(x), x.fieldId)} value={value?.toString()} /> :
                        requiredFieldIds.includes(x.fieldId) ?
                            <HorizontalItem key={x.key} label={x.label()} >
                                <Dropdown onChange={requiredManager.makeRequiredWithId(updateColumn(x), x.fieldId)} items={addOwnValue(availableSheetColumns, value)} optionLabel={translate.Select} selectClasses={"w-sm"} defaultValue={value?.toString()} />
                                <ValidationMessage onChange={requiredManager.getValidation(x.fieldId)!} defaultValue={value} />
                            </HorizontalItem>
                            :
                            <HorizontalItem key={x.key} label={x.label()} ><Dropdown onChange={updateColumn(x)} items={addOwnValue(availableSheetColumns, value)} optionLabel={translate.Ignore} selectClasses={"w-sm"} defaultValue={value?.toString()} /></HorizontalItem>
                );
            })}
        </>);
};

export const ReferenceItem = ({ data, onChange, value }: { data: TemplateField, onChange: (value: string) => void, value: string | undefined }) => {
    const { translate } = TranslationService;
    const [selectedColumns, setSelectedColumns] = useState<number[]>(OptionalMap(value, x => x.split(",").map(x => parseIntOrDefault(x, undefined)).filter(x => !Number.isNaN(x))) ?? [undefined!]);
    const onSelectedItem = (index: number) => (value?: number) => {
        let selected = [...selectedColumns];
        if (value === undefined) {
            selected.splice(index, 1);
        }
        else {
            selected[index] = value;
        }
        selected = selected.filter(x => x !== undefined);
        setSelectedColumns(selected.length === 0 ? [undefined!] : selected);
        onChange(selected.length === 0 ? "" : selected.join(","));
    };
    return (
        <div className="row mb-3 inline-error">
            <label className="col-4 w-lg col-form-label">{data.label()} <i className="fal fa-question-circle ps-1" title={translate.TipReference} /></label>
            <div className="col-4">
                <div className="col d-flex flex-column gap-1 mb-2">
                    <div className="d-flex w-100 flex-column gap-3">
                        {selectedColumns.map((x, i) => (
                            <div className="w-sm" key={i}>
                                <Dropdown onChange={onSelectedItem(i)} items={sheetColumns} optionLabel={translate.Select} defaultValue={x} />
                            </div>
                        ))}
                    </div>
                    <div className="col-12 d-flex justify-content-between">
                        <ValidationMessage onChange={onChange} defaultValue={value} />
                        <span></span>
                        <button className="btn btn-link p-0" onClick={() => setSelectedColumns([...selectedColumns, 0])}>{translate.CombineWithMoreColumns}</button>
                    </div>
                </div>
            </div>
        </div>);
};


export const HorizontalItem = ({ label: Label, children, size = "-4" }: React.PropsWithChildren<{ label: React.ReactChild, size?: string }>) => {

    return (<div className="row mb-3">
        <label className="col-4 w-lg pt-0 col-form-label font-weight-normal">{Label}</label>
        <div className={`col${size}`}>
            {children}
        </div>
    </div>);
};

export function ImportTemplateName(props: { updateModel: UpdateModelFunc, data: string | undefined, requiredManager: RequiredManager }) {
    const onChange = props.requiredManager.makeRequiredWithId(props.updateModel("Name"), "Name");
    return <div className="mb-4">
        <label className="col w-xl col-form-label">{TranslationService.translate.TemplateName}</label>
        <div className="col-9"><TextEditor onChange={onChange} defaultValue={props.data} /></div>
        <ValidationMessage onChange={props.requiredManager.getValidation("Name")} defaultValue={props.data} />
    </div>;
}

export function getAvailableSheetColumns(columns: Partial<ImportTemplateListResponse.Item>["Columns"] | undefined, sheetColumns: readonly { value: number; text: string; }[]): readonly { value: string; text: string; }[] {
    if (columns === undefined) {
        return sheetColumns.map(x => ({ text: x.text, value: x.value.toString() }));
    }
    const usedColumns = columns?.filter(x => x.name !== "referenceNumber").map(x => parseInt(x.ix)) ?? [];
    return sheetColumns.filter(x => !usedColumns.includes(x.value)).map(x => ({ text: x.text, value: x.value.toString() }));
}


export default FieldList;
