import React, { createContext, useCallback, useState } from "react";
import TableContext, { TableContextValues } from "../task/TableContext";

import ActivityService from "../../services/ActivityService";
import { SortDirection } from "../shared/entities/Sort";
import { applyFiltersHelper, calculatePageCount, setCurrentPageHelper, sortHelper, useEndpointData } from "../../utils/TableUtils";

export class TypeListRequest {
    constructor(quickfilter: number) {
        this.quickfilter = quickfilter;
    }
    page: number = 0;
    sortColumn: string = "personName";
    descending: SortDirection = SortDirection.Descending;
    text: string | null = null;
    impid: null = null;
    actionsentid: null = null;
    reason: null = null;
    pagesize: number = 15;
    filter: string | null = null;
    quickfilter: number = 8;
    archived?: boolean = false;
    read?: boolean = false;
}

class InboxTableContextValues extends TableContextValues<any> {
    setViewRead: (val: boolean | undefined) => void = () => { };
    setViewArchived: (val: boolean) => void = () => { };
    viewArchived: boolean = false;
    request: TypeListRequest = new TypeListRequest(7);
    setRequest: React.Dispatch<React.SetStateAction<TypeListRequest>> = () => { };
    viewRead?: boolean = false;
}

export const InboxContext = createContext(new InboxTableContextValues())

const InboxTableProvider = ({ children, prevRequest }: React.PropsWithChildren<{ prevRequest?: TypeListRequest }>) => {
    const [request, setRequest] = useState(prevRequest ?? new TypeListRequest(8));
    const endpoint = useCallback(() => ActivityService.getList(request), [request]);
    const { loading, error, response, reload } = useEndpointData(endpoint);
    const pageCount = calculatePageCount(response);
    const { sort, setSortColumn } = sortHelper(request, setRequest);
    const applySearch = (keyword: any) => {
        setRequest({ ...request, text: keyword })
    };

    const [viewRead, setViewRead] = useState<boolean | undefined>();
    const [viewArchived, setViewArchived] = useState<boolean>(false);

    const value: InboxTableContextValues = {
        loading,
        error,
        response,
        setSortColumn,
        sort,
        currentPage: request.page,
        setCurrentPage: setCurrentPageHelper(setRequest, pageCount),
        pageCount: pageCount,
        applyFilters: applyFiltersHelper(setRequest),
        applySearch,
        reload,
        setViewRead,
        setViewArchived,
        viewArchived,
        viewRead,
        request,
        setRequest,
        pageSize: request.pagesize,
    }

    return (
        <InboxContext.Provider value={value}>
            <TableContext.Provider value={value}>
                {children}
            </TableContext.Provider>
        </InboxContext.Provider>
    )
}

export default InboxTableProvider;
