import { SortDirection } from "../pages/shared/entities/Sort";
import RequestPortalService from "./RequestPortalService";

export type PortalClientResponse = {
    personName: string,
    urlLogo: string,
    paymentMethod: string,
    clientInfo: any,
    customerFieldName?: string,
    customersFieldName?: string,
    customerMessages?: boolean
}

export type PortalIoStatusestResponse = {
    personName: string,
    urlLogo: string,
    paymentMethod: string,
    clientInfo: any,
    customerFieldName?: string,
    customersFieldName?: string,
}

export type PortalClientDataResponse = {
    data: PortalClientResponse,
    itemCount?: number;
    pageSize?: number;
}
export type PortalIoStatusesDataResponse = {
    "statuses": [
        {
            "Key": number,
            "Value": string
        }
    ]
};

export type PortalClientIoDataResponse = {
    "headers": [
        {
            "columnId": string,
            "dataType": number
            "columnTitle": string
        }
    ],
    "rowsToShow": [
        {
            "Key": string,
            "Value": true
        }
    ],
    "count": number
    "totalAmount": number
    "totalPending": number
    "currencyTotals": {
        "(1994, $)": [
            {
                "Item1": number
                "Item2": number
            }
        ],
        "(19037, U$D)": [
            {
                "Item1": number
                "Item2": number
            }
        ],
        "(67586, €)": [
            {
                "Item1": number
                "Item2": number
            }
        ]
    }
}

export class PortalClientDataRequest {
    pagesize: number = 1;
    page: number = 0;
    sortColumn: string = 'client';
    descending: SortDirection = SortDirection.Descending;
    filter: string | null = null;
    quickFilter: number | null = null;
}

class PortalClientService {

    public static getClientData(customerToken: string): Promise<PortalClientDataResponse | Error> {
        return RequestPortalService.post("/customer/get", {}, customerToken);
    }

    public static getIoList(request: PortalClientDataRequest, customerToken: string): Promise<PortalClientIoDataResponse | Error> {
        return RequestPortalService.post("/customer/getiolist", { ...request }, customerToken);
    }
    public static getAdditionals(customerToken: string): Promise<PortalClientDataResponse | Error> {
        return RequestPortalService.post("/customer/getadditionals", {}, customerToken);
    }
    public static getIoStatuses(customerToken: string): Promise<PortalIoStatusesDataResponse | Error> {
        return RequestPortalService.post("/customer/getiostatuses", {}, customerToken);
    }
}

export default PortalClientService;