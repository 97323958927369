import { DashboardEntity } from "../../../entities/company/Dashboard/DashboardEntity";
import DashboardService from "../../../services/DashboardService";
import FloatingPanelService from "../../shared/FloatingPanel";
import { TranslationService } from '../../../services/TranslationService';
import DashboardEdit from "./DashboardEdit";
import { useMemo } from "react";
import CompanyService from "../../../services/CompanyService";
import { ClientFields } from "../../../services/ClientService";


const DashboardItem = ({ data, dragEvents, reload }: { data: DashboardEntity, dragEvents: Object, reload: () => void }) => {
    const { translate } = TranslationService;

    const segments = useMemo(() => {
        return CompanyService.getSegments();
    }, []);

    const editDashboard = () => {
        FloatingPanelService.showPanel({
            children: <DashboardEdit reload={reload} dashboard={data} />,
            title: translate.EditPanel,
            width: 700,
            height: 650,
            position: "center",
        });
    }

    return (
        <tr key={data.DashboardID} className="cursor-sort show-child-on-hover"
            {...dragEvents}
            data-id={data.DashboardID} >
            <td>{DashboardService.getPanelTypes()[data.Type]}</td>
            <td>{data.Title}</td>
            <td>{DashboardService.getVariableName(data.Variable)}</td>
            <td>
                {DashboardService.getGroupName(data.GroupBy)}
            </td>
            <td>
                {data.GroupBy === ClientFields.Segments && data.Data && data.Data.split(",").map(x => segments.find(y => y.Id === x)!.Value).join(", ")}
            </td>
            <td>
                <div className="show-when-hovering-parent">
                    <i className="far fa-fw pointer fa-pen mx-2" onClick={editDashboard}></i>
                </div>
            </td>
        </tr>)
}

export default DashboardItem;
