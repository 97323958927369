import React, { useCallback, useState } from "react";
import TableContext, { TableContextValues } from "../../task/TableContext";
import { calculatePageCount, setCurrentPageHelper, sortHelper, useEndpointData } from "../../../utils/TableUtils";
import LogService from "../../../services/LogService";
import { ReportLogListClassRequest } from "../../../entities/reports/ReportLog/ReportLogListRequest";
import { ReportLogListResponse } from "../../../entities/reports/ReportLog/ReportLogListResponse";

export class ReportLogListTableContextValues extends TableContextValues<ReportLogListResponse, { extraFilters: string[], pagesize: any; }> {
    request: ReportLogListClassRequest = new ReportLogListClassRequest();
    setRequest: (request: ReportLogListClassRequest) => void = () => { };
}

const ReportLogListProvider = ({ children, prevRequest = undefined }: React.PropsWithChildren<{ prevRequest?: ReportLogListClassRequest }>) => {
    const [request, setRequest] = useState(prevRequest ?? new ReportLogListClassRequest());
    const endpoint = useCallback(() => LogService.getReportLogList(request), [request]);
    const { loading, error, response, reload } = useEndpointData(endpoint);
    const pageCount = calculatePageCount(response);
    const { sort, setSortColumn } = sortHelper({ ...request, descending: 0, sortColumn: 'date' }, setRequest);

    const value: ReportLogListTableContextValues =
    {
        request,
        setRequest,
        loading,
        error,
        response,
        reload,
        pageCount,
        sort,
        setSortColumn,
        setCurrentPage: setCurrentPageHelper(setRequest, pageCount),
        pageSize: response?.pageSize || 0,
        applyFilters: () => { },
        applySearch: () => { },
        currentPage: response?.page
    }

    return (
        <TableContext.Provider value={value}>
            {children}
        </TableContext.Provider>
    )
}

export default ReportLogListProvider; 