import { TranslationService } from '../../../../services/TranslationService';
import { CompanySettings } from '../../../../entities/company/Company/CompanySettings';
import { useContext, useState } from 'react';
import Loading from '../../../shared/components/Loading';
import ConfigurationContext from '../../ConfigurationContext';
import CompanyService from '../../../../services/CompanyService';
import Dropdown from '../../../shared/components/Dropdown';

type DropdownItemType = {
    value: number | string;
    text: string;
}

type DropdownSelectWrapperType = {
    initialValue?: string | number;
    action?: keyof CompanySettings;
    title?: string;
    items: DropdownItemType[];
    fullSize?: boolean
}

export const DropdownSelectWrapper = ({ title, action, initialValue, items, fullSize = false }: DropdownSelectWrapperType) => {
    const [loading, setLoading] = useState(false)
    // eslint-disable-next-line eqeqeq
    const initValueSelected = items.find(el => el.value == initialValue);
    const { refreshConfigValues } = useContext(ConfigurationContext)

    const handleSelect = (valueUpdated: any) => {
        setLoading(true)

        action && CompanyService.setConfigCompany({ [action]: valueUpdated })
            .finally(async () => {
                await refreshConfigValues()
                await setLoading(false)
            })
    }

    return loading ? <Loading /> : (
        <div className={`d-flex ${fullSize ? '' : 'col-md-8'} justify-content-between align-items-center`}>
            {title && <td className="col py-0"><div className="configuration-display-item">{TranslationService.getTranslation(title)}</div></td>}
            <td className={`col${title ? '-sm-5' : ''} py-0`}>
                <Dropdown items={items} onChange={handleSelect} value={initValueSelected?.value || initValueSelected} />
            </td>
        </div>
    )
}
