import { TranslationService } from "../../../services/TranslationService";
import { ProfileData } from "./ProfileData";

export const DataPersonal = () => {
    return (<div className="main-layout-container mx-3">
        <div className="main-panel">
            <div className="card">
                <h2 className="my-3">{TranslationService.translate.DataPersonal}</h2>
                <ProfileData />
            </div>
        </div>
    </div>);
};