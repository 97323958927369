import { useCallback, useState } from "react";
import { TranslationService } from "../../services/TranslationService";
import { calculatePageCount, setCurrentPageHelper, useEndpointData } from "../../utils/TableUtils";
import { DatePickerEditor } from "../shared/components/Editors";
import { Table, TableHeader } from "../shared/Table";
import TableContext from "../task/TableContext";
import { ImportService } from "../../services/ImportService";
import { ImportFileLogRequest } from "../../entities/import/ImportFileLogRequest";
import { ImportFileLogResponse } from "../../entities/import/ImportFileLogResponse";
import { formatDateTimeDigits, formatIntizaDate } from "../../utils/FormatUtils";
import ExportService from "../../services/ExportService";

export const FileLog = () => {
    const [stagingRequest, setStagingRequest] = useState<ImportFileLogRequest>({ pageSize: 20, page: 0 });
    const [request, setRequest] = useState<ImportFileLogRequest>(stagingRequest);
    const endpoint = useCallback(() => ImportService.fileLog(request), [request]);
    const context = useEndpointData(endpoint);
    context.currentPage = request.page;
    context.pageCount = calculatePageCount(context.response);
    context.setCurrentPage = setCurrentPageHelper(setRequest, context.pageCount);
    const headers = [
        new TableHeader("date", TranslationService.translate.Date, false, false),
        new TableHeader("file", TranslationService.translate.File, false, false),
        new TableHeader("client", TranslationService.translate.Client, false, false),
        new TableHeader("invoice", TranslationService.translate.Invoice, false, false),
    ];
    const Find = () => {
        setRequest(({ ...stagingRequest, page: 0 }));
    };
    const changeDate = (value: string, key: "from" | "to") => {
        const date = new Date(value);
        const dateValue = isNaN(date.getTime()) ? undefined : formatIntizaDate(date);
        setStagingRequest(prev => ({ ...prev, [key]: dateValue }));
    };
    const Export = () => {
        ExportService.requestExport("/import/exportfilelog", { filename: stagingRequest.filename, from: stagingRequest.from, to: stagingRequest.to }, context.response?.count ?? 1);
    };
    return (
        <div className="container-fluid padding">
            <div className="card mh-100 p-0">
                <div className="genericHeader row">
                    <div className="row">
                        <div className="col-2">
                            <input className="form-control" placeholder={TranslationService.translate.FileOfName} onChange={e => setStagingRequest(prev => ({ ...prev, filename: e.target.value }))} />
                        </div>
                        <div className="col-2">
                            <DatePickerEditor onChange={date => changeDate(date, "from")} placeholder={TranslationService.translate.StartDatev2} />
                        </div>
                        <div className="col-2">
                            <DatePickerEditor onChange={date => changeDate(date, "to")} placeholder={TranslationService.translate.EndDate} />
                        </div>
                        <div className="col">
                            <button className="btn btn-primary" type="button" onClick={Find}>{TranslationService.translate.Find}</button>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-secondary" type="button" onClick={Export}>{TranslationService.translate.ExportToXls}</button>
                        </div>

                    </div>
                </div>
                <div className="p-4">
                    <TableContext.Provider value={context}>
                        <Table headers={headers} item={FileLogItem} stickyHeader={true} limitSize />
                    </TableContext.Provider>
                </div>
            </div>
        </div>);
};

const FileLogItem = ({ data }: { data: ImportFileLogResponse.Item }) => {
    return (<tr>
        <td>{formatDateTimeDigits(new Date(data.Date))}</td>
        <td>{data.Filename}</td>
        <td>{data.IO.personName ?? "-"}</td>
        <td>{data.IO.referenceNumber ?? "-"}</td>
    </tr>);
};