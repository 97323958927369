import { useHistory } from "react-router-dom";
import { TranslationService } from "../../../services/TranslationService";

export const BackButton = () => {
  const { translate } = TranslationService;
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className="back" onClick={handleBack}>
      <small>{translate.GoBack}</small>
    </div>
  );
};
