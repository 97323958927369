import { ActionType } from "../../../services/ActionService"

export interface ActionListResponse {
    disabled: boolean
    page: number
    pageSize: number
    itemCount: number
    list: ActionListResponse.List[]
    response: string
    item: any
    Id: number
}
export namespace ActionListResponse {
    export interface List {
        id: number
        name?: string
        items: Item[]
    }
    export enum Whens {
        Custom = 0,
        NewInvoice = 1,
        NextDue = 2,
        Due = 3
    }
   export enum Periods {
        Once = 0,
        After = 1,
        Every = 2,
        Weekly = 3,
        Monthly = 4
    }
    export interface Item {
        segments: Segment[]
        Action_AdditionalDefinition: any[]
        files: any[]
        file: any
        ActionID: number
        Type: ActionType
        Name: string
        ActionFolderID?: number
        Created: string
        CreatorUserID: number
        Content: any
        Mail_Subject: any
        Mail_From: any
        Mail_To: any
        Mail_Include: any
        Mail_Attach: any
        Task_Due: any
        Task_ResponsibleUserID: any
        Mail_Period: Periods | null | undefined
        Mail_Frequency: number | null | undefined
        Mail_AttachDue: any
        Mail_IncludeDue: any
        Mail_FromName: any
        Hour?: string
        Disabled: boolean
        Mail_IOFilter: any
        When: Whens | null | undefined
        Mail_MinAmount: any
        MaxAge: any
        Mail_IncludeLink: any
        Mail_SignatureID: any
        Mail_IncludeAttachment: any
        Letter_HasFile?: boolean
        Letter_Format: any
        Letter_Method: any
        LastRun: any
        Mail_IncludeClaimable: any
        Mail_AttachClaimable: any
        Mail_IncludeDueClaimable: any
        Mail_AttachDueClaimable: any
        Mail_Saturday: any
        Mail_Sunday: any
        Mail_FromType: any
        Mail_ToType: any
        Day0?: boolean
        Day1?: boolean
        Day2?: boolean
        Day3?: boolean
        Day4?: boolean
        Day5?: boolean
        Day6?: boolean
        Mail_ToTypeField: any
        Task_ResponsibleType: any
        Task_ValidatorUserID: any
        Task_HasTags: any
        Language: any
        MinInterval: any
        HasAttachments: any
        SMS_ToTypeField: any
        MailFrameID: any
        ActivityTypeID: any
        ErrorFilter: any
        Mail_cc: any
        Mail_bcc: any
        Mail_CCType: any
        Mail_BCCType: any
        Mail_CCField: any
        Mail_BCCField: any
        MailEngine: any
        FTP_Folder: any
        ActionID_ref: any
        ReportExportID: any
        IOListNoSumarize: any
        FTP_Filename: any
        BlobName: any
        NotifyTo: any
        NotifyToEmail: any
        Mail_GroupBy: any
        IncludeClientData: any
        HasAdditionalDefinitions: any
        Mail_ReportExportID: any
    }
    export interface Segment {
        UserID?: number
        Id: string
        Value: string
    }
}