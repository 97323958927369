import { ToastService } from "../pages/shared/bootstrap/Toast";
import { getError } from "../utils/RequestUtils";
import FileService from "./FileService";
import RequestService from "./RequestService";
import { TranslationService } from "./TranslationService";

class ExportService {
    public static async requestExport(endpoint: string, request: {}, count: number, fileName: string = "export.xls") {
        ToastService.showToast(TranslationService.translate.Exporting + "...");

        const response: { Response: "Ok", file?: string, fileId: string, processrequestid?: number } | Error =
            await RequestService.post(endpoint, { ...request, expectedRows: count });

        if (response instanceof Error) {
            const { status } = getError(response);
            if (status === 409) {
                ToastService.showToast(TranslationService.translate.ThereIsProcessPending, undefined, "warning");
                return;
            }
            ToastService.showToast(TranslationService.translate.Network, undefined, "danger");
            return;
        }
        if (response.processrequestid) {
            ToastService.showToast(TranslationService.translate.ExportSentToMail, undefined, "success");
            return;
        }
        if (response?.file || response?.fileId) {
            FileService.download(response.file ?? response.fileId, fileName);
            return;
        }
        ToastService.showToast(TranslationService.translate.Network, undefined, "danger");
    }
}

export default ExportService;