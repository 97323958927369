import { ReactElement } from "react";

type PaginadorProps = {
    totalRecords: number;
    recordsPerPage: number;
    currentPage: number;
    onPageChange: (page: number) => void;
};

export const PortalPaginator = ({
    totalRecords,
    recordsPerPage,
    currentPage,
    onPageChange,
}: PaginadorProps): ReactElement => {
    const totalPages = Math.ceil(totalRecords / recordsPerPage);

    const handleClick = (page: number) => {
        onPageChange(page);
    };

    return (
        <nav className="d-flex justify-content-between align-items-center mt-4" aria-label="Paginación">
            <p className="col"></p>
            <ul className="pagination justify-content-center">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handleClick(currentPage - 1)} disabled={currentPage === 1}>
                        Anterior
                    </button>
                </li>

                {[...Array(totalPages)].map((_, idx) => (
                    <li key={idx} className={`page-item ${currentPage === idx + 1 ? 'active' : ''}`}>
                        <button className="page-link" onClick={() => handleClick(idx + 1)}>
                            {idx + 1}
                        </button>
                    </li>
                ))}

                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handleClick(currentPage + 1)} disabled={currentPage === totalPages}>
                        Siguiente
                    </button>
                </li>
            </ul>
            <p className="col text-end">Total de registros: {totalRecords}</p>
        </nav>
    );
};
