import { TranslationService } from "../../services/TranslationService";
import { Cast } from "../../utils/Utils";
import Footer from "../shared/Footer";
import StaticHeader from "./components/StaticHeader";
import logo from "../../assets/logo-default.png";

export const Contact = () => {
    const onRef = () => {
        const script = document.createElement("script");
        script.src = "https://js.hsforms.net/forms/v2.js?pre=1";
        document.body.appendChild(script);
        script.addEventListener("load", () => {
            const win_hb = Cast<{ hbspt: { forms: { create: (value: Record<string, string>) => void } } }>(window);
            if (win_hb.hbspt) {
                win_hb.hbspt.forms.create({
                    portalId: "22073597",
                    formId: "82b36f3b-a008-48c3-9bd4-460d507e9b33",
                    target: "#hubspotForm",
                    region: "na1",
                });
            }
        });
    };
    return (<>

        <div className="old-page">
            <StaticHeader lang={TranslationService.currentLanguage} />
            <div
                className={"d-flex flex-column justify-content-center align-items-center flex-grow-1 py-5"}
                style={{ backgroundColor: "#f2f1f6", minHeight: "calc(100vh - 179px)" }}
            >
                <img
                    src={logo}
                    alt="intiza"
                    width={"150px"}
                    height={"auto"}
                    className={"d-none mb-3 mt-1"}
                />
                <div className="pt-5" style={{ width: "70%" }}>
                    <div className="row">
                        <div className="col-md-5">
                            <h1>Contacto</h1>
                            <p className="mt-3">Completá este formulario y nosotros nos contactaremos con vos a la brevedad.</p>
                            <div className="row my-3">
                                <div className="col-auto">
                                    <i className="far fa-envelope fa-fw fa-2xl" aria-hidden="true"></i>
                                </div>
                                <div className="email col-auto">
                                    <div className="pb-2">
                                        <a href="mailto:info@intiza.com">info@intiza.com</a>
                                    </div>
                                    <div className="pb-2">
                                        <a href="mailto:soporte@intiza.com">soporte@intiza.com</a>
                                    </div>
                                    <div className="pb-2">
                                        <a href="mailto:administracion@intiza.com">administracion@intiza.com</a>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-auto">
                                    <i className="fas fa-mobile-alt fa-fw fa-2xl" aria-hidden="true"></i>
                                </div>
                                <div className="email col-auto">
                                    <div className="pb-2">
                                        <a href="tel://+541152356195">
                                            <img src="https://cdn.intiza.com/content/v5/img/flag-argentina.png" width="20" alt="flag" /> Argentina +54 (11) 5235-6195
                                        </a>
                                    </div>
                                    <div className="pb-2">
                                        <a href="tel://+56228999382">
                                            <img src="https://cdn.intiza.com/content/v5/img/flag-chile.png" width="20" alt="flag" /> Chile +56 (2) 2899-9382
                                        </a>
                                    </div>
                                    <div className="pb-2">
                                        <a href="tel://+5728912861">
                                            <img src="https://cdn.intiza.com/content/v5/img/flag-colombia.png" width="20" alt="flag" /> Colombia +57 (601) 355-7675
                                        </a>
                                    </div>
                                    <div className="pb-2">
                                        <a href="tel://+5255852602413">
                                            <img src="https://cdn.intiza.com/content/v5/img/flag-mexico.png" width="20" alt="flag" /> México +52 (55) 85 26 31 24
                                        </a>
                                    </div>
                                    <div className="pb-2">
                                        <a href="tel://+5116419336">
                                            <img src="https://cdn.intiza.com/content/v5/img/flag-peru.png" width="20" alt="flag" /> Perú +51 (1) 641-9336
                                        </a>
                                    </div>
                                    <div className="pb-2">
                                        <a href="tel://+18299548322">
                                            <img src="https://cdn.intiza.com/content/v5/img/flag-republica-dominicana.png" width="20" alt="flag" /> Rep. Dominicana +1 (829) 954-8322
                                        </a>
                                    </div>
                                    <div className="pb-2">
                                        <a href="tel://+16502784405">
                                            <img src="https://cdn.intiza.com/content/v5/img/flag-usa.png" width="20" alt="flag" /> Estados Unidos +1 (650) 278-4405
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-7">
                            <div ref={onRef} id="hubspotForm">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </>);
};