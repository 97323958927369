import CompanyService from "./CompanyService";
import RequestService from "./RequestService";

class CurrencyService {
    public static set(currency: CurrencyEdit): Promise<CurrencyListResponse | Error> {
        return RequestService.post("/currency/set", { item: currency });
    }
    public static getList(): Promise<CurrencyListResponse | Error> {
        return RequestService.post("/currency/list", {});
    }
    public static delete(id: number): Promise<unknown | Error> {
        return RequestService.post("/currency/delete", { id });
    }
    public static convert(pendingAmount: number, fromCurrencyId: number, toCurrencyId: number) {
        if (fromCurrencyId === toCurrencyId) {
            return pendingAmount;
        }
        const fromCurrency = CompanyService.getCompanyAuth()?.currencies.find(x => x.CurrencyId === fromCurrencyId)!;
        const toCurrency = CompanyService.getCompanyAuth()?.currencies.find(x => x.CurrencyId === toCurrencyId)!;
        return pendingAmount * (fromCurrency.Rate / toCurrency.Rate);
    }
}

export interface CurrencyEdit {
    code: string;
    name: string;
    rate: number;
    symbol: string;
    autoupdate: boolean;
    defaultcurrency: boolean;
    autoreference?: string | null;
    currencyId: number | undefined;
}

export interface CurrencyListResponse {
    Response: string
    list: CurrencyListResponseItem[]
}
export interface CurrencyListResponseItem {
    CurrencyID: number
    Name: string
    Symbol: string
    Code: string
    Rate: number
    ExternalID: any
    ExternalIntegrator: any
    AutoType: number
    AutoReference: string
    AutoDate: string
    CurrencyID_ref: any
}

export default CurrencyService;