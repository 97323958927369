import React, { useEffect, useState } from "react";

type IFormContext = {
    data: any,
    disabled: boolean,
    setData: (key: string, value: any) => any,
    getData: (value: string) => any
}

const defaultFormContext: IFormContext = {
    data: {},
    disabled: false,
    setData: () => { },
    getData: () => { }
}

const FormContext = React.createContext<IFormContext>(defaultFormContext)

const FormProvider = (props: any) => {
    const segment = props.data;
    const [data, _setData] = useState(segment);
    const [disabled, setDisabled] = useState(props.disabled);
    const setData = (key: string, value: any) => {

        const k = key || "_default";

        if (value === undefined) {
            _setData((prevState: any) => {
                const foo = prevState
                delete foo[k]
                return foo
            })
        }
        else {
            _setData((prevState: any) => {
                const foo = prevState
                foo[k] = value
                return foo
            })
        }
    }

    const getData = (key: string) => {
        return data ? data[key] : undefined
    }

    const value: IFormContext = {
        data, disabled, setData, getData
    }

    useEffect(() => {
        _setData(props.data);
        setDisabled(props.disabled)
    }, [props.data, props.disabled])

    return (
        <FormContext.Provider value={value}  {...props} />
    )
}

export { FormProvider };
export default FormContext;