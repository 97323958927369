import { useCallback, useState } from "react";
import { TranslationService } from "../../../services/TranslationService";
import { RrsBaseStructure } from "./RrsBaseStructure";
import { useEndpointData } from "../../../utils/TableUtils";
import { ReportReceivablesSummaryService } from "../../../services/ReportReceivablesSummaryService";
import Loading from "../../shared/components/Loading";
import TooltipComponent from "../../shared/TooltipComponent";
import moment from "moment";
import { ReportReceivablesResponse } from "../../../entities/reports/ReportReceivablesSummary/ReceivablesResponse";

export const RrsAveragePayment = ({ filter, receivables }: { filter: string, receivables: ReportReceivablesResponse | undefined }) => {
    const [monthCount, setMonthCount] = useState(6);
    const toggleStartDate = () => {
        setMonthCount(x => 18 - x);
    }
    const endpoint = useCallback(() => ReportReceivablesSummaryService.getAveragePayment(filter, moment().add(-1, "years").endOf("month").toDate()), [filter]);
    const { loading, response } = useEndpointData(endpoint);
    if (loading || response === undefined || receivables === undefined) {
        return <Loading height={262} />;
    }
    const LeftSide = () =>
        <div className="dashboard__sales d-flex flex-column gap-3">
            <div className="dashboard__total card p-3">
                <div className="card-body px-2">
                    <div className="card-header justify-content-start mb-1">
                        <p>{TranslationService.translate.DaysSalesOutstanding}</p>
                        <TooltipComponent title={TranslationService.translate.AvaragePaymentsTipReports}>
                            <i className="ms-2 fal fa-question-circle"></i>
                        </TooltipComponent>
                    </div>
                    <h2 className="fw-bolder">{Math.round(receivables.averagePeriod)} {TranslationService.translate.Days}</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-auto">
                    <button className="btn btn-primary" onClick={toggleStartDate}>{TranslationService.translate.ShowXMonths.replace("{count}", (18 - monthCount).toString())}</button>
                </div>
            </div>
        </div>;
    return (<RrsBaseStructure UnderTitle={LeftSide} highchartsOptions={ReportReceivablesSummaryService.chartLineOptions(response.payments[0], response.payments[1], monthCount)} />);
}