import React, { useEffect, useRef } from "react";

export type DropdownItems = readonly { value: string, text: string }[];

export type ButtonDropdownProps =
    {
        onCollapse?: () => void,
        onShow?: () => void,
        buttonClass?: string,
        items: DropdownItems,
    } & (
        { optionLabel?: undefined; onChange: (value: string) => void; } |
        { optionLabel: string; onChange: (value: string | undefined) => void; }
    );


const ButtonDropdown = (props: React.PropsWithChildren<ButtonDropdownProps>) => {
    const collapsable = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const onCollapse = () => {
            props.onCollapse?.();
        };
        const onShow = () => {
            props.onShow?.();
        };
        const col = collapsable.current;
        col?.addEventListener("hidden.bs.dropdown", onCollapse);
        col?.addEventListener("shown.bs.dropdown", onShow);
        return () => {
            col?.removeEventListener("hidden.bs.dropdown", onCollapse);
            col?.removeEventListener("shown.bs.dropdown", onShow);
        }
    }, [props])

    return (
        <div className="dropdown dropdown-intiza" ref={collapsable}>
            <button className={"btn dropdown-toggle " + (props.buttonClass ?? "")} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {props.children}
            </button>
            <ul className="dropdown-menu">
                {props.optionLabel && <li onClick={() => props.onChange && props.onChange(undefined)}><button type="button" className="dropdown-item">{props.optionLabel}</button></li>}
                {props.items.map(x => (<li key={x.value + x.text} onClick={() => props.onChange && props.onChange(x.value)}><button type="button" className="dropdown-item">{x.text}</button></li>))}
            </ul>
        </div>
    );
}

export default ButtonDropdown;