import { DashboardEntity } from "../entities/company/Dashboard/DashboardEntity";
import { DashboardPanelEntity } from "../entities/company/DashboardPanel/DashboardPanelEntity";
import { DashboardPanelGetResponse } from "../entities/company/DashboardPanel/DashboardPanelGetResponse";
import { DashboardPanelListResponse } from "../entities/company/DashboardPanel/DashboardPanelListResponse";
import RequestService from "./RequestService";

class DashboardPanelService {
    public static getList(): Promise<DashboardPanelListResponse | Error> {
        return RequestService.post<DashboardPanelListResponse | Error>("/dashboardpanel/list", {}).then((x: DashboardPanelListResponse | Error) => {
            if (x instanceof Error) {
                return x;
            }
            x.list.forEach(y => this.sortDashboards(y));
            return x;
        });
    }

    public static get(id: number | undefined): Promise<DashboardPanelGetResponse | Error> {
        return RequestService.post<DashboardPanelGetResponse | Error>("/dashboardpanel/get", { id }).then((x: DashboardPanelGetResponse | Error) => {
            if (x instanceof Error) {
                return x;
            }
            this.sortDashboards(x.item);
            return x;
        });
    }

    public static sortDashboards(dashboardPanel: DashboardPanelEntity) {
        dashboardPanel.Dashboards.sort((a, b) => a.Position - b.Position);
    }

    public static set(dashboardPanelEdit: Omit<DashboardPanelEntity, "Dashboards">): Promise<{ id: number } | Error> {
        const request = {
            item: { ...dashboardPanelEdit }
        }
        return RequestService.post("/dashboardpanel/set", request);
    }

    public static delete(id: number): Promise<unknown | Error> {
        return RequestService.post("/dashboardpanel/delete", { id });
    }

    public static clone(id: number, count: number): Promise<unknown | Error> {
        return RequestService.post("/dashboardpanel/clone", { id, count });
    }

    public static sort(id: number, dashboards: DashboardEntity[]): Promise<unknown | Error> {
        const sorting = dashboards.map((x, index) => ({
            dashboardid: x.DashboardID,
            position: index
        }));
        return RequestService.post("/dashboardpanel/sort", { id, sorting });
    }
}

export default DashboardPanelService;