import CompanyService from "../services/CompanyService";
import { TranslationService } from "../services/TranslationService";
import { addTimezone } from "./ParseUtils";

export function addDots(x: any) {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "0";
}

/*
 * Makes text no table to separate in multiple lines
 * Replaces spaces for &nbsp; (non-breaking space)
 */
export function unbreak(x: string) {
    return x.replaceAll(" ", "\u00A0");
}

export function capitalize(text: string | undefined | null) {
    if (!text || text.length < 1) {
        return "";
    }
    return text[0].toUpperCase() + text.substring(1);
}

export function uncapitalize(text: string | undefined | null) {
    if (!text || text.length < 1) {
        return "";
    }
    return text[0].toLowerCase() + text.substring(1);
}
// export function formatDate(date: Date, format: string) {
//     let val = format.replace("yyyy", date.getFullYear().toString());
//     val = val.replace("MM", date.getMonth().toString().padStart(2, "0"));
//     val = val.replace("dd", date.getDate().toString().padStart(2, "0"));
//     return val;
// }

//"20231001"
export function formatIntizaDate(date: Date) {
    const formatter = new Intl.DateTimeFormat("es-AR", { year: "numeric", month: "2-digit", day: "2-digit" });
    return formatter.formatToParts(date).reverse().map(x => x.value).join("").replaceAll("/", "");
}

//"20231001 03:00"
export function formatIntizaDateTime(date: Date) {
    const newDate = addTimezone(date);
    return formatIntizaDate(date) + " " + newDate.getHours().toString().padStart(2, "0") + ":" + newDate.getMinutes().toString().padStart(2, "0");
}

//"2023-10-01"
export function formatIntizaOldDate(date: Date) {
    const formatter = new Intl.DateTimeFormat("es-AR", { year: "numeric", month: "2-digit", day: "2-digit" });
    return formatter.formatToParts(date).reverse().map(x => x.value).join("").replaceAll("/", "-");
}


/// 25/12/2022
export function formatDateDigits(date: Date, lang?: string) {
    return date.toLocaleDateString(lang ?? TranslationService.currentLanguage, { day: "2-digit", month: "2-digit", year: "numeric" });
}

/// 25/12/2022 12:00
export function formatDateTimeDigits(date: Date) {
    return date.toLocaleDateString(TranslationService.currentLanguage, { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" }).replace(",", "");
}

/// 12:00
export function formatTimeIfNonZero(date: Date) {
    return (date.getHours() !== 0 && date.getMinutes() !== 0 && date.getMilliseconds() !== 0) ?
        date.toLocaleDateString(TranslationService.currentLanguage, { hour: "2-digit", minute: "2-digit" }).replace(",", "")
        : "";
}

/// 2022-12-25
export function formatDateDigitsMin(date?: string, lang?: string) {
    if (date && date !== "Invalid Date") {
        const dateToFormat = new Date(date);
        let dateData = dateToFormat.toLocaleDateString(lang ?? TranslationService.currentLanguage, { year: "numeric", month: "2-digit", day: "2-digit" });
        if (dateData.includes("/")) {
            dateData = dateData.replaceAll("/", "-");
        }
        const result = dateData.toString().split("-").reverse().join("-");
        return result;
    } else {
        return "";
    }
}

// recibe 2024-05-12 devuelve el forato fecha correcto segun funcion
export function formatAddDate(date: string, lang?: string, fn?: () => void) {
    const formatDate = new Date(date.replaceAll("-", "/"));
    return formatDateDigits(formatDate, lang);
}


/// 12:00:62
export function formatTimeLong(date: Date) {
    return date.toLocaleTimeString(TranslationService.currentLanguage, { hour: "2-digit", minute: "2-digit", second: "2-digit" }).replace(",", "");
}

/// 12:00
export function formatTimeShort(date: Date) {
    return date.toLocaleTimeString(TranslationService.currentLanguage, { hour: "2-digit", minute: "2-digit" }).replace(",", "");
}

/// 29 de junio
export function formatDateLong(date: Date) {
    return date.toLocaleDateString(TranslationService.currentLanguage, { day: "2-digit", month: "long" });
}

/// 29 jun 2022
export function formatDateShort(date: Date) {
    return date.toLocaleDateString(TranslationService.currentLanguage, { day: "2-digit", month: "short", year: "numeric" });
}

/// 29/01/22 12:00:00
export function formatDateTimeLong(date: Date) {
    return date.toLocaleDateString(TranslationService.currentLanguage, { day: "2-digit", month: "2-digit", year: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" }).replace(",", "");
}

/// 29 jun 22 12:00
export function formatDateTimeShort(date: Date) {
    return date.toLocaleDateString(TranslationService.currentLanguage, { day: "2-digit", month: "short", year: "2-digit", hour: "2-digit", minute: "2-digit" }).replace(",", "");
}

/// junio 2022
export function formatDateMonthYear(date: Date) {
    return date.toLocaleDateString(TranslationService.currentLanguage, { month: "long", }) + " " +
        date.toLocaleDateString(TranslationService.currentLanguage, { year: "numeric" });
}

/// jun-22
export function formatDateMonthYearShort(date: Date) {
    return date.toLocaleDateString(TranslationService.currentLanguage, { month: "short", }) + "-" +
        date.toLocaleDateString(TranslationService.currentLanguage, { year: "2-digit" });
}

/// $ 1.126.216(,53 depending if enabled)
export function formatCurrency(value: number, currencyId: number = CompanyService.getDefaultCurrencyId(), numberFormater = (CompanyService.getCompanyAuth()?.ShowCents ? formatNumber : formatInteger)) {
    if (value === null || value === undefined) { return undefined; }
    const symbol = CompanyService.getCurrencies().find(x => x.CurrencyId === currencyId)?.Symbol;
    return (symbol ?? '') + "\u00A0" + numberFormater(value);
}

/// 1.126.216,53
export function formatNumber(value: number) {
    return formatNumberFractions(value, 2);
}

export function formatNumberFractions(value: number, fractions: number) {
    value = parseFloat(value.toString());
    return value.toLocaleString(TranslationService.currentLanguage + "-AR", { minimumFractionDigits: fractions, maximumFractionDigits: fractions });
}

/// 1.126.216
export function formatInteger(value: number) {
    if (value === 0) {
        return "0";
    }
    return value.toLocaleString(TranslationService.currentLanguage + "-AR", { maximumFractionDigits: 0 });
}

export function exponentialToDecimal(exponential: number) {
    let decimal = exponential.toString().toLowerCase();
    if (decimal.includes("e+")) {
        const exponentialSplitted = decimal.split("e+");
        let postfix = "";
        for (
            let i = 0; i <
            +exponentialSplitted[1] -
            (exponentialSplitted[0].includes(".") ? exponentialSplitted[0].split(".")[1].length : 0); i++
        ) {
            postfix += "0";
        }
        function addCommas(text: string) {
            let j = 3;
            let textLength = text.length;
            while (j < textLength) {
                text = `${text.slice(0, textLength - j)},${text.slice(textLength - j, textLength)}`;
                textLength++;
                j += 3 + 1;
            }
            return text;
        }
        decimal = addCommas(exponentialSplitted[0].replace(".", "") + postfix);
    }
    if (decimal.toLowerCase().includes("e-")) {
        const exponentialSplitted = decimal.split("e-");
        let prefix = "0.";
        for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
            prefix += "0";
        }
        decimal = prefix + exponentialSplitted[0].replace(".", "");
    }
    return decimal;
}

export function isMoreThan24Hours(oldDate: string | Date): boolean {
    const currentDate = new Date();
    const oldDateObj = new Date(oldDate);
    if (isNaN(oldDateObj.getTime())) {
        throw new Error('Invalid date format');
    }

    const differenceInMilliseconds = currentDate.getTime() - oldDateObj.getTime();
    const millisecondsIn24Hours = 24 * 60 * 60 * 1000;
    return differenceInMilliseconds > millisecondsIn24Hours;
}
