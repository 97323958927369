import { useContext, useEffect, useState } from "react";
import CompanyService from "../../services/CompanyService";
import UserService, { UserRole } from "../../services/UserService";
import ToastContext from "../shared/bootstrap/Toast";
import Dropdown from "../shared/components/Dropdown";
import { CheckBoxEditor, MultiselectEditor, TextEditor } from "../shared/components/Editors";
import FloatingPanelService from "../shared/FloatingPanel";
import { TranslationService } from '../../services/TranslationService';
import { UserGetResponse } from "./entities/UserGetResponse";
import { RequiredManager, ValidationMessage } from "../shared/RequieredManager";
import { parseIntOrDefault, validateMail } from "../../utils/ParseUtils";
import { FloatingPanelFooter } from "../shared/components/FloatingPanelFooter";
import { getError } from "../../utils/RequestUtils";
import { DefaultModalProps } from "../shared/bootstrap/DefaultModal";
import ModalService from "../shared/bootstrap/Modal";

const permissions = [
    "NewPerson",
    "EditPerson",
    "DeletePerson",
    "NewIO",
    "EditIO",
    "DeleteIO",
    "DisableIOStatusEdit",
    "NewTransaction",
    "EditTransaction",
    "DeleteTransaction",
    "NewComment",
    "EditComment",
    "DeleteComment",
    "NewMail",
    "EditMail",
    "NewTask",
    "EditTask",
    "DeleteTask",
    "CheckTask",
    "CheckOthersTask",
    "NewTag",
    "EditTag",
    "DeleteTag",
    "ViewReport",
    "Export",
];

export class UserEditModel {
    UserID: number = 0;
    email?: string;
    role?: UserRole = UserRole.Guest;
    segments: string[] = [];
    creatorUserId?: number;
    chooseSegments: boolean = false;
    DashboardPanelID?: number;

    NewPerson: boolean = true;
    EditPerson: boolean = true;
    DeletePerson: boolean = true;

    NewIO: boolean = true;
    EditIO: boolean = true;
    DeleteIO: boolean = true;
    DisableIOStatusEdit: boolean = false;

    NewTransaction: boolean = true;
    EditTransaction: boolean = true;
    DeleteTransaction: boolean = true;

    NewComment: boolean = true;
    EditComment: boolean = true;
    DeleteComment: boolean = true;

    NewMail: boolean = true;
    EditMail: boolean = true;

    NewTask: boolean = true;
    EditTask: boolean = true;
    DeleteTask: boolean = true;
    CheckTask: boolean = true;
    CheckOthersTask: boolean = true;

    NewTag: boolean = true;
    EditTag: boolean = true;
    DeleteTag: boolean = true;

    ViewReport: boolean = true;
    Export: boolean = true;

    constructor(user?: UserGetResponse.User) {
        if (!user) { return; }

        this.UserID = user.UserID;
        this.email = user.email;
        this.DashboardPanelID = parseIntOrDefault(user.DashboardPanelID, undefined);
        this.segments = user.segments.map(x => x.toString()) ?? [];
        this.chooseSegments = this.segments.length > 0;
        this.creatorUserId = user.CreatorUserID;
        if (user.Admin) {
            this.role = UserRole.Admin;
        }
        else if (user.ReadOnly) {
            this.role = UserRole.QueriesOnly;
        }
        else {
            this.role = UserRole.Guest;
        }

        permissions.forEach((permission: string) => {
            (this[permission as keyof UserEditModel] as boolean) = (user[permission as keyof typeof user] ?? false) as boolean;
        });
    }
}


export const UserEdit = ({ userId, panelOptions, onSubmit }: { userId?: number, panelOptions: { value: number, text: string }[], onSubmit?: () => void }) => {
    const { showToast } = useContext(ToastContext);
    const { translate } = TranslationService;
    const { showDefaultModal } = ModalService;
    const [userEdit, setUserEdit] = useState(new UserEditModel());
    const [saving, setSaving] = useState(false);
    const [hasPassword, setHasPassword] = useState(true);
    const segmentOptionList = CompanyService.getSegments();
    const requiredManager = new RequiredManager();

    const editPanel = userId !== undefined && userEdit.UserID > 0 && (userEdit.creatorUserId === null || userEdit.UserID.toString() === CompanyService.getUserid());
    const isLoadingUser = () => userId && userEdit.UserID === 0;
    const userIsCreator = () => userEdit.creatorUserId === null;
    const canOnlyEditPanel = () => userId && (userIsCreator() || userId === parseInt(CompanyService.getUserid()));

    const updateUser = (propName: string) => (value: string | string[] | number | boolean | undefined) => {
        setUserEdit(user => ({ ...user, [propName]: value }));
    }
    const onEmailChange = requiredManager.makeRequired(updateUser("email"));

    useEffect(() => {
        const loadUser = async () => {
            if (!userId) {

                let newUser = new UserEditModel();
                setUserEdit(newUser);

                return;
            }
            const result = await UserService.get(userId.toString());
            if (result instanceof Error) {
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
                return undefined;
            }
            const newUserEdit = new UserEditModel(result?.item);
            setHasPassword(result?.item.HasPassword ?? true);
            setUserEdit(newUserEdit);
            return;
        }

        loadUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId])

    const chkAllPermissions = (checked: boolean) => {
        permissions.forEach((permission: string) => {
            (userEdit[permission as keyof UserEditModel] as boolean) = checked;
        });
        userEdit.DisableIOStatusEdit = !checked;
        userEdit.CheckOthersTask = !checked;
        setUserEdit({ ...userEdit });
    }

    const roleChange = (role: UserRole | undefined) => {
        setUserEdit({ ...userEdit, role })
    }

    const canSubmit = () => {
        return !isLoadingUser() && !saving && requiredManager.validate();
    }

    const resendInvitation = async () => {

        const modalProps: DefaultModalProps = {
            message: TranslationService.translate.ResendInvitationConfirm,
            acceptButtonLabel: TranslationService.translate.Accept,
            onAcceptClick: async () => {
                const result = await UserService.reinvite(userEdit.email!);
                if (result instanceof Error) {
                    showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
                }
                else {
                    showToast(TranslationService.translate.ResendInvitationSent, undefined, "success");
                    ModalService.hideModal();
                    //context.reload();
                }
            }
        }
        showDefaultModal(modalProps);
    }

    const submit = async () => {
        if (!canSubmit()) { return; }
        setSaving(true);
        if (!userEdit.chooseSegments) {
            userEdit.segments = [];
        }
        const result = await UserService.set(userEdit);
        if (result instanceof Error) {
            const err = getError(result);
            let errorMessage = translate.ErrorProcessingRequest;
            if (err.status === 400) {
                if (err.message === "Fail-Exists") {
                    errorMessage = translate.IdentifierAlreadyInUsed;
                }
            }
            showToast(errorMessage, undefined, "danger");
            setSaving(false);
        }
        else {
            showToast(translate.SavedChanges, undefined, "success");
            onSubmit && onSubmit();
            FloatingPanelService.hidePanel();
        }
    }

    return (
        <>
            <div className="floatingBody p-4">
                {isLoadingUser() && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                {!isLoadingUser() && canOnlyEditPanel() &&
                    <div className="row ">
                        <div className="col-6">
                            <label className="form-label">{translate.Panel}</label>
                            <Dropdown
                                items={panelOptions}
                                onChange={updateUser("DashboardPanelID")}
                                optionLabel={translate.Standard}
                                value={userEdit.DashboardPanelID} />
                        </div>
                    </div>
                }
                {!isLoadingUser() && !canOnlyEditPanel() && <>
                    <div className="row">
                        <div className="col">
                            <div className="d-flex input-column mb-3">
                                <label className="form-label">{translate.Email}</label>
                                {userEdit.UserID ?
                                    <div className="d-flex align-items-center">
                                        <span>{userEdit.email}</span>
                                        {!hasPassword && <button className="btn btn-link ms-3" onClick={resendInvitation}>{translate.ResendInvitation}</button>}
                                    </div>
                                    : <>
                                        <TextEditor onChange={onEmailChange} defaultValue={userEdit.email} />
                                        <ValidationMessage onChange={onEmailChange} message={translate.RequiredField} validationMethod={validateMail} defaultValue={userEdit.email} />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="d-flex input-column mb-3">
                                <label className="form-label">{translate.Profile}</label>
                                <Dropdown
                                    items={[
                                        { value: UserRole.Admin, text: translate.AdminUser },
                                        { value: UserRole.Guest, text: translate.Guest },
                                        { value: UserRole.QueriesOnly, text: translate.QueryUser },
                                    ]}
                                    onChange={(role: UserRole) => roleChange(role)}
                                    value={userEdit.role}
                                    readonly={editPanel}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex input-column mb-3">
                                <label className="form-label">{translate.Panel}</label>
                                <Dropdown
                                    items={panelOptions}
                                    onChange={updateUser("DashboardPanelID")}
                                    optionLabel={translate.Standard}
                                    value={userEdit.DashboardPanelID} />
                            </div>
                        </div>
                    </div>
                    {userEdit.role === UserRole.Guest &&
                        <>
                            <div className="d-flex align-items-center py-3">
                                <h2 className="pe-4">
                                    {translate.Permissions}
                                </h2>
                                <CheckBoxEditor onChange={chkAllPermissions} message={translate.CheckAll}></CheckBoxEditor>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="d-flex input-column mb-3">
                                        <label className="form-label">{translate.Clients}</label>
                                        <div className="d-flex gap-5">
                                            <CheckBoxEditor onChange={updateUser("NewPerson")} message={translate.New} value={userEdit.NewPerson}></CheckBoxEditor>
                                            <CheckBoxEditor onChange={updateUser("EditPerson")} message={translate.Edit} value={userEdit.EditPerson}></CheckBoxEditor>
                                            <CheckBoxEditor onChange={updateUser("DeletePerson")} message={translate.Delete} value={userEdit.DeletePerson}></CheckBoxEditor>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="d-flex input-column mb-3">
                                        <label className="form-label">{translate.Invoices}</label>
                                        <div className="d-flex gap-5">
                                            <CheckBoxEditor onChange={updateUser("NewIO")} message={translate.New} value={userEdit.NewIO}></CheckBoxEditor>
                                            <CheckBoxEditor onChange={updateUser("EditIO")} message={translate.Edit} value={userEdit.EditIO}></CheckBoxEditor>
                                            <CheckBoxEditor onChange={updateUser("DeleteIO")} message={translate.Delete} value={userEdit.DeleteIO}></CheckBoxEditor>
                                            <CheckBoxEditor onChange={checked => updateUser("DisableIOStatusEdit")(!checked)} message={translate.InvoiceStatus} value={!userEdit.DisableIOStatusEdit}></CheckBoxEditor>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="d-flex input-column mb-3">
                                        <label className="form-label">{translate.Payments}</label>
                                        <div className="d-flex gap-5">
                                            <CheckBoxEditor onChange={updateUser("NewTransaction")} message={translate.New} value={userEdit.NewTransaction}></CheckBoxEditor>
                                            <CheckBoxEditor onChange={updateUser("EditTransaction")} message={translate.Edit} value={userEdit.EditTransaction}></CheckBoxEditor>
                                            <CheckBoxEditor onChange={updateUser("DeleteTransaction")} message={translate.Delete} value={userEdit.DeleteTransaction}></CheckBoxEditor>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="d-flex input-column mb-3">
                                        <label className="form-label">{translate.Comments}</label>
                                        <div className="d-flex gap-5">
                                            <CheckBoxEditor onChange={updateUser("NewComment")} message={translate.New} value={userEdit.NewComment}></CheckBoxEditor>
                                            <CheckBoxEditor onChange={updateUser("EditComment")} message={translate.Edit} value={userEdit.EditComment}></CheckBoxEditor>
                                            <CheckBoxEditor onChange={updateUser("DeleteComment")} message={translate.Delete} value={userEdit.DeleteComment}></CheckBoxEditor>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="d-flex input-column mb-3">
                                        <label className="form-label">{translate.Emails}</label>
                                        <div className="d-flex gap-5">
                                            <CheckBoxEditor onChange={updateUser("NewMail")} message={translate.Send} value={userEdit.NewMail}></CheckBoxEditor>
                                            <CheckBoxEditor onChange={updateUser("EditMail")} message={translate.Edit} value={userEdit.EditMail}></CheckBoxEditor>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="d-flex input-column mb-3">
                                        <label className="form-label">{translate.Tasks}</label>
                                        <div className="d-flex gap-5">
                                            <CheckBoxEditor onChange={updateUser("NewTask")} message={translate.New} value={userEdit.NewTask}></CheckBoxEditor>
                                            <CheckBoxEditor onChange={x => { updateUser("EditTask")(x); updateUser("CheckTask")(false); updateUser("CheckOthersTask")(true); }} message={translate.Edit} value={userEdit.EditTask}></CheckBoxEditor>
                                            <CheckBoxEditor onChange={updateUser("DeleteTask")} message={translate.Delete} value={userEdit.DeleteTask}></CheckBoxEditor>
                                            <CheckBoxEditor onChange={x => { updateUser("CheckTask")(x); updateUser("CheckOthersTask")(true); }} message={translate.ChangeStatus} value={userEdit.EditTask || userEdit.CheckTask} disabled={userEdit.EditTask}></CheckBoxEditor>
                                            <CheckBoxEditor onChange={checked => updateUser("CheckOthersTask")(!checked)} message={translate.OnlyMarkOwnTasks} value={userEdit.EditTask || (userEdit.CheckTask && !userEdit.CheckOthersTask)} disabled={userEdit.EditTask || !userEdit.CheckTask}></CheckBoxEditor>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="d-flex input-column mb-3">
                                        <label className="form-label">{translate.Tags}</label>
                                        <div className="d-flex gap-5">
                                            <CheckBoxEditor onChange={updateUser("NewTag")} message={translate.New} value={userEdit.NewTag}></CheckBoxEditor>
                                            <CheckBoxEditor onChange={updateUser("EditTag")} message={translate.Edit} value={userEdit.EditTag}></CheckBoxEditor>
                                            <CheckBoxEditor onChange={updateUser("DeleteTag")} message={translate.Delete} value={userEdit.DeleteTag}></CheckBoxEditor>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="d-flex input-column mb-3">
                                        <CheckBoxEditor onChange={updateUser("ViewReport")} message={translate.UserCanAccessReports} value={userEdit.ViewReport}></CheckBoxEditor>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="d-flex input-column mb-3">
                                        <CheckBoxEditor onChange={updateUser("Export")} message={translate.UserCanExport} value={userEdit.Export}></CheckBoxEditor>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {(userEdit.role === UserRole.Guest || userEdit.role === UserRole.QueriesOnly) &&
                        <>
                            <div className="row">
                                <div className="col">
                                    <div className="d-flex input-column mb-3">
                                        <label className="form-label">{translate.ThisUserHasAccessTo}</label>
                                        <Dropdown
                                            items={[
                                                { value: 'false', text: translate.AllClients },
                                                { value: 'true', text: translate.OnlyClientsInSelectedSegments },
                                            ]}
                                            onChange={val => updateUser("chooseSegments")(val === 'true')}
                                            value={userEdit.chooseSegments.toString()} />
                                    </div>
                                </div>
                            </div>
                            {userEdit.chooseSegments &&
                                <div className="row">
                                    <div className="col">
                                        <div className="d-flex input-column mb-3">
                                            {/* <label className="form-label">{translate.ThisUserHasAccessTo}</label> */}
                                            <MultiselectEditor
                                                items={segmentOptionList.map(x => ({ value: x.Id, label: x.Value }))}
                                                callback={updateUser("segments")}
                                                placeholder={translate.SelectSegments}
                                                value={userEdit.segments.map(x => ({ value: x, label: segmentOptionList.find(y => y.Id === x)!.Value }))} />
                                        </div>
                                    </div>
                                </div>
                            }
                        </>}
                </>}
            </div >
            <FloatingPanelFooter>
                <button className='btn btn-primary me-3' onClick={submit}>
                    {userId ? translate.Save : translate.Invite}
                    {saving && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </FloatingPanelFooter>
        </>);
}