import { TranslationService } from "../../../../../services/TranslationService";
import DropdownWithOptionalText from "./DropdownWithOptionalText";

const DateFormat = ({defaultValue, updateModel: onChange, label = undefined}:{defaultValue: string | undefined, updateModel: (value: string) => void, label?: string}) => {
    const { translate } = TranslationService;    

    const dateFormats = [
        { value: "", text: translate.Automatic },
        { value: "d/m/y", text: `${translate.Day}/${translate.Month}/${translate.Year}` },
        { value: "m/d/y", text: `${translate.Month}/${translate.Day}/${translate.Year}` },
        { value: "other", text: translate.Other },
    ];

    return (
        <div className="mb-3">
            <label className="w-lg col-form-label font-weight-normal">{label ?? translate.DateFormat}</label>
            <div className=""><DropdownWithOptionalText onChange={onChange} items={dateFormats} toTextValue={"other"} defaultValue={defaultValue} /></div>
        </div>
    )
}

export default DateFormat;