import { useCallback, useEffect, useState } from "react";
import ImportTemplateService from "../../../../services/ImportTemplateService";
import { OptionalMap, GroupBy } from "../../../../utils/Utils";
import ErrorMessage from "../../../shared/components/Error";
import Loading from "../../../shared/components/Loading";
import { ImportTemplateListResponse } from "../../../shared/entities/ImportTemplateListResponse";
import { TranslationService } from "../../../../services/TranslationService";
import { CollapsableTr } from "../../../segment/List";
import DictionaryService from "../../../../services/DictionaryService";
import { ImportIds, ImportService } from "../../../../services/ImportService";

const ImportTemplateConfiguration = () => {
    const { translate } = TranslationService;
    const [response, setResponse] = useState<ImportTemplateListResponse>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [showRows, setShowRows] = useState<Record<string | number, boolean>>({});

    const requestData = async () => {
        setLoading(true);
        const dictPromise = DictionaryService.getList();
        const result = await ImportTemplateService.getList();
        const dictionaries = await dictPromise;
        if (result instanceof Error || dictionaries instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        result.list.filter(x => x.entity === 11).forEach(y => {
            y.Name = dictionaries.list.find(x => x.ImportTemplateID === y.ImportTemplateID)?.Name ?? y.Name;
        });
        result.list = result.list.filter(x => x.Entity !== 10);
        setResponse(result);
        setLoading(false);
        setError(false);
    };

    const requestDataCallback = useCallback(requestData, []);
    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    if (loading) {
        return (<Loading />);
    }

    if (error) {
        return (<ErrorMessage onRefresh={requestDataCallback} message={translate.ErrorLoadingList} />);
    }

    // const ShowNewImportTemplateModal = () => {
    //     FloatingPanelService.showPanel({
    //         children: <NewImportTemplateModal/>,
    //         title: translate.EditImportTemplate,
    //         width: 600,
    //         height: 800,
    //         position: "center",
    //     });
    // }

    const importDefinitions = ImportService.getImports();
    const order = [
        ImportIds.Client, // Cliente
        9, // Automatico cliente
        0, // Facturas
        5, // Editar multiples facturas
        6, // Automático facturas
        7, // Eliminicacion facturas
        8, // Nuevas facturas
        2,
        3,
        4,
        10,
        11,
        12,
    ];

    const groupImports = OptionalMap(response, x => Object.values(GroupBy(x.list, y => y.Entity))) ?? [];
    const sortedGroupImports = order.filter(x => importDefinitions[x].isEnabled).map(x => groupImports.find(y => y[0].Entity === x)!).filter(x => x);
    return (
        <div className="card px-5">
            <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center my-4">
                <h2>{TranslationService.translate.ImportTemplates}</h2>
                {/* <div className="d-flex align-items-center">
                    <button className="btn btn-primary" onClick={() => ShowNewImportTemplateModal()}><i className="fal fa-plus" /> {translate.AddNewTemplate}</button>
                </div> */}
            </div>
            <table className="table shadow-hover collapsableFolder">
                <tbody>
                    {sortedGroupImports && sortedGroupImports.map(imports => <>
                        <tr key={imports[0].Entity} onClick={() => setShowRows(rows => ({ ...rows, [imports[0].entity]: !rows[imports[0].entity] }))} >
                            <td valign="middle">
                                <span className="arrowDown mb-2"><i className={"fa-solid fa-fw pe-3 " + (!showRows[imports[0].entity] ? "fa-chevron-down" : "fa-chevron-up")}></i></span>
                                <span className="iconFolder"><i className={"fa-light fa-fw mx-2 fa-folder" + (showRows[imports[0].entity] ? "-open" : "")}></i></span>
                                <span className="folderName">{importDefinitions[imports[0].entity].text}</span>
                            </td>
                            <td></td>
                        </tr>
                        {imports.map(x =>
                            <CollapsableTr show={showRows[imports[0].entity]}
                                className={"pointer show-child-on-hover"} key={x.ImportTemplateID}
                                onClick={() => ImportTemplateService.showImportTemplateEditModal(x, response, requestDataCallback)}>
                                <td><span style={{marginLeft: 57}}>{x.Name}</span></td>
                                <td width={200}></td>
                            </CollapsableTr>
                        )}
                    </>)}
                </tbody>
            </table>
        </div>
    );
};

export default ImportTemplateConfiguration;