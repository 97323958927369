import Highcharts from "highcharts";
import HighchartsExport from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DashboardEntity } from "../../../entities/company/Dashboard/DashboardEntity";
import { DashboardFilterEntity } from "../../../entities/company/Dashboard/DashboardFilterEntity";
import { FilterRequest } from "../../../entities/Filter/FilterRequest";
import CompanyService from "../../../services/CompanyService";
import DashboardService from "../../../services/DashboardService";
import { FilterCollection } from "../../../services/FilterService";
import { formatIntizaDate } from "../../../utils/FormatUtils";
import { TranslationService } from "../../../services/TranslationService";
import { Cast, OptionalMap } from "../../../utils/Utils";
import moment from "moment";
HighchartsExport(Highcharts);

Highcharts.AST.allowedAttributes.push("onclick");
Highcharts.AST.allowedAttributes.push("onmouseup");
Highcharts.setOptions({
    lang: {
        thousandsSep: window.location.href.replace(window.location.origin, "").startsWith("/en/") ? "," : ".",
        decimalPoint: window.location.href.replace(window.location.origin, "").startsWith("/en/") ? "." : ",",
    }
});

type HighchartChartType = {
    dashboard: DashboardEntity,
    options: Highcharts.Options,
    dashboardFilters: FilterRequest[],
    filterCallback: (dashboardFilterEntity: DashboardFilterEntity) => void,
    valuesEnabled: boolean

}

const HighchartWithChildrenChart = ({ dashboard, options, dashboardFilters, filterCallback, valuesEnabled }: HighchartChartType) => {
    if (options?.tooltip) {
        options!.tooltip.enabled = false;
    }
    const responseBar = JSON.parse(JSON.stringify(options));

    const [highchartOptions, setHighchartOptions] = useState<Highcharts.Options>(responseBar);
    const { currentLanguage } = TranslationService;
    const history = useHistory();

    useEffect(() => {
        Highcharts.setOptions({ lang: { decimalPoint: TranslationService.currentLanguage === "en" ? "," : "." } });
    }, []);

    /*ITS OK TO SET 2 TIMES*/
    useEffect(() => {
        setHighchartOptions(options);
        setHighchartOptions({ ...options });
    }, [options]);

    Cast<Record<string, (event: MouseEvent, index: number) => void>>(window)["goToClient" + dashboard.DashboardID.toString().replace("-", "")] =
        function (event: MouseEvent, index: number) {
            goTo("/client", index, FilterCollection.Client, event.button === 1);
        };

    Cast<Record<string, (event: MouseEvent, index: number) => void>>(window)["goToInvoice" + dashboard.DashboardID.toString().replace("-", "")] =
        function (event: MouseEvent, index: number) {
            goTo("/report/iolist", index, FilterCollection.ReportInvoice, event.button === 1);
        };

    const goTo = function (url: string, index: number, filterType: FilterCollection, newTab: boolean) {
        const totalFilters = [...dashboardFilters];
        const data: Array<any> = ((highchartOptions.series![0] ?? highchartOptions.series) as any).data[index];
        const idMap: Record<string | number, string> = {
            "-29": "-7",
            // "-33": "-10",
        };
        let addExtraFilters = false;
        if (data[2] === -36) {
            const val = CompanyService.GetAgeingValues()[data[3]];
            if (filterType === FilterCollection.Client) {
                totalFilters.push({
                    field: "-8",
                    operator: data[8],
                    value: val.filterVal
                });
            }
            else {
                totalFilters.push({
                    field: "-8",
                    operator: data[8],
                    value: val.filterVal
                });
                addExtraFilters = true;
            }
        }

        else {
            const value = OptionalMap(data[3] as string ?? undefined, x => [...x].filter(x => x === "-").length === 2 ? x.replaceAll("-", "/") : x) ?? "";
            const i = totalFilters.push({
                field: idMap[data[2]] ?? data[2],
                operator: data[8],
                value,
            });
            const fixDue = totalFilters.find(x => x.field === "-10" && x.value === "1");
            if (fixDue) {
                fixDue.operator = 2;
                fixDue.value = "0";
            }
            const fixDue2 = totalFilters.find(x => x.field === Cast<string>(-8));
            const val = CompanyService.GetAgeingValues()[parseInt(data[3])];
            if (fixDue2 && val) {
                fixDue2.value = val.filterVal;
            }
            if (data[8] === "0" && value === "" && data[0] === TranslationService.translate.Empty) {
                totalFilters[i - 1].operator = 7;
            }
        }
        if (filterType === FilterCollection.ReportInvoice && dashboard.GroupBy !== -29 && dashboard.Type !== 1) {
            addExtraFilters = true;
        }
        if (filterType === FilterCollection.ReportInvoice && dashboard.GroupBy === -29) {
            totalFilters.push({
                operator: 5,
                field: "-1012",
                value: "0"
            });
        }
        if (dashboard.Variable === 7) {
            totalFilters.push({
                operator: 0,
                field: "-1016",
                value: "1",
            });
        }
        if (dashboard.GroupBy === -33) {
            const val = CompanyService.GetAgeingValues()[data[3]];
            totalFilters.push({
                field: "-8",
                operator: data[8],
                value: val.filterVal
            });
        }
        if (data[2] === -33) {
            totalFilters.splice(totalFilters.findIndex(x => x.field === "-1012"), 1);
            const value = (formatIntizaDate(new Date(new Date().setFullYear(new Date().getFullYear() - 50))) + "-" + formatIntizaDate(moment().add(-1, "day").toDate()));

            const dateOperator = 0;
            totalFilters.push({
                operator: dateOperator,
                field: "-1024",
                value: value
            });
        }

        if (addExtraFilters) {
            const status = totalFilters.find(x => x.field === "-8");
            if (status) {
                totalFilters.push({
                    operator: 5,
                    field: "-1012",
                    value: "0"
                });
            }
            if (dashboard.Type === 9) {
                totalFilters.push({
                    operator: 5,
                    field: "-1012",
                    value: "0"
                });
            }
        }

        const qs = encodeURIComponent(DashboardService.GetQueryFilter(totalFilters, filterType) || "");

        if (newTab) {
            window.open(`/${currentLanguage}${url}?filter=${qs}`, "_blank");
        }
        else {
            history.push(`/${currentLanguage}${url}?filter=${qs}`);
        }
    };


    useEffect(() => {
        const toggleShowValues = (checked?: boolean) => setHighchartOptions(x => ({
            ...Object.assign(x, {
                plotOptions: {
                    pie: {
                        dataLabels: {
                            format: checked ?
                                "{point.name}: {point.percentage:.1f} %<br>{point.y:,.0f}" :
                                "{point.name}: {point.percentage:.1f} %"
                        },
                        series: {
                            animation: { duration: 0 }
                        }
                    },
                    series: {
                        dataLabels: {
                            enabled: checked && dashboard.Type === 2
                        }
                    }
                },
                yAxis: {
                    stackLabels: { enabled: checked }
                }

            })
        }));
        toggleShowValues(valuesEnabled);
    }, [valuesEnabled, dashboard.Type]);

    return (
        <HighchartsReact highcharts={Highcharts} options={highchartOptions} />
    );
};

export default HighchartWithChildrenChart;