
import { ScheduledListResponse, ScheduledRequestItem } from "../pages/reports/entities/ScheduledListResponse";
import RequestService from "./RequestService";

export class ScheduledService {
    public static getScheduledReportList(): Promise<ScheduledListResponse | Error> {
        return RequestService.post("/scheduledreport/list", {});
    }
    public static setScheduledReport(request: ScheduledRequestItem) {
        return RequestService.post("/scheduledreport/set", { item: request });
    }
    public static deleteScheduledReport(id: number) {
        return RequestService.post("/scheduledreport/delete", { id });
    }
    public static processScheduledReport(id: number) {
        return RequestService.post("/scheduledreport/process", { id });
    }
}
