import { useEffect } from "react";
import Section from "../components/form/Section";
import { SectionsData, TitleRefs, EditProps } from "./EditAction";

export const ListSections = ({ sections, titleRefs, editProps }: {
    sections: SectionsData,
    titleRefs: React.MutableRefObject<TitleRefs>,
    editProps: EditProps
}) => {
    const { headings, listItems } = titleRefs.current;
    useEffect(() => {
        let prevScroll = 0;
        const interval = setInterval(() => {
            const htmlElem = document.documentElement;
            if (htmlElem.scrollTop === prevScroll) {
                return;
            }
            prevScroll = htmlElem.scrollTop;
            const currentHighlighted = listItems.findIndex(x => x.classList.contains('highlight'));

            const limit = 35;
            let switchToIndex: number | null = null;
            for (let index = 0; index < headings.length; index++) {
                const element = headings[index];
                const top = element.getBoundingClientRect().top;
                if (index > currentHighlighted && top < limit) {
                    switchToIndex = index;
                    break;
                }
                const nextTop = headings[index + 1]?.getBoundingClientRect().top;
                if (index < currentHighlighted && nextTop > limit) {
                    switchToIndex = index;
                    break;
                }
            }
            const scrolledToTheBottom = htmlElem.clientHeight + htmlElem.scrollTop === htmlElem.scrollHeight;
            if (scrolledToTheBottom && (switchToIndex ?? 0) < (listItems.length - 1)) {
                switchToIndex = listItems.length - 1;
            }

            if (switchToIndex !== null) {
                listItems.forEach(section => {
                    section.classList.remove("highlight");
                });
                listItems[switchToIndex].classList.add("highlight");
            }
        }, 200);
        return () => clearInterval(interval);
    }, [headings, listItems]);

    const setupTitleRef = (index: number) => (div: HTMLHeadingElement | null) => {
        if (div === null) {
            return;
        }
        const sectionIndex = sections.filter(x => x.name !== undefined).indexOf(sections[index]);
        if (sectionIndex !== -1) {
            headings[sectionIndex] = div;
        }
    }

    return (<>
        {
            sections.map((x, i) =>
                <Section key={i} ref={setupTitleRef(i)} name={x.name}>
                    <x.Component {...editProps} />
                </Section>)
        }</>);
}