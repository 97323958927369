import { useState } from "react";
import ButtonDropdown from "./ButtonDropdown";
import TooltipComponent from "../TooltipComponent";

export type MenuButtonActions = { text: string, action: () => void }[];

const MenuButton = (props: { text: string, actions: MenuButtonActions, extraClassName?: string, icon?: string, buttonClass?: string, showTitle?: boolean }) => {
    const [chevronClass, setChevronClass] = useState("fa-chevron-down");
    const onClick = (indexString: string) => {
        const index = parseInt(indexString);
        props.actions[index].action();
    };
    return (props.showTitle ?
        <ButtonDropdown items={props.actions.map((x, i) => ({ value: i.toString(), text: x.text }))} onCollapse={() => setChevronClass("fa-chevron-down")} onShow={() => setChevronClass("fa-chevron-up")} onChange={onClick} buttonClass={props.buttonClass}>
            <div className={"select-intiza select-intiza-primary " + (props.extraClassName ?? "")}>
                {props.icon && <i className={props.icon + " " + props.extraClassName}></i>}
                {props.text}<i className={"fas ms-2 " + chevronClass + " " + props.extraClassName}></i>
            </div>
        </ButtonDropdown>
        :
        <TooltipComponent title={props.text}>
            <ButtonDropdown items={props.actions.map((x, i) => ({ value: i.toString(), text: x.text }))} onCollapse={() => setChevronClass("fa-chevron-down")} onShow={() => setChevronClass("fa-chevron-up")} onChange={onClick} buttonClass={props.buttonClass}>
                <div className={"select-intiza select-intiza-primary " + (props.extraClassName ?? "")}>
                    {props.icon && <i className={props.icon + " " + props.extraClassName}></i>}
                    <i className={"fas ms-2 " + chevronClass + " " + props.extraClassName}></i>
                </div>
            </ButtonDropdown>
        </TooltipComponent>

    );
};

export default MenuButton;