import RequestService from "./RequestService";

export type WhatsappTemplateItemType = {
    waid: string,
    Name: string,
    Structure: string,
    Example: string,
    Parameters: number,
    WATemplateID: number,
    FieldList: string,
}

export type WhatsappTemplateListType = {
    list: WhatsappTemplateItemType[]
}

export type WhatsAppRequestBodySet = {
    WATemplateID: number,
    CompanyID: number,
    FieldList: string,
}
class WhatsappService {

    public static getList(): Promise<Error | WhatsappTemplateListType> {
        return RequestService.post("/watemplate/list", {});
    }

    public static sincroImportTemplates(): Promise<Error | any> {
        return RequestService.post("/watemplate/import", {});
    }

    public static setTemplate(bodyRequest: WhatsAppRequestBodySet): Promise<Error | WhatsappTemplateListType> {
        return RequestService.post("/watemplate/set", { ...bodyRequest });
    }

    // public static getImageMessage(imgageId: string): Promise<Error | any> {
    //     return () => null;
    //     // return RequestService.post("/watemplate/list", {});
    // }

    public static getConfigItemsByWhatsApp() {
        return [-21, -22, -23, -24, -25, -26, -28, -30, -32, -33, -34]
    }
}


export default WhatsappService;