import FloatingPanelService from "../../shared/FloatingPanel";
import { TranslationService } from '../../../services/TranslationService';
import SMSConfigurationEdit from "./SMSConfigurationEdit";


const SMSConfiguration = () => {
    const { translate } = TranslationService;
  
    const showChangeSMS = () => {
        FloatingPanelService.showPanel({
            children: <SMSConfigurationEdit/>,
            title: translate.EditImportTemplate,
            width: 400,
            height: 600,
            position: "center",
        });
    }

    return (
        <div className="card px-5">
            <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center my-4">
                <h2>{TranslationService.translate.SMSSetup}</h2>        
            </div>
            <div className="mb-3 row align-items-center show-child-on-hover">
                <div className="col-auto"><label className="">Método de envío</label></div>         
                <div className="col-4"><input className="form-control" value="Twilio"></input></div>
                <div className="col"><button className="btn btn-primary"onClick={() => showChangeSMS()}>{TranslationService.translate.Change}</button></div>
            </div>
        </div>
    )
}


export default SMSConfiguration;