import { useContext, useState } from "react";
import { CollapsableConfiguration } from "./OtherConfigurationItems/CollapsableConfiguration";
import { SearchBar } from "./SearchBar";
import { TranslationService } from "../../../services/TranslationService";
import ConfigurationContext from "../ConfigurationContext";
import Loading from "../../shared/components/Loading";

const OtherConfiguration = () => {
    const { otherConfigurationList } = useContext(ConfigurationContext);
    const [valueSearch, setValueSearch] = useState("");

    return (
        <div className="card px-5">
            <SearchBar onSearch={setValueSearch} />

            <div className="d-flex flex-row flex-nowrap justify-contnt-between align-items-center my-4">
                <h2>{TranslationService.translate.OtherSettings}</h2>
            </div>

            <table className="table shadow-hover collapsableFolder">
                <tbody>
                    {otherConfigurationList ? otherConfigurationList?.map(({ titleGroup, childrenGroup }, index) =>
                        <CollapsableConfiguration
                            key={index + titleGroup}
                            titleGroup={titleGroup}
                            children={childrenGroup}
                            valueSearch={valueSearch} />
                    ) : <Loading />}
                </tbody>
            </table>
        </div>
    );
};

export default OtherConfiguration;