import { useMemo } from "react";
import CompanyService, { Entities } from "../../../services/CompanyService";
import { TranslationService } from "../../../services/TranslationService";
import { Cast } from "../../../utils/Utils";
import { EntityField, sortEntityFields } from "../../shared/entities/EntityField";
import { getClientFieldDisplayValueHtml } from "../ClientDetail";
import { Client } from "../entities/ClientGetResponse";
import { ClientEntities } from "../entities/ClientListResponse";
import { ClientGetAgingResponse } from "../entities/ClientGetAgingResponse";
import { formatCurrency } from "../../../utils/FormatUtils";

const fixedColumns = [-25, -26, -22, -23, -21, -24, -27, -29];

const TooltipClients = ({ client }: { client: ClientEntities.ListItem }) => {
    if (client.Person_Segment.length > 0 || client.Segments) {
        console.log({ client })
    }
    const leftColumnFields = useMemo(() => [
        ...CompanyService.getConfigItems()
            .filter(x => x.Entity === Entities.Client)
            .map(x => new EntityField(x))
            .filter(z => fixedColumns.includes(z.id)),
        ...CompanyService.getAdditionalDefinitions().filter(x => x.Entity === Entities.Client).map(x => new EntityField(x)),
    ].sort(sortEntityFields(CompanyService.getPersonSortedFields().split(","))), []);

    const fields = useMemo(() => [
        ...leftColumnFields.map(field => {
            const newClient = Cast<Client>({
                ...client,
                additionals: client.additionals.map(x => ({ id: x.id || x.Id, value: x.value || x.Value })),
            });
            const displayValue = getClientFieldDisplayValueHtml(field, newClient, false);
            if (field.title === 'Comentarios') {
                field.title = 'Notas'
            }
            return {
                title: field.title,
                displayValue: displayValue || '',
            }
        }),
        // {
        //     title: TranslationService.translate.Priority,
        //     displayValue: client.CalculatedRank ? <>
        //         <span className={"priority priority-" + client.CalculatedRank}>{client.CalculatedRank.toString()}</span>
        //         <TooltipComponent title={TranslationService.translate.PriorityTip} placement="right" className="align-middle"><i className=" fal fa-question-circle"></i></TooltipComponent></>
        //         : undefined
        // }
    ].filter(x => x?.displayValue !== undefined), [client, leftColumnFields]);


    return (
        <div className="tooltipClients">
            <h5>{client.Name}</h5>
            {fields.map((field, index) => {
                return (
                    <div className="tooltipData" key={index}>
                        <h6>{field?.title}:</h6>
                        <p>{field?.displayValue}</p>
                    </div>
                );
            })}
            {Boolean(client.Segments || client.Person_Segment.length) &&
                <div className="tooltipData">
                    <h6>{TranslationService.translate.Segments}:</h6>
                    {client.Person_Segment.map((el: any) => <p>{el.Segment.Name}</p>)}
                </div>}

            {client.customerSite &&
                <div className="tooltipData">
                    <h6>{TranslationService.translate.CustomerSite}</h6>
                    <a href={client.customerSite} target="blank">{TranslationService.translate.Open} <i className="far fa-external-link ms-1"></i></a>
                </div>}
        </div>
    );
}



export const TooltipBarClient = ({ ageing, amount, overdueAmount, noDueAmount }: { ageing: ClientGetAgingResponse[] | undefined, amount: number, overdueAmount: number, noDueAmount: number }) => {

    if (amount === 0) {
        return (
            <div>
                <h5 className="text-danger">{TranslationService.translate.Due} {formatCurrency(overdueAmount, CompanyService.getDefaultCurrencyId())}</h5>
                <h5 className="text-intiza">{TranslationService.translate.NoDue} {formatCurrency(noDueAmount, CompanyService.getDefaultCurrencyId())}</h5>
            </div>
        )
    }

    return (
        <div>
            <h5 className="text-danger">{TranslationService.translate.Due} {formatCurrency(overdueAmount, CompanyService.getDefaultCurrencyId())}</h5>
            {ageing?.filter(x => x.due).map(x => {
                const values = x.title.split("|");
                let title = "";
                switch (values[0]) {
                    case "GraphFirst":
                        title = TranslationService.getTranslation("Aging-MoreXDaysAgo").replace("{0}", values[1]);
                        break;
                    case "GraphLess0":
                        title = TranslationService.getTranslation("Aging-LessXDaysAgo").replace("{0}", values[1]);
                        break;
                    default:
                        title = TranslationService.getTranslation("Aging-MoreXYDays").replace("{0}", values[1]).replace("{1}", values[2]);
                }
                return <p key={x.title} className="text-danger">{title} {formatCurrency(x.value, CompanyService.getDefaultCurrencyId())}</p>
            })}
            <h5 className="text-intiza mt-3">{TranslationService.translate.NoDue} {formatCurrency(noDueAmount, CompanyService.getDefaultCurrencyId())}</h5>
            {ageing?.filter(x => !x.due).map(x => {

                const values = x.title.split("|");
                let title = "";
                switch (values[0]) {
                    case "GraphLast":
                        title = TranslationService.getTranslation("Aging-MoreXDays").replace("{0}", values[1]);
                        break;
                    case "GraphMore0":
                        title = TranslationService.getTranslation("Aging-LessXDays").replace("{0}", values[2]);
                        break;
                    default:
                        title = TranslationService.getTranslation("Aging-InXYDays").replace("{0}", values[1]).replace("{1}", values[2]);
                }

                return <p key={x.title} className="text-intiza">{title} {formatCurrency(x.value, CompanyService.getDefaultCurrencyId())}</p>
            })}
        </div>
    );
}

export default TooltipClients;
