import React, { useState } from "react";
import ActivityService from "../../services/ActivityService";
import { Subject } from "rxjs";
import { ReportActivityCountResponse } from "../../entities/reports/ReportActivitySummary/ReportActivitySummaryResponse";

type ITabPositionContext = {
    currentTabPosition: string | null,
    setCurrentTabPosition: (value: string) => void,
    inboxCount: ReportActivityCountResponse | undefined,
    setInboxCount: (value: ReportActivityCountResponse) => void,
    getCounterInbox: () => void
}

const defaultTabPositionContext: ITabPositionContext = {
    currentTabPosition: "",
    setCurrentTabPosition: (value: string) => {
        console.debug("defaultTabPositionContext", "setCurrentTabPosition", value);
        console.error("No TabPositionContext defined");
    },
    inboxCount: undefined,
    setInboxCount: (value: ReportActivityCountResponse) => {
        console.debug("defaultTabPositionContext", "setCurrentTabPosition", value);
        console.error("No TabPositionContext defined");
    },
    getCounterInbox: () => { }
};

const TabPositionContext = React.createContext<ITabPositionContext>(defaultTabPositionContext);

const onSectionChangeSubject = new Subject();
export const onSectionChangeObservable = onSectionChangeSubject.asObservable();
const TabPositionProvider = (props: any) => {
    const [currentTabPosition, _setCurrentTabPosition] = useState<string | null>(null);
    const [inboxCount, setInboxCount] = useState<ReportActivityCountResponse>();

    const setCurrentTabPosition = (pos: string) => {
        if (currentTabPosition === pos) {
            return;
        }
        onSectionChangeSubject.next(pos);
        getCounterInbox();
        _setCurrentTabPosition(pos);
    };

    const getCounterInbox = async () => {
        try {
            const result = await ActivityService.getUnreadCount();
            if (result instanceof Error) {
                return;
            }
            setInboxCount(result);
        } catch (error) {
            return;
        }
    };

    const value: ITabPositionContext = {
        currentTabPosition,
        setCurrentTabPosition,
        inboxCount,
        setInboxCount,
        getCounterInbox
    };

    return (
        <TabPositionContext.Provider value={value}>
            {props.children}
        </TabPositionContext.Provider>
    );
};

export { TabPositionProvider };
export default TabPositionContext;