import { useCallback, useContext, useEffect, useState } from "react";
import { TranslationService } from "../../../services/TranslationService";
import { getStatusTranslateKey, TaskStatus } from "../entities/TaskStatus";
import Loading from "../../shared/components/Loading";
import TaskService, { TaskFieldId } from "../../../services/TaskSerivce";
import { Log, TaskGetResponse } from "../entities/TaskGetResponse";
import { TextAreaBigEditor, EditorProps, UserDropdownEditor, DatePickerEditor, StatusDropdownEditor, TagsDropdownEditor, DateTimePickerEditor, ActivityTaskDropdownEditor, HasValidationEditor, HasValidationEditorProps, MultiselectEditor, AutocompleteGroup, EditShowFormProps, EditShowForm, EditShowFormReadOnly, FileApi } from "../../shared/components/Editors";
import { formatDateDigits, formatDateTimeDigits, formatIntizaDate, formatIntizaDateTime } from "../../../utils/FormatUtils";
import ModalService from "../../shared/bootstrap/Modal";
import ToastContext from "../../shared/bootstrap/Toast";
import ActivityService from "../../../services/ActivityService";
import UserService from "../../../services/UserService";
import CompanyService from "../../../services/CompanyService";
import { ActivitySetComment } from "../../client/activity/entities/ActivitySetComment";
import { CollapsableWithTtitle } from "../../shared/form/CollapsableWithTtitle";
import { FloatingPanelFooter } from "../../shared/components/FloatingPanelFooter";
import FloatingPanelService from "../../shared/FloatingPanel";
import { FileUploadResponse } from "../../file/entities/FileUploadResponse";
import { addTimezone, restTimezone } from "../../../utils/ParseUtils";
import FileService from "../../../services/FileService";
import { MultiFileUpload } from "../../shared/components/MultiFileUpload";
const CustomStatusDropdownEditor = ({ taskId, response, reload, readonly }: { taskId: number, response: TaskGetResponse, reload: () => void, readonly: boolean }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [fullfilledDate, setFullfilledDate] = useState(response.item.Done);
    const [currentValue, setCurrentValue] = useState(response.item.Status);
    const [editingValue, setEditingValue] = useState(response.item.Status);
    const [editingDate, setEditingDate] = useState(response.item.Done ?? new Date().toString());
    const { translate } = TranslationService;
    const save = async () => {
        setCurrentValue(editingValue);
        setIsEditing(false);
        if (editingValue === TaskStatus.Fulfilled || editingValue === TaskStatus.Validated) {
            await TaskService.setField(taskId, TaskFieldId.Status, editingValue.toString() + "," + formatIntizaDate(new Date(editingDate)));
            setFullfilledDate(editingDate);
        } else {
            await TaskService.setField(taskId, TaskFieldId.Status, editingValue.toString());
        }
        reload();
    };
    const validStatus = [TaskStatus.Pending, TaskStatus.Fulfilled, TaskStatus.Canceled];
    // if (response.item.ResponsibleUserID?.toString() === getUserid()) {
    //     validStatus.push(TaskStatus.Canceled);
    // }
    if (response.item.ValidatorUserID?.toString() === CompanyService.getUserid()) {
        validStatus.push(TaskStatus.Validated);
    }
    const onChangeValue = (value: TaskStatus) => {
        setEditingValue(value);
    };
    const formatValue = () => {
        const translation = TranslationService.getTranslation(getStatusTranslateKey(currentValue));
        let text = translation;
        if ((currentValue === TaskStatus.Fulfilled || currentValue === TaskStatus.Validated) && fullfilledDate) {
            text += " (" + formatDateDigits(new Date(fullfilledDate)) + ")";
        }
        return text;
    };
    return (
        <div className="d-flex mb-3 me-3">
            <label className="col form-label">
                {translate.Status}
            </label>
            <div className="show-child-on-hover col ml-auto align-items-center">
                <div className="col">
                    {!isEditing &&
                        <div className="d-flex justify-content-end gap-2">
                            <div className="col-auto text-end">
                                {!readonly &&
                                    <i className={"pointer hideOnPrint far fa-pen show-when-hovering-parent"}
                                        onClick={() => setIsEditing && setIsEditing(!isEditing)} />}
                            </div>
                            <div className="col-auto">
                                <p className="form-label-detail">{formatValue()}</p>
                            </div>
                        </div>}

                    {isEditing &&
                        <div className="row">
                            <div className="col p-0">
                                <StatusDropdownEditor defaultValue={currentValue} onChange={onChangeValue} validStatus={validStatus} />
                            </div>
                            {(editingValue === TaskStatus.Fulfilled || editingValue === TaskStatus.Validated) &&
                                <div className="col">
                                    <DatePickerEditor defaultValue={editingDate} onChange={setEditingDate} />
                                </div>}
                            <div className="col-auto d-flex align-items-center">
                                <i className="fa-fw fa-solid fa-check ms-2 text-primary pointer"
                                    onClick={save} />
                            </div>
                        </div>}
                </div>
            </div>
            {/* <div className="show-child-on-hover ml-auto">
                <div className="row">
                    <div className="col-auto">
                        {!readonly && <i className={"pointer hideOnPrint far  " + (isEditing ? "fa-times text-danger" : "fa-pen show-when-hovering-parent")}
                            style={{ marginTop: (isEditing ? "10px" : "") }} onClick={() => setIsEditing(isEditing => !isEditing)} />
                        }
                    </div>
                    <div className={"col" + (isEditing ? "" : " me-3")}>
                        {
                            isEditing ?
                                <div className="row">
                                    <div className="col">
                                        <StatusDropdownEditor defaultValue={currentValue} onChange={onChangeValue} validStatus={validStatus} />
                                    </div>
                                    {
                                        (editingValue === TaskStatus.Fulfilled || editingValue === TaskStatus.Validated) &&
                                        <div className="col">
                                            <DatePickerEditor defaultValue={editingDate} onChange={setEditingDate} />
                                        </div>
                                    }
                                </div>
                                :
                                <p className="form-label-detail">{formatValue()}</p>
                        }
                    </div>
                    {isEditing &&
                        <div className="col-auto me-3">
                            <button type="button" className="btn btn-small btn-success" onClick={save}>{translate.Save}</button>
                        </div>
                    }
                </div>
            </div > */}
        </div >);
};

const TaskEdition = ({ taskId, response, reload, editingValues, setEditingValues }: { taskId: number, response: TaskGetResponse, reload: () => void, editingValues: boolean[], setEditingValues: (index: number, isEditing: boolean) => void }) => {
    const { translate } = TranslationService;
    const { getUsers, getActivityTypes, getTags, getGroupName } = CompanyService;
    const findUser = (selected?: string) => getUsers()?.find(x => x.Id === selected)?.Value;
    const findActivity = (selected?: string) => getActivityTypes()?.find(x => x.ActivityTypeID.toString() === selected)?.ActivityTypeName;
    const findTags = (selected?: string) => {
        const ids = selected?.split(", ");
        return getTags()?.filter(x => ids?.includes(x.Id) && x.Value).map(x => x.Value ?? "").join(", ");
    };
    let groupName = response.item.Group?.Name;
    const formatGroup = (x?: string) => groupName;
    const onGroupChange = (onChange: (value: string) => void) => (value: { value: string, label: string } | undefined) => {
        groupName = value?.label;
        onChange(value?.value ?? "");
    };
    const groupDefaultValue = response.item.Group !== null ? { value: response.item.Group.GroupID.toString(), label: response.item.Group.Name } : undefined;
    const groupEdit = (x: EditorProps) => <AutocompleteGroup clientId={response.item.Person.PersonId.toString()} defaultVal={groupDefaultValue} onChange={onGroupChange(x.onChange)} />;
    const onSaveGenerator = (fieldId: TaskFieldId, formatInput: (input?: string) => string | undefined = x => x) => async (value: string | undefined) => {
        await TaskService.setField(taskId, fieldId, formatInput(value));
        reload();
    };
    const parseDate = (dateString: string | undefined | null) => {
        if (!dateString) {
            return undefined;
        }
        const date = new Date(dateString);
        if (date.getHours() + date.getMinutes() + date.getSeconds() + date.getMilliseconds() === 0) {
            return date.toString();
        }
        return restTimezone(date).toString();
    };

    const LineComponent = CompanyService.canDo("edittask") ? EditShowForm : EditShowFormReadOnly;
    return (<>
        <EditShowFormReadOnly {...{ title: TranslationService.translate.Client, value: response.item.Person.Name, formatValue: x => x } as EditShowFormProps} />
        {CompanyService.getGroupName()?.length > 0 && <LineComponent {...new EditShowFormProps(getGroupName(), response.item.GroupID?.toString(), groupEdit, onSaveGenerator(TaskFieldId.Group), formatGroup, undefined, editingValues[0], (isEditing) => setEditingValues(0, isEditing))} />}
        <LineComponent {...new EditShowFormProps(translate.Responsible, response.item.ResponsibleUserID?.toString(), UserDropdownEditor, onSaveGenerator(TaskFieldId.Responsible), findUser, undefined, editingValues[1], (isEditing) => setEditingValues(1, isEditing))} />
        {CompanyService.getSetting("taskvalidator") && <LineComponent {...new EditShowFormProps(translate.Validator, response.item.ValidatorUserID?.toString(), UserDropdownEditor, onSaveGenerator(TaskFieldId.Validator), findUser, undefined, editingValues[2], (isEditing) => setEditingValues(2, isEditing))} />}
        {/* <LineComponent {...new EditShowFormProps(translate.Expiration, response.item.dueDate ?? new Date(), DatePickerEditor, onSaveGenerator(TaskFieldId.Expiration, x => x && formatDateDigits(new Date(x), "en")), (x) => x && formatDateDigits(new Date(x)))} /> */}
        <LineComponent {...new EditShowFormProps(translate.ExpirationDay, parseDate(response.item.dueDate), DatePickerEditor, onSaveGenerator(TaskFieldId.Expiration, x => x && formatIntizaDate(addTimezone(new Date(x)))), (x) => x && formatDateDigits(new Date(x)), undefined, editingValues[3], (isEditing) => setEditingValues(3, isEditing))} />
        <LineComponent {...new EditShowFormProps(translate.Description, response.item.Description, TextAreaBigEditor, onSaveGenerator(TaskFieldId.Description), undefined, undefined, editingValues[4], (isEditing) => setEditingValues(4, isEditing), true,
            <div className="col">
                <p className='text-danger mb-0'>{translate.DescriptionMandatoryMessage}</p>
            </div>)} />
        <CustomStatusDropdownEditor reload={reload} taskId={taskId} response={response} readonly={!CompanyService.canDo("edittask")} />
        <LineComponent {...new EditShowFormProps(translate.ActivityType, response.item.ActivityTypeID?.toString(), ActivityTaskDropdownEditor, onSaveGenerator(TaskFieldId.ActivityType), findActivity, undefined, editingValues[6], (isEditing) => setEditingValues(6, isEditing))} />
        {Boolean(CompanyService.getTags()?.length) && <LineComponent {...new EditShowFormProps(translate.Tags, response.item.Tag_Task.map(x => { return x.TagID; }).join(", "), TagsDropdownEditor, onSaveGenerator(TaskFieldId.Tags), findTags, undefined, editingValues[7], (isEditing) => setEditingValues(7, isEditing))} />}
        <LineComponent {...new EditShowFormProps(translate.Reminder, parseDate(response.item.Remember), DateTimePickerEditor, onSaveGenerator(TaskFieldId.Reminder, x => x && formatIntizaDateTime(restTimezone(new Date(x)))), (x) => x && formatDateTimeDigits(new Date(x)), true, editingValues[8], (isEditing) => setEditingValues(8, isEditing))} />
    </>);
};

const TaskNewComment = ({ reload, taskId, clientId, showNewCommentForm, setShowNewCommentForm }: { taskId: number, clientId: number, reload: () => void, showNewCommentForm: boolean, setShowNewCommentForm: (showComment: boolean) => void }) => {
    const { showToast } = useContext(ToastContext);
    const { translate } = TranslationService;
    //const [showNewCommentForm, setShowNewCommentForm] = useState(false);
    const getUserOptions = CompanyService.getUsers()?.map(x => ({ value: x.Id, label: x.Value ?? "" })) ?? [];
    const [newComment, setNewComment] = useState<{ text: string, notifyPersonArray: string[] }>({ text: "", notifyPersonArray: [] });
    const [isSavingComment, setIsSavingComment] = useState(false);

    const updateComment = (propName: string) => (value: string | string[] | undefined) => {
        setNewComment({ ...newComment, [propName]: value });
    };

    const saveComment = async () => {
        if (isSavingComment || !newComment.text) {
            //FIXME: show validation messages
            return;
        }
        setIsSavingComment(true);
        const request = {
            item: {
                PersonID: clientId,
                Content: newComment.text,
                TaskID: taskId,
                NotifyTo: newComment.notifyPersonArray.join(","),
            },
        } as ActivitySetComment;
        const result = await ActivityService.setComment(request);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            setIsSavingComment(false);
            return;
        }
        reload();
        setIsSavingComment(false);
        setShowNewCommentForm(false);
        setNewComment({ text: "", notifyPersonArray: [] });
    };

    return (
        <>
            {showNewCommentForm &&
                <div>
                    <h6 className="mb-3">{translate.AddComment}</h6>
                    <div className="d-flex input-column mb-3">
                        <label className="form-label">{translate.Comment}</label>
                        <HasValidationEditor {...new HasValidationEditorProps(
                            TextAreaBigEditor,
                            updateComment("text"),
                            <p className='text-danger'>{translate.RequiredField}</p>
                        )} />
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="d-flex input-column">
                                <label className="form-label">{translate.NotifyTo}</label>
                                <MultiselectEditor placeholder={translate.SelectOptions} items={getUserOptions} callback={updateComment("notifyPersonArray")} />
                            </div>
                        </div>
                        <div className="col d-flex flex-row align-items-end">
                            <div className="d-flex flex-row align-items-center">
                                <button className="btn btn-primary" onClick={saveComment}>
                                    {translate.AddComment}
                                    {isSavingComment && <i className="fas fa-spinner-third fa-spin ms-2"></i>}
                                </button>
                                <button className="btn btn-intiza" onClick={() => setShowNewCommentForm(false)}>
                                    {translate.Cancel}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {!showNewCommentForm &&
                <button className="btn btn-intiza ps-0" onClick={() => setShowNewCommentForm(true)}>
                    <i className="far fa-message-dots me-2"></i>
                    {translate.AddComment}
                </button>
            }
        </>
    );
};

class TaskLogsProps {
    constructor(
        public dateCreated: string,
        public creatorUserId: number | null | undefined,
        public logs: Log[]
    ) { }
}

const TaskLogs = ({ logs, creatorUserId, dateCreated }: TaskLogsProps) => {
    const { translate } = TranslationService;
    const creatorName = creatorUserId && UserService.getUserName(creatorUserId);

    const getLogList = (logs: Log[]) => logs.map(x => {
        const date = new Date(x.date);
        return {
            key: date.getTime(),
            dateStr: formatDateDigits(date),
            content: x.content,
            user: x.user,
            icon: (x.type === "log") ? "fa-edit" : "fa-comment",
        };
    });
    const logList = getLogList(logs);

    return (<>
        <div>
            <table className="table table-sm">
                <thead>
                    <tr>
                        <th>{translate.Date}</th>
                        <th>{translate.Action}</th>
                    </tr>
                </thead>
                <tbody>
                    {logList.map(x => {
                        return (
                            <tr key={x.key}>
                                <td style={{ width: 70 }}>{x.dateStr}</td>
                                <td>
                                    <i className={"pe-3 fas fa-fw " + x.icon} />
                                    <strong>{x.user}: </strong>
                                    <span dangerouslySetInnerHTML={{ __html: x.content }} />
                                </td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td>{formatDateDigits(new Date(dateCreated))}</td>
                        <td>
                            <i className={"pe-3 fas fa-fw fa-edit"} />
                            <strong>{creatorName}: </strong>
                            {translate.Created}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>);
};

const TaskStatusDisplay = (data: TaskGetResponse): { title: string, className: string } => {
    const { translate } = TranslationService;
    let title = "";
    let className = "";
    if (data.item.status === TaskStatus.Fulfilled || data.item.status === TaskStatus.Validated) {
        title = data.item.status === TaskStatus.Fulfilled ? translate.StatusFulfilled : translate.StatusValidated;
        className = "alert-success";
    }
    else if (data.item.status === TaskStatus.Canceled) {
        title = translate.StatusCanceled;
    }
    else if (data.item.dueDays > 0) {
        title = translate.ExpiredDueDays.replace("{dueDays}", data.item.dueDays.toString());
        className = "alert-danger";
    }
    else if (data.item.status === TaskStatus.Pending) {
        title = translate.StatusPending;
    }
    return { title, className };
};

export const TaskDetail = ({ taskId, dateCreated, creatorUserId, clientId, reload: reloadList }: { taskId: number, dateCreated: string, creatorUserId: number | null | undefined, clientId: number, reload: () => void }) => {
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState<TaskGetResponse>({} as TaskGetResponse);
    const [savedFiles, setSavedFiles] = useState<string[]>([]);
    const [files, setFiles] = useState<FileUploadResponse[]>([]);
    const [editingValues, setEditingValues] = useState<boolean[]>([false, false, false, false, false, false, false, false, false]);
    const [isEditingComment, setIsEditingComment] = useState(false);
    const { hideModal } = ModalService;
    const { showToast } = useContext(ToastContext);
    const { translate } = TranslationService;

    const reloadTask = useCallback(() => {
        const getData = async () => {
            const result = await TaskService.getTask(taskId);
            if (result instanceof Error || result === undefined) {
                hideModal();
                setLoading(false);
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
                return;
            }
            setResponse(result);
            setLoading(false);
            setFiles(result.item.files);
            const { title, className } = TaskStatusDisplay(result);
            FloatingPanelService.setTitle(title, className);
        };
        getData();
    }, [taskId, hideModal, showToast, translate.ErrorProcessingRequest]);

    useEffect(() => {
        setLoading(true);
        reloadTask();
    }, [reloadTask]);

    const onChange = () => {
        reloadTask();
        reloadList();
    };

    const taskFileDelete = async (id: string) => {
        await TaskService.deleteFile(taskId.toString(), parseInt(id));
    };

    const changeEditing = (index: number, isEditing: boolean) => {
        setEditingValues(x => {
            x[index] = isEditing;
            return [...x];
        });
    };

    const onFileChange = async (files: FileApi[]) => {
        const tempFiles = files.filter(x => x.id.startsWith("temp") && !savedFiles.includes(x.id));
        const promises = tempFiles.map(x => x.id).map(x => TaskService.setFile(taskId.toString(), x));
        for (let index = 0; index < promises.length; index++) {
            const res = await promises[index];
            if (res instanceof Error) {
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
                continue;
            }
            setSavedFiles(x => [...x, tempFiles[index].id]);
            setFiles(x => [...x, { id: res.id.toString(), fileName: tempFiles[index].name, response: "" }]);
        }
        reloadList();
    };


    FloatingPanelService.OnBeforeClose = () => {
        if (isEditingComment || editingValues.reduce((prev, curr) => prev || curr, false)) {
            ModalService.showDefaultModal({
                message: translate.ValuesBeingEdit,
                acceptButtonLabel: translate.Accept,
                closeButtonLabel: translate.Cancel,
                onAcceptClick: () => {
                    FloatingPanelService.OnBeforeClose = undefined;
                    FloatingPanelService.hidePanel();
                }
            });
            return false;
        }
        else {
            return true;
        }
    };

    return (
        <>
            {loading || !response ? (
                <Loading />
            ) : (<>
                <div className="floatingBody p-4">
                    <CollapsableWithTtitle title={translate.TaskDetail} >
                        <TaskEdition taskId={taskId} response={response} reload={onChange} editingValues={editingValues} setEditingValues={changeEditing} />
                    </CollapsableWithTtitle>
                    <CollapsableWithTtitle title={translate.AttachedFile} showDefault={false} >
                        <MultiFileUpload
                            fileDelete={taskFileDelete}
                            files={files.map(x => ({ id: x.id.toString(), name: x.fileName }))}
                            canEdit={CompanyService.canDo("edittask")}
                            onFilesChange={onFileChange}
                            downloadFile={(fileId, filename) => FileService.downloadTask(fileId, taskId.toString(), filename)}
                        />
                    </CollapsableWithTtitle>
                    <CollapsableWithTtitle title={translate.Log} showDefault={false} >
                        <TaskLogs logs={response.log} dateCreated={dateCreated} creatorUserId={creatorUserId} />
                    </CollapsableWithTtitle>
                    <TaskNewComment reload={onChange} taskId={taskId} clientId={clientId} showNewCommentForm={isEditingComment} setShowNewCommentForm={setIsEditingComment} />
                </div>
                <FloatingPanelFooter>
                </FloatingPanelFooter>
            </>)
            }
        </>);
};

export default TaskDetail;    