import React, { useContext, useEffect, useMemo, useState } from "react";
import { TranslationService } from "../../../services/TranslationService";
import CompanyService from "../../../services/CompanyService";
import AccountService, { SignatureResponse } from "../../../services/AccountService";
import ToastContext from "../../shared/bootstrap/Toast";
import FileService from "../../../services/FileService";
import Loading from "../../shared/components/Loading";

export const SignData = () => {
    const { showToast } = useContext(ToastContext);
    const [changingData, setChangingData] = useState(false);
    const [dataText, setDataText] = useState({ signature: "" });
    const [dataImg, setDataImg] = useState<SignatureResponse["item"]>();

    const [imageChanged, setImageChanged] = useState(true);
    const [fileUploaded, setFileUploaded] = useState<File>();
    const [loading, setLoading] = useState(true);

    const showSignatureImage = useMemo(() => Boolean(dataImg?.Content.length), [dataImg?.Content.length]);
    const imgSrc = useMemo(() => dataImg?.Content.replace("cid:", "https://az645407.vo.msecnd.net/mailsignature/").replace(">", " height=\"200\">"), [dataImg?.Content]);

    const handleChangeData = () => setChangingData(p => !p);

    const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDataText(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleFileInputChange = (file: File | null) => {
        if (!file) {
            return;
        }
        setImageChanged(true);
        setFileUploaded(file);
    };


    const saveSignature = async () => {
        await AccountService.setAccountSignature({ signature: dataText.signature }).finally(handleChangeData);
    };

    const deleteSignature = async () => {
        const result = await AccountService.deleteAccountSignature();
        if (result instanceof Error) {
            showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
        setDataImg(undefined);
    };

    const uploadFile = async () => {
        try {
            setLoading(true);
            if (fileUploaded) {
                const result = await FileService.upload(fileUploaded);
                if (result instanceof Error) {
                    showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
                    return result;
                }
                await AccountService.setAccountSignatureImage({ id: result.id })
                    .finally(() => {
                        loadDataSignature();
                        setImageChanged(false);
                        setLoading(false);
                    });
            }
        } catch (error) {
            showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
            setLoading(false);
        }
    };

    const cancelUploadFile = () => {
        loadDataSignature();
        setImageChanged(false);
    };

    const loadDataSignature = async () => {
        setLoading(true);
        const result = await AccountService.getAccountSignature();
        const resultText = await AccountService.getAccountSignatureText();

        if (result instanceof Error || resultText instanceof Error) {
            showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
            setLoading(false);
            return;
        }
        setDataImg(result.item);
        setDataText({ signature: resultText.signature });
        setLoading(false);
    };

    useEffect(() => {
        loadDataSignature();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loading ? <Loading /> : (
        <div className="card-body">
            <div className='mb-2'>
                <h5>{TranslationService.translate.PreloadedSignature.replace("{1}", CompanyService.getCompanyName())}</h5>
            </div>

            {showSignatureImage ?
                <>
                    <div className='mb-2'>
                        {imgSrc && <div style={{ justifyContent: "center", display: "flex" }} dangerouslySetInnerHTML={{ __html: imgSrc }} />}
                    </div>
                    <div className='mb-2 d-flex justify-content-end'>
                        <button className="btn btn-primary m-2" onClick={deleteSignature}>{TranslationService.translate.Clear}</button>
                    </div>
                </>
                :
                <>
                    <div className='mb-2'>
                        <input type="file" className="form-control" accept=".jpg,.jpeg,.png" onChange={e => handleFileInputChange(e.target.files?.[0] ?? null)} />
                    </div>
                    {imageChanged ?
                        <div className='mb-2 d-flex justify-content-end'>
                            <div className='d-flex gap-2'>
                                <button className="col btn btn-primary mt-2" onClick={uploadFile}>{TranslationService.translate.Save}</button>
                                <button className=" col btn btn-primary mt-2" onClick={cancelUploadFile}>{TranslationService.translate.Cancel}</button>
                            </div>
                        </div>
                        :
                        null}
                    <hr />

                    {changingData ?
                        <div className="col">
                            <div className="col mb-3">
                                <label className="font-weight-normal col-form-label">{TranslationService.translate.Signature}</label>
                                <textarea className="form-control" onChange={handleInputChange} name='signature' defaultValue={dataText.signature} style={{ resize: "none" }} rows={3} />
                            </div>
                        </div>
                        :
                        <div className="col">
                            <div className="col mb-3">
                                <label className="font-weight-normal col-form-label">{TranslationService.translate.Signature}</label>
                                <p>{dataText.signature}</p>
                            </div>
                        </div>
                    }

                    <div className='mb-2 d-flex justify-content-end'>
                        {!changingData ?
                            <button className="btn btn-primary m-2" onClick={handleChangeData}>{TranslationService.translate.Change}</button>
                            :
                            <div className='d-flex gap-2'>
                                <button className="col btn btn-primary mt-2" onClick={saveSignature}>{TranslationService.translate.Save}</button>
                                <button className=" col btn btn-primary mt-2" onClick={handleChangeData}>{TranslationService.translate.Cancel}</button>
                            </div>}
                    </div>
                </>}
        </div>
    );
};
