import { TaskDeleteFileResponse } from "../pages/task/entities/TaskDeleteFileResponse";
import { TaskEdit } from "../pages/task/entities/TaskEdit";
import { TaskGetMessageListResponse } from "../pages/task/entities/TaskGetMessageListResponse";
import { TaskGetResponse } from "../pages/task/entities/TaskGetResponse";
import { TaskListRequest } from "../pages/task/entities/TaskListRequest";
import { TaskListResponse } from "../pages/task/entities/TaskListResponse";
import { TaskSetFileResponse } from "../pages/task/entities/TaskSetFileResponse";
import { TaskStatus } from "../pages/task/entities/TaskStatus";
import { formatIntizaOldDate } from "../utils/FormatUtils";
import ClientService from "./ClientService";
import CompanyService from "./CompanyService";
import ExportService from "./ExportService";
import RequestService from "./RequestService";

export enum TaskFieldId {
    Group = -511,
    Responsible = -508,
    Validator = -509,
    Expiration = -510,
    Description = -505,
    Status = -501,
    Client = -502,
    ActivityType = -513,
    Tags = -506,
    Priority = -507,
    Reminder = -512,
    Date = -504,
}

class TaskService {
    public static toggle(id: number, status: TaskStatus) {
        return RequestService.post("/task/toggle", { id, status });
    }
    public static getTask(taskId: number): Promise<TaskGetResponse | Error> {
        return RequestService.post("/task/get", { id: taskId, users: CompanyService.getUsers() ?? [], additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }
    public static getList(request: TaskListRequest): Promise<TaskListResponse | Error> {
        return RequestService.post("/task/list", { ...request, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }
    public static fav(taskId: number) {
        return RequestService.post<{ response: string, fav?: number }>("/task/fav", { id: taskId });
    }
    public static setField(taskId: number, fieldId: TaskFieldId, value: string | undefined): Promise<{ item?: string, Id: TaskFieldId } | Error> {
        const requestBody = {
            id: taskId,
            field: fieldId,
            value,
            users: CompanyService.getUsers() ?? [],
            activitytypes: CompanyService.getActivityTypes() ?? [],
        }
        return RequestService.post("/task/setfield", requestBody);
    }
    public static markAs(status: TaskStatus, taskIds: Number[], all: boolean, filters: TaskListRequest): Promise<unknown | Error> {
        const request = {
            ids: taskIds.join(", "),
            newstatus: status,
            chkall: all ? "1" : "0",
            ...filters,
            additionaldefinitions: CompanyService.getAdditionalDefinitions(),
        }
        return RequestService.post("/task/mark", request);
    }
    public static deleteSingle(taskId: number) {
        return RequestService.post("/task/delete", { id: taskId, additionaldefinitions: CompanyService.getAdditionalDefinitions() });

    }
    public static deleteMultiple(taskIds: Number[], all: boolean, filters: TaskListRequest) {
        const request = {
            ids: taskIds.join(","),
            chkall: all ? "1" : "0",
            ...filters,
            additionaldefinitions: CompanyService.getAdditionalDefinitions(),
        };
        return RequestService.post("/task/delete", request);
    }

    public static async export(request: TaskListRequest, count: number) {
        return await ExportService.requestExport("/task/export", { ...request, additionaldefinitions: CompanyService.getAdditionalDefinitions() }, count);
    }

    public static async setFile(taskId: string, fileId: string): Promise<TaskSetFileResponse | Error> {
        const request = {
            taskid: parseInt(taskId),
            id: fileId
        }
        const result: TaskSetFileResponse | Error = await RequestService.post("/task/setfile", request);
        if (!(result instanceof Error) && result.response !== 'Ok') {
            return new Error(result.response);
        }
        return result;
    }
    public static deleteFile(taskId: string, fileId: number): Promise<TaskDeleteFileResponse | Error> {
        const request = {
            taskid: taskId,
            id: fileId
        }
        return RequestService.post("/task/deletefile", request);
    }

    public static getComments(taskId: number): Promise<TaskGetMessageListResponse | Error> {
        return RequestService.post("/task/getmessagelist", { id: taskId, users: CompanyService.getUsers() ?? [] });
    }

    static async setTask(newTask: TaskEdit, loggedUserId: string): Promise<any | Error> {
        if (!newTask.clientId) { return Promise.reject(new Error("Client missing")) };

        const clientId = parseInt(newTask.clientId);
        const clientGetResponse = await ClientService.get(clientId);
        if (clientGetResponse instanceof Error) {
            return new Error("Client missing")
        };

        let rememberDateJson = undefined;
        const userResponsibleIsNotLoggedUser = newTask.userResponsibleId && newTask.userResponsibleId !== loggedUserId;
        if (newTask.emailReminder && newTask.reminderDay && newTask.reminderHour) {
            rememberDateJson = new Date(new Date(newTask.reminderDay).setHours(parseInt(newTask.reminderHour), 0, 0, 0)).toJSON();
        }
        
        const requestBody = {
            //"id":"631775",
            users: CompanyService.getUsers() ?? [],
            activitytypes: CompanyService.getActivityTypes() ?? [],
            item: {
                //"TaskID": 631775,
                //"DoneUserID": null,
                GroupID: newTask.groupId,
                //"ExternalID": null,
                Person: clientGetResponse.item,
                dueDate: newTask.dueDate ? formatIntizaOldDate(new Date(newTask.dueDate)) : undefined,
                Description: newTask.description,
                Done: (newTask.statusId !== undefined && parseInt(newTask.statusId) === TaskStatus.Fulfilled && newTask.dateDone) ? new Date(newTask.dateDone).toJSON() : undefined,
                Created: new Date().toJSON(),
                CreatorUserID: newTask.creatorUserId ? parseInt(newTask.creatorUserId) : undefined,
                ResponsibleUserID: newTask.userResponsibleId ? parseInt(newTask.userResponsibleId) : undefined,
                PersonID: clientId,
                Remember: rememberDateJson,
                //Remembered: newTask.emailReminder,
                //ActionID: 12340,
                NotifyCompleted: Boolean(userResponsibleIsNotLoggedUser && newTask.notifyCompleted),
                HasAttachments: newTask.files.length > 0,
                ValidatorUserID: newTask.userValidatorId ? parseInt(newTask.userValidatorId) : undefined,
                Status: parseInt(newTask.statusId),
                status: parseInt(newTask.statusId),
                ValidatedDate: null,
                ScheduledTaskID: null,
                HasComments: false,
                ActivityTypeID: newTask.activityTypeId,
                Fav: 0,
                TaskID_ref: null,
                Tag_Task: newTask.tagsCsv?.split(',').map(x => ({ tagid: x.trim() })) ?? [],
                Group: null,
                TaskFiles: [],
                files: newTask.files.map(x => ({ filename: x, id: x })),
                ActivityType: null,
                IsLoggedResponsibleUser: loggedUserId === newTask.userResponsibleId,
                IsLoggedValidatorUser: loggedUserId === newTask.userValidatorId,
                IsLoggedDoneUser: false,
                //"dueDays": 2414,
                //"check": "off",
                //Tags: "",
            }
        }

        return RequestService.post("/task/set", requestBody);
    }

    /**
     * 
     * @param ResponsibleUserID Useful for checking only one task. 
     *  It's ok to be undefined if checking for all task
     * @returns If user can check task
     */
    public static canCheckTask(ResponsibleUserID?: number) {
        const responsibleRequirement = !ResponsibleUserID ||
            ResponsibleUserID === parseInt(CompanyService.getUserid()) ||
            CompanyService.canDo("checkotherstask")
            || CompanyService.canDo("edittask");
        return responsibleRequirement &&
            (CompanyService.canDo("checktask") || CompanyService.canDo("edittask") || CompanyService.canDo("deletetask"));
    }
}

export default TaskService;