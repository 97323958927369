import { useCallback, useContext, useMemo, useState, useEffect } from "react";
import { formatCurrency } from "../../../utils/FormatUtils";
import { TranslationService } from "../../../services/TranslationService";
import { InvoiceGroupContext } from "./providers/InvoiceTableProvider";

export type GroupType = {
    Children: GroupType[] | [],
    amount?: number,
    cant?: number,
    due?: number,
    iocount?: number,
    max?: number,
    tree?: string,
    value?: string,
    valueid?: string,
    index?: number,
    indeterminate?: boolean
}

type InvoiceGroupWithChildrenType = {
    dataValues: any
    spaceMargin: number
    currencyTotals: any
    maxDebt: number,
    firstOpen?: boolean
}

export const InvoiceGroupWithChildren = ({
    dataValues,
    maxDebt,
    spaceMargin,
    currencyTotals,
    firstOpen = false
}: InvoiceGroupWithChildrenType) => {
    const { checkValuesByTree, valuesChecked, calculateIndeterminate } = useContext(InvoiceGroupContext);
    const { due, amount, iocount, Children: ChildrenData, value } = dataValues;

    const [showChildren, setShowChildren] = useState<boolean>(false);
    const [indeterminate, setIndeterminate] = useState<boolean>(false);

    const dataValueChecked = useMemo(() => {
        return valuesChecked?.some((x: any) => x?.tree === dataValues?.tree);
    }, [dataValues?.tree, valuesChecked]);

    useEffect(() => {
        setIndeterminate(calculateIndeterminate(dataValues?.tree));
    }, [valuesChecked, dataValues?.tree, calculateIndeterminate]);

    const hasChildren = ChildrenData && ChildrenData.length > 0;
    const noDueShow = amount - due;
    const dueCalc = ((due * 100) / maxDebt);
    const noDueCalc = ((noDueShow * 100) / maxDebt);
    const percentDue = `${dueCalc}%`;
    const percentNoDue = `${noDueCalc}%`;

    const handleCheckValues = useCallback((checked: boolean) => {
        dataValues?.tree && checkValuesByTree(dataValues?.tree, checked);
    }, [checkValuesByTree, dataValues?.tree]);

    const handleShowChildren = () => setShowChildren((p: boolean) => !p);

    return (
        <>
            {firstOpen ? null : (
                <tr>
                    <td className={`d-flex align-items-center col`}>
                        <div
                            className='col-1'
                            style={{ marginLeft: `${spaceMargin}%` }}
                            onClick={handleShowChildren}
                        >
                            {hasChildren && <i className={`fas ${showChildren ? "fa-minus" : "fa-plus"} icono-input`} />}
                        </div>

                        <div style={{ marginLeft: `${spaceMargin / 2}%` }}>
                            <input
                                className="checkbox-invoice me-3"
                                type="checkbox"
                                name="check-invoice"
                                checked={dataValueChecked}
                                onChange={(e) => handleCheckValues(e.target.checked)}
                                ref={input => input && (input.indeterminate = indeterminate)}
                            />
                            <span>{value}</span>
                        </div>
                    </td>
                    <td className='col text-end'>{iocount}</td>
                    <td className='col text-end'>{formatCurrency(amount, currencyTotals)}</td>

                    <td className='popover__wrapper'>
                        <div className="progress bar-bg">
                            <div className="progress-bar red-bar-bg" style={{ width: percentDue }} />
                            <div className="progress-bar blue-bar-bg" style={{ width: percentNoDue }} />
                        </div>

                        <div className="popover__content">
                            <p className="popover__message" style={{ color: 'red' }}>{TranslationService.translate.Due}: {formatCurrency(due, currencyTotals)}</p>
                            <p className="popover__message" style={{ color: 'blue' }}>{TranslationService.translate.NoDue}: {formatCurrency(noDueShow, currencyTotals)}</p>
                        </div>
                    </td>
                </tr>
            )}

            {((hasChildren && showChildren) || firstOpen)
                && ChildrenData.map((value: any, index: any) =>
                    <InvoiceGroupWithChildren
                        key={index}
                        dataValues={{ ...value, index }}
                        spaceMargin={spaceMargin + 4}
                        maxDebt={maxDebt}
                        currencyTotals={currencyTotals}
                        firstOpen={false}
                    />
                )}
        </>
    );
};
