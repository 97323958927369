import { useState, Dispatch, SetStateAction } from "react";
import Dropdown from "../../../../shared/components/Dropdown";
import { CheckBoxEditor, TextEditor } from "../../../../shared/components/Editors";
import { ImportTemplateListResponse } from "../../../../shared/entities/ImportTemplateListResponse";
import { TranslationService } from "../../../../../services/TranslationService";
import EditItem from "./EditItem";
import { UpdateModelFunc } from "../ImportTemplateEditBase";
import { capitalize } from "../../../../../utils/FormatUtils";
import { OptionalMap, Switch } from "../../../../../utils/Utils";
import CompanyService from "../../../../../services/CompanyService";

const Alarm = ({ data, updateModel, setModel, model }: { data?: ImportTemplateListResponse.Item, updateModel: UpdateModelFunc, setModel: Dispatch<SetStateAction<Partial<ImportTemplateListResponse.Item>>>, model: Partial<ImportTemplateListResponse.Item> }) => {
    const periods = [null, "daily", "weekly", "monthly"] as const;
    const alarmPeriodFromData = (alarmPeriod: number | null | undefined) => periods[alarmPeriod ?? 0] ?? null;
    const periodToData = (period: string | undefined) => OptionalMap(periods.indexOf((period ?? null) as typeof periods[number]), x => x > 0 ? x : null) ?? null;
    const [alarmSettings, setAlarmSettings] = useState<null | "daily" | "weekly" | "monthly">(alarmPeriodFromData(data?.AlarmPeriod));
    const { translate } = TranslationService;
    const getDayOffset = (i: number, hour: number = model.AlarmHour ?? 0) => Switch([hour - (CompanyService.getTimezone() / 60) < 0, (i + 1) % 7],
        [hour - (CompanyService.getTimezone() / 60) >= 24, (i - 1 + 7) % 7]) ?? i;
    const updateDay = (index: number) => (selected: boolean) =>
        setModel(x => ({ ...x, AlarmFrequency: (x.AlarmFrequency ?? 0) ^ (1 << getDayOffset(index)) }));
    const getDay = (i: number) => (Number(data?.AlarmFrequency) & (1 << getDayOffset(i))) > 0;
    const updateHour = (hour: number) => {
        const newHour = ((hour ?? 0) + (CompanyService.getTimezone() / 60) + 24) % 24;
        if (getDayOffset(0) !== getDayOffset(0, newHour)) {
            if (getDayOffset(1) > getDayOffset(1, newHour)) {
                const dom = ((model.AlarmFrequency ?? 0) & 1) << 6;
                updateModel("AlarmFrequency")(((model.AlarmFrequency ?? 0) >> 1) + dom);
            } else {
                const sab = Boolean((model.AlarmFrequency ?? 0) & 64);
                updateModel("AlarmFrequency")(((model.AlarmFrequency ?? 0) << 1) + Number(sab));
            }
        }
        updateModel("AlarmHour")(newHour);
    };

    const daysOfWeek = [translate.Sunday, translate.Monday, translate.Tuesday, translate.Wednesday, translate.Thursday, translate.Friday, translate.Saturday,];
    return (
        <>
            <h5>{translate.Alarm}</h5>
            <EditItem label={translate.Period} >
                <Dropdown onChange={(value?: string) => { setAlarmSettings((value ?? null) as null | "daily" | "weekly" | "monthly"); updateModel("AlarmPeriod")(periodToData(value)); }}
                    optionLabel={translate.None} defaultValue={alarmSettings ?? undefined}
                    items={[{ text: translate.DailyShort, value: "daily" }, { text: translate.Weekly, value: "weekly" }, { text: translate.Monthly, value: "monthly" },]} />
            </EditItem>
            {alarmSettings === "weekly" &&
                <div className="row">
                    <label className="px-3 w-xl col-form-label font-weight-normal">{translate.WeekDays}</label>
                    <div className="px-3">
                        <div className="d-flex align-items-center justify-content-between h-100" style={{ gap: 10 }}>
                            {daysOfWeek.map((x, i) => <CheckBoxEditor key={i} message={x} onChange={updateDay(i)} defaultValue={getDay(i)} />)}
                        </div>

                    </div>
                </div>
            }
            {alarmSettings === "monthly" &&
                <EditItem label={translate.Day} ><Dropdown onChange={updateModel("AlarmFrequency")} items={Array.from({ length: 31 }, (x, i) => (i + 1)).map(x => ({ text: x.toString(), value: x }))} defaultValue={data?.AlarmFrequency} /></EditItem>
            }
            {alarmSettings !== null && <div>
                <EditItem label={capitalize(translate.Hour)}><Dropdown onChange={x => updateHour(x)} items={Array.from({ length: 24 }, (x, i) => i).map(x => ({ text: x.toString().padStart(2, "0"), value: x }))} defaultValue={((data?.AlarmHour ?? 0) - (CompanyService.getTimezone() / 60) + 24) % 24} /></EditItem>
                <EditItem label={translate.SendAlarmTo} ><TextEditor onChange={updateModel("AlarmEmail")} defaultValue={data?.AlarmEmail} /></EditItem>
            </div>}
        </>);
};

export default Alarm;