import { TextEditor } from "../../../shared/components/Editors";
import { ImportTemplateListResponse } from "../../../shared/entities/ImportTemplateListResponse";
import { TranslationService } from '../../../../services/TranslationService';
import EditItem from "./Components/EditItem";
import ImportTemplateEditBase, { ImportTemplateComponentProps } from "./ImportTemplateEditBase";

const ImportTemplateEditInvoiceDelete = ({ data, reload, importsList = undefined, entityId = undefined }: { data?: ImportTemplateListResponse.Item, reload: () => void, importsList?: ImportTemplateListResponse, entityId?: number }) => {
    const { translate } = TranslationService;
    const requiredIds = ["22962", "referenceNumber"];

    const Header = () => {
        return (<div className="mb-3 mt-4">
            <h5 className="mb-4">{translate.InvoiceDeletionSettings}</h5>
            <details className="mt-3">
                <summary className="ps-1"><h6 style={{ display: "unset" }}>{translate.SeeTheRequirementsOfTheFileToImport}</h6></summary>
                {translate.RequirementsOfTheFileToImport.split("\n").map((x, i) => <div key={i} className="text-muted ps-3">{x}</div>)}
            </details>
        </div>);
    }

    const AdvancedOptions = ({ data, updateModel, model, setModel, }: ImportTemplateComponentProps) => {
        return (<div>
            <EditItem label={translate.SendResultsTo} ><TextEditor onChange={updateModel("EmailReturn")} defaultValue={data?.EmailReturn} /></EditItem>
        </div>);
    }
    return (
        <ImportTemplateEditBase  entityId={entityId} AdvancedOptions={AdvancedOptions} Header={Header} reload={reload} requiredFieldIds={requiredIds} data={data} />
    );
}

export default ImportTemplateEditInvoiceDelete;