import { ReportLogListClassRequest, RequestLogExport } from "../entities/reports/ReportLog/ReportLogListRequest";
import { ReportLogListResponse } from "../entities/reports/ReportLog/ReportLogListResponse";
import ExportService from "./ExportService";
import RequestService from "./RequestService";

class LogService {
    public static getReportLogList(request: ReportLogListClassRequest): Promise<ReportLogListResponse | Error> {
        return RequestService.post("/log/report", { ...request });
    }

    public static getReportLogMainList(request: ReportLogListClassRequest): Promise<ReportLogListResponse | Error> {
        return RequestService.post("/log/reportmain", { ...request });
    }

    public static async exportLog(requestExport: RequestLogExport, count: number, main: boolean = false) {
        return ExportService.requestExport('/log/exportreport', { ...requestExport, main: main ? 1 : 0 }, count);
    }

}

export default LogService;