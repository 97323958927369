import { useContext } from "react";
import CompanyService from "../../../services/CompanyService";
import { formatCurrency, formatInteger } from "../../../utils/FormatUtils";
import { TranslationService } from "../../../services/TranslationService";
import TableContext from "../../task/TableContext";

const ClientHistoryTableTotal = () => {
    const { response } = useContext(TableContext);
    const { translate } = TranslationService;
    return (
        <tr className={"font-weight-bold"} style={{ backgroundColor: "#ddedf9" }}>
            <td>{translate.Total}</td>
            <td className={"text-end"}>{response.ioCount ?? 0}</td>
            <td className={"text-end"}>{formatCurrency(response.totalAmount ?? 0, CompanyService.getDefaultCurrencyId(), formatInteger)}</td>
            <td className={"text-end"}></td>
            <td className={"text-end"}></td>
            <td className={"text-end"}></td>
            <td className={"text-end"}></td>
            <td className={"text-end"}></td>
        </tr>);
};

export default ClientHistoryTableTotal;

