import { BackButton } from "./BackButton";

type FormType = {
  children: any;
  back?: boolean;
};

const Form = ({ children, back = true }: FormType) => {
  return (
    <form>
      <div className="box">
        {children}
        {back && <BackButton />}
      </div>
    </form>
  );
};

export default Form;
