import { useCallback, useState, useRef } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { TranslationService } from '../../../services/TranslationService'
import CompanyService from '../../../services/CompanyService'
import ButtonPrimary from '../../account/components/ButtonPrimary'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Cast } from '../../../utils/Utils';

type PaymentMethodsType = {
    paymentMethod: string
}
type EditorType = { onChange: (state: EditorState) => void };

export const PaymentMethods = ({ paymentMethod }: PaymentMethodsType) => {
    const editorRef = useRef<null | EditorType>(null);
    const [saveData, setSaveData] = useState(false)
    const [content, setContent] = useState<string>(paymentMethod)

    const getDefaultState = () => {
        const blocksFromHtml = htmlToDraft(paymentMethod ?? "");
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        convertToRaw(contentState);
        return EditorState.createWithContent(contentState);
    };
    const defaultEditorState = useRef(getDefaultState());

    const saveValues = useCallback(() => {
        setSaveData(false)
        CompanyService.setConfigCompany({ paymentMethod: content });
    }, [content])

    return (
        <div className="mx-3 align-items-end">
            <div className='col-12'>
                <Editor
                    defaultEditorState={defaultEditorState.current}
                    onEditorStateChange={(editorState) => {
                        setSaveData(true)
                        const rawContentState = convertToRaw(editorState.getCurrentContent());
                        const markup = draftToHtml(rawContentState, {}, false);
                        setContent(markup)
                        defaultEditorState.current = editorState;
                    }}
                    locale={TranslationService.currentLanguage}
                    ref={x => editorRef.current = Cast<EditorType>(x)}
                />
            </div>
            {saveData &&
                <div className='col-12 mt-2' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <ButtonPrimary onClick={saveValues}>{TranslationService.translate.Save}</ButtonPrimary>
                </div>}
        </div>
    )
}
