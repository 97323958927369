import { TranslationService } from "../../../../../services/TranslationService";
import Dropdown from "../../../../shared/components/Dropdown";
import { TextEditor, CheckBoxEditor } from "../../../../shared/components/Editors";
import { ImportTemplateListResponse } from "../../../../shared/entities/ImportTemplateListResponse";
import { UpdateModelFunc } from "../ImportTemplateEditBase";
import EditItem from "./EditItem";

const ExcelOptions = ({ data, updateModel }: { data?: ImportTemplateListResponse.Item, updateModel: UpdateModelFunc }) => {
    const { translate } = TranslationService;
    return (
        <>
            <EditItem label={translate.NumericFormat} ><Dropdown onChange={updateModel("numberCulture")} defaultValue={data?.numberCulture} items={[{ value: "", text: "(en) 1,500.50" }, { value: "es", text: "(es) 1.500,50" },]} /></EditItem>
            <EditItem label={translate.SendResultsTo} ><TextEditor onChange={updateModel("EmailReturn")} defaultValue={data?.EmailReturn} /></EditItem>
            <CheckBoxEditor message={<>{translate.IgnoreFirstRow} <i className="fal fa-question-circle px-2" title={translate.IgnoreFirstRowTip} /></>} onChange={updateModel("IgnoreFirstRow")} defaultValue={data?.IgnoreFirstRow} />
        </>
    );
}

export default ExcelOptions;