type TextInputProps = {
    id?: string,
    name?: string,
    label?: string,
    placeholder?: string,
    type?: string,
    value?: string,
    onEnter?: () => void,
    onChange?: (value: string) => void
}

const TextInput: React.FC<TextInputProps> = (props: TextInputProps) => {
    const id: string = props.id || "";
    const name: string = props.name || id;
    const label: string = props.label || "";
    const placeholder: string = props.placeholder || "";
    const type: string = props.type || "text";
    const value: string = props.value || "";

    const onEnter: () => void = props.onEnter || (() => { });

    function handleKeyPress(e: any): void {
        if (e.key === 'Enter') {
            onEnter();
        }
    }

    const onChange: ((value: string) => void) | undefined = props.onChange;
    function handleOnChange(e: any): void {
        if (onChange) {
            onChange(e.target.value);
        }
    }

    return (
        <div className="mb-3 text-start">
            {label ? <label className="form-label">{label}</label> : <></>}
            <input
                className="form-control input-lg text-box single-line valid"
                type={type} id={id} name={name} defaultValue={value}
                placeholder={placeholder}
                onKeyPress={handleKeyPress} onChange={handleOnChange} />
        </div>
    );
}

export default TextInput
