import { Client } from '../entities/ClientGetResponse';
import DashboardExceededLimit from './DashboardExceededLimit';

type ClientDashboardExtraValuesType = {
    client: Client | null | undefined,
    extraValues: {
        averageAging: number;
        averagePeriod: number;
        averageAgingPerson: number;
        averagePeriodPerson: number;
    } | undefined,
    displayCurrencyId: number,
    convertClientCurrency: (amount: number | undefined) => number
}
export const ClientDashboardExtraValues = ({ client, extraValues, displayCurrencyId, convertClientCurrency }: ClientDashboardExtraValuesType) => {
    return (
        <DashboardExceededLimit {...{ client, displayCurrencyId }} />
    )
}
