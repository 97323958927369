import { useContext, useEffect } from "react";
import ToastContext from "../../../shared/bootstrap/Toast";
import { CheckBoxEditor, TextEditor } from "../../../shared/components/Editors";
import { ImportTemplateListResponse } from "../../../shared/entities/ImportTemplateListResponse";
import { TranslationService } from "../../../../services/TranslationService";
import Alarm from "./Components/Alarm";
import DateFormat from "./Components/DateFormat";
import EditItem from "./Components/EditItem";
import PrecedingProcesses from "./Components/PrecedingProcesses";
import UploadTemplate from "./Components/UploadTemplate";
import ImportTemplateEditBase, { ImportTemplateComponentProps } from "./ImportTemplateEditBase";
import SeparatorSimple from "./Components/SeparatorSimple";
import AdvancedFilters, { AdvancedFiltersContext } from "../../../shared/components/AdvancedFilters";
import { FilterCollection } from "../../../../services/FilterService";
import ImportTemplateService from "../../../../services/ImportTemplateService";
import ModalService from "../../../shared/bootstrap/Modal";
import Dictionaries from "./Components/Dictionaries";
import Frequency from "./Components/Frecuency";
import FloatingPanelService from "../../../shared/FloatingPanel";
import ImportTemplateEditClientExportTemplate from "./ImportTemplateEditClientExportTemplate";
import AdvancedFilterService from "../../../../services/AdvancedFilterService";

const ImportTemplateEditClientAutomatic = ({ data, reload, importsList = undefined, entityId = undefined }: { data?: ImportTemplateListResponse.Item, reload: () => void, importsList?: ImportTemplateListResponse, entityId?: number }) => {
    const { translate } = TranslationService;
    
    const Header = () => {
        return (<div className="mb-3 mt-4 d-flex justify-content-between">
            <h5 className="mb-4">{translate.ConfigureInvoicePaymentImport} </h5>
            <div>
                <button className="btn btn-outline-primary" type="button" onClick={() => FloatingPanelService.showPanel({
                    title: TranslationService.translate.ImportTemplates,
                    children: <ImportTemplateEditClientExportTemplate reload={() => undefined} data={data} />
                })}>{TranslationService.translate.SelectColumns}</button>
            </div>
        </div>);
    };

    const AdvancedOptions = ({ data, updateModel, model, setModel, requiredManager }: ImportTemplateComponentProps) => {
        const { showToast } = useContext(ToastContext);

        const customValidation = requiredManager.getValidation("validationCustomPersonIdentityID");
        customValidation.isValid = () => {
            if (!model.CustomPersonIdentityID || model.CustomPersonIdentityID === 22962) {
                return true;
            }
            const valid = model.Columns?.find(x => x.name === model.CustomPersonIdentityID?.toString()) !== undefined;
            if (!valid) {
                showToast(translate.TheFieldSelectedForUpdateMustHaveASpecifiedColumn);
            }
            return valid;
        };

        const onFilterApply = (filters: string[] | undefined) => setModel(x => ({ ...x, IOFilter: AdvancedFilterService.filterStringsToQueryString(filters) }));
        const advancedFiltersContext = useContext(AdvancedFiltersContext);
        useEffect(() => {
            advancedFiltersContext.setShow(true);
        }, [advancedFiltersContext]);

        return (<div>
            <Frequency updateModel={updateModel} data={data} setModel={setModel} model={model} />
            <hr className="my-5" />
            <small className="text-muted mb-3">{translate.IfYouLeaveFormatBlank}</small>
            <DateFormat defaultValue={data?.DateFormat} updateModel={updateModel("DateFormat")} />
            <SeparatorSimple updateModel={updateModel} data={data} />
            <EditItem label={translate.SendResultsTo} ><TextEditor onChange={updateModel("EmailReturn")} defaultValue={data?.EmailReturn} /></EditItem>
            <CheckBoxEditor message={<>{translate.IgnoreFirstRow} <i className="fal fa-question-square px-2" title={translate.IgnoreFirstRowTip} /></>} onChange={updateModel("IgnoreFirstRow")} defaultValue={data?.IgnoreFirstRow} />
            <UploadTemplate model={model} updateModel={updateModel} />
            <hr className="my-5" />
            <Dictionaries data={data} model={model} setModel={setModel} />
            <hr className="my-5" />
            <h5 className="mb-3">{translate.FilterClients}</h5>
            <AdvancedFilters page={FilterCollection.Client} onFilterApply={onFilterApply} defaultValue={data?.IOFilter} />
            <hr className="my-5" />
            <PrecedingProcesses data={data} model={model} setModel={setModel} importsList={importsList} />
            <hr className="my-5" />
            <Alarm data={data} updateModel={updateModel} setModel={setModel} model={model} />
        </div>);
    };

    const RunNow = () => {
        ModalService.showDefaultModal({
            acceptButtonLabel: TranslationService.translate.RunNow,
            message: TranslationService.translate.AreYouSureYouWantToAutomaticallyUpdateCustomersNow,
            onAcceptClick: () => ImportTemplateService.runPersonUpdate(),
        });
    };

    return (
        <ImportTemplateEditBase  entityId={entityId} AdvancedOptions={AdvancedOptions} Header={Header} reload={reload} requiredFieldIds={[]} data={data} runNow={RunNow} />
    );
};
export default ImportTemplateEditClientAutomatic;
