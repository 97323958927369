import React, { JSXElementConstructor, ReactElement } from "react";
import { Tooltip } from "bootstrap";
import { renderToStaticMarkup } from "react-dom/server";
import { Cast, Switch } from "../../utils/Utils";

const TooltipComponent = (props: React.PropsWithChildren<{ title: string | JSX.Element | false, placement?: string, className?: string, html?: boolean, onShow?: () => void, onInserted?: () => void, hideDelay?: number }>) => {
    const elementsRef = React.useRef<(HTMLSpanElement | null)[]>([]);

    React.useEffect(() => {
        if (props.title === false) {
            return;
        }
        const options: Partial<Tooltip.Options> = {
            customClass: 'tooltipIntiza' + (typeof props.title === "string" ? '' : " bigTooltip"),
            delay: { "show": 300, "hide": props?.hideDelay || 500 },
            title: typeof props.title === "string" ? props.title : renderToStaticMarkup(props.title),
            html: typeof props.title !== "string" || Boolean(props.html),
        }
        const tooltips = elementsRef.current
            .filter(x => x !== null)
            .map(span => Tooltip.getOrCreateInstance(span!, options));
        const elements = elementsRef.current.filter(x => x !== null);
        if (props.onShow && elementsRef.current) {
            elements.forEach(x => x!.addEventListener("shown.bs.tooltip", props.onShow!));
        }
        if (props.onInserted && elementsRef.current) {
            elements.forEach(x => x!.addEventListener("inserted.bs.tooltip", props.onInserted!));
        }
        return () => {
            tooltips.forEach(tooltip => {
                tooltip.dispose();
            });
            if (props.onShow) {
                elements.forEach(x => x!.removeEventListener("shown.bs.tooltip", props.onShow!));
            }
            if (props.onInserted) {
                elements.forEach(x => x!.removeEventListener("inserted.bs.tooltip", props.onInserted!));
            }
        }
    }, [props.html, props.onShow, props.onInserted, props.title, props?.hideDelay])
    if (!props.title) {
        return <>{props.children}</>
    }
    if (typeof props.children === "string") {
        return <span ref={ref => elementsRef.current[0] = ref} data-bs-placement={props.placement} className={props.className}>{props.children}</span>
    }
    const items = React.Children.map(props.children, (child, index) => {
        const assingRef = (ref: HTMLElement) => elementsRef.current[index] = ref;
        return Switch(
            [typeof child === "string", <span ref={assingRef}>{child}</span>],
            [Boolean(child && typeof child === "object" && "type" in child && typeof child.type === "function"), <span ref={ref => elementsRef.current[0] = ref} data-bs-placement={props.placement} className={props.className}>{props.children}</span>])
            ?? React.cloneElement(Cast<ReactElement<any, string | JSXElementConstructor<any>>>(child), {
                ref: assingRef,
            });
    });
    return <>{items}</>;
}

export default TooltipComponent;