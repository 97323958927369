import { useContext, useEffect, useState } from "react";
import { PortalPaginator } from "./PortalPaginador";
import { capitalize, formatDateDigits, formatInteger } from "../../../utils/FormatUtils";
import { DataType } from "../../../services/CompanyService";
import { AdvancedFiltersContext } from "../../shared/components/AdvancedFilters";
import { PortalClientDataRequest } from "../../../services/PortalClientService";
import { SortDirection } from "../../shared/entities/Sort";
import Loading from "../../shared/components/Loading";
import { TranslationService } from "../../../services/TranslationService";

type HeaderType = {
    columnId: string,
    dataType: DataType,
    columnTitle: string,
}

type RowType = { Key: string, Value: number | string }

export const PortalInvoicesTable = ({
    response,
    selectedRows,
    setSelectedRows,
    selectAll,
    setSelectAll,
    setRequest,
    request,
    isLoading = true
}: {
    response: any,
    selectedRows: number[],
    setSelectedRows: React.Dispatch<React.SetStateAction<number[]>>,
    selectAll: boolean,
    setSelectAll: React.Dispatch<React.SetStateAction<boolean>>,
    setRequest: (request: PortalClientDataRequest) => void,
    request: PortalClientDataRequest,
    isLoading: boolean
}) => {
    const { headers = [], rowsToShow, currencyTotals } = response.data;
    const tableHeaders: HeaderType[] = headers;
    const totalRows = Object.keys(currencyTotals);

    const advancedFiltersContext = useContext(AdvancedFiltersContext);
    const [sortOrder, setSortOrder] = useState<{ [key: string]: SortDirection.Descending | SortDirection.Ascending | undefined }>({});

    useEffect(() => {
        advancedFiltersContext.setShow(true);
    }, [advancedFiltersContext]);

    const toggleSortOrder = (columnId: string) => {
        let order: Partial<PortalClientDataRequest> = {};
        const newOrder = sortOrder[columnId] === SortDirection.Ascending ? SortDirection.Descending : sortOrder[columnId] === SortDirection.Descending ? undefined : SortDirection.Ascending;
        order.sortColumn = newOrder === undefined ? undefined : tableHeaders.find(h => h.columnId === columnId)?.columnTitle;
        order.descending = newOrder;

        setRequest({ ...request, ...order })
        setSortOrder({ [columnId]: newOrder });
    };

    const handleRowSelect = (index: number) => {
        setSelectedRows(prevSelectedRows =>
            prevSelectedRows.includes(index)
                ? prevSelectedRows.filter(i => i !== index)
                : [...prevSelectedRows, index]
        );
    };

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        setSelectedRows(!selectAll ? rowsToShow.map((_: any, i: number) => i) : []);
    };

    return (
        <div className="tableContainer overflow-x-hidden sticky-top">
            {Boolean(response.data.count) ?
                <>
                    <div className="table-responsive px-md-4 p-0">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th style={{ maxWidth: 50, minWidth: 50 }}>
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                        />
                                    </th>
                                    {tableHeaders?.map((header, i) => (
                                        <HeaderValue
                                            key={i}
                                            header={header}
                                            sortOrder={sortOrder[header.columnId]}
                                            onClick={() => toggleSortOrder(header.columnId)}
                                        />
                                    ))}
                                </tr>
                            </thead>
                            {!isLoading &&
                                <tbody className="overflow-x">
                                    {rowsToShow.map((row: any, i: number) => (
                                        <tr key={i}>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedRows.includes(i)}
                                                    onChange={() => handleRowSelect(i)}
                                                />
                                            </td>
                                            {tableHeaders?.map((header, id) => {
                                                const rowToShow: any = row.find((r: any) => r.Key === header.columnId);
                                                const currency = header.dataType === DataType.Currency
                                                    ? row.find((r: any) => r.Key === "currencySymbol")?.Value.toString()
                                                    : "";
                                                return rowToShow ? (
                                                    <ItemValue
                                                        key={id}
                                                        row={rowToShow}
                                                        header={header}
                                                        currency={currency || ""}
                                                    />
                                                ) : null;
                                            })}
                                        </tr>
                                    ))}
                                    {totalRows?.map((ct, k) => {
                                        const dataCurrency = currencyTotals[ct];
                                        const simbol = ct?.split(", ")[1].replace(")", "");
                                        return (
                                            <tr key={k} style={{ backgroundColor: "#ddedf9" }}>
                                                <td></td>
                                                {tableHeaders?.map((header, i) => {
                                                    const rowToShow = dataCurrency.find(
                                                        (r: any) => r.Item1.toString() === header.columnId.toString()
                                                    );
                                                    return (
                                                        <td key={i}>
                                                            <div className='text-end'>
                                                                {rowToShow?.Item2 >= 0 ? simbol + formatInteger(parseInt(rowToShow.Item2)) : ""}
                                                            </div>
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>}
                        </table>
                        {isLoading &&
                            <div
                                className="d-flex justify-content-center align-items-center mh-100 p-0"
                                style={{ backgroundColor: "#f2f1f6", minHeight: "calc(100vh - 179px)" }}
                            >
                                <Loading />
                            </div>}
                    </div>
                    <div className="container mb-2">
                        {<PortalPaginator
                            totalRecords={response.data.count}
                            recordsPerPage={response.data.count}
                            currentPage={1}
                            onPageChange={() => { }}
                        />}
                    </div>
                </>
                :
                <nav className="d-flex justify-content-between align-items-center mt-4" aria-label="Paginación">
                    <p className="col text-center">{TranslationService.translate.NoDataToShow}</p>
                </nav>
            }
        </div>
    );
}

const HeaderValue = ({
    header,
    sortOrder,
    onClick
}: {
    header: HeaderType,
    sortOrder: SortDirection.Ascending | SortDirection.Descending | undefined,
    onClick: () => void
}) => {

    const endClass = alignByType(header);
    return (
        <th onClick={onClick} style={{ cursor: 'pointer' }}>
            <div className={"d-flex " + endClass}>
                <div>
                    {capitalize(header.columnTitle)}
                </div>
                <div className="ms-1">
                    {sortOrder === SortDirection.Ascending ? '▲' : sortOrder === SortDirection.Descending ? '▼' : undefined}
                </div>
            </div>
        </th>
    );
};

const alignByType = (header: HeaderType): string => {
    const isCurrency = header.dataType === DataType.Currency;
    const isNumber = header.dataType === DataType.Number;
    return (isNumber || isCurrency) ? 'text-end  align-items-end justify-content-end' : 'text-start  align-items-start justify-content-start';
};

const ItemValue = ({ row, header, currency = '' }: { row: RowType, header: HeaderType, currency: string }) => {
    const endClass = alignByType(header);
    const valueRow = row?.Value ? row.Value.toString() : '';
    let dataRow: any = valueRow;

    if (header.dataType === DataType.Number) {
        dataRow = formatInteger(parseInt(valueRow || '0'));
    } else if (header.dataType === DataType.Currency && currency) {
        dataRow = currency + ' ' + formatInteger(parseInt(valueRow || '0'));
    } else if (header.dataType === DataType.Date) {
        dataRow = valueRow ? formatDateDigits(new Date(valueRow)) : '';
    } else if (header.dataType === DataType.Link && valueRow.length > 0) {
        dataRow = <a href={valueRow} target='_blank noreferrer'>Link</a>;
    } else if (row.Key === 'paidStatus') {
        switch (valueRow) {
            case '0':
            case '':
                dataRow = 'Pendiente';
                break;
            case '1':
                dataRow = 'Completado';
                break;
            case '2':
                dataRow = 'Vencido';
                break;
            default:
                dataRow = valueRow;
                break;
        }
    }

    return <td className={endClass}>{dataRow}</td>;
};
