import { useRef, ReactNode, useContext, CSSProperties, useEffect, MouseEventHandler } from "react";
import TooltipComponent from "./TooltipComponent";
import CompanyService from "../../services/CompanyService";
import { SortDirection } from "./entities/Sort";
import TableContext from "../task/TableContext";
import React from "react";
import { TranslationService } from "../../services/TranslationService";
import { Switch } from "../../utils/Utils";
import Empty from "./components/Empty";
import ErrorMessage from "./components/Error";
import TableLoader from "./components/TableLoader";
import Paginator from "./Paginator";
import { TableHeader, TableHeaderWithFieldId } from "../client/components/TableHeader";

export { TableHeaderWithFieldId, TableHeader };
export type TableProps<TResponse extends { list: unknown[] }> = {
    item: (props: TResponse) => JSX.Element;
    headers: TableHeader[];
    title?: string;
    children?: ReactNode;
    stickyHeader?: boolean;
    emptyMessage?: string;
    limitSize?: boolean;
    getKey?: (item: TResponse["list"][number], index: number) => (string | number);
}

export const Table = ({ item, headers, getKey, limitSize = false, title = undefined, children, stickyHeader = false, emptyMessage }: TableProps<any>) => {
    const tableTheadRef = useRef<null | HTMLDivElement>(null);
    const tableTbodyRef = useRef<null | HTMLDivElement>(null);
    const tableTfootRef = useRef<null | HTMLDivElement>(null);

    const handleScroll: React.UIEventHandler<HTMLDivElement> = (event) => {
        const tableContainerThead = tableTheadRef.current;
        const tableContainerTbody = tableTbodyRef.current;
        const tableContainerTfoot = tableTfootRef.current;
        if (tableContainerThead === null ||
            tableContainerTbody === null
        ) {
            return;
        }
        tableContainerThead.scrollLeft = tableContainerTbody.scrollLeft;
        if (tableContainerTfoot) {
            tableContainerTfoot.scrollLeft = tableContainerTbody.scrollLeft;
        }
    };

    const { error, response, loading, currentPage, reload } = useContext(TableContext);
    const Item = item;
    const extractKey = getKey ?? ((x, index) => `tableItem_${currentPage}_${index}`);

    useEffect(() => {
        if (response && response.list && response.list.length > 0 && !loading && !limitSize) {
            const thList = [...(tableTheadRef.current?.querySelectorAll("th") ?? [])];
            const th = thList[0];
            if (th !== undefined) {
                const itemWidth = thList.map((th) => th.offsetWidth);
                const tdList = [...tableTbodyRef.current!.querySelectorAll("tbody td"),
                ...(tableTfootRef.current?.querySelectorAll("td") ?? [])
                ] as HTMLTableCellElement[];

                const indexTD = tdList.map(td => {
                    const parentTD = td.parentElement;
                    const children = [...parentTD!.children];
                    return children.indexOf(td);
                });

                const rowHasColspan = ![...tableTbodyRef.current?.children.item(0)?.children.item(0)?.children!]
                    .every(x => [...(x as HTMLTableRowElement).children!].every(y => (y as HTMLTableCellElement).colSpan === 1));

                if (!rowHasColspan) {
                    headers.forEach((tableHeader, indexTableHeader) => {
                        if (tableHeader.numeric !== true) {
                            return;
                        }

                        const tdsFromHeader = tdList.filter((td, index) => {
                            const thIndex = indexTD[index];
                            return thIndex === indexTableHeader;
                        });

                        tdsFromHeader.forEach(td => {
                            const numericTdWidth = td.offsetWidth;
                            if (numericTdWidth > itemWidth[indexTableHeader]) {
                                itemWidth[indexTableHeader] = numericTdWidth;
                            }
                        });
                    });
                }

                indexTD.forEach((indexTH, index) => {
                    const td = tdList[index];
                    const thWidth = itemWidth[indexTH];
                    if (thWidth) {
                        td.style.minWidth = thWidth.toString() + "px";
                        td.style.maxWidth = thWidth.toString() + "px";
                        thList[indexTH].style.minWidth = thWidth.toString() + "px";
                        thList[indexTH].style.maxWidth = thWidth.toString() + "px";
                    }
                });
            }
        }
    });

    const component = Switch(
        [error && !loading,
        () => <ErrorMessage onRefresh={reload} message={TranslationService.translate.ErrorLoadingList} />],
        [!loading && (response && response.list && response.list.length === 0), <Empty message={emptyMessage} />])
        ?? (<TableLoader loading={loading} response={response}>
            {title &&
                <div className="row d-flex align-items-center">
                    {title && <div className="col"><h5 className="h5">{title}</h5></div>}
                </div>
            }
            {response && response.list && response.list.length > 0 &&
                <>
                    {!limitSize && <div className={"tableContainer overflow-x-hidden" + (stickyHeader ? " sticky-top" : "")} ref={tableTheadRef}>
                        <table className={"table genericTable table-ellipsis mb-0"}>
                            <Headers headers={headers} limitSize={limitSize} />
                        </table>
                    </div>}
                    <div ref={tableTbodyRef} className="tableContainer overflow-x-auto" onScroll={handleScroll} >
                        <table className={"table genericTable table-ellipsis"}>
                            {limitSize && <Headers headers={headers} limitSize={limitSize} />}
                            <tbody>
                                {response.list.map((item: any, index: number) => {
                                    return <Item key={extractKey(item, index)} data={item} />;
                                })}
                            </tbody>
                        </table>
                    </div>
                    {children !== undefined && <div ref={tableTfootRef} className="tableContainer overflow-x-hidden text-nowrap">
                        <div className="table table-ellipsis ">
                            <div className="h-100">
                                {children}
                            </div>
                        </div>
                    </div>
                    }
                </>
            }
        </TableLoader>);
    return (
        <>
            {component}
            <Paginator />
        </>
    );
};

const Headers = ({ headers, limitSize }: { headers: TableHeader[], limitSize: boolean }) => {
    const { sort, setSortColumn } = useContext(TableContext);
    const sortClass = sort.sortDirection === SortDirection.Descending ? "fa-sort-down" : "fa-sort-up";
    const isAdditional = (item: TableHeader): item is TableHeaderWithFieldId => item.key.includes("additional") && "fieldId" in item;
    const handleHover: MouseEventHandler<HTMLTableCellElement> = (event) => {
        if (limitSize) {
            return;
        }
        const setWidth = () => {
            let th = event.target as HTMLTableCellElement;
            while (th.tagName !== "TH") {
                th = th.parentElement as HTMLTableCellElement;
                if (th === null) {
                    return;
                }
            }
            const tr = th.parentElement!;
            const trChildren = [...tr.children];
            const index = trChildren.indexOf(th);
            const thWidth = th.offsetWidth;
            let thParent = th;
            while (thParent?.className !== "table-loader-container") {
                thParent = thParent?.parentElement as HTMLTableCellElement;
            }
            const tdList = thParent.querySelectorAll(`td:nth-child(${index + 1})`);
            const tds = [...tdList] as HTMLTableCellElement[];
            tds.forEach((td) => {
                td.style.minWidth = thWidth + "px";
                td.style.maxWidth = thWidth + "px";
            });
        };
        setTimeout(setWidth, 20);
    };

    return (<thead>
        <tr>
            {headers.map((item: TableHeader, index: number) => {
                const onClick = item.sorteable ? () => setSortColumn(item.key) : undefined;
                let className = "ellipsis-oneline";
                let style: CSSProperties = {};
                if (isAdditional(item) && !item.numeric) {
                    const definition = CompanyService.getAdditionalDefinitions().find(x => x.AdditionalDefinitionID.toString() === item.fieldId);
                    if (definition?.Width) {
                        style = { minWidth: definition.Width, maxWidth: definition.Width };
                    }
                    else {
                        className += " w-100px";
                    }
                }
                const headerClassName = (item.className ?? "") + (item.numeric ? " header-numeric" : "");
                const sortLimitClass = limitSize ? " super-text" : "";
                return (
                    <th onMouseEnter={handleHover} onMouseLeave={handleHover} key={index} onClick={onClick} className={headerClassName} style={style} role={item.sorteable ? "button" : ""} >
                        {(item.tooltip !== "" &&
                            <TooltipComponent title={item.tooltip}>
                                <div className={className}>
                                    {item.label()}
                                </div>
                            </TooltipComponent>)
                            ||
                            <div className={className}>
                                {item?.label && item.label()}
                            </div>
                        }
                        {item.sorteable ? item.key === sort.sortColumn ?
                            <i className={"mx-1 fas " + sortClass + sortLimitClass} />
                            : <i className={"mx-1" + sortLimitClass} />
                            : <></>}
                    </th>
                );
            })}
        </tr>
    </thead>);
};

export default Table;