import React, { useCallback, useEffect, useState } from 'react'
import { TranslationService } from '../../../services/TranslationService';
import ButtonPrimary from '../../account/components/ButtonPrimary';
import * as yup from "yup";
import CompanyService from '../../../services/CompanyService';
import { TitleConfig } from './components/TitleConfig';

type PasswordConfigurationType = {
    pwdUpper?: string;
    pwdLower?: string;
    pwdNumbers?: string;
    pwdSymbols?: string;
    pwdLength?: string;
    pwdExpireDays?: string;
    pwdUnableToReuseLastNPwds?: string;
    pwdUnableToChangePwdForNDays?: string;
    fullSize?: boolean
}

const MIN_LENGTH_PASS = 8
const Max_LENGTH_PASS = 50

export const PasswordConfiguration = ({
    pwdUpper,
    pwdLower,
    pwdNumbers,
    pwdSymbols,
    pwdLength,
    pwdExpireDays,
    pwdUnableToReuseLastNPwds,
    pwdUnableToChangePwdForNDays,
    fullSize = false
}: PasswordConfigurationType) => {
    const [error, setError] = useState('')
    const [saveData, setSaveData] = useState(false)
    const [formData, setFormData] = useState({
        pwdUpperValue: pwdUpper,
        pwdLowerValue: pwdLower,
        pwdNumbersValue: pwdNumbers,
        pwdSymbolsValue: pwdSymbols,
        pwdLengthValue: pwdLength,
        pwdExpireDaysValue: pwdExpireDays,
        pwdUnableToReuseLastNPwdsValue: pwdUnableToReuseLastNPwds,
        pwdUnableToChangePwdForNDaysValue: pwdUnableToChangePwdForNDays,
    })

    const { translate } = TranslationService;

    const formDataSchema = yup.object({
        pwdUpperValue: yup.string().required(`${translate.ValueRequiredFor} ${translate.Uppercase}`),
        pwdLowerValue: yup.string().required(`${translate.ValueRequiredFor} ${translate.Lowercase}`),
        pwdNumbersValue: yup.string().required(`${translate.ValueRequiredFor} ${translate.Numbers}`),
        pwdSymbolsValue: yup.string().required(`${translate.ValueRequiredFor} ${translate.Symbols}`),
        pwdLengthValue: yup.number().min(MIN_LENGTH_PASS, translate.PasswordLengthLimits).max(Max_LENGTH_PASS, translate.PasswordLengthLimits)
            .required(translate.PasswordLengthLimits)
            .typeError(translate.PasswordLengthLimits),
        pwdExpireDaysValue: yup.number().min(0, translate.NegativeExpirationDays)
            .required(`${translate.ValueRequiredFor} "${translate.ExpirationDays}"`)
            .typeError(`${translate.ValueRequiredFor} "${translate.ExpirationDays}"`),
        pwdUnableToReuseLastNPwdsValue: yup.number()
            .required(`${translate.ValueRequiredFor} "${translate.CannotReuseLastNPasswords}"`)
            .typeError(`${translate.ValueRequiredFor} "${translate.CannotReuseLastNPasswords}"`),
        pwdUnableToChangePwdForNDaysValue: yup.number()
            .required(`${translate.ValueRequiredFor} "${translate.CannotChangePasswordForNDays}"`)
            .typeError(`${translate.ValueRequiredFor} "${translate.CannotChangePasswordForNDays}"`)
    });

    const saveValues = useCallback(() => {
        setSaveData(false)
        CompanyService.setConfigCompany({
            pwdUpper: formData.pwdUpperValue,
            pwdLower: formData.pwdLowerValue,
            pwdNumbers: formData.pwdNumbersValue,
            pwdSymbols: formData.pwdSymbolsValue,
            pwdLength: formData.pwdLengthValue,
            pwdExpireDays: formData.pwdExpireDaysValue,
            pwdUnableToReuseLastNPwds: formData.pwdUnableToReuseLastNPwdsValue,
            pwdUnableToChangePwdForNDays: formData.pwdUnableToChangePwdForNDaysValue,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevState) => ({ ...prevState, [name]: value }));
        setSaveData(true)
    }

    useEffect(() => {
        formDataSchema.validate(formData)
            .then(() => {
                setError('')
                setSaveData(true)
            })
            .catch((err) => setError(err.errors[0]))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])



    return (
        <div className={`${fullSize ? '' : 'col-md-8'} mx-3`}>
            <TitleConfig title={translate.PasswordSecurityLevelProperties} />
            <div className="col align-items-center mb-2">
                <div className="col-sm-2 form-check form-check-inline">
                    {translate.Uppercase}
                </div>
                <div className="form-check form-check-inline">
                    <label className="form-check-label" htmlFor="inlineRadio1">{translate.Optional}</label>
                    <input className="form-check-input" type="radio" onChange={handleInputChange} name='pwdUpperValue' id="inlineRadio1" value="0" checked={formData.pwdUpperValue === '0'} />
                </div>
                <div className="form-check form-check-inline">
                    <label className="form-check-label" htmlFor="inlineRadio2">{translate.Mandatory}</label>
                    <input className="form-check-input" type="radio" onChange={handleInputChange} name='pwdUpperValue' id="inlineRadio2" value="1" checked={formData.pwdUpperValue === '1'} />
                </div>
                <div className="form-check form-check-inline">
                    <label className="form-check-label" htmlFor="inlineRadio3">{translate.OneOrTheOther}</label>
                    <input className="form-check-input" type="radio" onChange={handleInputChange} name='pwdUpperValue' id="inlineRadio3" value="2" checked={formData.pwdUpperValue === '2'} />
                </div>
            </div>

            <div className="align-items-center mb-2">
                <div className="col-sm-2 form-check form-check-inline">
                    {translate.Lowercase}
                </div>
                <div className="form-check form-check-inline">
                    <label className="form-check-label" htmlFor="inlineRadio12">{translate.Optional}</label>
                    <input className="form-check-input" type="radio" onChange={handleInputChange} name='pwdLowerValue' id="inlineRadio12" value="0" checked={formData.pwdLowerValue === '0'} />
                </div>
                <div className="form-check form-check-inline">
                    <label className="form-check-label" htmlFor="inlineRadio22">{translate.Mandatory}</label>
                    <input className="form-check-input" type="radio" onChange={handleInputChange} name='pwdLowerValue' id="inlineRadio22" value="1" checked={formData.pwdLowerValue === '1'} />
                </div>
                <div className="form-check form-check-inline">
                    <label className="form-check-label" htmlFor="inlineRadio32">{translate.OneOrTheOther}</label>
                    <input className="form-check-input" type="radio" onChange={handleInputChange} name='pwdLowerValue' id="inlineRadio32" value="2" checked={formData.pwdLowerValue === '2'} />
                </div>
            </div>

            <div className="align-items-center mb-2">
                <div className="col-sm-2 form-check form-check-inline">
                    {translate.Numbers}
                </div>
                <div className="form-check form-check-inline">
                    <label className="form-check-label" htmlFor="inlineRadio13">{translate.Optional}</label>
                    <input className="form-check-input" type="radio" onChange={handleInputChange} name='pwdNumbersValue' id="inlineRadio13" value="0" checked={formData.pwdNumbersValue === '0'} />
                </div>
                <div className="form-check form-check-inline">
                    <label className="form-check-label" htmlFor="inlineRadio23">{translate.Mandatory}</label>
                    <input className="form-check-input" type="radio" onChange={handleInputChange} name='pwdNumbersValue' id="inlineRadio23" value="1" checked={formData.pwdNumbersValue === '1'} />
                </div>
                <div className="form-check form-check-inline">
                    <label className="form-check-label" htmlFor="inlineRadio33">{translate.OneOrTheOther}</label>
                    <input className="form-check-input" type="radio" onChange={handleInputChange} name='pwdNumbersValue' id="inlineRadio33" value="2" checked={formData.pwdNumbersValue === '2'} />
                </div>
            </div>

            <div className="align-items-center mb-2">
                <div className="col-sm-2 form-check form-check-inline">
                    {translate.Symbols}
                </div>
                <div className="form-check form-check-inline">
                    <label className="form-check-label" htmlFor="inlineRadio14">{translate.Optional}</label>
                    <input className="form-check-input" type="radio" onChange={handleInputChange} name='pwdSymbolsValue' id="inlineRadio14" value="0" checked={formData.pwdSymbolsValue === '0'} />
                </div>
                <div className="form-check form-check-inline">
                    <label className="form-check-label" htmlFor="inlineRadio24">{translate.Mandatory}</label>
                    <input className="form-check-input" type="radio" onChange={handleInputChange} name='pwdSymbolsValue' id="inlineRadio24" value="1" checked={formData.pwdSymbolsValue === '1'} />
                </div>
                <div className="form-check form-check-inline">
                    <label className="form-check-label" htmlFor="inlineRadio34">{translate.OneOrTheOther}</label>
                    <input className="form-check-input" type="radio" onChange={handleInputChange} name='pwdSymbolsValue' id="inlineRadio34" value="2" checked={formData.pwdSymbolsValue === '2'} />
                </div>
            </div>

            <div className="col-sm-7 align-items-center my-3">
                <div className="align-items-center input-group mb-3">
                    <div className="col-sm-6 mx-2 text-end">{translate.Length}</div>
                    <input name='pwdLengthValue' onChange={handleInputChange} type="number" className="form-control form-control-md" defaultValue={formData.pwdLengthValue} aria-label='pwdLength' aria-describedby="basic-addon1" />
                    <span className="col-sm-4 input-group-text"><small>(min: 8 - max: 50)</small></span>
                </div>
                <div className="align-items-center input-group mb-3">
                    <div className="col-sm-6 mx-2 text-end">{translate.ExpirationDays}</div>
                    <input name='pwdExpireDaysValue' onChange={handleInputChange} type="number" className="form-control form-control-md" defaultValue={formData.pwdExpireDaysValue} aria-describedby="basic-addon1" />
                    <span className="col-sm-4 input-group-text"><small>{translate.NoExpiration}</small></span>
                </div>
                <div className="align-items-center input-group mb-3">
                    <div className="col-sm-10 mx-2 text-end">{translate.CannotReuseLastNPasswords}</div>
                    <input name='pwdUnableToReuseLastNPwdsValue' onChange={handleInputChange} type="number" className="form-control form-control-md" defaultValue={formData.pwdUnableToReuseLastNPwdsValue} aria-describedby="basic-addon1" />
                </div>
                <div className="align-items-center input-group mb-3">
                    <div className="col-sm-10 mx-2 text-end">{translate.CannotChangePasswordForNDays}</div>
                    <input name='pwdUnableToChangePwdForNDaysValue' onChange={handleInputChange} type="number" className="form-control form-control-md" defaultValue={formData.pwdUnableToChangePwdForNDaysValue} aria-describedby="basic-addon1" />
                </div>
            </div>

            <div className='justify-content-between align-items-center my-2'>
                {formData.pwdLengthValue &&
                    parseInt(formData.pwdLengthValue) >= MIN_LENGTH_PASS &&
                    parseInt(formData.pwdLengthValue) <= Max_LENGTH_PASS &&
                    <>
                        <p>{translate.PasswordResetText}:</p>
                        <b>{translate.PasswordMinimunLength.replace('{{p1}}', formData.pwdLengthValue)}</b>
                    </>}
            </div>

            <div className='d-flex col-sm-9 justify-content-between align-items-center my-2'>
                <div id="errorMsj" className="incorrect" style={{ color: "red", fontSize: "14px", margin: "10px", }}>{error}</div>
                {saveData && !error && <ButtonPrimary className='ml-auto p-2' onClick={saveValues}>{translate.Save}</ButtonPrimary>}
            </div>
        </div>
    )
}
