import React from "react";
import { Sort } from "../shared/entities/Sort";

const defaultPageSize = 25;
export class TableContextValues<TResponse = any, TFilters = any> {
    // Table
    loading = false;
    error = false;
    response?: TResponse = undefined; 
    sort: Sort = {sortColumn: "", sortDirection: 0};
    setSortColumn: (key: string) => void = () => { throw Error("setSortColumn in TableContext not defined."); };
    reload: () => void = () => { throw Error("reload in TableContext not defined."); };
    // Pagination
    pageCount?: number = undefined;
    currentPage?: number = undefined;
    setCurrentPage: (page: number) => void = () => { throw Error("setCurrentPage in TableContext not defined."); };
    pageSize: number = defaultPageSize;
    setPageSize?: (pageCount: number) => void = undefined;
    // Filters
    applyFilters: (filters: TFilters) => void = () => { throw Error("applyFilters in TableContext not defined."); };
    applySearch: (keyword: string) => void = () => { throw Error("applySearch in TableContext not defined."); };
}
const TableContext = Object.assign(React.createContext<TableContextValues>(new TableContextValues()), {defaultPageSize});
export default TableContext;    