import { CompanyAuth } from "../entities/login/CompanyAuth";
import { LanguageCache } from "../utils/PersistenceUtils";
import { hasValuesLength } from "../utils/Utils";
import ActionService from "./ActionService";
import { ClientFields } from "./ClientService";
import CompanyService, { DataType, Entities } from "./CompanyService";
import InvoiceService from "./InvoiceService";
import { TaskFieldId } from "./TaskSerivce";
import { TranslationService } from "./TranslationService";

export enum FilterCollection {
    Client,
    Payment,
    Task,
    Activity,
    Invoice,
    ReportInvoice,
    ReportPayment,
    ReportPaymentAnalysis,
    ReportCollection,
    DefaultOrderEmailsLetters,
    GroupInvoicesEmailsAndLetters,
    ReportActivity,
    ReportIoActivity,
    ReportInvoiceGroup,
    ReportPaymentGroup,
    ActionEdit,
    ActionInvoice,
    ReportAgeing,
    OnlyClient,
    ReportCustomerAnalysis,
    Tag,
    PortalFixed
}

export enum FilterEntity {
    Client,
    Payment,
    Task,
    Activity,
    Invoice,
    InvoiceForPayments,
    ClientForPayments,
    InvoiceGroup
}
export const ENTITY_KEY = "{EntityName}";
class FilterService {
    private static oldLangCache: LanguageCache<typeof FilterService.CreateFiltersForPage> | undefined = undefined;
    private static langCache: LanguageCache<typeof FilterService.CreateFilterForEntity> | undefined = undefined;
    /**
    * @deprecated Use CreateFiltersForEntity
    */
    public static GetFiltersForPage(page: FilterCollection): FilterOption[] {
        if (FilterService.oldLangCache === undefined) {
            FilterService.oldLangCache = new LanguageCache(FilterService.CreateFiltersForPage);
        }
        return FilterService.oldLangCache.get(page);
    }
    public static GetFiltersForEntity(entity: FilterEntity) {
        if (FilterService.langCache === undefined) {
            FilterService.langCache = new LanguageCache(FilterService.CreateFilterForEntity);
        }
        return FilterService.langCache.get(entity);
    }
    public static GetFiltersForEntities(entities: FilterEntity[]) {
        return entities.map(FilterService.GetFiltersForEntity);
    }
    private static CreateFilterForEntity(entity: FilterEntity): FilterOption {
        switch (entity) {
            case FilterEntity.Client:
                return FilterService.GetClientFilters();
            case FilterEntity.ClientForPayments:
                return FilterService.GetClientFilters(FilterService.GetClientFiltersToExcludeForPayments());
            case FilterEntity.Payment:
                return FilterService.GetTransactionFilters();
            case FilterEntity.Task:
                return FilterService.GetTaskFilters();
            case FilterEntity.Activity:
                return FilterService.GetActivityFilters();
            case FilterEntity.Invoice:
                return FilterService.GetInvoiceFilters();
            case FilterEntity.InvoiceForPayments:
                return FilterService.GetInvoiceFilters(FilterService.GetInvoiceFiltersToExcludeForPayments());
            case FilterEntity.InvoiceGroup:
                return FilterService.GetInvoiceGroupFilters();
            default:
                throw new Error("Filters not found for FilterEntity: " + entity);
        }
    }

    private static CreateFilters(page: FilterCollection): FilterOption[] {
        switch (page) {
            case FilterCollection.Task:
                return FilterService.GetFiltersForEntities([FilterEntity.Task, FilterEntity.Client]);
            case FilterCollection.OnlyClient:
                return FilterService.GetFiltersForEntities([FilterEntity.Client]);
            case FilterCollection.Client:
                return FilterService.GetFiltersForEntities([FilterEntity.Client, FilterEntity.Invoice]);
            case FilterCollection.Invoice:
                return FilterService.GetFiltersForEntities([FilterEntity.Invoice]);
            case FilterCollection.Activity:
                return FilterService.GetFiltersForEntities([FilterEntity.Activity, FilterEntity.Invoice]);
            case FilterCollection.ReportInvoice:
                return FilterService.GetFiltersForEntities([FilterEntity.Invoice, FilterEntity.Client]);
            case FilterCollection.ReportAgeing:
                return FilterService.GetFiltersForEntities([FilterEntity.Invoice, FilterEntity.Client]);
            case FilterCollection.ReportPayment:
                return FilterService.GetFiltersForEntities([FilterEntity.Payment, FilterEntity.InvoiceForPayments, FilterEntity.ClientForPayments]);
            case FilterCollection.Payment:
                return FilterService.GetFiltersForEntities([FilterEntity.Payment, FilterEntity.Invoice]);
            case FilterCollection.ReportPaymentAnalysis:
                return FilterService.GetFiltersForEntities([FilterEntity.Payment, FilterEntity.InvoiceForPayments, FilterEntity.ClientForPayments]);
            case FilterCollection.ReportCollection:
                return FilterService.GetFiltersForEntities([FilterEntity.Invoice, FilterEntity.Client]);
            case FilterCollection.DefaultOrderEmailsLetters:
                return [FilterService.GetFiltersForOrderOfInvoicesInEmailsAndLetters(), FilterService.GetFiltersForEntity(FilterEntity.Invoice)];
            case FilterCollection.GroupInvoicesEmailsAndLetters:
                return [FilterService.GetGroupInvoicesEmailsAndLetters(), FilterService.GetFiltersForEntity(FilterEntity.Invoice)];
            case FilterCollection.ReportActivity:
                return [FilterService.GetFiltersForEntity(FilterEntity.Activity), FilterService.ActivityClientFilters()];
            case FilterCollection.ReportIoActivity:
                return FilterService.GetFiltersForEntities([FilterEntity.Activity, FilterEntity.Invoice, FilterEntity.Client]);
            case FilterCollection.ReportInvoiceGroup:
                return FilterService.GetFiltersForEntities([FilterEntity.Invoice, FilterEntity.Client]);
            case FilterCollection.ReportPaymentGroup:
                return FilterService.GetFiltersForEntities([FilterEntity.Payment, FilterEntity.Invoice, FilterEntity.Client]);
            case FilterCollection.ActionEdit:
                return FilterService.GetFiltersForEntities([FilterEntity.Invoice, FilterEntity.Client]);
            case FilterCollection.ActionInvoice:
                return [FilterService.GetInvoiceFiltersForActionInvoice()];
            case FilterCollection.ReportCustomerAnalysis:
                return [FilterService.GetFiltersForReportCustomerAnalysis()];
            case FilterCollection.Tag:
                return [FilterService.GetFiltersForReportTags()];
            case FilterCollection.PortalFixed:
                return [FilterService.GetFiltersPortal()];
        }
    }

    private static CreateFiltersForPage(page: FilterCollection) {
        const filters = FilterService.CreateFilters(page);
        filters.forEach(x => x.definitions = [...new Set(x.definitions.map(x => x.Name)).values()].map(name => x.definitions.find(y => y.Name === name)!));
        filters.forEach(x => x.definitions = [...new Set(x.definitions.map(x => x.Field)).values()].map(field => x.definitions.find(y => y.Field === field)!));
        // if (!CompanyService.getTags()?.length) {
        if (hasValuesLength(CompanyService.getCompanyAuth()?.tags)) {
            filters.forEach(x => x.definitions = x.definitions.filter(y => y.Name !== TranslationService.translate.Tags));
            filters.forEach(x => x.definitions = x.definitions.filter(y => y.Name !== TranslationService.translate.Tag));
        }
        return filters;
    }

    private static GetTaskFilters(): FilterOption {
        const { translate } = TranslationService;
        const taskFixedFilters: FilterDefinition[] = [
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Task,
                Name: translate.Date,
                Field: TaskFieldId.Date.toString(),
                Type: DataType.Date,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Task,
                Name: translate.Description,
                Field: TaskFieldId.Description.toString(),
                Type: DataType.Text,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Task,
                Name: translate.Tags,
                Field: TaskFieldId.Tags.toString(),
                Type: DataType.Tags,
            },
            {
                AdditionalDefinitionItems: CompanyService.getCompanyAuth()?.activityTypes.map(x => ({ Label: x.ActivityTypeName, Value: x.ActivityTypeID.toString() })) ?? [],
                Entity: Entities.Task,
                Name: translate.ActivityType,
                Field: TaskFieldId.ActivityType.toString(),
                Type: DataType.ActivityType,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Task,
                Name: CompanyService.getGroupName(),
                Field: TaskFieldId.Group.toString(),
                Type: DataType.Text,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.Client,
                Field: TaskFieldId.Client.toString(),
                Type: DataType.Client,
            },
        ];
        return {
            title: translate.FilterData.replace(ENTITY_KEY, translate.Task),
            entity: Entities.Task,
            definitions: taskFixedFilters
        };
    }

    public static GetClientFilters(excludeSpecificFilters?: FilterDefinition[], includeSpecificFilters: FilterDefinition[] = []): FilterOption {
        const { translate } = TranslationService;
        const additionalDefinitions = CompanyService.getAdditionalDefinitions()!;
        const excludeFilters = CompanyService.getCompanyAuth()?.excludedFilterFields || [];

        const clientDefinitions: FilterDefinition[] = [
            ...FilterService.AdditionalDefinitionToFilterDefinition(additionalDefinitions.filter(x => x.Entity === Entities.Client)),
        ];

        const clientExtraDefinitionsFiltered =
            FilterService.GetClientFiltersExtraDefinitions()
                .filter(e => !excludeFilters.find(f => f.toString() === e.Field))
                .filter(filter => !excludeSpecificFilters?.map(el => el.Field).includes(filter.Field));
        return {
            title: translate.FilterData.replace(ENTITY_KEY, translate.Client),
            entity: Entities.Client,
            definitions: [...includeSpecificFilters, ...clientExtraDefinitionsFiltered, ...clientDefinitions]
        };
    }

    private static GetInvoiceFilters(excludeSpecificFilters?: FilterDefinition[], includeSpecificFilters: FilterDefinition[] = []): FilterOption {
        const { translate } = TranslationService;
        const additionalDefinitions = CompanyService.getAdditionalDefinitions()!;
        const excludeFilters = CompanyService.getCompanyAuth()?.excludedFilterFields || [];

        const aditionalFilters: FilterDefinition[] =
            FilterService.AdditionalDefinitionToFilterDefinition(
                additionalDefinitions.filter(x => x.Entity === Entities.Invoice)
            );

        const invoiceExtraDefinitionsFiltered =
            FilterService.GetIOFiltersExtraDefinitions()
                .filter(e => !excludeFilters.find(f => f.toString() === e.Field))
                .filter(filter => !excludeSpecificFilters?.map(el => el.Field).includes(filter.Field));

        return {
            title: translate.FilterData.replace(ENTITY_KEY, translate.Invoice),
            entity: Entities.Invoice,
            definitions: [...aditionalFilters, ...invoiceExtraDefinitionsFiltered]
        };
    }

    private static GetInvoiceFiltersToExcludeForPayments(): FilterDefinition[] {
        const { translate } = TranslationService;

        return [
            {  // cashflow = -1026,
                AdditionalDefinitionItems: [{ Label: translate["Next-prox"], Value: "-1" }, { Label: translate["1-7days"], Value: "0" }, { Label: translate["8-14days"], Value: "1" }, { Label: translate["15-21days"], Value: "2" }, { Label: translate["22-28days"], Value: "3" }],
                Entity: Entities.Invoice,
                Name: translate.EstimatedPaymentDate,
                Field: "-1026",
                Type: DataType.ListNoOperator,
            },
        ];
    }

    private static GetInvoiceGroupFiltersDef(): FilterDefinition[] {
        const { translate } = TranslationService;

        return [
            {
                AdditionalDefinitionItems: CompanyService.getIoStatus().map(x => ({ Label: x.Value, Value: x.IOStatusID.toString() })),
                Entity: Entities.Invoice,
                Name: translate.IOStatus,
                Field: "-1015",
                // Field: window.location.pathname.includes("reportpaymentanalysis") ? "ioStatus" : "-1015",
                Type: DataType.List,
            },
        ];
    }
    private static GetInvoiceGroupFilters(): FilterOption {
        const { translate } = TranslationService;

        return {
            title: translate.FilterData.replace(ENTITY_KEY, translate.Invoice),
            entity: Entities.Invoice,
            definitions: [...FilterService.GetInvoiceGroupFiltersDef()]
        };
    }

    private static GetClientFiltersExtraDefinitions(): FilterDefinition[] {
        const { translate } = TranslationService;

        return [
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.Name,
                Field: "-1",
                Type: DataType.TextWithOperator,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.Contact,
                Field: "-2",
                Type: DataType.TextWithOperator,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.Email,
                Field: "-3",
                Type: DataType.TextWithOperator,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.Phone,
                Field: "-4",
                Type: DataType.TextWithOperator,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.Address,
                Field: "-5",//Conflict with ClientFields.Address,
                Type: DataType.TextWithOperator,
            },
            {//Comments
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.Notes,
                Field: "-6",
                Type: DataType.TextWithOperator,
            },
            {
                AdditionalDefinitionItems: CompanyService.getSegments().map(x => ({ Label: x.Value, Value: x.Id })),
                Entity: Entities.Client,
                Name: translate.Segment,
                Field: "-7",
                Type: DataType.List,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.DuePeriod,
                Field: "-8",
                Type: DataType.ExpirationDate,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.Amount,
                Field: "-9",
                Type: DataType.Currency,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.AmountDue,
                Field: "-10",
                Type: DataType.Currency,
            },
            // qty -11
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.Invoices,
                Field: "-11",
                Type: DataType.Number,
            },
            {//LastActivity
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.LastActivityDate,
                Field: "-12",
                Type: DataType.Date,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.HasPendingTasks,
                Field: "-13",
                Type: DataType.YesNo,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.Priority,
                Field: "-14",
                Type: DataType.Priority,
            },
            {//CreditLimit
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.UsedCredit,
                Field: "-15",
                Type: DataType.Percentage,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.FollowUpMail,
                Field: "-16",
                Type: DataType.TextWithOperator,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.HasFailedEmails,
                Field: "-17",
                Type: DataType.YesNo,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.LastActivityManual,
                Field: "-18",
                Type: DataType.Date,
            },
            { // paymentsLaps -19
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.WithPaymentsInThePeriod,
                Field: "-19",
                Type: DataType.Date,
            },
            { //actovityLaps -20
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.WithActivitiesInThePeriod,
                Field: "-20",
                Type: DataType.ActivityTypeWithDate,
            },
            // activityTypeLapse = -21,
            {// ActivityLapsPending -22
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.WithBalanceAndActivitiesInThePeriod,
                Field: "-22",
                Type: DataType.ActivityTypeWithDate,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.LastModified,
                Field: "-23",
                Type: DataType.Date,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.LastModifiedManual,
                Field: "-24",
                Type: DataType.Date,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.LastActivityEffective,
                Field: "-25",
                Type: DataType.Date,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.LastActivityByTheCustomer,
                Field: "-26",
                Type: DataType.YesNo,
            },
        ];
    }

    private static GetIOFiltersExtraDefinitions(): FilterDefinition[] {
        const { translate } = TranslationService;
        return [
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Invoice,
                Name: CompanyService.getGroupName(),
                Field: "-1008",
                Type: DataType.TextWithOperator,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Invoice,
                Name: InvoiceService.getReferenceNumberName(),
                Field: "-1009",
                Type: DataType.TextWithOperator,
            },
            {
                AdditionalDefinitionItems: [
                    { Label: TranslationService.translate.StatusPending, Value: "0" },
                    { Label: TranslationService.translate.Paid, Value: "1" },
                    { Label: TranslationService.translate.Due, Value: "2" },
                    { Label: TranslationService.translate.All, Value: "3" },
                ],
                Entity: Entities.Invoice,
                Name: translate.Status,
                Field: "-1010",
                Type: DataType.List,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Invoice,
                Name: translate.Amount,
                Field: "-1011",
                Type: DataType.Number,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Invoice,
                Name: translate.PendingAmount,
                Field: "-1012",
                Type: DataType.Number,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Invoice,
                Name: translate.Observations,
                Field: "-1013",
                Type: DataType.Text,
            },
            // tags -1014
            {
                AdditionalDefinitionItems: CompanyService.getIoStatus().map(x => ({ Label: x.Value, Value: x.IOStatusID.toString() })),
                Entity: Entities.Invoice,
                Name: translate.IOStatus,
                Field: "-1015",
                // Field: window.location.pathname.includes("reportpaymentanalysis") ? "ioStatus" : "-1015",
                Type: DataType.List,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Invoice,
                Name: translate.Claimable,
                Field: "-1016",
                // Field: window.location.pathname.includes("reportpaymentanalysis") ? "reclaimable" : "-1016",
                Type: DataType.YesNo,
            },
            {
                AdditionalDefinitionItems: CompanyService.getCurrencies().map(x => ({ Label: x.Name, Value: x.CurrencyId.toString() })),
                Entity: Entities.Invoice,
                Name: translate.Currency,
                Field: "-1017",
                Type: DataType.List,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Invoice,
                Name: translate.HasFiles,
                Field: "-1020",
                Type: DataType.YesNo,
            },

            {// emittedDate = -1023,
                AdditionalDefinitionItems: [],
                Entity: Entities.Invoice,
                Name: translate.IssueDate,
                Field: "-1023",
                Type: DataType.Date,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Invoice,
                Name: translate.DueDate,
                Field: "-1024",
                Type: DataType.Date,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Invoice,
                Name: translate.PaidDate,
                Field: "-1025",
                Type: DataType.Date,
            },
            {  // cashflow = -1026,
                AdditionalDefinitionItems: [{ Label: translate["Next-prox"], Value: "-1" }, { Label: translate["1-7days"], Value: "0" }, { Label: translate["8-14days"], Value: "1" }, { Label: translate["15-21days"], Value: "2" }, { Label: translate["22-28days"], Value: "3" }],
                Entity: Entities.Invoice,
                Name: translate.EstimatedPaymentDate,
                Field: "-1026",
                Type: DataType.ListNoOperator,
            },
            { // lastModified = -1027,
                AdditionalDefinitionItems: [],
                Entity: Entities.Invoice,
                Name: translate.LastUpdateDate,
                Field: "-1027",
                Type: DataType.Date,
            },
            {  // createdDate = -1030
                AdditionalDefinitionItems: [],
                Entity: Entities.Invoice,
                Name: translate.CreatedDate,
                Field: "-1030",
                Type: DataType.Date,
            },
        ];
    }

    private static GetClientFiltersToExcludeForPayments(): FilterDefinition[] {
        const { translate } = TranslationService;

        return [
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: translate.HasFailedEmails,
                Field: "-17",
                Type: DataType.YesNo,
            },
        ];
    }

    private static GetGroupInvoicesEmailsAndLetters(): FilterOption {
        const { translate } = TranslationService;

        const filtersGetGroupInvoicesEmailsAndLetters: FilterDefinition[] = [
            {
                AdditionalDefinitionItems: CompanyService.getCurrencies().map(x => ({ Label: x.Name, Value: x.CurrencyId.toString() })),
                Entity: Entities.Invoice,
                Name: translate.Currency,
                Field: "-1017",
                Type: DataType.List,
            },
        ];

        return {
            title: translate.FilterData.replace(ENTITY_KEY, translate.DefaultOrderOfInvoicesInEmailsAndLetters),
            entity: Entities.Activity,
            definitions: filtersGetGroupInvoicesEmailsAndLetters
        };
    }

    private static GetFiltersForOrderOfInvoicesInEmailsAndLetters(): FilterOption {
        const { translate } = TranslationService;

        const filtersOrderInvoicesInEmailsAndLettersDefinition: FilterDefinition[] = [
            {
                AdditionalDefinitionItems: [
                    { Label: translate.Pending, Value: "0" },
                    { Label: translate.Paid, Value: "1" },
                    { Label: translate.InvoiceExpired, Value: "2" },
                    { Label: translate.AllInvoices, Value: "3" },
                ],
                Entity: Entities.Invoice,
                Name: translate.Status,
                Field: "-1010",
                Type: DataType.Status,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Invoice,
                Name: translate.PendingAmount,
                Field: "-1012",
                Type: DataType.Number,
            },
            {
                AdditionalDefinitionItems: CompanyService.getCurrencies().map(x => ({ Label: x.Name, Value: x.CurrencyId.toString() })),
                Entity: Entities.Invoice,
                Name: translate.Currency,
                Field: "-1017",
                Type: DataType.List,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Invoice,
                Name: translate.HasFiles,
                Field: "-1020",
                Type: DataType.YesNo,
            },
        ];
        return {
            title: translate.FilterData.replace(ENTITY_KEY, translate.DefaultOrderOfInvoicesInEmailsAndLetters),
            entity: Entities.Activity,
            definitions: filtersOrderInvoicesInEmailsAndLettersDefinition
        };
    }


    private static GetInvoiceFiltersForActionInvoice(): FilterOption {
        const invoiceFilters = FilterService.CreateFilterForEntity(FilterEntity.Invoice);
        invoiceFilters.definitions.filter(x => x.Type === DataType.Date || x.Type === DataType.ExpirationDate).forEach(x => x.Type = DataType.DayCompare);
        return invoiceFilters;
    }

    private static GetFiltersForReportCustomerAnalysis(): FilterOption {
        const { translate } = TranslationService;
        const additionalDefinitions = CompanyService.getAdditionalDefinitions()!;

        // 0 = name,
        // 1 = amount,
        // 2 = overdueamount,
        // 3 = count,
        // 4 = averagePaymentDays,
        // 5 = averageAgingDays,
        // 6 = dueDays,
        // 7 = duePercent,
        // 8 = segment
        // AverageAgingDays = -27, (Antiguedad Promedio)
        // AveragePaymentDays = -28, (Plazo Promedio de Pagos)
        // DueDays = -29,  (Mora (dias de)
        // DuePercent = -30  (Porcentaje Vencido?)
        const filtersDefinitions: FilterDefinition[] = [
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.ReportCustomerAnalysis,
                Name: translate.Name,
                Field: "-1",
                Type: DataType.Text,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.ReportCustomerAnalysis,
                Name: translate.Amount,
                Field: "-9",
                Type: DataType.Number,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.ReportCustomerAnalysis,
                Name: translate.ReportOverdueAmount,
                Field: "-10",
                Type: DataType.Number,
            },
            // {
            //     AdditionalDefinitionItems: [],
            //     Entity: Entities.ReportCustomerAnalysis,
            //     Name: translate.count,
            //     Field: "3",
            //     Type: DataType.Number,
            // },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.ReportCustomerAnalysis,
                Name: translate.ReportAveragePayment,
                Field: "-28",
                Type: DataType.Number,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.ReportCustomerAnalysis,
                Name: translate.ReportAverageAging,
                Field: "-27",
                Type: DataType.Number,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.ReportCustomerAnalysis,
                Name: translate.DueDays,
                Field: "-29",
                Type: DataType.Number,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.ReportCustomerAnalysis,
                Name: translate.ReportPercentDue,
                Field: "-30",
                Type: DataType.Number,
            },
            {
                AdditionalDefinitionItems: CompanyService.getSegments().map(x => ({ Label: x.Value, Value: x.Id })),
                Entity: Entities.Client,
                Name: translate.Segment,
                Field: "-7",
                Type: DataType.List,
            },
        ];

        const filterAdditionals = FilterService.AdditionalDefinitionToFilterDefinition(additionalDefinitions.filter(x => x.Entity === Entities.Client))
            .map(fa => ({ ...fa, Field: fa.Field.replace("additional-", "") }));
        const clientDefinitions: FilterDefinition[] = [
            ...filtersDefinitions,
            ...filterAdditionals,
        ];

        return {
            title: translate.FilterData.replace(ENTITY_KEY, translate.CustomerAnalysis),
            entity: Entities.ReportCustomerAnalysis,
            definitions: [...clientDefinitions]
        };
    }

    private static GetFiltersForReportTags(): FilterOption {
        const { translate } = TranslationService;
        const filtersDefinitions: FilterDefinition[] = [

            {
                AdditionalDefinitionItems: CompanyService.getUsers().map(x => ({ Label: x.Value, Value: x.Id })),
                Entity: Entities.Tag,
                Name: translate.User,
                Field: "1",
                Type: DataType.User,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Tag,
                Name: translate.Date,
                Field: "2",
                Type: DataType.Date,
            },
            ...(CompanyService.getTags()?.length ? [{
                AdditionalDefinitionItems: CompanyService.getTags().map(x => ({ Label: x.Value, Value: x.Id })),
                Entity: Entities.Tag,
                Name: translate.Tags,
                Field: "3",
                Type: DataType.Tags,
            }] : []),
            {
                AdditionalDefinitionItems: CompanyService.getSegments().map(x => ({ Label: x.Value, Value: x.Id })),
                Entity: Entities.Tag,
                Name: translate.Segment,
                Field: "4",
                Type: DataType.List,
            },
        ];

        return {
            title: translate.FilterData.replace(ENTITY_KEY, translate.Tags),
            entity: Entities.ReportCustomerAnalysis,
            definitions: [...filtersDefinitions]
        };
    }

    private static GetFiltersPortal(): FilterOption {
        const filtersDefinitions: FilterDefinition[] = [

            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Portal,
                Name: TranslationService.translate.IssueDate,
                Field: "-1023",
                Type: DataType.Date,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Portal,
                Name: TranslationService.translate.DueDate,
                Field: "-1024",
                Type: DataType.Date,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Portal,
                Name: TranslationService.translate.Amount,
                Field: "-1011",
                Type: DataType.Number,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Portal,
                Name: TranslationService.translate.PendingAmount,
                Field: "-1012",
                Type: DataType.Number,
            },
        ];
        const ioAdditionals = (CompanyService.getAdditionalDefinitions() as any)?.ioAdditionals;
        const personAdditionals = (CompanyService.getAdditionalDefinitions() as any)?.personAdditionals;

        const filterDefinitions: FilterDefinition[] = [
            ...FilterService.AdditionalDefinitionToFilterDefinition(ioAdditionals),
            ...FilterService.AdditionalDefinitionToFilterDefinition(personAdditionals),
        ];
        return {
            title: '',// translate.FilterData.replace(ENTITY_KEY, translate.Tags),
            entity: Entities.Portal,
            definitions: [...filtersDefinitions, ...filterDefinitions]
        };
    }

    private static GetActivityFilters(): FilterOption {
        const { translate } = TranslationService;
        const activityFilters: FilterDefinition[] = [
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Activity,
                Name: translate.Date,
                Field: "-100",
                Type: DataType.Date,
            },
            {
                AdditionalDefinitionItems: CompanyService.getUsers().map(x => ({ Label: x.Value, Value: x.Id })),
                Entity: Entities.Activity,
                Name: translate.User,
                Field: "-102",
                Type: DataType.User,
            },
            {
                AdditionalDefinitionItems: [
                    { Label: translate.FilterDefaultOptionLabel, Value: "", },
                    { Label: translate.Mails, Value: "1", },
                    { Label: translate.Comments, Value: "2", },
                    { Label: translate.Tasks, Value: "3", },
                    { Label: "SMS", Value: "4", },
                    { Label: "Whatsapp", Value: "6", },
                    { Label: translate.OutgoingEmails, Value: "-2", },
                    { Label: translate.BouncedEmail, Value: "-1", },
                ],
                Entity: Entities.Activity,
                Name: translate.Type,
                Field: "-103",
                Type: DataType.Type,
            },
            ...(CompanyService.getTags()?.length ? [{
                AdditionalDefinitionItems: CompanyService.getTags().map(x => ({ Label: x.Value, Value: x.Id })),
                Entity: Entities.Activity,
                Name: translate.Tags,
                Field: "-106",
                Type: DataType.Tags,
            }] : []),
            {
                AdditionalDefinitionItems: function () {
                    const res: { Label: string; Value: string; }[] = [];
                    ActionService.list().then(x => {
                        if (x instanceof Error) {
                            return;
                        }
                        res.splice(0, res.length, ...x.list
                            .flatMap(x => x.items)
                            .map(x => ({ Label: x.Name, Value: x.ActionID.toString() }))
                            .sort((a, b) => (a.Label.toLowerCase() > b.Label.toLowerCase()) ? 1 : ((b.Label.toLowerCase() > a.Label.toLowerCase()) ? -1 : 0)));
                    });
                    return res;
                }(),
                Entity: Entities.Activity,
                Name: translate.ProgrammedAction,
                Field: "-109",
                Type: DataType.List,
            },
            {
                AdditionalDefinitionItems: CompanyService.getActivityTypes().map(x => ({ Label: x.ActivityTypeName, Value: x.ActivityTypeID.toString() })),
                Entity: Entities.Activity,
                Name: translate.ActivityType,
                Field: "-104",
                Type: DataType.ActivityType,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Activity,
                Name: "Gestión Efectiva",
                Field: "-111",
                Type: DataType.YesNo,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Activity,
                Name: translate.Created,
                Field: "-107",
                Type: DataType.Date,
            },
        ];

        return {
            title: translate.FilterData.replace(ENTITY_KEY, translate.Activity),
            entity: Entities.Activity,
            definitions: activityFilters
        };
    }

    private static AdditionalDefinitionToFilterDefinition = (additional: CompanyAuth.AdditionalDefinition[]): FilterDefinition[] => {
        return additional.filter(x => !x.ExcludeInFilters).map(x => (
            {
                Name: x.Name ?? TranslationService.getTranslation(ClientFields[x.Field]),
                Field: "additional-" + x.AdditionalDefinitionID.toString(),
                AdditionalDefinitionItems: x.AdditionalDefinitionItems.map(x => ({ Label: x.Value, Value: x.AdditionalDefinitionItemID.toString() })),
                Type: x.Type,
                Entity: x.Entity,
            }));
    }

    static AddStatus(filterOptions: FilterOption): FilterOption {
        const { translate } = TranslationService;
        filterOptions.definitions = [{
            AdditionalDefinitionItems: [
                { Label: translate.Pending, Value: "0" },
                { Label: translate.Paid, Value: "1" },
                { Label: translate.InvoiceExpired, Value: "2" },
                { Label: translate.AllInvoices, Value: "3" },
            ],
            Entity: filterOptions.entity,
            Name: translate.Status,
            Field: "-1010",
            Type: DataType.Status,
        }, ...filterOptions.definitions];
        return filterOptions;
    }

    private static GetTransactionFilters(): FilterOption {
        const { translate } = TranslationService;
        const transactionFixedFilters: FilterDefinition[] = [
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Payment,
                Name: translate.Date,
                Field: "-303",//"date",
                Type: DataType.Date,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Payment,
                Name: InvoiceService.getReferenceNumberName(),
                Field: "-304",//"referenceNumber",
                Type: DataType.Text,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Payment,
                Name: translate.Amount,
                Field: "-301",//"amount",
                Type: DataType.Number,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Payment,
                Name: translate.Observations,
                Field: "-305",//"comments",
                Type: DataType.Text,
            },
            {
                AdditionalDefinitionItems: CompanyService.getTransactionTypes().map(x => ({ Label: x.Value, Value: x.Id })),
                Entity: Entities.Payment,
                Name: translate.PaymentType,
                Field: "-306",// "transactiontype",
                Type: DataType.List,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Payment,
                Name: translate.HasReceipt,
                Field: "-307",//"receipt",
                Type: DataType.YesNo,
            },
            {
                AdditionalDefinitionItems: CompanyService.getUsers().map(x => ({ Label: x.Value, Value: x.Id })),
                Entity: Entities.Payment,
                Name: translate.User,
                Field: "-308",//"user",
                Type: DataType.User,
            },
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Payment,
                Name: translate.CreatedDate,
                Field: "-309",//"date",
                Type: DataType.Date,
            },
        ];

        const filterAdditionals = FilterService.AdditionalDefinitionToFilterDefinition(
            CompanyService.getAdditionalDefinitions().filter(x => x.Entity === Entities.Payment));
        return {
            title: translate.FilterData.replace(ENTITY_KEY, translate.Payments),
            entity: Entities.Payment,
            definitions: [...transactionFixedFilters, ...filterAdditionals],
        };
    }

    private static ActivityClientFilters(): FilterOption {
        const filterOptions = FilterService.CreateFilterForEntity(FilterEntity.Client);
        filterOptions.definitions.push(
            {
                AdditionalDefinitionItems: [],
                Entity: Entities.Client,
                Name: TranslationService.translate.Client,
                Field: "-4",
                Type: DataType.ClientGroup,
            });
        filterOptions.definitions.find(x => x.Name === TranslationService.translate.Segment)!.Field = "-7";
        return filterOptions;
    }

    /// Para cuando se envian ExtraFilters a un endpoint, suelen tener todos el mismo formato 
    public static GetExtraFiltersRequestString(filters: string[] | undefined) {
        return filters?.map((value, index) => "filter" + index + "=" + encodeURIComponent(value)).join("&");
    }

    public static ParseExtraFiltersResponseString(filters: string | undefined) {
        if (!filters) { return []; }
        return filters.split("&").filter(x => !!x).map(x => decodeURIComponent(x.split("=")[1]));
    }

}

export type FilterOption = { title: string, entity: Entities, definitions: FilterDefinition[] }

export interface FilterDefinition {
    AdditionalDefinitionItems: { Label: string, Value: string }[];
    Type: DataType;
    Name: string;
    Field: string;
    Entity: Entities;
}

export default FilterService;
