import { ErrorProvider } from "../shared/ErrorContext";
import { PortalClientContainer } from "../account/PortalClient";

const PortalSite = (props) => {
    return (
        <div>
            <ErrorProvider>
                <PortalClientContainer />
                <footer />
            </ErrorProvider>
        </div>
    );
};

export default PortalSite;
