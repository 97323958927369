import { useRef } from "react";

const DropdownNest = <T,>({ title, items, onClick }: { title: string, items: { text: string, value: T }[], onClick: (value: { text: string, value: T }, parentTitle: string) => void }) => {
    const aref = useRef<HTMLUListElement>(null);
    const toggleDropdown = (e: any) => {
        document.querySelectorAll(".dropdown-submenu>ul").forEach(element => {
            element.classList.remove("d-block")
        });
        aref.current?.classList.add("d-block");
        e.stopPropagation();
        e.preventDefault();
    }

    const closeOptions = (e: any) => {
        document.querySelectorAll(".dropdown-menu").forEach(element => {
            element.classList.remove("d-block");
        });
        e.stopPropagation();
        e.preventDefault();
    }

    return (
        <div className="dropdown-submenu" onClick={toggleDropdown} >
            <button className="d-flex justify-content-between align-items-center w-100">{title}<i className="fa-regular fa-chevron-right"></i></button>
            <ul className="dropdown-menu" ref={aref}>
                {items.map(x => (<li key={x.text + x.value}>
                    {"isTitle" in x && <h6 className="dropdown-header fw-bolder" style={{ userSelect: "none" }} onClick={e => { e.stopPropagation(); e.preventDefault(); return false; }}>{x.text}</h6>}
                    {"value" in x && <button type="button" onClick={(e) => { closeOptions(e); onClick(x, title) }} className="dropdown-item">{x.text}</button>}
                </li>))}
            </ul>
        </div>
    )
}
export default DropdownNest;