import { CheckBoxEditor } from '../../shared/components/Editors';
import { TranslationService } from '../../../services/TranslationService';
import CompanyService from '../../../services/CompanyService';
import { useCallback, useContext, useRef, useState } from 'react';
import { EmailComposeModel, EmailTabProps } from '../../client/components/EmailCompose';
import { OptionalMap } from '../../../utils/Utils';
import Dropdown from '../../shared/components/Dropdown';
import { v4 } from 'uuid';
import ConfigurationContext from '../ConfigurationContext';
import ButtonPrimary from '../../account/components/ButtonPrimary';
import { TitleConfig } from './components/TitleConfig';
import FilterService, { FilterCollection } from '../../../services/FilterService';

function getOrderBy(model: any, index: number) {
    const value = model?.item?.[(("orderby" + index) as "orderby1")];
    return value
}
function getOrderByDesc(model: any, index: number) {
    return model?.item?.[(("orderbyorderdesc" + index) as "orderbyorderdesc1")];
}
function getDefaultSortByItems(model: Partial<any>) {
    const items = Array.from({ length: 3 }).map((x, i) => ({
        orderBy: getOrderBy(model, i),
        orderbyorderdesc: getOrderByDesc(model, i),
        _key: v4(),
    })).filter((x, i) => x.orderBy);
    if (items.length === 0) {
        items.push({ orderBy: undefined, orderbyorderdesc: "off", _key: v4(), });
    }
    return items;
}

function getDefaultModel({ ordermailby, ordermailbyorderdesc }: { ordermailby: string, ordermailbyorderdesc: string }) {
    const model = { item: {}, temp: {}, } as any;
    const item = model.item!;

    const orderByArray = ordermailby?.split(";");
    const orderByDescArray = ordermailbyorderdesc?.split(";");
    item.orderby0 = OptionalMap(orderByArray?.[0], x => x.length > 0 ? parseInt(x) : undefined);
    item.orderby1 = OptionalMap(orderByArray?.[1], x => x.length > 0 ? parseInt(x) : undefined);
    item.orderby2 = OptionalMap(orderByArray?.[2], x => x.length > 0 ? parseInt(x) : undefined);
    item.orderbyorderdesc0 = (OptionalMap(orderByDescArray?.[0], x => x === "0" ? "off" : "on") ?? "off") as "on" | "off";
    item.orderbyorderdesc1 = (OptionalMap(orderByDescArray?.[1], x => x === "0" ? "off" : "on") ?? "off") as "on" | "off";
    item.orderbyorderdesc2 = (OptionalMap(orderByDescArray?.[2], x => x === "0" ? "off" : "on") ?? "off") as "on" | "off";
    return model;
}

const getValueDesc = (value: "on" | "off") => value === "on" ? 1 : 0;

type DefaultOrderOfInvoicesInEmailsAndLettersType = {
    ordermailby?: string
    ordermailbyorderdesc?: string
}

export const DefaultOrderOfInvoicesInEmailsAndLetters = ({ ordermailby = "", ordermailbyorderdesc = "" }: DefaultOrderOfInvoicesInEmailsAndLettersType) => {
    const [model, setModel] = useState<any>(getDefaultModel({ ordermailby, ordermailbyorderdesc }));
    const [sortByItems, setSortByItems] = useState(getDefaultSortByItems(model));

    const { refreshConfigValues } = useContext(ConfigurationContext)

    const orderByFeatureName = "orderby0";
    const orderByData = [
        model?.item["orderby0"],
        model?.item["orderby1"],
        model?.item["orderby2"]].filter(value => value !== undefined).join(';');
    const orderCountData = orderByData.split(';').length
    const orderByDescData = [
        getValueDesc(model?.item["orderbyorderdesc0"]),
        getValueDesc(model?.item["orderbyorderdesc1"]),
        getValueDesc(model?.item["orderbyorderdesc2"])].filter(value => value !== undefined).join(';');
    const allowSave = orderByData.length > 0 && orderByDescData && orderCountData === sortByItems.length;

    const saveValues = useCallback(() => {
        allowSave &&
            CompanyService.setConfigCompany({ ordermailby: orderByData, ordermailbyorderdesc: orderByDescData })
                .then(refreshConfigValues)
    }, [allowSave, orderByData, orderByDescData, refreshConfigValues])
    const features = useRef({} as Record<string, unknown>).current;
    const featuresCache: Record<string, ReturnType<EmailTabProps["getFeature"]>> = {};

    const invoiceFields = FilterService.GetFiltersForPage(FilterCollection.DefaultOrderEmailsLetters)
        .flatMap(({ definitions }) => definitions.map(x => ({ value: x.Field.replace("additional-", ""), text: x.Name ?? '' })))

    const getFeature: any = (featureKey: any, offValue?: any) => {
        if (featuresCache[featureKey] === undefined) {
            const setFeatures = () => {
                const newValue: any = features[featureKey + "isOn"] ? features[featureKey + "prevValue"] : offValue;
                setModel((model: any) => {
                    const newModel: any = { ...model };
                    if (!newModel.item) {
                        newModel.item = {} as any["item"];
                    }
                    if (newModel.item) {
                        if (features[featureKey + "isOn"]) {
                            Object.assign(newModel.item, features[featureKey + "otherValues"]);
                        }
                        newModel.item[featureKey] = newValue as never;
                    }
                    return newModel
                });
            }
            const toggleFeature = (isOn?: boolean | undefined) => {
                const value = model.item && model.item[featureKey];
                features[featureKey + "isOn"] = isOn !== undefined ? isOn : features[featureKey + "isOn"] === undefined ? (value === undefined || value === "off") : !features[featureKey + "isOn"];
                setFeatures();
            }
            const setFeatureValue = <TValueKey extends keyof EmailComposeModel["item"],>(key: TValueKey) =>
                (value: EmailComposeModel["item"][TValueKey]) => {
                    if (key === featureKey as string) {
                        features[featureKey + "prevValue"] = value;
                    } else {
                        const newValue = (features[featureKey + "otherValues"] ?? {}) as Record<string, unknown>;
                        newValue[key] = value;
                        features[featureKey + "otherValues"] = newValue;
                    }
                    setFeatures();
                }
            features[featureKey + "prevValue"] = model?.item?.[featureKey];
            features[featureKey + "isOn"] = features[featureKey + "isOn"] || (model?.item?.[featureKey] && model?.item?.[featureKey] !== offValue);

            featuresCache[featureKey] = {
                toggleFeature,
                setFeatureValue,
                isOn: Boolean(features[featureKey + "isOn"]),
            };
        }
        return featuresCache[featureKey];
    }

    const deleteSortBy = (index: number, key: string) => {
        setSortByItems(sortByItems.filter(x => x._key !== key));
        if (index === 0) {
            getFeature(orderByFeatureName).setFeatureValue("orderby0")(model?.item?.orderby1);
            getFeature(orderByFeatureName).setFeatureValue("orderbyorderdesc0")(model?.item?.orderbyorderdesc1 ?? "off");
        }
        if (index === 1) {
            getFeature(orderByFeatureName).setFeatureValue("orderby1")(model?.item?.orderby2);
            getFeature(orderByFeatureName).setFeatureValue("orderbyorderdesc1")(model?.item?.orderbyorderdesc2 ?? "off");
        }
        getFeature(orderByFeatureName).setFeatureValue("orderby2")(undefined);
        getFeature(orderByFeatureName).setFeatureValue("orderbyorderdesc2")("off");
    }

    return (
        <div className='mx-3'>
            <TitleConfig title={TranslationService.getTranslation('DefaultOrderOfInvoicesInEmailsAndLetters')} />

            <div className='d-flex align-items-center my-2'>
                {sortByItems.map((x: any, i: number) =>
                    <div key={x._key} className={"d-flex col-md-4 align-items-center" + (i === 0 ? "" : " ")}>
                        <div className="col" style={{ maxWidth: 300, width: '100%' }}>
                            <Dropdown
                                onChange={x => { getFeature(orderByFeatureName).setFeatureValue("orderby" + i.toString() as "orderby0")(x) }}
                                items={invoiceFields}
                                value={getOrderBy(model, i)}
                                defaultValue={getOrderBy(model, i)} />
                        </div>
                        <div className="mx-2">
                            <div className="d-flex align-items-center">
                                <CheckBoxEditor
                                    message=""
                                    onChange={x => getFeature(orderByFeatureName).setFeatureValue("orderbyorderdesc" + i.toString() as "orderbyorderdesc0")(x ? "on" : "off")}
                                    defaultValue={getOrderByDesc(model, i) === "on"}
                                    disabled={!getFeature(orderByFeatureName).isOn} />
                                <i className="fa-regular fa-arrow-down-a-z fs-5 btnSort"></i>
                            </div>
                        </div>
                        {sortByItems.length - 1 === i && <div className="mx-3">
                            <button className="btnTrash" type="button" onClick={() => deleteSortBy(i, x._key)}>
                                <i className="fa-regular fa-trash fs-5 text-danger"></i>
                            </button>
                        </div>}
                    </div>
                )}
            </div>


            {getFeature(orderByFeatureName).isOn && sortByItems.length < 3 &&
                <button className="btn btn-link fw-normal p-1 fs-7 my-2"
                    onClick={() => setSortByItems(x => [...x, { orderBy: undefined, orderbyorderdesc: "off", _key: v4() }])}>
                    <i className="far fa-plus"></i>{TranslationService.translate.AddOrder}
                </button>}

            {allowSave && <ButtonPrimary onClick={saveValues}>{TranslationService.translate.Save}</ButtonPrimary>}
        </div>
    )
}
