import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { v4 } from "uuid";
import FilterService from "../../../services/FilterService";
import InvoiceService from "../../../services/InvoiceService";
import { Sort, SortDirection } from "../../shared/entities/Sort";
import TableContext, { TableContextValues } from "../../task/TableContext";
import { InvoiceListRequest } from "./entities/InvoiceListRequest";
import { InvoiceListResponse } from "./entities/InvoiceListResponse";

let reqId = v4();

export type InvoiceListFilters = {
    textSearch?: string,
    quickfilter?: string,
    extraFilters: string[],
    quickExtraFilter: string,
    iotree?: string
};

export class InvoiceTableContextValues extends TableContextValues<InvoiceListResponse, InvoiceListFilters> {
    constructor(public getFilters: () => InvoiceListRequest) {
        super();
    }
}

const InvoiceTableProvider = (props: PropsWithChildren<{ clientId?: number, groupId?: number, pagesize?: number, extraFilters?: string, quickfilter?: string }>) => {
    const [response, setResponse] = useState<InvoiceListResponse>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [currentPage, _setCurrentPage] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(1);
    const [filters, setFilters] = useState<InvoiceListFilters>({ quickfilter: props.quickfilter ?? "1", extraFilters: FilterService.ParseExtraFiltersResponseString(props.extraFilters), quickExtraFilter: "", iotree: "" });
    const [sort, _setSort] = useState<Sort>({ sortColumn: "emittedDate", sortDirection: SortDirection.Descending });
    const [pagesize, setPagesize] = useState<number>(props.pagesize ?? 10);

    const getFilters = useCallback((currentPage: number, filters: InvoiceListFilters): InvoiceListRequest => {
        const extraFilters = [...filters.extraFilters];
        if (filters.quickExtraFilter !== "") {
            extraFilters.push(filters.quickExtraFilter);
        }
        return {
            personid: props.clientId?.toString(),
            quickfilter: filters.quickfilter,
            page: currentPage,
            pagesize,
            sortcolumn: sort.sortColumn,
            descending: sort.sortDirection,
            filter: FilterService.GetExtraFiltersRequestString(extraFilters),
            groupid: props.groupId,
            iotree: filters.iotree,
            text: filters.textSearch,
        };
    }, [pagesize, props.clientId, props.groupId, sort.sortColumn, sort.sortDirection]);

    const requestData = async () => {
        setLoading(true);
        reqId = v4();
        const ourRequest = reqId;
        const request = getFilters(currentPage, filters);
        const result = await InvoiceService.getList(request);
        if (ourRequest !== reqId) {
            return;
        }
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        const totalPages = Math.ceil(result.itemCount / result.pageSize);
        setResponse(result);
        _setCurrentPage(result.page);
        setPageCount(totalPages);
        setLoading(false);
        //setItemCount(result.itemCount);
        setError(false);
    };

    const requestDataCallback = useCallback(requestData, [currentPage, _setCurrentPage, filters, getFilters]);

    useEffect(() => {
        requestDataCallback();
    }, [currentPage, sort, requestDataCallback]);

    const setCurrentPage = (page: number) => {
        if (page >= 0 && (page - 1) <= pageCount) {
            _setCurrentPage(page);
        }
    };

    const applyFilters = (filters: InvoiceListFilters) => {
        setCurrentPage(0);
        setFilters(x => ({ ...x, ...filters }));
    };

    const setSortColumn = (key: string) => {
        _setSort({
            sortColumn: key,
            sortDirection: sort.sortColumn === key ? (sort.sortDirection === 1 ? 0 : 1) : 0
        });
    };

    useEffect(() => {
        setFilters(x => ({...x, quickfilter: props.quickfilter ?? x.quickfilter}));
    }, [props.quickfilter]);

    const applySearch = (keyword: string) => {
        setFilters(x => ({...x, textSearch: keyword  }));
    };    

    const value = new InvoiceTableContextValues(() => getFilters(currentPage, filters));
    value.loading = loading;
    value.error = error;
    value.response = response;
    value.currentPage = currentPage;
    value.pageSize = pagesize;
    value.setCurrentPage = setCurrentPage;
    value.pageCount = pageCount;
    value.reload = requestData;
    value.applyFilters = applyFilters;
    value.sort = sort;
    value.setSortColumn = setSortColumn;
    value.setPageSize = setPagesize;
    value.applySearch = applySearch;
    return (
        <TableContext.Provider value={value}>
            {props.children}
        </TableContext.Provider>
    );
};

export default InvoiceTableProvider;
