import { useMemo } from "react";
import CompanyService, { DataType, Entities } from "../../../../services/CompanyService";
import { TranslationService } from "../../../../services/TranslationService";
import Dropdown, { DropdownItems, DropdownProps } from "../../../shared/components/Dropdown";
import { ValidationMessage } from "../../../shared/RequieredManager";
import { defaultProps, EditProps, FieldKey, strEvent } from "../EditAction";
import { SendToOptions, CarbonCopySendToOptions } from "./EmailComponents";
import { Field } from "./FieldHelpers";

type validFields = "bcc" | "cc" | "To" | "Notify";
const datakeys: { [key in validFields]: [FieldKey<string>, FieldKey<number | null>, FieldKey<number>] } = {
    bcc: ["Mail_bcc", "Mail_BCCType", "Mail_BCCField"],
    cc: ["Mail_cc", "Mail_CCType", "Mail_CCField"],
    To: ["Mail_To", "Mail_ToType", "Mail_ToTypeField"],
    Notify: ["NotifyToEmail", "NotifyTo", "Mail_ToTypeField"],
}

export const EmailInput = ({ data, field, setField, items, title, propGen }:
    {
        data: EditProps["data"], field: validFields, setField: EditProps["setField"],
        items: DropdownProps<number>["items"], title: string | JSX.Element,
        propGen: EditProps["propGen"]
    }) => {
    const [emailKey, typeKey, fieldKey] = datakeys[field];
    const type = data[typeKey] ?? SendToOptions.Customer;
    const customerFields: DropdownItems<number> = useMemo(() =>
        [{ text: TranslationService.translate.Email, value: -1 },
        ...CompanyService.getAdditionalDefinitions()
            .filter(x => x.type === DataType.Text && x.entity === Entities.Client)
            .map(x => ({ text: x.Name, value: x.AdditionalDefinitionID }))], []);

    const emailProps = type === CarbonCopySendToOptions.EmailAddress ?
        propGen.str.required(emailKey) : {} as defaultProps<string>;
    return (
        <>
            <Field title={title}>
                <Dropdown items={items} {...propGen.num.notRequired(typeKey)} />
            </Field>
            {type === CarbonCopySendToOptions.Customer &&
                emailKey !== "NotifyToEmail" &&
                <Field title={TranslationService.translate.ReadEmailFrom}>
                    <Dropdown items={customerFields} defaultValue={data[fieldKey] ?? -1}
                        onChange={setField(fieldKey)} />
                </Field>
            }
            {type === CarbonCopySendToOptions.EmailAddress &&
                <Field title={TranslationService.translate.Email}>
                    <input className="form-control" {...strEvent(emailProps)} />
                    <ValidationMessage {...emailProps} />
                </Field>
            }
        </>
    )
}