import { useContext, useState } from 'react';
import Switch from 'react-switch';
import { TranslationService } from '../../../../services/TranslationService';
import { CompanySettings } from '../../../../entities/company/Company/CompanySettings';
import CompanyService from '../../../../services/CompanyService';
import ConfigurationContext from '../../ConfigurationContext';
import Loading from '../../../shared/components/Loading';

type ToggleEnableActionWrapperType = {
    title: string
    initialValue: 'False' | 'True'
    initialValueCustom?: boolean
    action?: keyof CompanySettings
    loadingListener?: boolean
    invertValue?: boolean
    fullSize?: boolean
    onChangeCustom?: (
        valUpdate: boolean,
        changeValueUpdate: (val: boolean) => void,
        changeLoadingListener: (val: boolean) => void) => void
}

export type TF = 'False' | 'True'

export const convertBool = {
    'False': false,
    'True': true
}

export const ToggleEnableActionWrapper = ({ title, action, initialValue, initialValueCustom, loadingListener = false, invertValue = false, fullSize = false, onChangeCustom }: ToggleEnableActionWrapperType) => {
    const [loading, setLoading] = useState(false)
    const [valueChecked, setvalueChecked] = useState<boolean>(initialValueCustom || invertValue ? !convertBool[initialValue] : convertBool[initialValue]);
    const { refreshConfigValues } = useContext(ConfigurationContext);

    const handleCheckedChange = (valueUpdated: boolean) => {

        if (onChangeCustom) {
            onChangeCustom(valueChecked, setvalueChecked, setLoading)

        } else {
            let valueCalculated: boolean | TF = invertValue ? !valueUpdated : valueUpdated
            valueCalculated = valueCalculated ? 'True' : 'False'

            loadingListener && setLoading(true)
            setvalueChecked(valueUpdated)

            action && CompanyService.setConfigCompany({ [action]: valueCalculated })
                .finally(async () => {
                    await refreshConfigValues()
                    loadingListener && await setLoading(false)
                })
        }
    }

    return loading ? <Loading /> : (
        <div className={`d-flex ${fullSize ? '' : 'col-md-8'} justify-content-between align-items-center`}>
            <td className="col-auto py-0"><div className="configuration-display-item">{TranslationService.getTranslation(title)}</div></td>
            <td className="py-0"><Switch checked={valueChecked} onChange={handleCheckedChange} height={24} width={50} /></td>
        </div>
    )
}

