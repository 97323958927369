import { FloatingPanelFooter } from '../../shared/components/FloatingPanelFooter';
import { TranslationService } from '../../../services/TranslationService';
import { TextAreaBigEditor } from '../../shared/components/Editors';
import TextInput from './TextInput';
import FieldEdit from '../../client/invoice/InvoiceMassiveChangesFieldEdit';

export const SendMessageCompose = ({ dataFields, ignoreFields }: any) => {

  return (
    <>
      <div className="tabBody">


        {!ignoreFields && dataFields.map((field: any) =>
          <FieldEdit
            key={field.id}
            field={field}
            onChange={() => console.log(field)}
            personId={'1'}
          />
        )}

        {!ignoreFields && <hr />}

        <div className={"d-flex input-column mb-3"}>
          <label className="form-label">{TranslationService.translate.Mail}</label>
          <TextInput />
        </div>


        <div className={"d-flex input-column mb-3"}>
          <label className="form-label">{TranslationService.translate.Message}</label>
          <TextAreaBigEditor extraStyle={{ height: 200 }} onChange={() => { }} />
        </div>

        Adjuntar archivo

      </div >
      <FloatingPanelFooter>
        <button className='btn btn-primary me-3'>
          {TranslationService.translate.Send}
        </button>
      </FloatingPanelFooter>
    </>
  )
}
