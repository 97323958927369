import { IOStatusEntity } from "../entities/company/IOStatus/IOStatusEntity";
import { IOStatusGetResponse } from "../entities/company/IOStatus/IOStatusGetResponse";
import { IOStatusListResponse } from "../entities/company/IOStatus/IOStatusListResponse";
import RequestService from "./RequestService";

class IOStatusService {

    public static get(id: number): Promise<Error | IOStatusGetResponse> {
        return RequestService.post("/iostatus/get", { id });
    }

    public static set(activitytype: IOStatusEntity): Promise<Error | unknown> {
        return RequestService.post("/iostatus/set", { item: activitytype });
    }

    public static delete(id: number): Promise<Error | unknown> {
        return RequestService.post("/iostatus/delete", { id });
    }

    public static getList(): Promise<Error | IOStatusListResponse> {
        return RequestService.post("/iostatus/list", {});
    }
}


export default IOStatusService;