import { SubscriptionResponse } from "../pages/account/entities/AccountResponse";
import RequestService from "./RequestService";

type Culture = "es" | "en" | "pt";

export type Tax = {
    name: string,
    address: string,
    taxNumber: string
}

export type DataUser = {
    firstName?: string,
    lastName?: string,
    email?: string,
    password?: string,
    currentPassword?: string,
    repeatPassword?: string
}

export type DataSignature = {
    signature: string
}

export type DataSignatureImage = {
    id: string
}

export type DataCulture = {
    culture: string
}

export type DataTimeZone = {
    offset: number
}

export type AccountSettingsRequest = {
    Key: keyof AccountSettings,
    Value: boolean
}

export type AccountConfigsResponse = {
    configs: AccountConfigsType[]
}

export type AccountSignatureTextResponse = {
    "signature": string
}


export type AccountConfigsType = {
    name: string,
    value: string | number
}

export type AccountSettings = {
    weekendcheck?: boolean,
    weeklycheck?: boolean,
    dailymailduescheck?: boolean,
    dailymailtaskscheck?: boolean,
}

export type SignatureResponse = {
    item?: SignatureItemResponse
}

type SignatureItemResponse = {
    SignatureAttachments: any[],
    SignatureID: number,
    UserID: number,
    Content: string,
    Image: true,
    SignatureID_ref: null
}



export enum USER_ACCOUNT_RESPONSE {
    OK = 'OK',
    Ok = 'Ok',
    INVALID_PASSWORD = 'invalid-password',
    WEEK_PASSWORD = 'weak-password',
    INVALID_TOKEN = 'invalidtoken',
    USER_DELEGATED = 'userdelegated',
    OLD_PASSWORD = 'old-password',
    WAIT_PASSWORD = 'wait-password',
    FAIL_MAIL = 'fail-mail',
}
class AccountService {
    public static setCulture(culture: Culture) {
        return RequestService.post("/account/setculture", { culture });
    }

    public static getSubscription(): Promise<SubscriptionResponse | Error> {
        return RequestService.post("/account/getsubscription", {});
    }

    public static getUsersByPlan(): Promise<any | Error> {
        return RequestService.post("/account/getusersbyplan", {});
    }

    // public static getInvoices(pageData: PageDataRequest): Promise<GetInvoicesResponse | Error> {
    //     return RequestService.post("/account/getinvoices", pageData);
    // }

    public static getTaxesDataUser(): Promise<any | Error> {
        return RequestService.post("/account/getTax", {});
    }

    public static setTaxesDataUser(tax: Tax): Promise<any | Error> {
        return RequestService.post("/account/setTax", tax);
    }

    public static setUserAccount(dataUser: DataUser): Promise<any | Error> {
        return RequestService.post("/account/setUserAccount", dataUser);
    }

    public static getAccountSignature(): Promise<SignatureResponse | Error> {
        return RequestService.post("/account/getsignature", {});
    }

    public static deleteAccountSignature(): Promise<any | Error> {
        return RequestService.post("/account/clearsignatureimage", {});
    }

    public static setAccountSignatureImage(data: DataSignatureImage): Promise<any | Error> {
        return RequestService.post("/account/setsignatureimage", data);
    }

    public static setAccountSignature(data: DataSignature): Promise<any | Error> {
        return RequestService.post("/account/setsignature", data);
    }

    public static setAccountCulture(data: DataCulture): Promise<any | Error> {
        return RequestService.post("/account/setculture", data);
    }

    public static setAccountTimeZone(data: DataTimeZone): Promise<any | Error> {
        return RequestService.post("/account/settimezone", data);
    }

    public static setAccountConfig(accountSettings: AccountSettingsRequest): Promise<any | Error> {
        return RequestService.post("/account/setconfig", accountSettings);
    }

    public static getAccountConfig(): Promise<AccountConfigsResponse | Error> {
        return RequestService.post("/account/getconfigs", {});
    }

    public static getAccountSignatureText(): Promise<AccountSignatureTextResponse | Error> {
        return RequestService.post("/account/getsignaturetext", {});
    }

}

export default AccountService;