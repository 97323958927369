import CompanyService from "../../../services/CompanyService";
import { TranslationService } from "../../../services/TranslationService";
import { CultureData } from "./CultureData";
import { EmailsNotificationsData } from "./EmailsNotificationsData";
import { SignData } from "./SignData";

export const CompanyConfiguration = () => {
    return (<div className="main-layout-container mx-3">
        <div className="main-panel">
            <div className="card">
                <h2 className="my-3">{TranslationService.translate.CompanySettingsProfile.replace("{1}", CompanyService.getCompanyName())}</h2>
                <div className="card-body">
                    <SignData />
                </div>
                <CultureData />
                <h2 className="my-3">{TranslationService.translate.EmailNotifications}</h2>
                <div className="card-body">
                    <EmailsNotificationsData />
                </div>
            </div>
        </div>
    </div >);
};