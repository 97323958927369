import { useEffect, useRef, useState } from "react";
import FileService from "../../../services/FileService";
import { TranslationService } from "../../../services/TranslationService";
import { ToastService } from "../../shared/bootstrap/Toast";
import CompanyService from "../../../services/CompanyService";
import { handleErrorWithToast } from "../../../utils/RequestUtils";
import AuthenticationService from "../../../services/AuthenticationService";
import { getCache } from "../../../utils/PersistenceUtils";
import { IAuthenticationData, AUTHENTICATION_DATA_STORAGE_KEY } from "../../shared/AuthenticationContext";
import Collapsable from "../../shared/bootstrap/Collapsable";
import UserService from "../../../services/UserService";
import Loading from "../../shared/components/Loading";
import { Cast } from "../../../utils/Utils";

const DataCompanyConfiguration = () => {
    const [imgSrc, setImgSrc] = useState(CompanyService.getCompanyAuth()?.logoUrl);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const newFile = useRef<Promise<undefined | string> | undefined>();
    const newName = useRef<string>();
    const [saving, setSaving] = useState(false);
    const [showDeleteCompany, setShowDeleteCompany] = useState<boolean>();

    const onFileChange = async (fileList: FileList | null) => {
        const uploadFile = async (fileList: FileList | null) => {
            const fileUpload = async (file: File) => {
                const result = await FileService.upload(file);
                if (result instanceof Error) {
                    ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
                    return result;
                }
                return { id: result.id, fileName: result.fileName };
            };
            if (!(fileList?.length)) {
                setImgSrc(undefined);
                newFile.current = Promise.resolve(undefined);
                return undefined;
            }
            const file = fileList[0];
            setImgSrc(URL.createObjectURL(file));
            const result = await fileUpload(file);
            if (result instanceof Error) {
                ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
                return undefined;
            }
            return result.id;
        };
        newFile.current = uploadFile(fileList);
    };

    useEffect(() => {
        const getData = async () => {
            const list = await UserService.getList({ page: 0, pagesize: 1000, text: null });
            if (!list || list instanceof Error) {
                setShowDeleteCompany(false);
                return;
            }
            const result = list.users.find(x => x.UserID.toString() === CompanyService.getUserid());
            if (!result) {
                setShowDeleteCompany(false);
                return;
            }
            if (UserService.getRole(Cast<Parameters<typeof UserService.getRole>[0]>(result)) === "Creator") {
                setShowDeleteCompany(true);
            } else {
                setShowDeleteCompany(false);
            }
        };
        getData();
    }, []);

    const onSave = async () => {
        const fileId = await newFile.current;
        setSaving(true);
        const saveImage = newFile.current ? handleErrorWithToast(CompanyService.changeCompanyLogo((fileId ?? null) as string)) : Promise.resolve();
        const saveName = newName.current ? handleErrorWithToast(CompanyService.changeCompanyName(newName.current)) : Promise.resolve();
        await saveImage;
        await saveName;
        await AuthenticationService.refreshToken({ refreshToken: getCache<IAuthenticationData>(AUTHENTICATION_DATA_STORAGE_KEY)!, refreshlists: 1 });
        window.location.reload();
        setSaving(false);
    };

    if (showDeleteCompany === undefined) {
        return <Loading height={238} />;
    }

    return (
        <div className="card px-5">
            <div className="d-flex justify-content-between my-4">
                <h2>{TranslationService.translate.Company}</h2>
                <button className="btn btn-primary" type="button" onClick={onSave}>{TranslationService.translate.SaveChanges} {saving && <i className="fas fa-spinner-third fa-spin third ms-2" />}</button>
            </div>
            <form className="mb-3">
                <div className="row">
                    <div className="col-sm-3">
                        <label>{TranslationService.translate.CompanyName}</label>
                        <input type="text" className="form-control w-75" defaultValue={CompanyService.getCompanyName()} onChange={(x) => newName.current = x.target.value}></input>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-column">
                            <label>{imgSrc ? TranslationService.translate.CompanyLogo : TranslationService.translate.YouHaveNotUploadedYourCompanyLogoYet}
                                <input type="file" className="d-none" accept=".jpg,.jpeg,.png" ref={inputRef} onChange={(e) => onFileChange(e.target.files)}></input>
                                {!imgSrc && <div className="row">
                                    <div className="col">
                                        <button className="btn btn-link px-0" type="button" onClick={() => inputRef.current?.click()}>{TranslationService.translate.UploadCompanysLogo}</button>
                                    </div>
                                </div>}
                                {imgSrc &&
                                    <div className="d-flex gap-3">
                                        <div className="companyLogo">
                                            <img className="" src={imgSrc} alt={TranslationService.translate.CompanyLogo} />
                                        </div>
                                        <div className="d-flex flex-column align-items-start">
                                            <button className="btn btn-link px-0" type="button" onClick={() => inputRef.current?.click()}>
                                                <i className="fa-regular fa-edit me-2"></i>{TranslationService.translate.ChangeLogo}
                                            </button>
                                            <button className="btn btn-link text-danger px-0" onClick={e => { e.preventDefault(); e.stopPropagation(); onFileChange(null); }}>
                                                <i className="fa-regular fa-times me-2" style={{ width: 16 }}></i>{TranslationService.translate.RemoveLogo}
                                            </button>
                                        </div>
                                    </div>}
                            </label>
                        </div>
                    </div>
                </div>
            </form>
            <Collapsable show={Boolean(showDeleteCompany)}>
                <hr />
                <div>
                    <button className="btn btn-link ps-0" onClick={() => CompanyService.deleteCompany()} >{TranslationService.translate.DeleteCompany}</button>
                    <button className="btn btn-link" onClick={() => CompanyService.resetAll()}>{TranslationService.translate.DeleteCompanyInformation}</button>
                    <button className="btn btn-link" onClick={() => CompanyService.resetIo()}>{TranslationService.translate.DeleteInvoiceAndPaymentsOnly}</button>
                </div>
            </Collapsable>
        </div>
    );
};

export default DataCompanyConfiguration;
