import { TranslationService } from "../../../../services/TranslationService";
import { getEnumArray } from "../../../../utils/EnumUtils";
import TooltipComponent from "../../../shared/TooltipComponent";
import { EditProps } from "../EditAction";
import { LetterSendMethods } from "./EmailComponents";
import { LetterSendMethodOptions } from "./LetterSendMethodOptions";

export const LetterSendMethod = (props: EditProps) => {

    return (<>

        <div className="row">
            <LetterSendMethodOptions {...props} field="To" items={getEnumArray(LetterSendMethods)}
                title={
                    <>
                        {TranslationService.translate.SendMethod}
                        <TooltipComponent placement="right" title={TranslationService.translate.TipSendLetterTo}>
                            <i className="fal fa-question-circle ms-2" />
                        </TooltipComponent>
                    </>
                }
            />
        </div >
    </>);
}