import { useCallback, useState } from "react";
import { TranslationService } from "../../../services/TranslationService";
import { RrsBaseStructure } from "./RrsBaseStructure";
import { useEndpointData } from "../../../utils/TableUtils";
import { ReportReceivablesSummaryService } from "../../../services/ReportReceivablesSummaryService";
import Loading from "../../shared/components/Loading";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { ReportReceivablesResponse } from "../../../entities/reports/ReportReceivablesSummary/ReceivablesResponse";
import moment from "moment";
import { formatInteger } from "../../../utils/FormatUtils";

export const RrsOverdueDebt = ({ filter, receivables }: { filter: string, receivables: ReportReceivablesResponse | undefined }) => {
    const [monthCount, setMonthCount] = useState(6);
    const toggleStartDate = () => {
        setMonthCount(x => 18 - x);
    }
    const endpoint = useCallback(() => ReportReceivablesSummaryService.getDuePercentageMonthly(filter, moment().add(-12, "months").endOf("month").toDate()), [filter]);
    const { loading, response } = useEndpointData(endpoint);
    if (loading || response === undefined || receivables === undefined) {
        return <Loading height={287} />;
    }
    const LeftSide = () =>
        <div className="dashboard__sales d-flex flex-column">
            <div className="row align-items-center">
                <div className="col-auto dashboard__total">
                    <div className="card-body">
                        <div className="card-header mb-1">
                            <p>{TranslationService.translate.OverdueDebtPorcentage}</p>
                        </div>
                    </div>
                </div>
                <div className="col-auto">
                    <button className="btn btn-primary" onClick={toggleStartDate}>{TranslationService.translate.ShowXMonths.replace("{count}", (18 - monthCount).toString())}</button>
                </div>
            </div>
            <HighchartsReact highcharts={Highcharts} options={ReportReceivablesSummaryService.chartPieOptions(receivables.due, receivables.nodue)} />
        </div>;
    return (<RrsBaseStructure UnderTitle={LeftSide} highchartsOptions={ReportReceivablesSummaryService.chartLineOptions(response.data[0], response.data[1], monthCount, undefined, undefined, function () { return formatInteger(parseFloat(this.value.toString())) + "%" })} />);
}

