import { Route, Switch } from "react-router";
import ReportActivity from "../reports/ReportActivity";
import ReportActivitySummary from "../reports/ReportActivitySummary";
import ReportAgingGroup from "../reports/ReportAgingGroup";
import ReportExport from "../reports/ReportExport";
import ReportInvoice from "../reports/ReportInvoice";
import ReportInvoiceGroup from "../reports/ReportInvoiceGroup";
import ReportPayment from "../reports/ReportPayment";
import TabPosition from "./TabPosition";
import ReportClientMap from "../reports/ReportClientMap";
import ReportPaymentAnalysis from "../reports/ReportPaymentAnalysis";
import { ReportCollection } from "../reports/ReportCollection";
import ReportHistoryClientList from "../reports/ReportHistoryClientList";
import ReportHistoryIoList from "../reports/ReportHistoryIoList";
import ReportIoActivityList from "../reports/ReportIoActivityList";
import { ReportScheduledList } from "../reports/ReportScheduledList";
import ReportCustomerAnalysis from "../reports/ReportCustomerAnalysis";
import ReportReceivablesSummary from "../reports/ReportReceivablesSummary";
import { ReportCredit } from "../reports/ReportCredit";
import { ReportClientGroup } from "../reports/ReportClientGroup";
import { ReportActivityTypeSummary } from "../reports/ReportActivityTypeSummary";
import { ReportLog } from "../reports/ReportLog";
import { ReportLogMain } from "../reports/ReportLogMain";
import { ReportTagList } from "../reports/ReportTagList";
import { ReportPaymentGroup } from "../reports/ReportPaymentGroup";
import { AdvancedFiltersProvider } from "./components/AdvancedFilters";
import ErrorBoundary from "./ErrorBoundary";
import { RouteProps, useLocation } from "react-router-dom";

const ReportRoutes = () => {
    return (
        <TabPosition module="Reports">
            <Switch>
                <IsolatedRoute path="/:lang/report/iolist" component={ReportInvoice} />
                <IsolatedRoute path="/:lang/report/historyclientlist" component={ReportHistoryClientList} />
                <IsolatedRoute path="/:lang/report/historyiolist" component={ReportHistoryIoList} />
                <IsolatedRoute path="/:lang/report/ioactivity" component={ReportIoActivityList} />
                <IsolatedRoute path="/:lang/report/scheduled" component={ReportScheduledList} />
                <IsolatedRoute path="/:lang/report/client" component={ReportCustomerAnalysis} />
                <IsolatedRoute path="/:lang/report/payment" component={ReportPayment} />
                <IsolatedRoute path="/:lang/report/reportexport" component={ReportExport} />
                <IsolatedRoute path="/:lang/report/reportactivity" component={ReportActivity} />
                <IsolatedRoute path="/:lang/report/persongroup" component={ReportClientGroup} />
                <IsolatedRoute path="/:lang/report/invoicegroup" component={ReportInvoiceGroup} />
                <IsolatedRoute path="/:lang/report/activitytypesummary" component={ReportActivityTypeSummary} />
                <IsolatedRoute path="/:lang/report/reportActivitySummary" component={ReportActivitySummary} />
                <IsolatedRoute path="/:lang/report/ReportAgingGroup" component={ReportAgingGroup} />
                <IsolatedRoute path="/:lang/report/credit" component={ReportCredit} />
                <IsolatedRoute path="/:lang/report/ReportClientMap" component={ReportClientMap} />
                <IsolatedRoute path="/:lang/report/collection" component={ReportCollection} />
                <IsolatedRoute path="/:lang/report/ReportPaymentAnalysis" component={ReportPaymentAnalysis} />
                <IsolatedRoute path="/:lang/report/ReportReceivablesSummary" component={ReportReceivablesSummary} />
                <IsolatedRoute path="/:lang/report/log" component={ReportLog} />
                <IsolatedRoute path="/:lang/report/logmain" component={ReportLogMain} />
                <IsolatedRoute path="/:lang/report/taglist" component={ReportTagList} />
                <IsolatedRoute path="/:lang/report/paymentgroup" component={ReportPaymentGroup} />
            </Switch>
        </TabPosition>);
};

const IsolatedRoute = (props: RouteProps) => {
    return (
        <Route path={props.path}>
            <AdvancedFiltersProvider key={props.path?.toString()}>
                <RouteBoundary component={props.component} />
            </AdvancedFiltersProvider>
        </Route>);
};

const RouteBoundary = ({ component }: RouteProps) => {
    const Component = component as (() => JSX.Element);
    const location = useLocation();
    return (
        <ErrorBoundary location={location.pathname + location.search}>
            <Component />
        </ErrorBoundary>);
};
export default ReportRoutes;