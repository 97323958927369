import React, { useCallback, useState } from "react";
import TableContext, { TableContextValues } from "../../task/TableContext";
import { applyFiltersHelper, setCurrentPageHelper, setPageSizeHelper, sortHelper, useEndpointData } from "../../../utils/TableUtils";
import PortalClientService, { PortalClientDataRequest } from "../../../services/PortalClientService";

export class PortalClientTableContextValues extends TableContextValues<any> {
    request: PortalClientDataRequest = new PortalClientDataRequest();
    setRequest: (request: PortalClientDataRequest) => void = () => { };
}

const PortalClientTableProvider = ({ token, prevRequest, children }: React.PropsWithChildren<{ token: string, prevRequest: PortalClientDataRequest }>) => {
    const [request, setRequest] = useState(prevRequest || new PortalClientDataRequest());
    const endpoint = useCallback(() => PortalClientService.getIoList(request, token), [request, token]);
    const { loading, error, response, reload } = useEndpointData(endpoint);
    const { sort, setSortColumn } = sortHelper(request, setRequest);
    const applySearch = (keyword: any) => {
        setRequest({ ...request, filter: keyword })
    };
    const value: PortalClientTableContextValues =
    {
        loading,
        error,
        response,
        setSortColumn,
        currentPage: request.page,
        setCurrentPage: setCurrentPageHelper(setRequest, 1),
        // todo implementar paginado del backend
        pageCount: 1,
        applyFilters: applyFiltersHelper(setRequest),
        applySearch,
        reload,
        sort,
        pageSize: request.pagesize,
        setPageSize: setPageSizeHelper(setRequest),
        request,
        setRequest,
    }

    return (
        <TableContext.Provider value={value} >
            {children}
        </TableContext.Provider>
    )
}

export default PortalClientTableProvider; 