import { useContext, useEffect, useRef, useState } from "react";
import CompanyService, { Entities, DataType } from "../../services/CompanyService";
import FilterService, { FilterCollection } from "../../services/FilterService";
import { InvoiceList } from "../client/invoice/entities/InvoiceListResponse";
import { TableColumn } from "../client/invoice/InvoiceTableItem";
import AdvancedFilters, { AdvancedFiltersButton } from "../shared/components/AdvancedFilters";
import Table, { TableHeader, TableHeaderWithFieldId } from "../shared/Table";
import { Link, useLocation } from "react-router-dom";
import TableContext from "../task/TableContext";
import TransactionTableProvider, { TransactionTableContextValues } from "../transaction/TransactionTableProvider";
import ClientService from "../../services/ClientService";
import { TransactionListResponse } from "../transaction/entities/TransactionListResponse";
import InvoiceDetail from "../client/invoice/InvoiceDetail";
import FloatingPanelService from "../shared/FloatingPanel";
import { TranslationService } from "../../services/TranslationService";
import { formatCurrency, formatDateShort, formatInteger, formatNumber } from "../../utils/FormatUtils";
import { CurrencyTotal } from "../../entities/shared/CurrencyTotal";
import { Cast, OptionalMap } from "../../utils/Utils";
import { ActivityContent, ActivityTypeName } from "./ReportActivity";
import { ActivityListResponse } from "../client/activity/entities/AcitivityListResponse";
import { parseDate } from "../../utils/ParseUtils";
import ModalService from "../shared/bootstrap/Modal";
import FileService from "../../services/FileService";
import TranasactionListRequest from "../transaction/entities/TransactionListRequest";
import TransactionService from "../../services/TransactionService";
import MenuButton, { MenuButtonActions } from "../shared/components/MenuButton";

const ReportPayment = () => {
    const { search } = useLocation<{ prevRequest?: TranasactionListRequest } | undefined>();
    const urlParams = new URLSearchParams(search);
    const filterQS = urlParams.get("filter") ? [...urlParams.entries()].map((x, i) => "filter" + i.toString() + "=" + x[1].replace("filter0=", "")).join("&") : "";

    const tableHeaders = getTableHeaders();

    return (
        <TransactionTableProvider defaultFilter={FilterService.ParseExtraFiltersResponseString(filterQS)}>
            <div className="container-fluid padding">
                <div className="d-flex justify-content-between">
                    <h2 className="mb-3">{TranslationService.translate.Payments}</h2>
                </div>
                <div className="card mh-100 p-0">
                    <div className="genericHeader">
                        <div className="searcherFilterHeader align-items-center">
                            <ExportButton ReportTypeId={5} />
                            <AdvancedFiltersButton/>
                        </div>
                    </div>
                    <div className="pt-0">
                        <AdvancedFilters page={FilterCollection.ReportPayment} defaultValue={filterQS} />
                    </div>
                    <div className="p-4">
                        <Table headers={tableHeaders} item={ReportInvoiceItem} stickyHeader={true} >
                            <ReportPaymentTotal tableHeaders={tableHeaders}></ReportPaymentTotal>
                        </Table>
                    </div>

                </div>
            </div>
        </TransactionTableProvider>);
};

export const ReportInvoiceItem = ({ data }: { data: InvoiceList.Item }) => {
    const currentLanguage = TranslationService.currentLanguage;
    const { reload } = useContext(TableContext);
    const tableHeaders = getTableHeaders();
    const specialCases = {
        "Name": () => (<td className="is-link">
            <span className="w-md">
                <Link to={`/${currentLanguage}/client/details?id=${data["PersonId" as "personId"]}`}>
                    {Cast<{ Name: string }>(data).Name}
                </Link>
            </span>
        </td>),
        "iostatus": () => (<td className="is-link">
            <span className="w-md">
                {CompanyService.getIoStatus().find(x => x.IOStatusID === Cast<TransactionListResponse.Item>(data).io.IOStatusID)?.Value ?? CompanyService.getNormalIOStatusName()}
            </span>
        </td>),
        "IOId": (x: TransactionListResponse.Item) => (<td className="is-link">
            <span className="w-md pointer txt-blue" onClick={() => FloatingPanelService.showPanel(
                {
                    children: <InvoiceDetail invoiceId={x.IOId} reload={() => { reload(); }} canEdit={false} />,
                    title: TranslationService.translate.Invoice,
                    width: 750,
                    height: 800,
                }
            )}>
                {data.referenceNumber}
            </span>
        </td>),
        "Amount": (x: TransactionListResponse.Item) => <td className="text-end">{x.CurrencySymbol} {(CompanyService.getCompanyAuth()?.ShowCents ? formatNumber : formatInteger)(x.Amount)}</td>,
        "activity-type-gestion": (x: TransactionListResponse.Item) => x.Activity ? ActivityTypeName(Cast<ActivityListResponse.Item>(x.Activity)) : <td></td>,
        "activity-date": (x: TransactionListResponse.Item) => (OptionalMap(parseDate(x.Activity?.date), y => <td>{formatDateShort(y)}</td>) ?? <td></td>),
        "activity-content": ActivityContentTableData,
        "reciept": (x: TransactionListResponse.Item) => <td onClick={() => x.ReceiptID && FileService.downloadReceipt(x.ReceiptID.toString())}>{x.ReceiptID && <i className={"fa-light fa-fw fa-file-arrow-down pointer fa-lg"} />}</td>
    };
    return (<tr key={data.IOID} >
        {tableHeaders.map(header => <TableColumn key={header.fieldId} data={data} header={header} specialCases={specialCases} />)}
    </tr >);
}

export function ActivityContentTableData(x: TransactionListResponse.Item) {
    const renderedDiv = useRef<HTMLTableCellElement | null>(null);
    const [show, setShow] = useState(false);
    const maxHeight = 70;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (!renderedDiv.current) {
            return;
        }
        if (!show && (renderedDiv.current.clientHeight ?? 0) > maxHeight) {
            setShow(true);
        } else if (show && (renderedDiv.current.clientHeight) <= maxHeight) {
            setShow(false);
        }
    });
    return (<td style={{ maxWidth: 250, minWidth: 250, display: show ? "block" : undefined }}>
        <div style={{ maxWidth: 250, minWidth: 250, maxHeight, display: show ? "block" : undefined, overflowY: "hidden" }}>
            {x.Activity ? <ActivityContent item={Cast<ActivityListResponse.Item>(x.Activity)} ref={renderedDiv} /> : <td></td>}
        </div>
        {(show && (renderedDiv.current?.clientHeight ?? 0) > maxHeight) ? <button className="btn btn-link ps-0" onClick={() => ModalService.showDefaultModal({
            title: TranslationService.translate.Content,
            message: () => <ActivityContent item={Cast<ActivityListResponse.Item>(x.Activity)} ref={renderedDiv} />,
        })}>{TranslationService.translate.SeeMore}</button> : <span>&nbsp;</span>}
    </td>);
}

export const ReportPaymentTotal = ({ tableHeaders }: { tableHeaders: TableHeaderWithFieldId[] }) => {
    const response = useContext(TableContext).response as TransactionListResponse;
    return (<>
        {response && tableHeaders && response.currencyTotals.map((totals, index) =>
            <tr key={index} className={"font-weight-bold"} style={{ backgroundColor: (index % 2 === 0) ? "#ddedf9" : "#e4f4ff", }}>
                {tableHeaders.map(header => <TotalColumn key={header.fieldId} currencyTotal={totals} header={header} index={index} />)}
            </tr>
        )}
    </>)
}

const TotalColumn = ({ currencyTotal, header }: { currencyTotal: CurrencyTotal, header: TableHeaderWithFieldId, index: number }) => {
    switch (header.type) {
        case DataType.Currency:
            return <td className={"text-end"}>{formatCurrency(currencyTotal.value, currencyTotal.currencyid)}</td>;
        default:
            return <td>{ }</td>;
    }
}

export const ExportButton = ({ ReportTypeId = 1 }: { ReportTypeId?: number }) => {
    const { getFilters, response } = useContext(TableContext) as TransactionTableContextValues;
    const exportInvoices = (reportId: number | null) => {
        TransactionService.export({ ...getFilters(), reid: reportId }, response!.itemCount);
    };
    const exportItems: MenuButtonActions = [
        {
            text: TranslationService.translate.Default,
            action: () => exportInvoices(null),
        },
        ...CompanyService.getReportExports()
            .filter(x => x.Report === ReportTypeId)
            .map(x => ({ text: x.Name ?? TranslationService.translate.Default, action: () => exportInvoices(x.ReportExportID) })),
    ];
    return (<div className="actionButtonsHeader">
        {CompanyService.canDo("export") && <MenuButton text={TranslationService.translate.ExportToXls} actions={exportItems} extraClassName="select-export" icon="fa-light fa-arrow-down-to-line pe-1 text-granite-gray" />}
    </div>)
}
function getTableHeaders() {
    const clientFields = ClientService.getClientHeaders();
    clientFields.forEach(x => x.sorteable = x.fieldId === "Name");
    const translate = TranslationService.translate;
    const paymentFixedFields: TableHeaderWithFieldId[] = [
        {
            ...new TableHeader(
                "date",
                translate.Date,
                false,
                true
            ),
            fieldId: "Date",
            type: DataType.Date,
        },
        {
            ...new TableHeader(
                "amount",
                translate.Amount,
                true,
                true
            ),
            fieldId: "Amount",
            type: DataType.Currency,
        },
        {
            ...new TableHeader(
                "io",
                translate.Invoice,
                false,
                true,
                "long-text"
            ),
            fieldId: "IOId",
            type: DataType.Text,
        },
        {
            ...new TableHeader(
                "iostatus",
                translate.IOStatus,
                false,
                true,
                "long-text"
            ),
            fieldId: "iostatus",
            type: DataType.Text,
        },
        {
            ...new TableHeader(
                "lastname",
                translate.User,
                false,
                true
            ),
            fieldId: "Fullname",
            type: DataType.Text,
        },
        {
            ...new TableHeader(
                "paymenttype",
                translate.PaymentType,
                false,
                true
            ),
            fieldId: "TransactionType",
            type: DataType.Text,
        },
        {
            ...new TableHeader(
                "transactiontype",
                translate.Receipt,
                false,
                false,
            ),
            fieldId: "ReceiptNumber",
            type: DataType.Text,
        },
    ];
    const additionalFields = CompanyService.getAdditionalDefinitions()
        .filter(x => x.Entity === Entities.Payment)
        .map(x => ({
            ...new TableHeaderWithFieldId(
                "ioadditional." + (x.Type === DataType.List ? "list" : "text") + "field" + x.Field,
                x.Name,
                [DataType.Currency, DataType.Number].includes(x.Type),
                true,
                x.AdditionalDefinitionID.toString(),
                x.Type
            ),
        }));
    const activityFixedFields: TableHeaderWithFieldId[] = [
        {
            ...new TableHeader(
                "comments",
                translate.Observations,
                false,
                false,
            ),
            fieldId: "TransactionComments",
            type: DataType.Text,
        },
        ...(!CompanyService.getSetting("includelastlogtransaction") ? [] : [{
            ...new TableHeader(
                "activity",
                translate.Activity,
                false,
                false,
            ),
            fieldId: "activity-type-gestion",
            type: DataType.Text,
        },
        {
            ...new TableHeader(
                "activity-date",
                translate.Date,
                false,
                false,
            ),
            fieldId: "activity-date",
            type: DataType.Date,
        },
        {
            ...new TableHeader(
                "activity-type",
                translate.ActivityType,
                false,
                false,
            ),
            fieldId: "activityTypeName",
            type: DataType.Text,
        },
        {
            ...new TableHeader(
                "activity-content",
                translate.Content,
                false,
                false,
            ),
            fieldId: "activity-content",
            type: DataType.Text,
        },])
    ];
    const fields = [...clientFields, ...paymentFixedFields, ...additionalFields, ...activityFixedFields];
    fields.push(new TableHeaderWithFieldId(
        "reciept",
        "",
        false,
        false,
        "reciept",
        DataType.Text
    ));
    return fields;
}

export default ReportPayment;