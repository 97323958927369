import { DataType } from "../../../services/CompanyService";
import { getInvoiceAdditionalValue, getInvoiceFieldDisplayValue } from "../../../services/InvoiceService";
import { TranslationService } from "../../../services/TranslationService";
import { formatIntizaDate } from "../../../utils/FormatUtils";
import Dropdown from "../../shared/components/Dropdown";
import { AutocompleteGroup, DatePickerEditor, TextEditor, TextEditorNumber } from "../../shared/components/Editors";
import { EntityField } from "../../shared/entities/EntityField";
import { RequiredManager, ValidationMessage } from "../../shared/RequieredManager";
import { InvoiceEditStatus } from "./entities/InvoiceEditStatus";
import { InvoiceGetResponse } from "./entities/InvoiceGetResponse";

export class InvoiceMassiveChangesFieldEditProps {
    public onlyEditor?: boolean = false;
    public invoice?: InvoiceGetResponse.Item;
    public status?: InvoiceEditStatus = undefined;
    public halfSize?: boolean = false;
    public requiredManager?: RequiredManager | undefined;
    public fieldsToValidate?: number[] | undefined;
    constructor(
        public field: EntityField,
        public onChange: (value?: string) => void,
        public personId: string,
    ) { }
};

export const ifOldFormat = (dateString: string) => {
    return dateString.includes('-') ? formatIntizaDate(new Date(dateString)) : dateString;
}

export const InvoiceMassiveChangesFieldEdit = ({ field, onChange, onlyEditor, invoice, status, halfSize, personId, requiredManager, fieldsToValidate }: InvoiceMassiveChangesFieldEditProps) => {
    const { translate } = TranslationService;
    const title = field.title;
    let displayValue = invoice ? getInvoiceFieldDisplayValue(field, invoice) : undefined;
    let sizeCol = halfSize ? " col-md-6" : ""
    let editor = undefined;
    if ((requiredManager && fieldsToValidate) && fieldsToValidate.includes(field.id)) {
        onChange = requiredManager.makeRequired(onChange);
    }

    switch (field.type) {
        case DataType.List:
            const drpItems = field.listItems.map(x => ({ value: x.id.toString(), text: x.value }));
            const selectedValue = drpItems.find(x => x.text === displayValue)?.value;
            editor = <Dropdown optionLabel={translate.Select} onChange={onChange} items={drpItems} defaultValue={selectedValue} />;
            break;
        case DataType.Date:
            let date = invoice ? getInvoiceAdditionalValue(field, invoice) : undefined;
            let val = date ? ifOldFormat(date) : undefined;
            editor = <DatePickerEditor onChange={onChange} defaultValue={val} />;
            break;
        case DataType.Number:
        case DataType.Currency:
            editor = <TextEditorNumber onChange={onChange} defaultValue={displayValue} />;
            break;
        case DataType.Group:
            editor = <AutocompleteGroup onChange={x => onChange(x?.value)} clientId={personId} />;
            break;
        case DataType.Phone:
        case DataType.Text:
        case DataType.Link:
        default:
            editor = <div><TextEditor onChange={onChange} defaultValue={displayValue} /></div>;
            break;
    }

    return (onlyEditor ?
        <>
            {editor}
            <ValidationMessage onChange={onChange} defaultValue={status?.additionals?.find(x => x.Id === field.id)?.Value} />
        </>
        :
        <div className={"d-flex input-column mb-3" + sizeCol}>
            <label className="form-label">
                {title}
            </label>
            {editor}
            <ValidationMessage onChange={onChange} defaultValue={status?.additionals?.find(x => x.Id === field.id)?.Value} />
        </div>
    );
}

export default InvoiceMassiveChangesFieldEdit;