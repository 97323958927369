import { ImportFileLogRequest } from "../entities/import/ImportFileLogRequest";
import { ImportFileLogResponse } from "../entities/import/ImportFileLogResponse";
import { ImportPreviewResponse } from "../entities/import/ImportPreview";
import { ImportLogListResponse } from "../pages/shared/entities/ImportLogListResponse";
import CompanyService from "./CompanyService";
import ExportService from "./ExportService";
import RequestService from "./RequestService";
import { TranslationService } from "./TranslationService";

export class ImportService {
    public static getList(filter: ImportListFilter): Promise<ImportLogListResponse | Error> {
        return RequestService.post("/import/list", { ...filter, additionaldefinitions: CompanyService.getAdditionalDefinitions(), currencies: CompanyService.getCurrencies() });
    }
    public static delete(id: number): Promise<unknown | Error> {
        return RequestService.post("/import/delete", { id });
    }
    public static getImportTypes() {
        return [...ImportTypes];
    }
    public static findImportType(type: number | undefined, updateStrategy: number) {
        let importType = ImportService.getImportTypes().find(x => x.value === type?.toString());
        importType = importType ?? ImportService.getImportTypes().find(x => x.value === (type?.toString() + "-" + updateStrategy.toString()));
        return importType;
    }
    public static set(importtemplateid: number, fileid: string): Promise<{
        async: 0 | 1,
        importid: number,
        processid: number,
    } | Error> {
        return RequestService.post("/import/set", { importtemplateid, fileid });
    }
    public static preview(importid: number): Promise<ImportPreviewResponse | Error> {
        return RequestService.post("/import/preview", { importid });
    }
    public static getStatus(importid: number): Promise<{ percentage: string, status: string, } | Error> {
        return RequestService.post("/import/getstatus", { id: importid });
    }
    public static importData(importid: number): Promise<ImportPreviewResponse | Error> {
        return RequestService.post("/import/importdata", { importid });
    }
    public static runAuto(importTemplateId: number): Promise<ImportPreviewResponse | Error> {
        return RequestService.post("/import/runauto", { id: importTemplateId });
    }
    public static async export(filter: ImportListFilter, count: number) {
        return await ExportService.requestExport("/import/exportrequest", { ...filter, additionaldefinitions: CompanyService.getAdditionalDefinitions(), currencies: CompanyService.getCurrencies() }, count);
    }
    public static fileLog(request: ImportFileLogRequest): Promise<ImportFileLogResponse | Error> {
        return RequestService.post("/import/filelog", request);
    }
    public static getImports() {
        return [
            { text: TranslationService.translate.OutstandingInvoicesList, value: 0, isEnabled: !CompanyService.getSetting("hideioimport"), },
            { text: TranslationService.translate.Client, value: 1, isEnabled: !CompanyService.getSetting("hidepersonimport"), },
            { text: TranslationService.translate.Transaction, value: 2, isEnabled: !CompanyService.getSetting("hidetransactionimport"), },
            { text: TranslationService.translate.Comments, value: 3, isEnabled: !CompanyService.getSetting("hideactivityimport"), },
            { text: TranslationService.translate.InvoiceFiles, value: 4, isEnabled: !CompanyService.getSetting("hidefileimport"), },
            { text: TranslationService.translate.UpdateMultipleInvoices, value: 5, isEnabled: !CompanyService.getSetting("hideupdateioimport"), },
            { text: TranslationService.translate.AutomaticInvoiceUpdate, value: 6, isEnabled: CompanyService.getSetting("enableioautomaticupdate"), },
            { text: TranslationService.translate.MassInvoiceDeletion, value: 7, isEnabled: CompanyService.getSetting("enableiodelete"), },
            { text: TranslationService.translate.NewInvoices, value: 8, isEnabled: !CompanyService.getSetting("hidenewioimport"), },
            { text: TranslationService.translate.AutomaticCustomerUpdate, value: 9, isEnabled: CompanyService.getSetting("enablepersonautomaticupdate"), },
            { text: TranslationService.translate.Currencies, value: 10, isEnabled: false, },
            { text: TranslationService.translate.Dictionary, value: 11, isEnabled: CompanyService.getSetting("allowimportdictionary"), },
            { text: "", value: 12, isEnabled: false, },
        ];
    }
}

export enum ImportIds {
    OutstandingInvoicesList,
    Client,
    Transaction,
    Comments,
    InvoiceFiles,
    UpdateMultipleInvoices,
    AutomaticInvoiceUpdate,
    MassInvoiceDeletion,
    NewInvoices,
    AutomaticCustomerUpdate,
    Currencies,
    Dictionary,
}

const ImportTypes = [
    { text: 'SyncInvoices', value: '0-0' },
    { text: 'UpdateInvoices', value: '0-2' },
    { text: 'NewInvoices', value: '0-1' },
    { text: 'Customers', value: '1' },
    { text: 'PaymentsAdjustments', value: '2' },
    { text: 'Comments', value: '3' },
    { text: 'NewInvoices', value: '8' },
    { text: 'InvoicesUpdate', value: '5' },
    { text: 'Currencies', value: '10' },
    { text: 'InvoiceAutoUpdates', value: '6' },
    { text: 'CustomerAutoUpdates', value: '9' }
];

type ImportListFilter = {
    from?: string;
    to?: string;
    importTemplateId?: number;
    type?: string;
    filename?: string;
    page: number;
    pagesize: number;
}
