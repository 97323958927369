import React, { useState } from 'react';

type IErrorContext = {
    currentError?: any, 
    setCurrentError: (value: any) => void,
}

const defaultErrorContext: IErrorContext = {
    currentError: undefined,
    setCurrentError: (value: any) => {
        console.debug('defaultErrorContext', 'setCurrentError', value);
        console.error('No LanguageContext defined');
    }
}

const ErrorContext = React.createContext<IErrorContext>(defaultErrorContext);

const ErrorProvider = (props: any) => {

    const [currentError, setCurrentError] = useState<string>('');

    const value: IErrorContext = {
        currentError,
        setCurrentError,
    }

    return (
        <ErrorContext.Provider value={value}>
            {props.children}
        </ErrorContext.Provider>
    )
}

export { ErrorProvider };
export default ErrorContext;