import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { InvoiceGroupCheckboxSelect } from "../entities/InvoiceListRequest";
import { useEndpointData } from "../../../../utils/TableUtils";
import InvoiceService from "../../../../services/InvoiceService";
import { GroupType } from "../InvoiceGroupWithChildren";

export const InvoiceGroupContext = createContext({
    ...new InvoiceGroupCheckboxSelect(),
    loading: false,
    error: false,
    reload: () => { },
    arrCheckboxValues: [],
    checkValuesByTree: (val: string, checked: boolean) => { },
    responseData: { Children: [] },
    maxDEbt: 0,
    currencyTotals: null,
    valuesChecked: [],
    calculateIndeterminate: (tree: string) => false,
    ioTree: '',
    setValuesCheckedTree: (val: any) => { },
});

const InvoiceGroupProvider = ({ children, personid }: React.PropsWithChildren<{ personid: number }>) => {
    const endpoint = useCallback(() => InvoiceService.getInvoiceGroups({ personid: personid.toString() }), [personid]);
    const { loading, error, response, reload } = useEndpointData(endpoint);
    const [arrCheckboxValues, setArrCheckboxValues] = useState<any[]>([]);
    const [valuesChecked, setValuesChecked] = useState<any[]>([]);
    const [valuesCheckedIndeterminated, setValuesCheckedIndeterminated] = useState<any[]>([]);
    const [responseData, setResponseData] = useState<any>({
        Children: [],
        valueChecked: false,
        parent: undefined
    });

    const ioTree = useMemo(() => {
        const valuesCheck = valuesChecked.map(chVal => chVal.tree);
        const valuesIntermediate = valuesCheckedIndeterminated.map(chVal => chVal.tree);
        const valuesWithoutCheck = valuesCheck.filter(elCheck => !valuesIntermediate.includes(elCheck))
        return valuesWithoutCheck.join(',')
    }, [valuesChecked, valuesCheckedIndeterminated]);

    const treeUpdated = useMemo(() => {
        if (response?.tree && response.tree.length > 0) {
            return arrCheckboxValues;
        } else {
            return [];
        }
    }, [arrCheckboxValues, response?.tree]);

    const calculateIndeterminate = useCallback((tree: string) => {
        const valueCheck = arrCheckboxValues.find((val: any) => val?.tree === tree);
        if (!valueCheck?.Children) return false;

        const childrenChecked = valueCheck.Children.filter((ch: any) => valuesChecked.some((val: any) => val.tree === ch.tree));
        const resultIndeterminate = (childrenChecked.length > 0 && childrenChecked.length < valueCheck.Children.length);
        const isInArrIndeterminate = valuesCheckedIndeterminated.find(vcht => vcht?.tree === tree);

        valueCheck.indeterminate = resultIndeterminate; // actualiza la propiedad indeterminate

        if (resultIndeterminate && !isInArrIndeterminate) {
            setValuesCheckedIndeterminated((prev: any) => ([...prev, valueCheck]));
        } else if (!resultIndeterminate && isInArrIndeterminate) {
            setValuesCheckedIndeterminated((prev: any) => prev.filter((val: any) => val.tree !== tree));
        }
        return resultIndeterminate;
    }, [arrCheckboxValues, valuesChecked, valuesCheckedIndeterminated]);

    const checkValuesByTree = useCallback((treeSelected: string, checked: boolean) => {
        const valueCheck = arrCheckboxValues.find((val: any) => val?.tree === treeSelected);
        if (!valueCheck) return;

        if (checked) {
            setValuesChecked((prev: any) => ([...prev, valueCheck]));
        } else {
            setValuesChecked((prev: any) => prev.filter((val: any) => val.tree !== treeSelected));
        }

        if (valueCheck?.Children && valueCheck.Children.length > 0) {
            valueCheck.Children.forEach((ch: any) => {
                checkValuesByTree(ch.tree, checked);
            });
        }
    }, [arrCheckboxValues]);



    useEffect(() => {
        const generateCheckboxGroup = (tree: GroupType[]) => {
            setResponseData((MainGroup: any) => ({
                ...MainGroup,
                Children: [...tree],
            }));
            const newCheckboxValues: any[] = [];
            const generateCheckbox = (el: any, parent?: string) => {
                el.valueChecked = false;
                el.indeterminate = false;
                el.parent = parent || undefined;
                newCheckboxValues.push({
                    valueChecked: false,
                    indeterminate: false,
                    parent,
                    ...el,
                });
                if (Array.isArray(el.Children)) {
                    el.Children.forEach((x: any) => generateCheckbox(x, el.tree));
                }
            }
            tree.forEach((x: GroupType) => generateCheckbox(x));
            setArrCheckboxValues(newCheckboxValues);
        }

        if (response?.tree) {
            generateCheckboxGroup(response.tree);
        }
    }, [response?.tree]);

    const value: InvoiceGroupCheckboxSelect & {
        loading: boolean,
        error: boolean,
        reload: () => void,
        arrCheckboxValues: any,
        checkValuesByTree: (val: string, checked: boolean) => void,
        responseData: any,
        maxDEbt: number,
        currencyTotals: any,
        valuesChecked: any,
        calculateIndeterminate: any,
        ioTree: string,
        setValuesCheckedTree: (value: React.SetStateAction<any[]>) => void,
    } = {
        stateValue: (response?.tree && response?.tree?.length > 0) || false,
        tree: treeUpdated,
        maxDEbt: response?.maxDEbt || 0,
        loading,
        error,
        reload,
        arrCheckboxValues,
        checkValuesByTree,
        responseData,
        currencyTotals: response?.currencyTotals || null,
        valuesChecked,
        calculateIndeterminate,
        ioTree,
        setValuesCheckedTree: setValuesCheckedIndeterminated
    }

    return (
        <InvoiceGroupContext.Provider value={value}>
            {children}
        </InvoiceGroupContext.Provider>
    )
}

export default InvoiceGroupProvider;
