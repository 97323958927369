import axios, { AxiosRequestConfig } from "axios";
import { getCache } from "../utils/PersistenceUtils";
import {
    AUTHENTICATION_DATA_STORAGE_KEY,
    IAuthenticationData,
    setAuthentication,
} from "../pages/shared/AuthenticationContext";
import RequestService from "./RequestService";
import { CompanyAuth } from "../entities/login/CompanyAuth";
import { sha256 } from "js-sha256";
import { CompanyToken } from "../entities/login/CompanyToken";
import { UserPayload } from "../entities/login/UserPayload";
import CompanyService from "./CompanyService";
import { onClearCacheSubject } from "../pages/shared/Logout";

export type ValidLogonResponse = {
    response: "OK" | "Ok";
    token: string;
    lastcompanyguid: string;
    twofadata?: string;
};
export type ValidChangePasswordResponse = {
    data: {
        response: string;
        token: string;
        twofadata: string;
        lastcompanyguid: string;
    };
};
export type LogonResponse =
    | {
        response: "2AF";
        twofadata: string;
    }
    | {
        response: "LoginIncorrect";
    }
    | {
        response: 'NextBlock';
    }
    | ValidLogonResponse
    | {
        response: "Blocked";
    }
    | Error;
export type ChangePasswordResponse =
    | {
        data: { response: "invalidtoken" };
    }
    | ValidChangePasswordResponse;

export type ICompanyData = { companyToken: undefined } | CompanyAuth;
export type RefreshRequest = {
    refreshToken: IAuthenticationData;
    refreshlists?: 1;
    filteruseridpaused?: 1 | 0;
};

export type ChangePasswordParamsType = {
    email: string;
    password: string;
    token: string;
};

class AuthenticationService {
    public static async getAuthenticationToken(
        abortController?: AbortController
    ): Promise<string | null> {
        const authenticationData = getCache<IAuthenticationData>(
            AUTHENTICATION_DATA_STORAGE_KEY
        );
        if (!authenticationData) {
            return null;
        }
        if ((authenticationData.refreshExpires * 1000) < (Date.now() + 1000 * 60 * 10)) {
            AuthenticationService.tryRefreshToken(
                authenticationData,
                abortController
            );
        }
        if (authenticationData.expires * 1000 < Date.now()) {
            return AuthenticationService.tryRefreshToken(
                authenticationData,
                abortController
            );
        }
        return authenticationData.token;
    }

    private static async tryRefreshToken(
        data: IAuthenticationData,
        abortController?: AbortController
    ): Promise<string | null> {
        if (
            !data.refreshToken ||
            !data.refreshExpires ||
            data.refreshExpires * 1000 < Date.now()
        ) {
            return null;
        }
        return await this.refreshToken({ refreshToken: data }, abortController);
    }

    public static async refreshToken(
        refreshRequest: RefreshRequest,
        abortController?: AbortController
    ): Promise<string | null> {
        const refreshData: string = JSON.stringify({
            ...refreshRequest,
            refreshToken: refreshRequest.refreshToken.refreshToken,
        });
        const config: AxiosRequestConfig = {
            method: "post",
            timeout: 20000,
            url: process.env.REACT_APP_INTIZA_AUTH + "/companyauth/refresh",
            headers: { "Content-Type": "application/json" },
            data: refreshData,
            signal: abortController?.signal,
        };
        try {
            const refreshResult = await axios(config);
            if (refreshRequest.refreshlists) {
                this.processCompanyLogin(refreshResult.data);
                return refreshResult.data.companyToken;
            } else if (refreshResult.data?.companyToken) {
                const companyToken: string = refreshResult.data.companyToken || "";
                const companyTokenPayload: CompanyToken = parseJwt(companyToken) || {};
                const companyTokenExp: number = companyTokenPayload["exp"] || 0;

                const refreshToken: string = refreshResult.data.refreshToken || "";
                const refreshTokenPayload: any = parseJwt(refreshToken) || {};
                const refreshTokenExp: number = refreshTokenPayload["exp"] || 0;

                CompanyService.setCompanyToken(companyTokenPayload);
                const authData: IAuthenticationData = {
                    token: companyToken,
                    expires: companyTokenExp,
                    refreshToken: refreshToken,
                    refreshExpires: refreshTokenExp,
                };

                setAuthentication(authData);
                return refreshResult.data.companyToken;
            }
        } catch (error) { }
        return null;
    }

    public static recoverPassword(email: string): Promise<unknown | Error> {
        return RequestService.post("/auth/recover", { email });
    }

    // TODO: Metodo se implementa pero no se usa ya que al ejecutarlo hace un refresh de la pag
    // public static changePassword(
    //   data: ChangePasswordParamsType
    // ): Promise<ChangePasswordResponse> {
    //   return RequestService.post("/auth/setpassword", data);
    // }

    public static async login(
        email: string,
        password: string
    ): Promise<LogonResponse> {
        const userData = await RequestService.post<LogonResponse>(
            "/auth/logon",
            {
                email,
                password: sha256(password),
            },
            false,
            process.env.REACT_APP_INTIZA_AUTH
        );
        if (userData instanceof Error) {
            return new Error("Failed to logon");
        }
        if (userData.response === "OK") {
            AuthenticationService.saveUserPayload(userData);
        }
        return userData;
    }

    public static async login2AFValidation(
        email: string,
        code: string,
        twofadata: string
    ): Promise<LogonResponse> {
        const userData = await RequestService.post<LogonResponse>(
            "/auth/code",
            {
                email,
                code,
                twofadata,
            },
            false,
            process.env.REACT_APP_INTIZA_AUTH
        );

        if (userData instanceof Error) {
            return new Error("LoginIncorrect");
        }
        if (userData.response === "LoginIncorrect") {
            return new Error("LoginIncorrect");
        }
        if (userData.response === "OK" || userData.response === "Ok") {
            AuthenticationService.saveUserPayload(userData);
        }
        return userData;
    }

    public static saveUserPayload(userData: ValidLogonResponse) {
        const userToken: string = userData.token;
        const userTokenPayload: UserPayload = parseJwt(userToken);
        CompanyService.setUserPayload(userTokenPayload);
    }

    public static async companyLogin(
        userData: ValidLogonResponse,
        rememberme: boolean
    ): Promise<Error | IAuthenticationData> {
        const companyConfig: AxiosRequestConfig = {
            method: "post",
            timeout: 20000,
            url: process.env.REACT_APP_INTIZA_AUTH + "/companyauth/authorize",
            headers: {
                Authorization: "Bearer " + userData.token,
                "Content-Type": "application/json",
            },
            data: {
                companyguid: userData.lastcompanyguid,
                rememberme,
            },
        };
        try {
            const response = await axios(companyConfig);
            return AuthenticationService.processCompanyLogin(response.data);
        } catch (error) {
            return error as Error;
        }
    }

    private static processCompanyLogin(
        loginData: ICompanyData
    ): Error | IAuthenticationData {
        if (!loginData.companyToken) {
            return new Error("No company token");
        }
        const companyToken: string = loginData.companyToken || "";
        const companyTokenPayload: CompanyToken = parseJwt(companyToken) || {};
        const companyTokenExp: number = companyTokenPayload["exp"] || 0;

        const refreshToken: string = loginData.refreshToken || "";
        const refreshTokenPayload: any = parseJwt(refreshToken) || {};
        const refreshTokenExp: number = refreshTokenPayload["exp"] || 0;

        CompanyService.setCompanyAuth(loginData);
        CompanyService.setCompanyToken(companyTokenPayload);

        const authData: IAuthenticationData = {
            token: companyToken,
            expires: companyTokenExp,
            refreshToken: refreshToken,
            refreshExpires: refreshTokenExp,
        };
        return authData;
    }

    public static async switchCompany(
        to: string
    ): Promise<Error | IAuthenticationData> {
        const result = await RequestService.post<ICompanyData>(
            "/companyauth/switchcompany",
            { companyguid: to },
            true,
            process.env.REACT_APP_INTIZA_AUTH
        );
        if (
            result instanceof Error ||
            result.companyToken === undefined ||
            result.companyGuid !== to
        ) {
            return new Error("Failed to switch company");
        }
        onClearCacheSubject.next(false);
        return AuthenticationService.processCompanyLogin(result);
    }
}

function parseJwt(token: string): any {
    const base64Payload = token.split(".")[1];
    const payload = Buffer.from(base64Payload, "base64");
    return JSON.parse(payload.toString());
}

export default AuthenticationService;
