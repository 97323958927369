import { useCallback, useContext, useEffect, useState } from "react";
import ErrorMessage from "../../shared/components/Error";
import Loading from "../../shared/components/Loading";
import FloatingPanelService from "../../shared/FloatingPanel";
import { TranslationService } from '../../../services/TranslationService';
import { TransactionTypeListResponse } from "../../../entities/company/TransactionType/TransactionTypeListResponse";
import TransactionTypeService from "../../../services/TransactionTypeService";
import { TransactionTypeEntity } from "../../../entities/company/TransactionType/TransactionTypeEntity";
import { RequiredManager, ValidationMessage } from "../../shared/RequieredManager";
import { TextEditor } from "../../shared/components/Editors";
import { FloatingPanelFooter } from "../../shared/components/FloatingPanelFooter";
import ToastContext from "../../shared/bootstrap/Toast";
import ModalService from "../../shared/bootstrap/Modal";


const TransactionType = () => {

    const { translate } = TranslationService;
    const [response, setResponse] = useState<TransactionTypeListResponse>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);


    const requestData = async () => {
        setLoading(true);
        const result = await TransactionTypeService.getList();
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        setResponse(result);
        setLoading(false);
        setError(false);
    }

    const requestDataCallback = useCallback(requestData, []);

    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    if (loading) {
        return (<Loading />)
    }

    if (error) {
        return (<ErrorMessage onRefresh={requestDataCallback} message={translate.ErrorLoadingList} />)
    }

    const showTransactionTypeEdit = (transactionType?: TransactionTypeEntity) => {
        FloatingPanelService.showPanel({
            children: <TransactionTypeEdit reload={requestDataCallback} transactionType={transactionType} />,
            title: transactionType ? translate.EditPaymentType : translate.NewPaymentType,
            width: 600,
            height: 300,
            position: "center",
        });
    }

    return (
        <div className="card px-5">
            <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center my-4">
                <h2>{TranslationService.translate.PaymentTypes}</h2>
                <div className="d-flex align-items-center">
                    <button className="btn btn-primary" onClick={() => showTransactionTypeEdit(undefined)}><i className="fal fa-plus" /> {translate.AddPaymentType}</button>
                </div>
            </div>
            <table className="table table shadow-hover">
                <thead>
                    <tr>
                        <th>{translate.Value}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {response?.list.map(x => (
                        <tr key={x.TransactionTypeID} className="pointer show-child-on-hover" onClick={(event) => {
                            showTransactionTypeEdit(x); event.stopPropagation();
                        }}>
                            <td>
                                {x.TypeName}
                            </td>
                            <td width={200}> </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}


const TransactionTypeEdit = ({ reload, transactionType }: { reload: () => void, transactionType?: TransactionTypeEntity }) => {

    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);
    const [submiting, setSubmiting] = useState<"set" | "del" | null>(null);
    const [value, setValue] = useState(transactionType?.TypeName ?? "");

    const requiredManager = new RequiredManager();
    const onValueChanged = requiredManager.makeRequired(setValue);

    const onSubmit = async () => {
        if (submiting !== null) {
            return;
        }
        if (!requiredManager.validate()) {
            showToast(translate.MissingRequiredFields);
            return;
        }
        setSubmiting("set");
        const transactionTypeModel = { TransactionTypeID: transactionType?.TransactionTypeID ?? 0, TypeName: value };
        const result = await TransactionTypeService.set(transactionTypeModel);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            setSubmiting(null);
            return;
        }
        setSubmiting(null);
        reload();
        FloatingPanelService.hidePanel();
        showToast(translate.PaymentTypeSaved, undefined, "success");
    }

    const onDelete = () => {
        const deleteTransactionType = async () => {
            if (submiting !== null) {
                return;
            }
            setSubmiting("del");
            const result = await TransactionTypeService.delete(transactionType?.TransactionTypeID!);
            if (result instanceof Error) {
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
                setSubmiting(null);
                return;
            }
            setSubmiting(null);
            reload();
            FloatingPanelService.hidePanel();
            showToast(translate.PaymentTypeDeleted, undefined, "success");
        }
        ModalService.showDefaultModal({
            acceptButtonLabel: translate.Delete,
            message:  () => <>{translate.AreYouSureDeletePaymentType.split("\n").map(x => (
                <><span>{x}</span><br /></>
            ))}</>,
            onAcceptClick: deleteTransactionType,
        })
    }


    return (
        <>
            <div className="floatingBody p-4">
                <div className="card-body">
                    <div className="d-flex flex-column">
                        <div className="row mb-1">
                            <label className="col-3 font-weight-normal col-form-label">{translate.Name}:</label>
                            <div className="col-8">
                                <TextEditor onChange={onValueChanged} defaultValue={value} />
                                {<ValidationMessage onChange={onValueChanged} defaultValue={value} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FloatingPanelFooter>

                {transactionType &&
                    <button type="button" className="btn btn-danger btn-sm" onClick={onDelete}>
                        {translate.Delete}
                        {submiting === "del" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                    </button>}

                <button className="btn btn-primary btn-sm" onClick={onSubmit}>
                    {translate.Save}
                    {submiting === "set" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </FloatingPanelFooter>
        </>
    )

}


export default TransactionType