import { RequestTagExport, TagListResponse, TagReportResponse } from "../entities/company/Tag/TagListResponse";
import CompanyService from "./CompanyService";
import ExportService from "./ExportService";
import RequestService from "./RequestService";

class TagService {
    public static set(Name: string, TagID: number | null = null): Promise<{ id: number } | Error> {
        return RequestService.post("/tag/set", { item: { TagID, Name } });
    }
    public static delete(id: number | string): Promise<{ id: number } | Error> {
        return RequestService.post("/tag/delete", { id: id.toString() });
    }

    public static getList(): Promise<Error | TagListResponse> {
        return RequestService.post("/tag/list", {});
    }

    public static getTagReport(filter: string): Promise<Error | TagReportResponse> {
        return RequestService.post("/tag/Report", { filter });
    }

    public static async exportTags(requestExport: RequestTagExport) {
        return ExportService.requestExport('/tag/export', { ...requestExport }, 100, 'tagsReport');
    }

    /**
     * @param tagString Tag string with or without new tags.
     * @returns A new tagString with the new tags repleaced by their ids.
     */
    public static async createMissingTags(tagString?: string): Promise<Error | string | undefined> {
        const tagList = tagString?.split(", ").filter(x => x);
        if (tagList === undefined || tagList.length === 0) {
            return tagString ?? undefined;
        }
        const taskTags = CompanyService.getTags().map(x => x.Id);
        const toCreate = tagList.filter(x => !taskTags.includes(x));

        const requests = toCreate.map(x => TagService.set(x).then(y => {
            if (!(y instanceof Error)) {
                tagList[tagList.indexOf(x)] = y.id.toString();
            }
            return y;
        }));
        const results = [];
        for (const req of requests) {
            results.push(await req);
        }
        const ok = results.filter(x => !(x instanceof Error));

        if (ok.length !== requests.length) {
            ok.map(x => x as { id: number }).map(x => TagService.delete(x.id));
            return results.find(x => x instanceof Error) as Error;
        }
        CompanyService.setTags([...CompanyService.getTags(), ...toCreate.map((x, i) => ({ Id: (ok[i] as { id: number }).id.toString(), Value: x }))]);
        return tagList.join(", ");
    }
}

export default TagService;