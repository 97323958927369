import { useCallback, useContext, useEffect, useState } from "react";
import { TranslationService } from "../../../services/TranslationService";
import Loading from "../../shared/components/Loading";
import { formatCurrency, formatDateDigits, formatDateDigitsMin, formatDateTimeDigits, formatNumber } from "../../../utils/FormatUtils";
import ToastContext, { ToastService } from "../../shared/bootstrap/Toast";
import { InvoiceGetResponse } from "./entities/InvoiceGetResponse";
import InvoiceService, { getInvoiceDefaultFieldType, InvoiceFields, InvoiceSetFields } from "../../../services/InvoiceService";
import { EditorProps, AutocompleteGroup, EditShowFormProps, DatePickerEditor, TextAreaEditor, EditShowForm, EditShowFormReadOnly, FileApi, TextAreaXLEditor, TextEditorNumber } from "../../shared/components/Editors";
import CompanyService, { DataType, Entities } from "../../../services/CompanyService";
import Collapsable from "../../shared/bootstrap/Collapsable";
import Dropdown from "../../shared/components/Dropdown";
import TransactionService from "../../../services/TransactionService";
import { StorageService, CacheEntity } from "../../../services/StorageService";
import FloatingPanelService from "../../shared/FloatingPanel";
import { InvoiceDetailStatus } from "./InvoiceDetailStatus";
import ModalService from "../../shared/bootstrap/Modal";
import { FloatingPanelFooter } from "../../shared/components/FloatingPanelFooter";
import { CollapsableWithTtitle } from "../../shared/form/CollapsableWithTtitle";
import { FileUploadResponse } from "../../file/entities/FileUploadResponse";
import { parseDate, restTimezone } from "../../../utils/ParseUtils";
import { Cast, MapNotUndefined, isNullOrWhitespace } from "../../../utils/Utils";
import { CompanyAuth } from "../../../entities/login/CompanyAuth";
import { getError } from "../../../utils/RequestUtils";
import FileService from "../../../services/FileService";
import { RequiredManager, ValidationMessage } from "../../shared/RequieredManager";
import { MultiFileUpload } from "../../shared/components/MultiFileUpload";

const InvoiceEdition = ({ response, reload, editingValues, setEditingValues, canEdit = true }: { response: InvoiceGetResponse, reload: () => void, editingValues: boolean[], setEditingValues: (editingValues: (editingValues: boolean[]) => boolean[]) => void, canEdit?: boolean }) => {
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);
    const onGroupChange = (onChange: (value: string) => void) => (value: { value: string, label: string } | undefined) => {
        onChange(value?.value.toString() ?? "");
    };
    const groupDefaultValue = response.item.Group !== null ? { value: response.item.Group.GroupID.toString(), label: response.item.Group.Name } : (response.item.GroupID && !isNullOrWhitespace(StorageService.getCache(CacheEntity.GroupName, response.item.GroupID))) ? { value: response.item.GroupID, label: StorageService.getCache(CacheEntity.GroupName, response.item.GroupID) } : undefined;
    const groupEdit = (props: EditorProps) => <div className="mw-200"><AutocompleteGroup clientId={response.item.Person.PersonId.toString()} defaultVal={groupDefaultValue} onChange={onGroupChange(props.onChange)} /></div>;
    const onSaveGenerator = (fieldId: string) => async (value: string | undefined) => {
        if (value) {
            //const field = CompanyService.getConfigItemsFiltered(Entities.Invoice).find(x => x.field.toString() === fieldId);
            const fieldIdInt = parseInt(fieldId);
            if (fieldIdInt > 0) {
                const additionalField = CompanyService.getAdditionalDefinitions().find(x => x.Entity === Entities.Invoice && x.AdditionalDefinitionID === fieldIdInt);
                if (additionalField?.Type === DataType.Date) {
                    // value = formatIntizaDate(new Date(value));
                    value = formatDateDigitsMin(value)
                }
            }
            else if (getInvoiceDefaultFieldType(fieldIdInt) === DataType.Date) {
                value = formatDateDigitsMin(value)
                // value = formatIntizaDate(new Date(value));
            }
        }
        const result = await InvoiceService.setField(response.item.IOID, fieldId, value ?? "");
        if (result instanceof Error) {
            const err = getError(result);
            let errorMessage = translate.ErrorProcessingRequest;
            if (err.status === 400) {
                if (err.message!.indexOf("Fail-Number") > -1) {
                    errorMessage = translate.IdentifierAlreadyInUsed;
                }
            }
            showToast(errorMessage, undefined, "danger");
            return;
        }
        reload();
    };
    const getEditor = (num: number, fieldId: number) => {
        const type = num as DataType;
        switch (type) {
            case DataType.Phone:
            case DataType.Link:
            case DataType.Text:
                return TextAreaEditor;
            case DataType.List:
                return function ListEditor({ onChange, defaultValue }: EditorProps) {
                    const items = CompanyService.getAdditionalDefinitions().find(x => x.AdditionalDefinitionID === fieldId)!.AdditionalDefinitionItems.map(x => ({ value: x.AdditionalDefinitionItemID.toString(), text: x.Value }));
                    return <Dropdown onChange={(x) => onChange(x ?? "")} defaultValue={defaultValue} items={items} optionLabel={translate.None} />;
                };
            case DataType.Date:
                return DatePickerEditor;
            case DataType.Number:
            case DataType.Currency:
                return TextEditorNumber;
            default:
                throw Error("Missing editor for additional field in Invoices");
        }
    };

    const formatValue = (x: CompanyAuth.AdditionalDefinition) => (value: string | undefined): string => {
        switch (x.type) {
            case DataType.List:
                return x.AdditionalDefinitionItems.find(x => x.AdditionalDefinitionItemID.toString() === value)?.Value ?? value ?? "";
            case DataType.Date:
                return MapNotUndefined(parseDate(value), x => formatDateDigits(x!)) ?? "";
            case DataType.Currency:
                if (value) {
                    return formatCurrency(parseFloat(value), response.item.CurrencyID, formatNumber)!;
                }
                return "";
            case DataType.Number:
                if (value) {
                    return formatNumber(parseFloat(value))!;
                }
                return "";
            case DataType.Link:
                return value?.length ? Cast<string>(<a href={value} target="_blank" rel="noreferrer">{value}</a>) : "";
            default:
                return value ?? "";
        }
    };

    const dateFormat = (x: string | undefined) => {
        return x && formatDateDigits(new Date(x));
    };

    const canEditFixed = (field: number) => CompanyService.getConfigItems().find(x => x.Entity === Entities.Invoice && x.field === field)!.ManualEdit;
    const fixedEditProps = [
        {
            ...new EditShowFormProps(
                InvoiceService.getReferenceNumberName(),
                response.item.referenceNumber,
                TextAreaEditor,
                emptyFunction,
            ),
            fieldId: InvoiceFields.referenceNumber,
            editable: canEditFixed(InvoiceFields.referenceNumber),
        },
        ...((CompanyService.getGroupName()?.length > 0) ? [{
            ...new EditShowFormProps(
                CompanyService.getGroupName(),
                response.item.GroupID,
                groupEdit,
                emptyFunction,
                (x: string | undefined) => x ? StorageService.getCache(CacheEntity.GroupName, x) : x,
            ),
            fieldId: InvoiceFields.group,
            editable: canEditFixed(InvoiceFields.group),
        }] : []),
        {
            ...new EditShowFormProps(
                translate.DueDate,
                response.item.dueDate,
                DatePickerEditor,
                emptyFunction,
                dateFormat,
            ),
            fieldId: InvoiceFields.dueDate,
            editable: canEditFixed(InvoiceFields.dueDate),
        },
        {
            ...new EditShowFormProps(
                translate.Observations,
                response.item.description,
                TextAreaXLEditor,
                emptyFunction,
            ),
            fieldId: InvoiceFields.description,
            editable: canEditFixed(InvoiceFields.description),
        },
        {
            ...new EditShowFormProps(
                translate.IssueDate,
                response.item.emittedDate,
                DatePickerEditor,
                emptyFunction,
                dateFormat,
            ),
            fieldId: InvoiceFields.emittedDate,
            editable: canEditFixed(InvoiceFields.emittedDate),
        },
    ];
    fixedEditProps.forEach(x => x.onSave = onSaveGenerator(x.fieldId.toString()));
    const additionalEditProps = CompanyService.getAdditionalDefinitions()
        .filter(x => x.Entity === Entities.Invoice)
        .filter(x => x.IOStatusDependencies.length === 0)
        .map(x => ({
            ...new EditShowFormProps(
                x.Name,
                response.item.additionals.find(y => y.Id === x.AdditionalDefinitionID.toString())?.Value,
                getEditor(x.type, x.AdditionalDefinitionID)! as React.FC<EditorProps>,
                onSaveGenerator(x.AdditionalDefinitionID.toString()),
                formatValue(x),
            ),
            fieldId: x.AdditionalDefinitionID,
            editable: x.ManualEdit !== false,
        }));

    const ids = CompanyService.getIoSortedFields().split(",").map(x => parseInt(x));
    ids.push(InvoiceFields.status);

    const getIndex = (fieldId: number) => {
        const index = ids.indexOf(fieldId);
        return index === -1 ? fieldId + 1000000 : index;
    };

    const editProps = additionalEditProps.concat(fixedEditProps).sort((a, b) => getIndex(a.fieldId) - getIndex(b.fieldId));
    editProps.forEach((x, i) => {
        x.isEditing = editingValues[i] ?? false;
        x.setIsEditing = (isEditing) => {
            setEditingValues(x => {
                x[i] = isEditing;
                return [...x];
            });
        };
    });

    return (<>
        {editProps.map((x, i) => (canEdit && CompanyService.canDo("editio") && !CompanyService.getSetting("hideeditio") && x.editable) ? <EditShowForm key={x.title} {...x} /> : <EditShowFormReadOnly key={x.title} {...x} />)}
    </>);
};

const InvoiceStatusDisplay = (response: InvoiceGetResponse): { title: string, className: string } => {
    const { translate } = TranslationService;
    let title = "";
    let className = "";
    if (response.item.pending === 0) {
        title = translate.Paid;
        className = "alert-success";
    }
    else if (response.item.dueDays > 0) {
        title = translate.ExpiredDueDays.replace("{dueDays}", response.item.dueDays.toString());
        className = "alert-danger";
    }
    else {
        title = translate.StatusPending;
        className = "alert-secondary";
    }
    return { title, className };
};

const InvoiceDetail = ({ invoiceId, reload: reloadList, canEdit = true }: { invoiceId: number, reload: () => void, canEdit?: boolean }) => {
    const [loading, setLoading] = useState(true);
    const [response, setResponse] = useState<InvoiceGetResponse>({} as any);
    const { showToast } = useContext(ToastContext);
    const { translate } = TranslationService;
    const [deleting, setDeleting] = useState(false);
    const [savedFiles, setSavedFiles] = useState<string[]>([]);
    const [files, setFiles] = useState<FileUploadResponse[]>([]);
    const [isEditingDetailStatus, setIsEditingDetailStatus] = useState(false);
    const [isEditingAmount, setIsEditingAmount] = useState(false);
    const [editionValues, setEditionValues] = useState<boolean[]>([]);
    const [editingPaymentIds, setEditingPaymentIds] = useState<number[]>([]);

    const reload = useCallback(() => {
        const getData = async () => {
            const result = await InvoiceService.getInvoice(invoiceId);
            if (result instanceof Error || result === undefined) {
                FloatingPanelService.hidePanel();
                setLoading(false);
                ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
                return;
            }
            setResponse(result);
            setFiles(result.item.files);
            const { title, className } = InvoiceStatusDisplay(result);
            FloatingPanelService.setTitle(title, className);
            setLoading(false);

        };
        getData();
    }, [invoiceId]);

    useEffect(() => {
        if (!deleting) {
            setLoading(true);
            reload();
        }
    }, [reload, deleting]);

    FloatingPanelService.OnBeforeClose = () => {
        if (isEditingDetailStatus || isEditingAmount || editingPaymentIds.length > 0 || editionValues.reduce((prev, curr) => prev || curr, false)) {
            ModalService.showDefaultModal({
                message: translate.ValuesBeingEdit,
                acceptButtonLabel: translate.Accept,
                closeButtonLabel: translate.Cancel,
                onAcceptClick: () => {
                    FloatingPanelService.OnBeforeClose = undefined;
                    FloatingPanelService.hidePanel();
                }
            });
            return false;
        }
        else {
            return true;
        }
    };

    const onDeleteInvoice = async () => {
        if (deleting) {
            return;
        }

        const deleteInvoice = async () => {
            setDeleting(true);
            const result = await InvoiceService.delete(response.item.IOID);
            if (result instanceof Error) {
                showToast(translate.Network, "", "danger");
                setDeleting(false);
                return;
            }
            showToast(translate.DeletedInvoice);
            reloadList();
            FloatingPanelService.hidePanel();
        };

        ModalService.showDefaultModal({
            acceptButtonLabel: translate.Delete,
            message: translate.AreYouSureDeleteInvoice,
            onAcceptClick: deleteInvoice,
            acceptButtonClassName: "btn btn-danger",
            title: TranslationService.translate.DeleteInvoice,
            closeButtonLabel: TranslationService.translate.Cancel,
        });
    };

    const onChange = () => {
        reload();
        reloadList();
    };

    const onPrint = () => {
        const panel = document.querySelector("#floating-window .content");
        if (panel) {
            const originalId = panel.id;
            const originalParent = panel.parentNode!;
            const sibling = panel.nextSibling;

            panel.id = "section-to-print";
            document.body.append(panel);
            window.print();

            originalParent.insertBefore(panel, sibling);
            panel.id = originalId;
        }
    };

    const onFileChange = async (files: FileApi[]) => {
        const tempFiles = files.filter(x => x.id.startsWith("temp") && !savedFiles.includes(x.id));
        const promises = tempFiles.map(x => x.id).map(x => InvoiceService.setFile(invoiceId.toString(), x));
        for (let index = 0; index < promises.length; index++) {
            const res = await promises[index];
            if (res instanceof Error) {
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
                continue;
            }
            setSavedFiles(x => [...x, tempFiles[index].id]);
            setFiles(x => [...x, { id: res.id.toString(), fileName: tempFiles[index].name, response: "" }]);
        }
    };

    const fileDelete = async (fileId: string) => {
        const result = InvoiceService.deleteFile(fileId);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, "", "danger");
        }
    };

    return (
        <>
            {loading || !response ? (
                <Loading />
            ) : (<>
                <div className="floatingBody p-4">
                    <div className="alert alert-info">
                        <h5 className="mb-3">{response.item.Person.Name}</h5>
                        <InvoiceStatusAmount response={response} onChange={onChange} isEditing={isEditingAmount} setEditing={setIsEditingAmount} canEdit={canEdit} />
                        <InvoiceDetailStatus invoice={response.item} isEditing={isEditingDetailStatus} afterSubmit={() => { reload(); reloadList(); }} editingChanged={setIsEditingDetailStatus} canEdit={canEdit} />
                    </div>

                    <CollapsableWithTtitle title={translate.Invoice}>
                        <InvoiceEdition response={response} reload={onChange} editingValues={editionValues} setEditingValues={setEditionValues} canEdit={canEdit} />
                    </CollapsableWithTtitle>
                    <CollapsableWithTtitle title={translate.Payments} showDefault={false} >
                        <InvoicePayments response={response} reload={onChange} editingIds={editingPaymentIds} setEditingIds={setEditingPaymentIds} canEdit={canEdit} />
                    </CollapsableWithTtitle>
                    <CollapsableWithTtitle title={translate.Files} showDefault={false} >
                        <MultiFileUpload
                            files={files.map(x => ({ id: x.id.toString(), name: x.fileName }))}
                            canEdit={canEdit && CompanyService.canDo("editio")}
                            onFilesChange={onFileChange}
                            downloadFile={(fileId, filename) => FileService.downloadIO(fileId, invoiceId.toString(), filename)}
                            fileDelete={fileDelete}
                        />
                    </CollapsableWithTtitle>
                    <div className="my-1 d-flex justify-content-between">
                        <small>
                            <b>{translate.CreatedDate}: </b>
                            {formatDateTimeDigits(restTimezone(new Date(response.item.Created)))}
                        </small>
                        <small>
                            <b className="ms-3">{translate.Creator}: </b>
                            {CompanyService.getUsers()?.find(x => x.Id === response.item.CreatorUserID.toString())?.Value}&nbsp;
                            {response.item.ImportID !== null && " (" + translate.ByImportProcess + ")"}
                        </small>
                    </div>
                </div>
                <FloatingPanelFooter>
                    {canEdit && CompanyService.canDo("deleteio") && !CompanyService.getSetting("hideeditio") && <button type="button" className="btn btn-danger" onClick={onDeleteInvoice}>
                        {translate.Delete}
                        {deleting && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                    </button>}
                    <button type="button" className="btn btn-primary" onClick={onPrint}>{translate.Print}</button>
                </FloatingPanelFooter>
            </>)}
        </>);
};

const InvoicePayments = ({ response, reload, editingIds, setEditingIds, canEdit = true }: { response: InvoiceGetResponse, reload: () => void, editingIds: number[], setEditingIds: React.Dispatch<React.SetStateAction<number[]>>, canEdit?: boolean }) => {
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);

    //const [editingIds, setEditingIds] = useState<number[]>([]);

    const getPaymentsList = (transactions: InvoiceGetResponse.Transaction[]) => {
        let balance = response.item.amount;
        return [{
            id: 0,
            date: new Date(response.item.emittedDate),
            dateStr: formatDateDigits(new Date(response.item.emittedDate)),
            type: -1,
            amount: balance,
            balance: balance,
            comments: "",
            transaction: undefined,
        }, ...transactions.map(x => {
            const date = new Date(x.Date);
            balance -= x.Amount;
            return {
                id: x.TransactionID,
                date: date,
                dateStr: formatDateDigits(date),
                type: x.TransactionTypeID,
                amount: x.Amount,
                balance: balance,
                comments: x.Comments,
                transaction: x,
            };
        })];
    };
    const payments = getPaymentsList(response.item.Transactions);
    const additionalPaymentTypes = (CompanyService.getCompanyAuth()?.transactionTypes ?? []).map(x => ({ value: parseInt(x.Id), text: x.Value }));
    const onShowEditor = (id: number) => { setEditingIds([...editingIds, id]); };
    const onDeletePayment = async (id: number) => {

        const deletePaymeny = async () => {
            const response = await TransactionService.delete(id);
            if (response instanceof Error) {
                showToast(translate.Network, "", "danger");
                return;
            }
            showToast(translate.DeletedPayment);
            reload();
        };

        ModalService.showDefaultModal({
            acceptButtonLabel: translate.Delete,
            message: translate.AreYouSureDeletePayment,
            onAcceptClick: deletePaymeny,
        });
    };

    const newTransaction = (): InvoiceGetResponse.Transaction => {
        return {
            additionals: [],
            TransactionID: 0,
            IOID: response.item.IOID,
            Amount: payments[payments.length - 1].balance,
            Date: new Date().toJSON(),
            Created: new Date().toJSON(),
            CreatorUserID: parseInt(CompanyService.getUserid()),
            Comments: "",
            ImportID: null,
            TransactionTypeID: null,
            ExternalID: undefined,
            ExternalIntegrator: undefined,
            ReceiptID: undefined,
            UserID: undefined,
            OriginalCurrencyID: response.item.CurrencyID,
            OriginalAmount: undefined,
            TransactionID_ref: undefined,
            ApiRequestLogID: undefined,
            TransactionType: undefined,
            Receipt: undefined,
            Currency: undefined,
            TransactionAdditional: undefined,
            IO: undefined,
            OriginalCurrencyRate: 1.0
        };
    };
    return (<>
        <table className="table table-sm table-striped">
            <thead>
                <tr>
                    <th>{translate.Date}</th>
                    <th>{translate.Type}</th>
                    <th>{translate.Amount}</th>
                    <th>{translate.Balance}</th>
                    <th>{translate.Comments}</th>
                    <th style={{ width: 70 }}>{/* Actions */}</th>
                </tr>
            </thead>
            <tbody>
                {payments.map((x, i) => {
                    if (editingIds.includes(x.id)) {
                        return (
                            <EditPayment key={x.id} transaction={x.transaction!} invoiceCurrencyId={response.item.CurrencyID} reload={reload} onClose={() => setEditingIds(editingIds.filter(y => y !== x.id))} />
                        );
                    }
                    const paymentType = x.type === -1 ? translate.InitialAmount :
                        additionalPaymentTypes.find(y => y.value === x.type)?.text ?? translate.Payment;
                    return (
                        <tr key={x.id} className="show-child-on-hover">
                            <td style={{ width: 70 }}>{x.dateStr}</td>
                            <td>{paymentType}</td>
                            <td>{formatCurrency(x.amount, response.item.CurrencyID, formatNumber)}</td>
                            <td>{formatCurrency(x.balance, response.item.CurrencyID, formatNumber)}</td>
                            <td>{x.comments}</td>
                            <td>
                                {x.id !== 0 &&
                                    <>
                                        {canEdit && CompanyService.canDo("edittransaction") && !CompanyService.getSetting("hideeditio") && <i className={"pointer hideOnPrint far fa-fw fa-pen show-when-hovering-parent"} onClick={() => onShowEditor(x.id)} />}
                                        {canEdit && CompanyService.canDo("deletetransaction") && !CompanyService.getSetting("hideeditio") && <i className={"pointer hideOnPrint far fa-fw fa-trash text-danger show-when-hovering-parent"} onClick={() => onDeletePayment(x.id)} />}
                                    </>
                                }
                            </td>
                        </tr>
                    );
                })}
                {editingIds.includes(-1) && <EditPayment key={-1} reload={reload} invoiceCurrencyId={response.item.CurrencyID} transaction={newTransaction()} onClose={() => setEditingIds(editingIds.filter(y => y !== -1))} />}
            </tbody>
        </table>
        <div className="d-flex">
            {canEdit && CompanyService.canDo("newtransaction") && !CompanyService.getSetting("hideeditpayment") && <button type="button" className="btn btn-intiza ms-0" onClick={() => onShowEditor(-1)}>{translate.AddPayment}</button>}
            <span className="ms-auto py-3" style={{ fontSize: "0.8rem" }}>{translate.Pending}: <b>{formatCurrency(response.item.pending, response.item.CurrencyID, formatNumber)}</b></span>
        </div>
    </>);
};

const EditPayment = ({ transaction, invoiceCurrencyId, onClose, reload }: { transaction: InvoiceGetResponse.Transaction, invoiceCurrencyId: number, onClose: () => void, reload: () => void }) => {
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);
    const [payment, setPayment] = useState(transaction);
    const [rate, setRate] = useState(1);
    const [currencyId, setCurrencyId] = useState(invoiceCurrencyId);
    const [submiting, setSubmititng] = useState(false);
    const requiredManager = new RequiredManager();

    const renderAdditionalForPayment = (def: CompanyAuth.AdditionalDefinition) => {
        const value = payment.additionals.find(x => x.Id === def.AdditionalDefinitionID.toString())?.Value;
        const onChange = (value: number | string) => {
            const additionals = payment.additionals.filter(x => x.Id !== def.AdditionalDefinitionID.toString());
            additionals.push({ Id: def.AdditionalDefinitionID.toString(), Value: value.toString() });
            setPayment({ ...payment, additionals });
        };
        if (def.type === DataType.List) {
            return (
                <div className="col-6" key={def.AdditionalDefinitionID}>
                    <div className="d-flex input-column mb-3">
                        <label className="form-label">
                            {def.Name}
                        </label>
                        <Dropdown defaultValue={value} onChange={onChange} items={def.AdditionalDefinitionItems.map(x => ({ value: x.AdditionalDefinitionItemID.toString(), text: x.Value }))} />
                    </div>
                </div>
            );
        }

        if (def.type === DataType.Date) {
            let formatValue = value;
            if (value?.includes('-')) {
                formatValue = formatValue?.replaceAll('-', '')
            }
            return (
                <div className="col-6" key={def.AdditionalDefinitionID}>
                    <div className="d-flex input-column mb-3">
                        <label className="form-label">
                            {def.Name}
                        </label>
                        <DatePickerEditor defaultValue={formatValue} onChange={(newDate) => onChange(formatDateDigitsMin(newDate))} />
                    </div>
                </div>
            );
        }

        return (
            <div className="col-6" key={def.AdditionalDefinitionID}>
                <div className="d-flex input-column mb-3">
                    <label className="form-label">
                        {def.Name}
                    </label>
                    <input defaultValue={value} type={[DataType.Number, DataType.Currency].includes(def.type) ? "number" : "text"} onChange={(event) => onChange(event.target.value)} className="form-control" />
                </div>
            </div>
        );
    };
    const additionalDefs = CompanyService.getAdditionalDefinitions().filter(x => x.Entity === Entities.Payment);
    const onCurrencyChange = (value: number) => {
        const currency = CompanyService.getCurrencies().find(x => x.CurrencyId === value);
        const invoiceCurrency = CompanyService.getCurrencies().find(x => x.CurrencyId === invoiceCurrencyId);
        setCurrencyId(value);
        setRate(currency!.Rate! / invoiceCurrency!.Rate!);
    };

    const onSave = async () => {
        if (submiting) {
            return;
        }
        if (!requiredManager.validate()) {
            showToast(translate.MissingRequiredFields);
            return;
        }

        setSubmititng(true);
        const result = await TransactionService.setTransaction({ ...payment, OriginalCurrencyID: currencyId, OriginalCurrencyRate: rate });
        setSubmititng(false);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
        reload();
        onClose();
    };
    return (
        <tr>
            <td colSpan={6}>
                <Collapsable show={true}>
                    <h6 className="mt-3 mb-3">{translate.AddPayment}</h6>
                    <div className="row">
                        <div className="col">
                            <div className="d-flex input-column mb-3">
                                <label className="form-label">
                                    {translate.Date}
                                </label>
                                <DatePickerEditor defaultValue={payment.Date} onChange={requiredManager.makeRequiredWithId((newDate) => setPayment({ ...payment, Date: new Date(newDate).toJSON() }), 'invoiceDate')} />
                                <ValidationMessage onChange={requiredManager.getValidation("invoiceDate")} message={translate.ValidateDateFormat} defaultValue={payment.Date} />
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex input-column mb-3">
                                <label className="form-label">
                                    {translate.Type}
                                </label>
                                <Dropdown items={(CompanyService.getCompanyAuth()?.transactionTypes ?? []).map(x => ({ value: parseInt(x.Id), text: x.Value }))}
                                    optionLabel={translate.None} defaultValue={payment.TransactionTypeID || undefined}
                                    onChange={requiredManager.makeRequiredIf(CompanyService.getSetting("transactiontyperequired"), (value?: number) => (setPayment({ ...payment, TransactionTypeID: value ?? null })), "transactiontype")} />
                                {CompanyService.getSetting("transactiontyperequired") &&
                                    <ValidationMessage onChange={requiredManager.getValidation("transactiontype")} message={translate.RequiredField} defaultValue={payment.TransactionTypeID} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {CompanyService.getSetting("enablemulticurrencytransaction") && <div className="col">
                            <div className="d-flex input-column mb-3">
                                <label className="form-label">
                                    {translate.Currency}
                                </label>
                                <Dropdown onChange={onCurrencyChange} selectClasses="w-100" defaultValue={currencyId} items={CompanyService.getCurrencies().map(x => ({ value: x.CurrencyId, text: x.Name }))} />
                            </div>
                        </div>}
                        <div className="col">
                            <div className="d-flex input-column mb-3">
                                <label className="form-label">
                                    {translate.Amount}
                                </label>
                                <input type={"number"} className="form-control" defaultValue={payment.Amount} onChange={(event) => setPayment({ ...payment, Amount: event.target.valueAsNumber })} />
                            </div>
                        </div>
                        {!CompanyService.getSetting("enablemulticurrencytransaction") && <div className="col" />}
                        {CompanyService.getSetting("enablemulticurrencytransaction") && <div className="col">
                            <div className="d-flex input-column mb-3">
                                <label className="form-label">
                                    {translate.ExchangeRate}
                                </label>
                                <input className="invoice-type-input form-control" value={rate} type={"number"} onChange={(event) => setRate(event.target.valueAsNumber)} />
                            </div>
                        </div>}
                    </div>
                    <div className="d-flex input-column mb-3">
                        <label>{translate.Comments}</label>
                        <textarea rows={2} className="form-control" defaultValue={payment.Comments} onChange={(event) => setPayment({ ...payment, Comments: event.target.value })}></textarea>
                    </div>
                    <div className="row">
                        {additionalDefs.map(x => renderAdditionalForPayment(x))}
                    </div>
                    <div className="d-flex justify-content-end gap-2">
                        <button type="button" className="btn btn-primary" onClick={onSave}>{translate.Save}
                            {submiting && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                        </button>
                        <button className="btn btn-secondary" onClick={onClose}>{translate.Close}</button>
                    </div>
                </Collapsable>
            </td>
        </tr>);
};

const InvoiceStatusAmount = ({ response, onChange, isEditing, setEditing, canEdit = true }: { response: InvoiceGetResponse, onChange: () => void, isEditing: boolean, setEditing: (editing: boolean) => void, canEdit?: boolean }) => {
    const { translate } = TranslationService;
    //const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [newAmount, setNewAmount] = useState(response.item.amount);
    const [newCurrency, setNewCurrency] = useState(response.item.CurrencyID);

    const onSubmit = async () => {
        if (saving) {
            return;
        }
        if (newAmount === 0 || Number.isNaN(newAmount)) {
            setEditing(false);
            return;
        }
        setSaving(true);
        await InvoiceService.setField(response.item.IOID, InvoiceSetFields.amount.toString(), newAmount.toString());
        if (newCurrency !== response.item.CurrencyID) {
            await InvoiceService.setField(response.item.IOID, InvoiceSetFields.currency.toString(), newCurrency.toString());
            response.item.CurrencyID = newCurrency;
        }
        response.item.amount = newAmount;
        onChange();
        setEditing(false);
        setSaving(false);
    };

    const content = isEditing ?
        (<>
            <div className="row">
                <div className="col-auto">
                    <i className="far fa-times text-danger" style={{ marginTop: 10 }} onClick={() => setEditing(false)}></i>
                </div>
                <div className="col-auto">
                    <Dropdown selectClasses="" onChange={setNewCurrency} defaultValue={response.item.CurrencyID} items={CompanyService.getCurrencies().map(x => ({ value: x.CurrencyId, text: x.Name }))} />
                </div>
                <div className="col-auto">
                    <input onChange={event => setNewAmount(parseFloat(event.target.value))} className="form-control" defaultValue={response.item.amount} />
                </div>
                <div className="col-auto">
                    <button className="btn btn-success" onClick={onSubmit}>{translate.Save}</button>
                    {saving && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </div>
            </div>
        </>) :
        (<>
            <div className="row">
                {canEdit && CompanyService.canDo("edittransaction") && !CompanyService.getSetting("hideeditio") && CompanyService.getConfigItems().find(x => x.field === -3)?.ManualEdit &&
                    <div className="col-auto">
                        <i className="pointer hideOnPrint far fa-pen show-when-hovering-parent" onClick={() => setEditing(true)} ></i>
                    </div>
                }
                <div className="col">
                    <p className="form-label-detail">{formatCurrency(response.item.amount, response.item.CurrencyID, formatNumber)}</p>
                </div>
            </div>

        </>);

    return (
        <div className="d-flex input-row mb-3">
            <label className="form-label">{translate.Amount}</label>
            <div className="show-child-on-hover ml-auto">
                {content}
            </div>
        </div >);
};

function emptyFunction() { }

export default InvoiceDetail;
