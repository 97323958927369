import { useState } from "react";
import CompanyService, { DataType } from "../../../services/CompanyService";
import EmailTemplateService, { EmailTemplateListResponse } from "../../../services/EmailTemplateService";
import FilterService from "../../../services/FilterService";
import { formatDateDigits } from "../../../utils/FormatUtils";
import Dropdown from "../../shared/components/Dropdown";
import { AutocompleteClient, AutocompleteGroup, CheckBoxEditor, DatePickerEditor, FileListEditor, TextEditor } from "../../shared/components/Editors";
import { TranslationService } from "../../../services/TranslationService";
import { RequiredManager, ValidationMessage, ValidationMessageProps } from "../../shared/RequieredManager";
import { ToastService } from "../../shared/bootstrap/Toast";
import FloatingPanelService from "../../shared/FloatingPanel";
import ModalService from "../../shared/bootstrap/Modal";
import { FloatingPanelFooter } from "../../shared/components/FloatingPanelFooter";
import FileService from "../../../services/FileService";

type EmailTemplateItem = EmailTemplateListResponse["list"][number];

const EmailTemplateEdit = ({ reload, current = undefined }: { reload: () => void, current?: EmailTemplateItem }) => {
    const { translate } = TranslationService;
    const [emailTemplate, setEmailTemplate] = useState<EmailTemplateItem>(from(current));
    const [submiting, setSubmiting] = useState("");
    const requiredManager = new RequiredManager();
    const defaultOnChange = (fieldId: keyof EmailTemplateItem, required = false) => {
        let onChange = (value?: string) => setEmailTemplate({ ...emailTemplate, [fieldId]: value });
        if (required) {
            onChange = requiredManager.makeRequired(onChange) as (value?: string) => void;
        }
        return onChange;
    };
    const onContentChange = defaultOnChange("Content", true);
    const onInsertField = (value?: string) => {
        const elem = document.getElementById("content-texteditor") as HTMLTextAreaElement;
        const text = elem.value?.slice(0, elem.selectionStart) + `[${value}] ` + elem.value?.slice(elem.selectionStart);
        elem.value = text;
        onContentChange(text);
    };
    const fields = [
        {
            title: translate.Template,
            onChange: defaultOnChange("Name", true),
            type: DataType.Text,
            items: [],
            defaultValue: emailTemplate?.Name,
        },
        {
            title: translate.Subject,
            onChange: defaultOnChange("Subject", true),
            type: DataType.Text,
            items: [],
            defaultValue: emailTemplate?.Subject,
        },
        {
            title: translate.InsertField,
            onChange: onInsertField,
            type: DataType.List,
            items: [{ value: "", text: translate.InsertField }, ...FilterService.GetClientFilters().definitions.map(x => x.Field === "-1" ? TranslationService.translate.Client : x.Name).map(x => ({ value: x, text: x }))],
            defaultValue: undefined,
        },
        {
            title: "texteditor",
            onChange: onContentChange,
            type: DataType.Text,
            items: [],
            defaultValue: emailTemplate?.Content,
        },
        {
            title: translate.ActivityType,
            onChange: defaultOnChange("ActivityTypeID"),
            type: DataType.List,
            items: [{ value: "", text: translate.None }, ...CompanyService.getActivityTypes().filter(x => x.Mail).map(x => ({ value: x.ActivityTypeID, text: x.ActivityTypeName }))],
            defaultValue: emailTemplate?.ActivityTypeID?.toString() ?? CompanyService.getActivityTypes().find(x => x.Mail && x.MailDefault)?.ActivityTypeID?.toString(),
        },
    ];
    const onSubmit = async () => {
        if (submiting !== "") {
            return;
        }
        if (!requiredManager.validate()) {
            ToastService.showToast(translate.MissingRequiredFields);
            return;
        }
        setSubmiting("set");
        const result = await EmailTemplateService.set(emailTemplate);
        if (result instanceof Error) {
            ToastService.showToast(translate.ErrorProcessingRequest, undefined, "danger");
            setSubmiting("");
            return;
        }
        setSubmiting("");
        ToastService.showToast(translate.EmailTemplateSaved, undefined, "success");
        FloatingPanelService.hidePanel();
        reload();
    };
    const onDelete = () => {
        if (submiting !== "") {
            return;
        }
        const deleteEmailTemplate = async () => {
            if (submiting) {
                return;
            }
            setSubmiting("del");
            const result = await EmailTemplateService.delete(current!.MailTemplateID!);
            if (result instanceof Error) {
                ToastService.showToast(translate.ErrorProcessingRequest, undefined, "danger");
                setSubmiting("");
                return;
            }
            setSubmiting("");
            ToastService.showToast(translate.EmailTemplateDeleted, undefined, "success");
            FloatingPanelService.hidePanel();
            reload();
        };
        ModalService.showDefaultModal({
            acceptButtonLabel: translate.Delete,
            message: translate.AreYouSureDeleteEmailTemplate,
            onAcceptClick: deleteEmailTemplate,
        });
    };
    return (<>
        <div className="floatingBody p-4 pb-2">
            <div>
                {fields.map(x =>
                    <EmailTemplateFieldEdit key={x.title.replaceAll(" ", "")} {...x} />
                )}
                <FileListEditor
                    files={current?.files?.map(x => ({ id: x.id.toString(), name: x.fileName }))}
                    onFilesChange={files => setEmailTemplate(email => ({ ...email, files: files.map(x => ({ fileName: x.name, response: null, id: x.id })) }))}
                    canEdit={CompanyService.canDo("edittask")}
                    downloadFile={(fileId, fileName) => FileService.downloadEmailTemplateFile(emailTemplate.MailTemplateID.toString(), fileId, fileName)}
                />
            </div>
        </div>
        <FloatingPanelFooter >
            {current && <button type="button" className="btn btn-danger" onClick={onDelete}>
                {translate.Delete}
                {submiting === "del" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
            </button>}
            <button className='btn btn-primary me-3' onClick={onSubmit}>
                {translate.Save}
                {submiting === "set" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
            </button>
        </FloatingPanelFooter>
    </>
    );
};

const EmailTemplateFieldEdit = ({ type, title, onChange, items, defaultValue = undefined }: { onChange: (value?: string) => void, type: DataType, title: string, items: { value: string | number, text: string }[], defaultValue?: string }) => {
    let editor = undefined;
    let validationMethod: ValidationMessageProps<string>["validationMethod"] = undefined;
    let divClass = "";
    switch (type) {
        case DataType.Client:
            editor = <AutocompleteClient onChange={onChange} defaultValue={defaultValue} />;
            break;
        case DataType.Group:
            editor = items.length === 1 ? <AutocompleteGroup onChange={(value) => onChange(value!.value!)} clientId={items[0].text} /> : <input type="text" readOnly className="form-control-plaintext"></input>;
            break;
        case DataType.List:
            if (items.length === 0)
                throw new Error("items missing");
            editor = <Dropdown onChange={(x) => onChange(x !== undefined ? x.toString() : undefined)} items={items} defaultValue={defaultValue} />;
            break;
        case DataType.Date:
            editor = <DatePickerEditor onChange={(x) => onChange(formatDateDigits(new Date(x), "en"))} defaultValue={defaultValue} />;
            break;
        case DataType.Number:
        case DataType.Currency:
            editor = <input onChange={(event) => onChange(event.target.value)} type={"number"} className="form-control" defaultValue={defaultValue} />;
            break;
        case DataType.Text:
            editor = <TextEditor onChange={onChange} defaultValue={defaultValue} />;
            validationMethod = (value: string | undefined) => value !== undefined && value.length > 0;
            break;
        case DataType.YesNo:
            editor = <CheckBoxEditor onChange={(x) => onChange(x as unknown as string)} defaultValue={defaultValue?.toString() === "true"} message={""} />;
            break;
        default:
            throw new Error("Missing editor: " + DataType[type]);
    }
    if (title === "texteditor") {
        editor = <textarea onChange={event => onChange(event.target.value)} className="form-control h-100" style={{ resize: "none" }} value={defaultValue} id="content-texteditor" rows={6} />;
        title = "";
        divClass = " flex-grow-1";
    }
    return (
        <div className={"form-group mb-3" + divClass}>
            {title && <label>{title}</label>}
            {editor}
            {<ValidationMessage onChange={onChange} validationMethod={validationMethod} defaultValue={defaultValue} />}
        </div>
    );
};
export default EmailTemplateEdit;

function from(current: EmailTemplateItem | undefined): EmailTemplateItem {
    if (current) {
        return current;
    }
    return {
        Content: "",
        Created: new Date().toJSON(),
        CreatorUserID: Number(CompanyService.getUserid()),
        files: [],
        MailTemplateAttachments: null,
        MailTemplateID: undefined as unknown as number,
        MailTemplateID_ref: null,
        Name: "",
        Subject: "",
        ActivityTypeID: undefined,
    };
}
