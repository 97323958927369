import { ToastService } from "../pages/shared/bootstrap/Toast";
import { getError } from "../utils/RequestUtils";
import RequestPortalService from "./RequestPortalService";
import { TranslationService } from "./TranslationService";

class ExportPortalService {
    public static async requestExport(endpoint: string, request: {}, count: number = 1, fileName: string = "export.xls", customerToken: string) {
        const queryString = new URLSearchParams(request).toString();
        const endpointComplete = `${endpoint}?${queryString}`;
        ToastService.showToast(TranslationService.translate.Exporting + "...");

        const response = await RequestPortalService.downloadFile(endpointComplete, customerToken);
        if (response instanceof Error) {
            const { status } = getError(response);
            if (status === 409) {
                ToastService.showToast(TranslationService.translate.ThereIsProcessPending, undefined, "warning");
                return;
            }
            ToastService.showToast(TranslationService.translate.Network, undefined, "danger");
            return;
        }
        ToastService.showToast('Descarga completa', undefined, "success");
    }
}

export default ExportPortalService;