import { TranslationService } from "../services/TranslationService";

type StandardEnum<T> = {
    [id: string]: T | string;
    [nu: number]: string;
}

export function getEnumArray<T extends StandardEnum<number>>(e: T): { value: number, key: number, text: string }[] {
    const strings = Object.values(e);
    const values = strings.splice(strings.length / 2);
    return strings.map((x, i) => ({ value: values[i] as number, key: values[i] as number, text: TranslationService.getTranslation(x.toString())}));
}