import { useMemo } from "react";
import CompanyService from "../../../../services/CompanyService";
import { TranslationService } from "../../../../services/TranslationService";
import Dropdown from "../../../shared/components/Dropdown";
import { EditProps } from "../EditAction";
import { Field } from "./FieldHelpers";
import { ActionType } from "../../../../services/ActionService";

export const ActivityType = ({ setField, data }: EditProps) => {
    const activityTypeOptions = useMemo(() => CompanyService.getActivityTypes().filter(x => data.Type === ActionType.ActionSMSSend ? x.SMS : x.Mail).map(x => ({ value: x.ActivityTypeID, text: x.ActivityTypeName })), [data.Type]);
    const defaultType  = useMemo(() => CompanyService.getActivityTypes().filter(x => data.Type === ActionType.ActionSMSSend ? x.SMS : x.Mail).find(x => data.Type === ActionType.ActionSMSSend ? x.SMSDefault : x.MailDefault)?.ActivityTypeID, [data.Type]);
    if (activityTypeOptions.length === 0) {
        return <></>;
    }
    return (
        <Field title={TranslationService.translate.ActivityType2}>
            <Dropdown onChange={setField("ActivityTypeID")} items={activityTypeOptions} optionLabel={TranslationService.translate.None} defaultValue={data.ActivityTypeID ?? defaultType} />
        </Field>);
};