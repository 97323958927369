export function negate(func: (val: boolean) => void) {
    return (val: boolean) => func(!val);
}

const throttledCache: [Function, Date, any][] = [];
export function throttleCached<TFunc extends (...params: any) => any>(func: TFunc, ...params: Parameters<TFunc>): ReturnType<TFunc> {
    let val = throttledCache.find(x => x[0] === func);
    if (val === undefined) {
        val = [func, new Date(), func.apply(null, params)];
        throttledCache.push(val);
    } else if ((Date.now() - val[1].getTime()) > 500) {
        val[1] = new Date();
        val[2] = func.apply(null, params);
    }
    return val[2];
}