import CompanyService from "../../../services/CompanyService";
import { TranslationService } from "../../../services/TranslationService";
import { formatDateShort } from "../../../utils/FormatUtils";
import { parseIntOrDefault } from "../../../utils/ParseUtils";
import { Switch } from "../../../utils/Utils";

const LayoutAlert = () => {
    enum AlertType {
        NoAlert = 0, // Default
        BlockingSoon,
        Blocked,
        ExpiringSoon,
        ExpiringToday,
        Expired,
        UserExceded,
        ClientsExceded,
        LimitsExceded,
        ContactMe, // Deprecated
    }

    const block: string | null | undefined = CompanyService.getBlock();
    const expire: string | null | undefined = CompanyService.getExpire();

    let alertStatus: AlertType = AlertType.NoAlert;

    function getDayDifference(dateInCustomFormat: string | undefined | null): number | undefined {
        if (!dateInCustomFormat || dateInCustomFormat === null) {
            return undefined;
        }

        const parts = dateInCustomFormat.split("-");
        const date = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));

        const dateNow = new Date();
        dateNow.setHours(0, 0, 0, 0);

        const diff = date.getTime() - dateNow.getTime();
        //GET DAY DIFFERENCE
        return Math.ceil(diff / (1000 * 3600 * 24));
    }

    // function daysFromNow(dateInCustomFormat: string | null | undefined): Number {
    //     if (!dateInCustomFormat) {
    //         return 0;
    //     }
    //     const millis = Date.parse(dateInCustomFormat);
    //     const nowMillis = Date.now();
    //     return Math.ceil(Math.abs(millis - nowMillis) / (86400000));
    // }

    function renderClickeableAlert(baseText: string, linkText: string) {
        // FIXME: Show modal contact/_contactprice on click
        const a = baseText.split("{here}", 2);
        return <span className='d-inline-flex justify-content-between align-intems-center'>{a[0]}<button className='btn btn-text text-primary py-0 px-2 border-0'>{linkText}</button>{a[1]}</span>;
    }

    const diffBlock = getDayDifference(block);
    const diffExpire = getDayDifference(expire);

    if (diffBlock) {
        if (diffBlock > 0) {
            alertStatus = AlertType.BlockingSoon;
        }
        else {
            alertStatus = AlertType.Blocked;
        }
    }

    if (diffExpire) {
        if (diffExpire === 0) {
            alertStatus = AlertType.ExpiringToday;
        }
        else if (diffExpire < 0) {
            alertStatus = AlertType.Expired;
        }
        else if (diffExpire < 7) {
            alertStatus = AlertType.ExpiringSoon;
        }
    }

    const userLimitExceded = (CompanyService.getCompanyAuth()?.accountUserCount ?? 0) > parseIntOrDefault(CompanyService.getCompanyToken()?.userlimit, Number.MAX_SAFE_INTEGER);
    const totalCountUsers = (CompanyService.getCompanyAuth()?.accountUserCount ?? 0) + (CompanyService.getCompanyAuth()?.accountReadonlyUserCount ?? 0);
    const totalCountUsersLimit = parseIntOrDefault(CompanyService.getCompanyToken()?.readonlyuserlimit, Number.MAX_SAFE_INTEGER) + parseIntOrDefault(CompanyService.getCompanyToken()?.userlimit, Number.MAX_SAFE_INTEGER);
    const readOnlyUserLimitExceded = totalCountUsers > totalCountUsersLimit;        
    const clientsExceded = (CompanyService.getCompanyAuth()?.accountPersonCount ?? 0) > parseIntOrDefault(CompanyService.getCompanyToken()?.personlimit, Number.MAX_SAFE_INTEGER);
    const usersExceded = userLimitExceded || readOnlyUserLimitExceded;

    if (alertStatus === AlertType.NoAlert && CompanyService.getIsAdmin()) {
        alertStatus = Switch(
            [clientsExceded && usersExceded, AlertType.LimitsExceded],
            [usersExceded, AlertType.UserExceded],
            [clientsExceded, AlertType.ClientsExceded],
        ) ?? AlertType.NoAlert;
    }

    if (alertStatus === AlertType.NoAlert) {
        return <></>;
    }
    return (
        <div className='alert alert-danger d-flex justify-content-between align-intems-center mb-0' role='alert'>
            {alertStatus === AlertType.BlockingSoon &&
                <p className='mb-0'>{TranslationService.translate.AccountBlocking.replace("{day}", diffBlock!.toString())}</p>}
            {alertStatus === AlertType.Blocked &&
                <p className='mb-0'>{TranslationService.translate.AccountBlockedAlert.replace("{day}", diffBlock!.toString())}</p>}
            {alertStatus === AlertType.ExpiringSoon &&
                <p className='mb-0'>
                    {TranslationService.translate.TrialExpiresOn.replace("{days}", diffExpire!.toString()).replaceAll("{plural}", diffExpire! === 1 ? "" : "s")}
                    <span className="ps-2">
                        {renderClickeableAlert(TranslationService.translate.ClickHerePlans, TranslationService.translate.Here)}
                    </span>
                </p>}
            {alertStatus === AlertType.ExpiringToday &&
                <p className='mb-0'>{TranslationService.translate.ExpiresToday} {renderClickeableAlert(TranslationService.translate.ClickHerePlans, TranslationService.translate.Here)}</p>}
            {alertStatus === AlertType.Expired &&
                <p className='mb-0'>{TranslationService.translate.TrialExpiredOn.replace("{day}", formatDateShort(new Date(expire!)))}
                    {/* {renderClickeableAlert(translate.ClickHerePlans, translate.Here)} */}
                </p>}
            {alertStatus === AlertType.UserExceded &&
                <p className='mb-0'>{TranslationService.translate.UserExceeded}</p>}
            {alertStatus === AlertType.LimitsExceded &&
                <p className='mb-0'>{TranslationService.translate.LimitsExceded}</p>}
            {alertStatus === AlertType.ClientsExceded &&
                <p className='mb-0'>{TranslationService.translate.ClientsExceded}</p>}
        </div>
    );
};

export default LayoutAlert;