
export const PortalHeader = ({ personName, logo }: { personName: string, logo?: string }) => {
    return (
        <div id="offmenu">
            <header>
                <nav
                    className="justify-content-between align-items-center navbar navbar-expand-sm"
                    style={{ backgroundColor: '#86c8ea' }}
                // style={{ background: window.innerWidth > 768 ? `linear-gradient(to right, white, ${colorTheme.backgroundLight}, ${colorTheme.backgroundEmphasis} )` : '' }}
                >
                    <div className="col-12 col-md-8">
                        <div className={"row d-flex justify-content-center"}>
                            <div className="row d-flex justify-content-center justify-content-md-between col-12">
                                <div className="d-flex col-md-auto col-12 align-items-center justify-content-center justify-content-md-start gap-2">
                                    <div className="dashboard__total">
                                        {
                                            (logo && logo?.length > 0) ?
                                                <img
                                                    alt="Cliente"
                                                    src={logo}
                                                    width={145}
                                                    style={{ minHeight: 50, alignItems: 'center', justifyContent: 'center', paddingTop: 10 }} />
                                                :
                                                <h5 className="card-body m-3">{personName}</h5>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </div>
    )
}
