import React, { useCallback, useEffect, useState } from 'react'
import { TranslationService } from '../../../services/TranslationService';
import ButtonPrimary from '../../account/components/ButtonPrimary';
import CompanyService from '../../../services/CompanyService';
import * as yup from "yup";
import { TitleConfig } from './components/TitleConfig';

type TotalSegmentPrioritiesType = {
    initialValue?: number
    fullSize?: boolean
}

const MIN_INTERVAL = 3
const MAX_INTERVAL = 100

export const TotalSegmentPriorities = ({ initialValue = 0, fullSize = false }: TotalSegmentPrioritiesType) => {
    const [error, setError] = useState('')
    const [saveData, setSaveData] = useState(false)
    const [formData, setFormData] = useState({ totalsegmentpriorities: initialValue })

    const formDataSchema = yup.object({
        totalsegmentpriorities: yup.number()
            .min(MIN_INTERVAL, TranslationService.translate.Min3AndMax100ValueLimits)
            .max(MAX_INTERVAL, TranslationService.translate.Min3AndMax100ValueLimits)
            .required(TranslationService.translate.Min3AndMax100ValueLimits)
            .typeError(TranslationService.translate.Min3AndMax100ValueLimits),
    });

    const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevState) => ({ ...prevState, [name]: value }));
        setSaveData(true)
    }

    const saveValues = useCallback(() => {
        setSaveData(false)
        CompanyService.setConfigCompany({ totalsegmentpriorities: formData.totalsegmentpriorities })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    useEffect(() => {
        formDataSchema.validate(formData)
            .then(() => {
                setError('')
                setSaveData(true)
            })
            .catch((err) => setError(err.errors[0]))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])


    return (
        <div className={`${fullSize ? '' : 'col-md-8'} mx-3`}>
            <div className='d-flex col-md-6 justify-content-between align-items-center'>
                <TitleConfig title={TranslationService.translate.TotalSegmentPriorities} />
                <div className="col-sm-2">
                    <input
                        name='totalsegmentpriorities'
                        type="number"
                        className="form-control form-control-sm"
                        defaultValue={formData.totalsegmentpriorities}
                        onChange={handleInputChange} />
                </div>
            </div>

            <div className='d-flex justify-content-between align-items-center'>
                <div id="errorMsj" className="incorrect" style={{ color: "red", fontSize: "14px", marginTop: "10px" }}>{error}</div>
                {saveData && !error && <ButtonPrimary onClick={saveValues}>{TranslationService.translate.Save}</ButtonPrimary>}
            </div>
        </div>
    )
}
