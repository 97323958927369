import RequestService from "./RequestService";

export class ContactService {
    public static sendMessage(item: {
        name: string,
        email: string,
        company: string,
        message: string,
        phone: string,
        country: string,
    }): Promise<unknown | Error> {
        return RequestService.post("/contact/sendmessage", { item });
    }
}