import CompanyService from "../../services/CompanyService";
import { formatDateShort } from "../../utils/FormatUtils";
import FloatingPanelService from "../shared/FloatingPanel";
import { TranslationService } from "../../services/TranslationService";
import TaskDetail from "./components/TaskDetail";
import TaskStar from "./components/TaskStar";
import { TaskEntities } from "./entities/TaskListResponse";
import { TaskStatus } from "./entities/TaskStatus";
import TableContext from "./TableContext";
import { StatusText } from "./components/StatusText";
import { LinkWithHistory } from "../shared/BreadcrumbHistory";
import TooltipComponent from "../shared/TooltipComponent";

type TaskTableItemWithCheckboxProps = {
    onChange: (taskId: number, checked: boolean) => void;
    selectedTaskIds: number[];
}

export const TaskTableItemWithCheckbox = (props?: TaskTableItemWithCheckboxProps) => function TaskTableItemImpl({ data }: { data: TaskEntities.ListItem }) {
    const canEditTask = CompanyService.canDo("edittask");
    const responsibleUser = CompanyService.getUsers()?.find(x => x.Id === data.ResponsibleUserID?.toString())?.Value;
    const statusColor = (data.Status === TaskStatus.Pending && data.dueDays > 0) ? "text-danger" : "";
    const checkTask = CompanyService.canDo("edittask") || CompanyService.canDo("checkotherstask") || data.ResponsibleUserID?.toString() === CompanyService.getUserid();
    return (
        <TableContext.Consumer>
            {({ reload }) => {
                return (
                    <tr key={data.TaskID}
                        onClick={() => canEditTask && FloatingPanelService.showPanel({
                            children: <TaskDetail taskId={data.TaskID} clientId={data.PersonID} creatorUserId={data.CreatorUserID} dateCreated={data.Created} reload={reload} />,
                            title: TranslationService.translate.Task,
                            position: "center",
                            width: 700,
                            height: 700,
                        })} style={{ cursor: (canEditTask ? "pointer" : "") }}>
                        {props && <td className="w-30px" onClick={(event) => event.stopPropagation()}>
                            {checkTask && <input type="checkbox" className="checkbox-task" data-taskselected={data.TaskID} defaultChecked={props!.selectedTaskIds.includes(data.TaskID)}
                                onChange={(event) => props!.onChange(data.TaskID, (event.target as HTMLInputElement).checked)} />}
                        </td>}
                        <td className="w-200px">
                            <LinkWithHistory to={`client/details?id=${data.Person.PersonId}`}>
                                {data.Person.Name}
                            </LinkWithHistory>
                        </td>
                        <td className="w-200px">{responsibleUser}</td>
                        <td className={"w-100px " + statusColor}>{formatDateShort(new Date(data.dueDate))}</td>
                        <td>
                            <StatusText data={data} />
                        </td>
                        <td className="w-200px">
                            <TooltipComponent title={data.Description}>
                                <span>{data.Description}</span>
                            </TooltipComponent>
                        </td>
                        <td className="w-40px" onClick={(event) => event.stopPropagation()}><TaskStar {...data} /></td>
                    </tr>);
            }}
        </TableContext.Consumer>
    );
};

const TaskTableItem = TaskTableItemWithCheckbox();
export default TaskTableItem;