import React, { useCallback, useEffect, useState } from 'react'
import { TranslationService } from '../../../services/TranslationService';
import ButtonPrimary from '../../account/components/ButtonPrimary';
import * as yup from "yup";
import CompanyService from '../../../services/CompanyService';
import { TitleConfig } from './components/TitleConfig';

type PriorityIntervalsType = {
    initialValue1?: string
    initialValue2?: string
    fullSize?: boolean
}
const MIN_INTERVAL = 2
const MAX_INTERVAL = 8

export const PriorityIntervals = ({ initialValue1 = '', initialValue2 = '', fullSize = false }: PriorityIntervalsType) => {
    const [error, setError] = useState('')
    const [saveData, setSaveData] = useState(false)
    const [formData, setFormData] = useState({
        minInterval: initialValue1,
        maxInterval: initialValue2
    })

    const { translate } = TranslationService;

    const formDataSchema = yup.object({
        minInterval: yup.number()
            .max(yup.ref("minInterval"), translate.MinIntervalLessThanMax)
            .min(MIN_INTERVAL, translate.MinAndMaxValueLimits)
            .required(translate.MinAndMaxValueLimits)
            .typeError(translate.MinAndMaxValueLimits),
        maxInterval: yup.number()
            .min(yup.ref("minInterval"), translate.MaxIntervalGreaterThanMin)
            .max(MAX_INTERVAL, translate.MinAndMaxValueLimits)
            .required(translate.MinAndMaxValueLimits)
            .typeError(translate.MinAndMaxValueLimits)
    });

    const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevState) => ({ ...prevState, [name]: value }));
        setSaveData(true)
    }

    const saveValues = useCallback(() => {
        setSaveData(false)
        CompanyService.setConfigCompany({ rank1: formData.minInterval, rank2: formData.maxInterval })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    useEffect(() => {
        formDataSchema.validate(formData)
            .then(() => {
                setError('')
                setSaveData(true)
            })
            .catch((err) => setError(err.errors[0]))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])


    return (
        <div className={`${fullSize ? '' : 'col-md-8'} mx-3`}>
            <TitleConfig title={translate.PriorityIntervals} />
            <div className="d-flex gap-2 col-sm-8">
                <div className="col-sm-2">
                    <input
                        name='minInterval'
                        type="number"
                        className="form-control"
                        defaultValue={formData.minInterval}
                        onChange={handleInputChange} />
                </div>
                <div className="col-sm-2">
                    <input
                        name='maxInterval'
                        type="number"
                        className="form-control"
                        defaultValue={formData.maxInterval}
                        onChange={handleInputChange} />
                </div>
            </div>
            <div className='d-flex justify-content-between align-items-center'>
                <div id="errorMsj" className="incorrect" style={{ color: "red", fontSize: "14px", marginTop: "10px" }}>{error}</div>
                {saveData && !error && <ButtonPrimary onClick={saveValues}>{translate.Save}</ButtonPrimary>}
            </div>
        </div>
    )
}
