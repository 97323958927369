import { useMemo } from "react";
import CompanyService, { DataType, Entities } from "../../../../services/CompanyService";
import { TranslationService } from "../../../../services/TranslationService";
import Dropdown, { DropdownItems, DropdownProps } from "../../../shared/components/Dropdown";
import { ValidationMessage } from "../../../shared/RequieredManager";
import {  EditProps, strEvent } from "../EditAction";
import {  LetterSendMethods, EmailBccButton, EmailBccInput } from "./EmailComponents";
import { Field } from "./FieldHelpers";

type validFields = "bcc" | "cc" | "To" | "Notify";


export const LetterSendMethodOptions = ({ data, field, setField, items, title, propGen }:
    {
        data: EditProps["data"], field: validFields, setField: EditProps["setField"],
        items: DropdownProps<number>["items"], title: string | JSX.Element,
        propGen: EditProps["propGen"]
    }) => {
    //const [emailKey, typeKey, fieldKey] = datakeys.Letter_Method;
    const type = data.Letter_Method ?? LetterSendMethods.SendByMailToCustomer;
    const customerFields: DropdownItems<number> = useMemo(() =>
        [{ text: TranslationService.translate.Email, value: -1 },
        ...CompanyService.getAdditionalDefinitions()
            .filter(x => x.type === DataType.Text && x.entity === Entities.Client)
            .map(x => ({ text: x.Name, value: x.AdditionalDefinitionID }))], []);

    const emailProps = propGen.str.notRequired("Mail_To");
    return (
        <>
            <Field title={title}>
                <Dropdown items={items} {...propGen.num.notRequired("Letter_Method")} />
            </Field>
            {type === LetterSendMethods.SendByMailToCustomer &&
                <>
                    <Field title={TranslationService.translate.ReadEmailFrom}>
                        <Dropdown items={customerFields} defaultValue={data.Mail_ToTypeField}
                            onChange={setField("Mail_ToTypeField")} />
                    </Field>

                    <Field>
                        <div className="d-flex py-1" style={{ height: 40 }}>
                            <EmailBccButton data={data} setField={setField} dataKey={"Cc"} />
                            <EmailBccButton data={data} setField={setField} dataKey={"Bcc"} />
                        </div>
                    </Field>
                    <EmailBccInput data={data} setField={setField} propGen={propGen}
                        dataKey={"cc"} />
                    <EmailBccInput data={data} setField={setField} propGen={propGen}
                        dataKey={"bcc"} />
                </>
            }
            {type !== LetterSendMethods.SendByMailToCustomer &&
                <Field title={TranslationService.translate.Email}>
                    <input className="form-control" {...strEvent(emailProps)} />
                    <ValidationMessage {...emailProps} />
                </Field>
            }



        </>
    )
}