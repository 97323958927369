import React, { useCallback, useState } from "react";
import ClientService from "../../../services/ClientService";
import TableContext, { TableContextValues } from "../../task/TableContext";
import { applyFiltersHelper, calculatePageCount, setCurrentPageHelper, sortHelper, useEndpointData } from "../../../utils/TableUtils";
import { CustomerAnalysisListResponse } from "../entities/CustomerAnalysisListResponse";
import { CustomerAnalysisListRequest } from "../entities/CustomerAnalysisListRequest";

export class CustomerAnalysisTableContextValues extends TableContextValues<CustomerAnalysisListResponse, { extraFilters: string[], pagesize: any; }> {
    max: number = 0;
    request: CustomerAnalysisListRequest = new CustomerAnalysisListRequest();
    setRequest: (request: CustomerAnalysisListRequest) => void = () => { };
}

const ReportCustomerAnalysisProvider = ({ children, prevRequest = undefined }: React.PropsWithChildren<{ prevRequest?: CustomerAnalysisListRequest }>) => {
    const [request, setRequest] = useState(prevRequest ?? new CustomerAnalysisListRequest());
    const endpoint = useCallback(() => ClientService.getCustomerAnalysisList(request), [request]);
    const { loading, error, response, reload } = useEndpointData(endpoint);
    const pageCount = calculatePageCount(response);
    const { sort, setSortColumn } = sortHelper(request, setRequest);
    const applySearch = (keyword: any) => {
        setRequest({ ...request, text: keyword })
    };
    const value: CustomerAnalysisTableContextValues =
    {
        loading,
        error,
        response,
        setSortColumn,
        currentPage: request.page,
        setCurrentPage: setCurrentPageHelper(setRequest, pageCount),
        pageCount: pageCount,
        applyFilters: applyFiltersHelper(setRequest),
        applySearch,
        reload,
        sort,
        max: 0, //adaptar
        pageSize: request.pageSize,
        // setPageSize: setPageSizeHelper(setRequest),
        request,
        setRequest,
    }

    return (
        <TableContext.Provider value={value}>
            {children}
        </TableContext.Provider>
    )
}

export default ReportCustomerAnalysisProvider; 