import { ActionType } from "../../../services/ActionService"

export interface ActionGetResponse {
  response: string
  item: ActionGetResponse.Action
  Id: number
}

export class ActionRun {
  id: number;
  overrideto?: string;
  maxcount?: number;

  constructor(actionId: number) {
    this.id = actionId;
  }
}

export namespace ActionGetResponse {
  export interface Action {
    segments: Segment[] | undefined
    Action_AdditionalDefinition: ActionAdditionalDefinition[]
    files: File[]
    file: any
    when?: number
    ActionID: number
    Type: ActionType
    Name: string
    ActionFolderID?: number
    Created: string
    CreatorUserID: number
    Content: string
    Mail_Subject?: string
    Mail_From?: string
    Mail_To?: string
    Mail_Include?: boolean
    Mail_Attach?: boolean
    Task_Due?: number
    Task_ResponsibleUserID?: number
    Mail_Period?: number
    Mail_Frequency?: number
    Mail_AttachDue?: boolean
    Mail_IncludeDue?: boolean
    Mail_FromName?: string
    Hour?: string
    Disabled: boolean
    Mail_IOFilter?: string
    When?: number
    Mail_MinAmount?: number
    MaxAge?: number
    Mail_IncludeLink?: boolean
    Mail_SignatureID?: number
    Mail_IncludeAttachment?: boolean
    Letter_HasFile?: boolean
    Letter_Format?: number
    Letter_Method?: number
    LastRun?: string
    Mail_IncludeClaimable?: boolean
    Mail_AttachClaimable?: boolean
    Mail_IncludeDueClaimable?: boolean
    Mail_AttachDueClaimable?: boolean
    Mail_Saturday?: boolean
    Mail_Sunday?: boolean
    Mail_FromType?: number
    Mail_ToType?: number
    Day0?: boolean
    Day1?: boolean
    Day2?: boolean
    Day3?: boolean
    Day4?: boolean
    Day5?: boolean
    Day6?: boolean
    Mail_ToTypeField?: number
    Task_ResponsibleType?: number
    Task_ValidatorUserID?: number
    Task_HasTags: any
    Language?: string
    MinInterval?: number
    HasAttachments?: boolean
    SMS_ToTypeField?: number
    MailFrameID?: number
    ActivityTypeID?: number
    ErrorFilter?: boolean
    Mail_cc?: string
    Mail_bcc?: string
    Mail_CCType?: number
    Mail_BCCType?: number
    Mail_CCField?: number
    Mail_BCCField?: number
    MailEngine?: number
    FTP_Folder?: string
    ActionID_ref: any
    ReportExportID?: number
    IOListNoSumarize: any
    FTP_Filename?: string
    BlobName?: string
    NotifyTo?: number
    NotifyToEmail?: string
    Mail_GroupBy?: number
    IncludeClientData?: boolean
    HasAdditionalDefinitions?: boolean
    Mail_ReportExportID?: number
    Mail_OrderBy: string
    Mail_OrderByOrderDesc: string
    WATemplateID?: string
    WATemplate?: string
  }

  export interface Segment {
    UserID?: number
    Id: string
    Value: string
  }

  export interface ActionAdditionalDefinition {
    ActionID: number
    AdditionalDefinitionID: number
    Value: string
  }

  export interface File {
    response: any
    fileName: string
    id: string
  }

}