import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FilterRequest } from "../../entities/Filter/FilterRequest";
import { ReportActivitySummaryResponse } from "../../entities/reports/ReportActivitySummary/ReportActivitySummaryResponse";
import ActivityService from "../../services/ActivityService";
import AdvancedFilterService, { Filter } from "../../services/AdvancedFilterService";
import CompanyService from "../../services/CompanyService";
import ExportService from "../../services/ExportService";
import FilterService, { FilterCollection } from "../../services/FilterService";
import { TranslationService } from "../../services/TranslationService";
import { formatIntizaDate } from "../../utils/FormatUtils";
import { sumList } from "../../utils/Utils";
import AdvancedFilters, { AdvancedFiltersButton } from "../shared/components/AdvancedFilters";
import Table, { TableHeader } from "../shared/Table";
import TableContext, { TableContextValues } from "../task/TableContext";
import { ButtonTooltipIcon } from "../shared/components/ButtonTooltipIcon";

export class ReportActivitySummaryTableContextValues extends TableContextValues<ReportActivitySummaryResponse, { extraFilters: string[] }> {
    total = 0;
}

const ReportActivitySummary = () => {
    const { translate } = TranslationService;
    const [response, setResponse] = useState<ReportActivitySummaryResponse>({} as ReportActivitySummaryResponse);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [filter, setFilter] = useState<string>(getDefaultFilter);
    const requestData = async () => {
        setLoading(true);
        const result = await ActivityService.getSummary({ page: 0, filter, quickfilter: undefined });
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        setResponse(result);
        setLoading(false);
        setError(false);
    };

    const requestDataCallback = useCallback(requestData, [filter]);

    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    const tableValues = new ReportActivitySummaryTableContextValues();
    const applyFilters = (filters: string[]) => {
        baseFilters = filters;
        setFilter(AdvancedFilterService.filterStringsToQueryString(filters));
    };

    tableValues.error = error;
    tableValues.response = response;
    tableValues.loading = loading;
    tableValues.reload = requestDataCallback;
    tableValues.total = 0;

    const headers: TableHeader[] = [
        new TableHeader("Usuario", translate.User),
        new TableHeader("Clientes", translate.ManagedCustomers, true, false),
        new TableHeader("Comentarios", translate.Comments, true, false),
        new TableHeader("Tareas", translate.Tasks, true, false),
        new TableHeader("Mails", translate.OutgoingEmails, true, false),
        new TableHeader("SMS", translate.SMS, true, false),
        new TableHeader("Whatsapp", translate.WhatsApp, true, false),
        new TableHeader("Total", translate.Total, true, false),
    ];
    const doExport = async () => {
        await ExportService.requestExport("/activity/exportsummary", { filter, additionaldefinitions: CompanyService.getAdditionalDefinitions(), currencies: CompanyService.getCurrencies() }, tableValues.pageCount ?? 1);
    };

    return (
        <TableContext.Provider value={tableValues} >
            <div className="container-fluid padding">
                <div className="d-flex justify-content-between">
                    <h2 className="mb-3">{TranslationService.translate.ActivitySummary}</h2>
                </div>
                <div className="card p-0">
                    <div className="genericHeader">
                        <div className="d-flex actionButtonsHeader">
                            {CompanyService.canDo("export") &&
                                <ButtonTooltipIcon
                                    title={TranslationService.translate.Export}
                                    icon="fa-light fa-arrow-down-to-line text-granite-gray"
                                    onClick={doExport}
                                />
                            }
                            <AdvancedFiltersButton />
                        </div>
                    </div>
                    <div className="pt-0">
                        <AdvancedFilters onFilterApply={applyFilters} page={FilterCollection.ReportActivity} defaultValue={filter} />
                    </div>
                    <div className="p-4">
                        <Table headers={headers} item={ReportActivitySummaryItem} stickyHeader>
                            <ReportActivitySummaryTotal response={response} />
                        </Table>
                    </div>

                </div>
            </div>


        </TableContext.Provider>
    );
};
let baseFilters: string[] = [];
const ReportActivitySummaryItem = ({ data }: { data: ReportActivitySummaryResponse.Item }) => {
    const filterDefinitions = FilterService.GetFiltersForPage(FilterCollection.ReportActivity).flatMap(x => x.definitions);
    const getQuerystring = (userId: number | null, typeId: number | null) => {
        const filters = baseFilters;
        const filterRequest: FilterRequest[] = [];
        if (userId !== null) {
            filterRequest.push({
                field: "-102",
                operator: 0,
                value: userId.toString()
            });
        }
        if (typeId !== null) {
            filterRequest.push({
                field: "-103",
                operator: 0,
                value: typeId.toString()
            });
        }
        const linkFilters = filterRequest.map<Filter>(x => {
            const definition = filterDefinitions.find(y => x.field === y.Field)!;
            return ({
                kind: definition.Type,
                definition,
                value: x.value,
                operator: x.operator
            });
        });

        const stringFilters = linkFilters.map(AdvancedFilterService.filterToString);

        return "reportactivity?filter=" + encodeURIComponent(FilterService.GetExtraFiltersRequestString([...filters, ...stringFilters]) || "");
    };
    return (
        <tr>
            <td>
                <Link to={getQuerystring(data.UserID, null)}>
                    {data.FullnameInv}
                </Link>
            </td>
            <td className="text-end">{data.Persons}</td>
            <td className="text-end">
                <Link to={getQuerystring(data.UserID, 2)}>{data.Comments || "-"}</Link>
            </td>
            <td className="text-end">
                <Link to={getQuerystring(data.UserID, 3)}>{data.Tasks || "-"}</Link>
            </td>
            <td className="text-end">
                <Link to={getQuerystring(data.UserID, 1)}>{data.Mails || "-"}</Link>
            </td>
            <td className="text-end">
                <Link to={getQuerystring(data.UserID, 4)}>{data.SMS || "-"}</Link>
            </td>
            <td className="text-end">
                <Link to={getQuerystring(data.UserID, 6)}>{data.Whatsapp || "-"}</Link>
            </td>
            <td className="text-end">
                <Link to={getQuerystring(data.UserID, null)}>
                    {(data.Comments + data.Tasks + data.Mails + data.SMS + data.Whatsapp) || "-"}
                </Link>
            </td>
        </tr>
    );
};

const ReportActivitySummaryTotal = ({ response }: { response: ReportActivitySummaryResponse }) => {
    if ((response?.list.length ?? 0) === 0) {
        return <tr><td></td><td></td><td></td><td></td></tr>;
    }

    const users = sumList(response.list, x => x.Persons);
    const comments = sumList(response.list, x => x.Comments);
    const tasks = sumList(response.list, x => x.Tasks);
    const mails = sumList(response.list, x => x.Mails);
    const smss = sumList(response.list, x => x.SMS);
    const was = sumList(response.list, x => x.Whatsapp);
    const total = comments + tasks + mails + smss + was;

    return (
        <tr className="font-weight-bold">
            <td>{TranslationService.translate.Total}</td>
            <td className="text-end">{users}</td>
            <td className="text-end">{comments}</td>
            <td className="text-end">{tasks}</td>
            <td className="text-end">{mails}</td>
            <td className="text-end">{smss}</td>
            <td className="text-end">{was}</td>
            <td className="text-end">{total}</td>
        </tr>
    );
};

function getDefaultFilter() {
    const filterDefinitions = FilterService.GetFiltersForPage(FilterCollection.ReportActivity).flatMap(x => x.definitions);
    const definition = filterDefinitions.find(x => x.Field === "-100")!;
    const startDate = new Date();
    startDate.setDate(1);
    const endDate = new Date();
    endDate.setMonth(startDate.getMonth() + 1, 0);
    const dateFilter: Filter = {
        kind: definition.Type,
        definition,
        value: formatIntizaDate(startDate) + "-" + formatIntizaDate(endDate),
        operator: 0,
    };

    const stringFilter = AdvancedFilterService.filterToString(dateFilter);
    baseFilters = [stringFilter];

    return FilterService.GetExtraFiltersRequestString(baseFilters) || "";
}

export default ReportActivitySummary;