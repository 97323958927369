import { AdditionalDefinitionsListResponse } from "../../../../services/FieldConfigurationService";
import FloatingPanelService from "../../../shared/FloatingPanel";
import { TranslationService } from "../../../../services/TranslationService";
import AdditionalDefinitionsEdit from "./AdditionalDefinitionsEdit";
import { CompanyAuth } from "../../../../entities/login/CompanyAuth";
import FixedFieldEdit from "./FixedFieldEdit";
import CompanyService, { DataType, Entities } from "../../../../services/CompanyService";
import { LanguageCache } from "../../../../utils/PersistenceUtils";
import { InvoiceFields } from "../../../../services/InvoiceService";
import { Switch } from "../../../../utils/Utils";

export const EntityFieldItem = (props: { data: (CompanyAuth.ConfigItem | AdditionalDefinitionsListResponse.Item), dragEvents: object, reload: () => void, valueSearch?: string }) => {
    return ("AdditionalDefinitionID" in props.data) ? <AdditionalField {...props} data={props.data} valueSearch={props?.valueSearch} /> : <FixedField {...props} data={props.data} valueSearch={props?.valueSearch} />;
};

const AdditionalField = ({ data, dragEvents, reload, valueSearch }: { data: AdditionalDefinitionsListResponse.Item, dragEvents: object, reload: () => void, valueSearch?: string }) => {
    const showAdditionalDefinitionsEditModal = (AdditionalDefinitions?: AdditionalDefinitionsListResponse["list"][number]) => {
        FloatingPanelService.showPanel({
            children: <AdditionalDefinitionsEdit reload={reload} fieldId={AdditionalDefinitions?.AdditionalDefinitionID} />,
            title: Switch(
                [data.Entity === Entities.Client, TranslationService.translate.EditCustomersFields],
                [data.Entity === Entities.Invoice, TranslationService.translate.EditInvoiceField],
                [data.Entity === Entities.Payment, TranslationService.translate.EditPaymentFields],
            ) ?? "",
            width: 600,
            height: 800,
            position: "center",
        });
    };
    if (valueSearch && !data.Name.toLocaleLowerCase()?.includes(valueSearch.toLocaleLowerCase())) {
        return null;
    }
    return (<tr key={data.AdditionalDefinitionID} className="pointer configuration" onClick={() => showAdditionalDefinitionsEditModal(data)}
        {...dragEvents} data-id={data.AdditionalDefinitionID} draggable={data.Entity !== Entities.Payment} >
        <td className="fst-italic">{data.Name}</td>
        <td>{getDataTypeNames().find(x => x.value === data.Type)?.text}</td>
        {data.Entity !== Entities.Payment && <>
            <td width={200} style={{ textAlign: "center" }}>{data.IsPK && <i className="fas fa-check fa-lg" />}</td>
            {data.Entity === Entities.Client && <td width={200} style={{ textAlign: "center" }}>{data.IncludeInCustomerPortal && <i className="fas fa-check fa-lg" />}</td>}
        </>}
        <td width={200}><button className="edit"><i className="far fa-pen undefined"></i></button></td>
    </tr>);
};

const FixedField = ({ data, dragEvents, reload, valueSearch }: { data: CompanyAuth.ConfigItem, dragEvents: object, reload: () => void, valueSearch?: string }) => {
    const showAdditionalDefinitionsEditModal = (fixedField: CompanyAuth.ConfigItem) => {
        if (data.Entity === Entities.Payment) {
            return;
        }
        FloatingPanelService.showPanel({
            children: <FixedFieldEdit reload={reload} field={fixedField} data={data} />,
            title: Switch(
                [data.Entity === Entities.Client, TranslationService.translate.EditCustomersFields],
                [data.Entity === Entities.Invoice, TranslationService.translate.EditInvoiceField],
                [data.Entity === Entities.Payment, TranslationService.translate.EditPaymentFields],
            ) ?? "",
            width: 600,
            height: 380,
            position: "center",
        });
    };
    const canEdit = (data.Entity !== Entities.Payment) &&
        !(data.Entity === Entities.Invoice && data.field === -1);
    const typeNames = getDataTypeNames();
    const title = data.name !== "" && data.name != null ? data.name : CompanyService.getEntityFieldTitle(data.Entity, data.field);

    if (((title == null || title === "") && data.Entity === Entities.Invoice && data.field === InvoiceFields.group)
        || (valueSearch && !title?.toLocaleLowerCase()?.includes(valueSearch.toLocaleLowerCase()))
    ) {
        return null;
    }

    const type = CompanyService.getEntityDefaultFieldType(data.Entity, data.field);
    return (<tr key={data.field} className={"configuration" + (canEdit ? " pointer" : "")} onClick={() => showAdditionalDefinitionsEditModal(data)}
        {...dragEvents} data-id={data.field} draggable={data.Entity !== Entities.Payment}>
        <td className="fw-bold">{title}</td>
        <td className={(type === DataType.Group) ? "fw-bold" : ""}>{typeNames.find(x => x.value === type)?.text ?? ""}</td>
        {data.Entity !== Entities.Payment && <>
            <td width={200} style={{ textAlign: "center" }}>{data.field === -4 && data.Entity === Entities.Invoice && <i className="fas fa-check fa-lg" />}</td>
            {data.Entity === Entities.Client && <td width={200} style={{ textAlign: "center" }}>{data.IncludeInCustomerPortal && <i className="fas fa-check fa-lg" />}</td>}
        </>}
        <td width={200}>{canEdit && <button className="edit"><i className="far fa-pen"></i></button>}</td>
    </tr>);
};

const dataTypesGenerator = () => [
    { value: DataType.Text, text: TranslationService.translate.Freetext },
    { value: DataType.List, text: TranslationService.translate.List },
    { value: DataType.Date, text: TranslationService.translate.Date },
    { value: DataType.Number, text: TranslationService.translate.Number },
    { value: DataType.Currency, text: TranslationService.translate.Currency2 },
    { value: DataType.Phone, text: TranslationService.translate.Phone },
    { value: DataType.Link, text: TranslationService.translate.Link },
    { value: DataType.Group, text: TranslationService.translate.Group },
];
let dataTypesCache: LanguageCache<typeof dataTypesGenerator> | undefined = undefined;
export const getDataTypeNames = () => {
    if (dataTypesCache === undefined) {
        dataTypesCache = new LanguageCache(dataTypesGenerator);
    }
    return dataTypesCache.get();
};