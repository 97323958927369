import { useState } from "react";
import CompanyService, { DataType, Entities } from "../../../services/CompanyService";
import Dropdown from "../../shared/components/Dropdown";
import { EntityField, sortEntityFields } from "../../shared/entities/EntityField";
import { TranslationService } from "../../../services/TranslationService";
import { InvoiceEditStatus } from "./entities/InvoiceEditStatus";
import FieldEdit, { ifOldFormat } from "./InvoiceMassiveChangesFieldEdit";
import { InvoiceGetResponse } from "./entities/InvoiceGetResponse";
import { Cast } from "../../../utils/Utils";
import { RequiredManager } from "../../shared/RequieredManager";
import { getClientDefaultFieldProperty } from "../../../services/ClientService";

export const InvoiceChangeStatusForm = ({ onSubmit, personId, defaultStatusId, defaultAdditionals }: {
    onSubmit: (statusEdit: InvoiceEditStatus) => Promise<void>, personId: string, defaultStatusId?: number, defaultAdditionals?: {
        Id: string;
        Value: string;
    }[]
}) => {
    const invoice = defaultAdditionals ? Cast<InvoiceGetResponse.Item>({ additionals: defaultAdditionals }) : undefined;
    const [status, setStatus] = useState(new InvoiceEditStatus(CompanyService.getUserid(), defaultStatusId, invoice));
    const [isSaving, setIsSaving] = useState(false);
    const { translate } = TranslationService;
    const statusList = CompanyService.getIoStatus();

    const fields = CompanyService.getAdditionalDefinitionsFiltered(Entities.Invoice)
        .filter(x => x.IOStatusDependencies.filter(y => y.IOStatusID === status.statusId).length > 0)
        .map(x => new EntityField(x))
        .sort(sortEntityFields(CompanyService.getIoSortedFields().split(",")));

    const requiredFields = fields.map(field => ({
        id: field.id, required: CompanyService.getAdditionalDefinitionsFiltered(Entities.Invoice)
            .find(x => x.AdditionalDefinitionID === field.id)
            ?.IOStatusDependencies.find(y => y.IOStatusID === status.statusId)
            ?.Mandatory
    }));

    const defValues = fields
        .filter(x => x.type === DataType.Date && !status.additionals.map(x => x.Id).includes(x.id))
        .map(x => ({ Id: x.id, Value: new Date().toJSON() }));

    const requiredFieldsIds = requiredFields.filter(fx => fx.required).map(f => f.id);
    const requiredManager = new RequiredManager();

    const onFieldValueChange = (field: EntityField) => (value: string | undefined) => {
        if (!status) { return; }
        if (value && field?.type === DataType.Date) {
            value = ifOldFormat(value);
        }

        if (field.isDefault()) {
            const clientProperty = getClientDefaultFieldProperty(field.id);
            setStatus({ ...status, [clientProperty]: value });
        }
        else {
            const newAdditionals = [...status.additionals.filter(x => x.Id !== field.id), { Id: field.id, Value: value ?? "" }];
            setStatus({ ...status, additionals: newAdditionals });
        }
    };

    const statusChange = (id?: number) => {
        setStatus(new InvoiceEditStatus(status.userId, id));
    };

    const saveStatus = async () => {
        if (isSaving || !requiredManager.validate()) {
            return;
        }
        setIsSaving(true);
        await onSubmit(status);
        setIsSaving(false);
    };

    if (defValues.length > 0) {
        setStatus(x => ({ ...x, additionals: [...x.additionals, ...defValues] }));
    }

    return (
        <>
            <div className="modal-body">
                <div className="d-flex input-column mb-3">
                    <label className="form-label">
                        {translate.NewStatus}
                    </label>
                    <Dropdown optionLabel={translate.Select} onChange={statusChange} items={statusList.map(x => ({ value: x.IOStatusID, text: x.Value }))} defaultValue={status.statusId} />
                </div>
                {fields.map(field =>
                    <FieldEdit
                        key={field.id}
                        field={field}
                        onChange={onFieldValueChange(field)}
                        status={status}
                        personId={personId}
                        invoice={invoice}
                        requiredManager={requiredManager}
                        fieldsToValidate={requiredFieldsIds}
                    />
                )}
            </div>
            <div className="modal-footer">
                <button className='btn btn-primary' onClick={saveStatus} disabled={status.statusId === undefined}>
                    {translate.Save}
                    {isSaving && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </div>
        </>
    );
};