import RequestService from "./RequestService";

class EmailTemplateService {
    public static get(id: number): Promise<EmailTemplateGetResponse | Error> {
        return RequestService.post("/emailtemplate/get", {id});
    }
    public static getList(): Promise<EmailTemplateListResponse | Error> {
        return RequestService.post("/emailtemplate/list", {});
    }
    public static set(email: EmailTemplateListResponse.EmailItem): Promise<EmailTemplateGetResponse | Error> {
        return RequestService.post("/emailtemplate/set", {response: "ok", item: email});
    }
    public static delete(id: number): Promise<unknown | Error> {
        return RequestService.post("/emailtemplate/delete", { id });
    }
}
export default EmailTemplateService;

export interface EmailTemplateListResponse {
    Response: string
    list: EmailTemplateListResponse.EmailItem[]
}

export interface EmailTemplateGetResponse {
    Response: string
    item: EmailTemplateListResponse.EmailItem
}
export namespace EmailTemplateListResponse {
    export interface EmailItem {
        files: File[]
        MailTemplateAttachments: null
        MailTemplateID: number
        Created: string
        CreatorUserID: number
        Content: string
        Name: string
        MailTemplateID_ref: null
        ActivityTypeID?: number
        Subject: string
    }
    export interface File {
        response: null
        fileName: string
        id: string
    }
}
