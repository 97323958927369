import CompanyService from "./CompanyService";
import { TranslationService } from "./TranslationService";

class PriorityService {
    private static rankings: number[];
    private static totalPriorities = 0;

    public static getRankings(): number[] {
        if (this.rankings && this.rankings !== null) {
            return this.rankings;
        }
        this.rankings = [];
        for (let i = 0; i < this.getTotalPriorities(); i++) {
            this.rankings.push(i);
        }
        return this.rankings;
    }

    private static getTotalPriorities() {
        if (this.totalPriorities === 0) {
            this.totalPriorities = CompanyService.getTotalPriorities()!;
        }
        return this.totalPriorities;
    }


    public static getRankingName() {
        return [
            TranslationService.translate.HighManagementPriority,
            TranslationService.translate.ManagementMediumPriority,
            TranslationService.translate.LowManagementPriority,
            TranslationService.translate.WithDebtCloseToMaturity,
            TranslationService.translate.NoDebtComingDue,
        ]
    }


    public static getRankingIndex(priority?: number) {
        if (priority === null || priority === undefined) {
            return null;
        }
        if (this.getTotalPriorities() === 3) {
            switch (priority) {
                case 1: return 1;
                case 2: return 3;
                case 3: return 5;
            }
        }

        if (this.getTotalPriorities() === 4) {  
            return priority;
        }

        const prio = (priority ?? 0) / this.getTotalPriorities();
        if (prio <= 0.2) {
            return 1;
        }
        else if (prio <= 0.4) {
            return 2;
        }
        else if (prio <= 0.6) {
            return 3;
        }
        else if (prio <= 0.8) {
            return 4;
        }
        else {
            return 5;
        }
    }

    public static getPriorityRankText(priority: number) {
        let ranking = 2;

        if (!CompanyService.getEnabledSegmentPriorities()) {
            ranking = priority - 1;
        }
        else {
            const prio = priority / this.getTotalPriorities();

            if (prio <= 1 / 3) {
                ranking = 0;
            }
            else if (prio <= 2 / 3) {
                ranking = 1;
            }
        }
        return this.getRankingName()[ranking];
    }

}

export default PriorityService;