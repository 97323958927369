import { useMemo } from "react";
import { v4 } from "uuid";

const SwitchButton = (props: { label?: string, onChange: (val: boolean) => void, defaultValue?: boolean, disabled?: boolean, danger?: boolean }) => {
    const id = useMemo(v4, []);
    return (
        <div className="form-check form-switch">
            <input className={"form-check-input" + (props.danger ? " dangerous" : "")} type="checkbox" role="switch" id={id}
                onChange={(e) => props.onChange(e.target.checked)} checked={props.defaultValue} disabled={props.disabled} />
            <label className="form-check-label" htmlFor={id}>{props.label}</label>
        </div>
    );
};

export default SwitchButton;