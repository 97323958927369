import { ActivityTypeEntity } from "../entities/company/ActivityType/ActivityTypeEntity";
import { ActivityTypeGetResponse } from "../entities/company/ActivityType/ActivityTypeGetResponse";
import { ActivityTypeListResponse } from "../entities/company/ActivityType/ActivityTypeListResponse";
import { ActivityTypeSummaryResponse } from "../entities/company/ActivityType/ActivityTypeSummaryResponse";
import CompanyService from "./CompanyService";
import ExportService from "./ExportService";
import RequestService from "./RequestService";

class ActivityTypeService {

    public static get(id: number): Promise<Error | ActivityTypeGetResponse> {
        return RequestService.post("/activitytype/get", { id });
    }

    public static set(activitytype: ActivityTypeEntity): Promise<Error | unknown> {
        return RequestService.post("/activitytype/set", { item: activitytype });
    }

    public static delete(id: number): Promise<Error | unknown> {
        return RequestService.post("/activitytype/delete", { id });
    }

    public static getList(): Promise<Error | ActivityTypeListResponse> {
        return RequestService.post("/activitytype/list", {});
    }

    public static getSummary(filter: string): Promise<Error | ActivityTypeSummaryResponse> {
        return RequestService.post("/activitytype/summary", { filter, additionaldefinitions: CompanyService.getAdditionalDefinitions(), activitytypes: CompanyService.getActivityTypes() });
    }

    public static async exportActivityTypeSummary(filter: string, itemCount: number) {
        return ExportService.requestExport("/activitytype/exportsummary", { filter, additionaldefinitions: CompanyService.getAdditionalDefinitions(), activitytypes: CompanyService.getActivityTypes() }, itemCount);
    }
}


export default ActivityTypeService;