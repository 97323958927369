import { useEffect, useRef } from "react";
import { Collapse } from "bootstrap";

const Collapsable: React.FC<{ show: boolean }> = (props) => {
    const collapsableDiv = useRef(null);
    useEffect(() => {
        if (collapsableDiv.current) {
            Collapse.getOrCreateInstance(collapsableDiv.current, {toggle: props.show})[props.show ? 'show' : 'hide']();
        }
    }, [props.show])
    return (
        <div className="collapse" ref={collapsableDiv}>{props.children}</div>
    );
}
export default Collapsable;
