import { useContext, useRef, useState } from "react";
import FileService from "../../../../../services/FileService";
import ToastContext from "../../../../shared/bootstrap/Toast";
import { TranslationService } from "../../../../../services/TranslationService";
import { ImportTemplateListResponse } from "../../../../shared/entities/ImportTemplateListResponse";
import { UpdateModelFunc } from "../ImportTemplateEditBase";
import ModalService from "../../../../shared/bootstrap/Modal";

const UploadTemplate = ({ model, updateModel, formatsAllowed = ".xlsx" }:
    { formatsAllowed?: string, model: Partial<ImportTemplateListResponse.Item>, updateModel: UpdateModelFunc, }) => {
    const { showToast } = useContext(ToastContext);
    const { translate } = TranslationService;
    const [loadingFileUpload, setLoadingFileUpload] = useState(false);
    const fileUploaded = useRef<File | null>(null);

    const onFileChange = async (fileList: FileList | null) => {
        const fileUpload = async (file: File) => {
            const result = await FileService.upload(file);
            if (result instanceof Error) {
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
                return result;
            }
            fileUploaded.current = file;
            return { id: result.id, fileName: result.fileName };
        };
        if (!(fileList?.length)) { return; }
        const file = fileList[0];
        setLoadingFileUpload(true);
        const result = await fileUpload(file);
        setLoadingFileUpload(false);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
        updateModel("BlobName")(result.id);
        updateModel("templateFileID")(result.id);
        updateModel("HasTemplate")(true);
    };

    const deleteTemplate = () => {
        ModalService.showDefaultModal({
            acceptButtonClassName: "btn btn-danger",
            acceptButtonLabel: TranslationService.translate.Delete,
            onAcceptClick: () => {
                updateModel("BlobName")("");
                updateModel("templateFileID")(undefined);
                updateModel("HasTemplate")(false);
            },
            message: TranslationService.translate.SureDeleteImportTemplate
        });
    };

    return (<>
        <label className="col-form-label">{translate.Template}</label>
        <div className="mb-3">
            {model?.HasTemplate && <div className="d-flex">
                <button type="button" className="btn btn-primary me-3" onClick={() => model.BlobName?.startsWith("temp") ? FileService.downloadTempFile(fileUploaded.current!) : FileService.downloadImportTemplate(model!.ImportTemplateID!.toString())}>{TranslationService.translate.Download}</button>
                <button type="button" className="btn btn-outline-danger me-3" onClick={deleteTemplate}>{TranslationService.translate.Delete}</button>
            </div>}
            {!model?.HasTemplate &&
                <label className='text-start pl-0 font-weight-normal'>
                    <input type="file" className="d-none" accept=".xlsx" onChange={(e) => onFileChange(e.target.files)} />
                    <span className="btn btn-primary me-3 has-pointer"><i className="fal fa-paperclip me-2" /> {translate.AttachFile}</span>
                    {translate.WarningTheFileHasToBeXLSXType}
                    {loadingFileUpload && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </label>
            }
        </div>
    </>);
};

export default UploadTemplate;