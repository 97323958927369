import { useEffect, useMemo } from "react";
import CompanyService from "../../../../services/CompanyService";
import { TranslationService } from "../../../../services/TranslationService";
import { getEnumArray } from "../../../../utils/EnumUtils";
import { capitalize } from "../../../../utils/FormatUtils";
import Collapsable from "../../../shared/bootstrap/Collapsable";
import Dropdown from "../../../shared/components/Dropdown";
import { ValidationMessage } from "../../../shared/RequieredManager";
import { EditProps, numEvent, strEvent } from "../EditAction";
import { EmailInput } from "./EmailInput";
import { TextWithField, Field } from "./FieldHelpers";

export const EmailMinimumInterval = ({ propGen: props, whatsappSectionEmail = false }: EditProps) => {
    return (
        <TextWithField text={whatsappSectionEmail ? TranslationService.translate.MinIntervalWhatsApp : TranslationService.translate.MinInterval}
            fieldTitle={TranslationService.translate.Days} >
            <input className="form-control" {...numEvent(props.num.notRequired("MinInterval"))} />
        </TextWithField >);
}

export const EmailBccButton = ({ data, dataKey, setField }: {
    data: EditProps["data"], setField: EditProps["setField"], dataKey: "Bcc" | "Cc"
}) => {
    const key = "Mail_" + dataKey.toUpperCase() + "Type" as keyof typeof data;
    const show = data[key] !== undefined && data[key] !== null;

    useEffect(() => {
        if (!show) {
            dataKey === 'Bcc' && setField('Mail_BCCField')(undefined)
            dataKey === 'Cc' && setField('Mail_CCField')(undefined)
        } else {
            dataKey === 'Bcc' && !data['Mail_BCCField'] && setField('Mail_BCCField')(-1)
            dataKey === 'Cc' && !data['Mail_CCField'] && setField('Mail_CCField')(-1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    return (
        <button type="button" onClick={() => setField(key)(show ? undefined : 0)}
            className={"btn ms-2 pb-1 chipItem" + (show ? "" : "Disabled")} >
            <i className={"far fa-fw me-2 " + (show ? "fa-times" : "fa-plus")} />
            {TranslationService.getTranslation(dataKey)}
        </button>
    );
}

export enum SendToOptions { Customer, EmailAddress };
export enum NotifyToOptions { Sender, EmailAddress };
export enum CarbonCopySendToOptions { Customer, EmailAddress, TheUser };
export enum LetterSendMethods { SendByMailToCustomer, SendByMailTo, SendDocumentTo };
export enum LetterFormatOptions { PDF, DOC };

export const EmailBccInput = ({ data, dataKey, setField, propGen }: {
    data: EditProps["data"], setField: EditProps["setField"],
    propGen: EditProps["propGen"],
    dataKey: "bcc" | "cc"
}) => {
    const key = "Mail_" + dataKey.toUpperCase() + "Type" as keyof typeof data;
    const value = data[key] as number | undefined;
    return (
        <Collapsable show={value !== undefined && value !== null}>
            <div className="row">
                <EmailInput data={data} setField={setField} propGen={propGen}
                    items={getEnumArray(CarbonCopySendToOptions)} field={dataKey}
                    title={TranslationService.getTranslation(capitalize(dataKey))} />
            </div>
        </Collapsable>
    )
}

export const EmailFrom = ({ propGen }: EditProps) => {
    const emailProps = propGen.str.required("Mail_From");
    return (<div className="row">
        <Field title={TranslationService.translate.From}>
            <input className="form-control" {...strEvent(propGen.str.notRequired("Mail_FromName"))} />
        </Field>
        <Field title={TranslationService.translate.Email}>
            <input className="form-control" {...strEvent(emailProps)} />
            <ValidationMessage {...emailProps} message={TranslationService.translate.EnterFrom} />
        </Field>
    </div>);
}

export const Subject = ({ propGen }: EditProps) =>
    <div className="row">
        <Field title={TranslationService.translate.Subject} colClass="col-8">
            <input className="form-control" {...strEvent(propGen.str.required("Mail_Subject"))} />
            <ValidationMessage {...propGen.str.required("Mail_Subject")}
                message={TranslationService.translate.EnterSubject} />
        </Field>
    </div>;

export const EmailContent = ({ propGen: props, data }: EditProps) =>
    <div className="row">
        <Field title={TranslationService.translate.Message} colClass="col-9">
            <textarea className="form-control" value={data.Content}
                onChange={e => props.str.required("Content").onChange(e.target.value)}
                style={{ resize: "none" }} rows={6} />
            <ValidationMessage {...props.str.required("Content")}
                message={TranslationService.translate.EnterBody} />
        </Field>
    </div>;

export const EmailDesings = ({ setField, data }: EditProps) => {
    const emailFrameOptions = useMemo(() => CompanyService.getEmailFrames().map(x => ({ value: x.MailFrameID, text: x.Name })), []);
    if (emailFrameOptions.length === 0) {
        return <></>
    }
    return (
        <Field title={TranslationService.translate.EmailDesign}>
            <Dropdown onChange={setField("MailFrameID")} items={emailFrameOptions} optionLabel={TranslationService.translate.None} defaultValue={data.MailFrameID} />
        </Field>);
}

export const EmailOrFtp = ({ setField, data }: EditProps) =>
    <div className="row">
        <Field title={TranslationService.translate.Send}>
            <Dropdown items={[{ text: "Email", value: undefined }, { text: "FTP", value: 3 }]} onChange={setField("Mail_ToType")} defaultValue={data.Mail_ToType} />
        </Field>
    </div>;

export const EmailTo = ({ propGen: props }: EditProps) =>
    <div className="row">
        <Field title={TranslationService.translate.To} colClass="col-8">
            <input className="form-control" {...strEvent(props.str.required("Mail_To"))} />
            <ValidationMessage {...props.str.required("Mail_To")}
                message={TranslationService.translate.EnterTo} />
        </Field>
    </div>;

export const Templates = ({ setField, data }: EditProps) => {
    const reportTemplates = useMemo(() => CompanyService.getReportExports()
        .filter(x => x.Report === 2)
        .map(x => ({ value: x.ReportExportID, text: x.Name ?? TranslationService.translate.Default })), []);
    return (<div className="row">
        <Field title={TranslationService.translate.Templates}>
            <Dropdown items={reportTemplates} optionLabel={TranslationService.translate.Default} onChange={setField("ReportExportID")} defaultValue={data.ReportExportID} />
        </Field>
    </div>);
}