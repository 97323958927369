import { PropsWithChildren, useRef } from "react";
import { DomEventService, EventName } from "../../services/DomEventService";
import { Observable, Subscriber, Subscription } from "rxjs";

export const Application = ({ children }: PropsWithChildren<Record<string, undefined>>) => {
    const appref = useRef<HTMLDivElement | null>(null);
    const getBodyEvents = <T extends EventName,>(eventName: T) => {
        if (eventsObservables[eventName] === undefined) {
            if (appref.current === null) {
                throw new Error("Too soon for body events");
            }
            const subscribers: Subscriber<HTMLElementEventMap[T]>[] = [];
            const observable = new Observable<HTMLElementEventMap[T]>((subscriber) => {
                subscribers.push(subscriber);
                return new Subscription(() => {
                    subscribers.splice(0, Number.MAX_SAFE_INTEGER, ...subscribers.filter(x => x !== subscriber));
                });
            });
            const eventListener = (ev: HTMLElementEventMap[T]) => {
                subscribers.forEach(sub => {
                    if (sub.closed) {
                        return;
                    }
                    sub.next(ev);
                });
                subscribers.splice(0, Number.MAX_SAFE_INTEGER, ...subscribers.filter(x => !x.closed));
            };
            appref.current.addEventListener(eventName, eventListener);
            eventsObservables[eventName] = observable;
        }
        return eventsObservables[eventName] as Observable<GlobalEventHandlersEventMap[T]>;
    };
    DomEventService.GetBodyEvents = getBodyEvents;
    return (<div ref={appref}>
        {children}
    </div>);
};

const eventsObservables: Record<EventName, Observable<Event>> = {} as Record<EventName, Observable<Event>>; 