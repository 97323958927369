import { useHistory } from "react-router-dom";
import CashAppDetail from "../client/components/CashAppDetail";
import { useContext, useEffect } from "react";
import TabPositionContext from "../shared/TabPositionContext";

const CashappPage = () => {
    const history = useHistory();
    const cashappUrl = new URLSearchParams(history.location.search).get("url");

    const { setCurrentTabPosition } = useContext(TabPositionContext);
    useEffect(() => {
        setCurrentTabPosition("cashapp");
    });

    if (cashappUrl !== null) {
        return (
            <div className="container-fluid padding">
                <div className="card mh-100">
                    <CashAppDetail url={cashappUrl} iframeStyle={{ width: "100%", height: "calc(100vh - 218px)" }} />
                </div>
            </div>
        );
    }
    return (<></>);
};

export default CashappPage;