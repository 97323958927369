import { useCallback, useEffect, useState } from "react";
import TaskService from "../../services/TaskSerivce";
import { TaskListResponse } from "./entities/TaskListResponse";
import TableContext, { TableContextValues } from "./TableContext";
import { TaskListFilters } from './entities/TaskListFilters';
import { TaskListRequest } from "./entities/TaskListRequest";
import CompanyService from "../../services/CompanyService";
import { SortDirection } from "../shared/entities/Sort";
import { v4 } from "uuid";
import { applyFiltersHelper, calculatePageCount, setCurrentPageHelper, setPageSizeHelper } from "../../utils/TableUtils";

let reqId = v4();

export class TaskTableContextValues extends TableContextValues<TaskListResponse, TaskListFilters> {
    constructor(public request: TaskListRequest,
        public setRequest: (request: TaskListRequest) => void,) {
        super();
    }
}

const TaskTableProvider = (props: React.PropsWithChildren<{ userId: string | null, statusId: number | null, request?: TaskListRequest }>) => {
    const [request, setRequest] = useState<TaskListRequest>(props.request ??
        new TaskListRequest(props.userId ?? CompanyService.getUserid(),
            props.statusId, CompanyService.getAdditionalDefinitions() ?? []));
    const [response, setResponse] = useState<TaskListResponse>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const applySearch = (keyword: string) => {
        setRequest({ ...request, text: keyword })
    };

    const requestData = async () => {
        setLoading(true);
        reqId = v4();
        const ourRequest = reqId;
        const result = await TaskService.getList(request);
        if (ourRequest !== reqId) {
            return;
        }
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        setResponse(result);
        setLoading(false);
        setError(false);
    }

    const requestDataCallback = useCallback(requestData, [request]);
    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    const sortDirection = request.descending as SortDirection;
    const sortColumn = request.sortcolumn;
    const setSortColumn = (key: string) => {
        const newSort = {
            sortcolumn: key,
            sortDirection: sortColumn === key ? Number(!sortDirection) : SortDirection.Descending
        };
        setRequest(request => ({ ...request, sortcolumn: newSort.sortcolumn, descending: newSort.sortDirection }));
    }
    const sort = { sortDirection, sortColumn };
    const pageCount = calculatePageCount(response);
    const value = new TaskTableContextValues(request, setRequest);
    value.loading = loading;
    value.error = error;
    value.response = response;
    value.currentPage = request.page;
    value.setCurrentPage = setCurrentPageHelper(setRequest, pageCount);
    value.pageCount = pageCount;
    value.reload = requestData;
    value.applyFilters = applyFiltersHelper(setRequest);
    value.applySearch = applySearch;
    value.sort = sort;
    value.pageSize = request.pagesize;
    value.setSortColumn = setSortColumn;
    value.setPageSize = setPageSizeHelper(setRequest);

    return (
        <TableContext.Provider value={value}>
            {props.children}
        </TableContext.Provider>
    )
}

export default TaskTableProvider;

