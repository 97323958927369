import { CSSProperties, useCallback, useContext, useEffect, useState } from "react";
import ErrorMessage from "../../shared/components/Error";
import Loading from "../../shared/components/Loading";
import FloatingPanelService from "../../shared/FloatingPanel";
import { TranslationService } from '../../../services/TranslationService';
import DashboardPanelService from "../../../services/DashboardPanelService";
import DashboardPanelEdit from "./DashboardPanelEdit";
import { DashboardPanelEntity } from "../../../entities/company/DashboardPanel/DashboardPanelEntity";
import { DashboardPanelListResponse } from "../../../entities/company/DashboardPanel/DashboardPanelListResponse";
import { Link } from "react-router-dom";
import ToastContext from "../../shared/bootstrap/Toast";
import OrderableTable from "./FieldConfiguration/OrderableTable";
import { DashboardEntity } from "../../../entities/company/Dashboard/DashboardEntity";
import DashboardEdit from "./DashboardEdit";
import DashboardItem from "./DashboardItem";
import DashboardService from "../../../services/DashboardService";

const DashboardConfiguration = () => {

    const { translate, currentLanguage } = TranslationService;
    const [response, setResponse] = useState<DashboardPanelListResponse>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [showingIds, setShowingIds] = useState<number[]>([]);
    const { showToast } = useContext(ToastContext);

    const requestData = async () => {
        setLoading(true);
        const result = await DashboardPanelService.getList();
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        setResponse(result);
        setLoading(false);
        setError(false);
    }

    const requestDataCallback = useCallback(requestData, []);

    const togglePanel = (id: number) => {
        if (showingIds.includes(id)) {
            let index = showingIds.indexOf(id);
            delete showingIds[index];
            setShowingIds([...showingIds]);
        }
        else {
            setShowingIds([...showingIds, id]);
        }
    }


    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    if (loading) {
        return (<Loading />)
    }

    if (error) {
        return (<ErrorMessage onRefresh={requestDataCallback} message={translate.ErrorLoadingList} />)
    }

    const showDashboardPanelEditModal = (dashboardPanelEntity?: DashboardPanelEntity) => {
        FloatingPanelService.showPanel({
            children: <DashboardPanelEdit reload={requestDataCallback} current={dashboardPanelEntity} />,
            title: dashboardPanelEntity ? translate.EditPanel : translate.NewPanel,
            width: 600,
            height: 350,
            position: "center",
        });
    }

    const showDashboardEditModal = (dashboardEntity: DashboardEntity) => {
        FloatingPanelService.showPanel({
            children: <DashboardEdit reload={requestDataCallback} dashboard={dashboardEntity} />,
            title: dashboardEntity ? translate.EditPanel : translate.NewPanel,
            width: 700,
            height: 650,
            position: "center",
        });
    }

    const createDashboard = (dashboardPanelId: number) => {
        showDashboardEditModal({
            DashboardID: 0,
            Variable: DashboardService.getVariables(false)[0].Value,
            GroupBy: DashboardService.getGrouping(false)[0].Value,
            Type: 1,
            Position: 0,
            Title: "",
            DashboardPanelID: dashboardPanelId,
            MaxItems: 0,
            Filter: null,
            Data: null,
            DashboardPersonDays: null 
        });
    }

    const onSort = async (dashboards: DashboardEntity[]) => {
        const result = await DashboardPanelService.sort(dashboards[0].DashboardPanelID, dashboards);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
    }

    const dashboardHeader: { title: string, style?: undefined | CSSProperties }[] = [
        { title: translate.Type, style: { width: "150px" } },
        { title: translate.Title },
        { title: translate.Variable, style: { width: "150px" } },
        { title: translate.GroupedBy, style: { width: "150px" } },
        { title: "", style: { width: "150px" } },
        { title: "", style: { width: "25px" } }
    ]

    return (
        <div className="card px-5 ja segments">
            <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center my-4">
                <h2>{TranslationService.translate.DashboardConfiguration}</h2>
                <div className="d-flex align-items-center gap-3">
                    <Link className="btn btn-secondary" to={`/${currentLanguage}/company/dashboardpanelpreview`}>{translate.TestPanel}</Link>
                    <button className="btn btn-primary" onClick={() => showDashboardPanelEditModal(undefined)}><i className="fal fa-plus" /> {translate.NewPanel}</button>
                </div>
            </div>
            <table className="table genericTable table-ellipsis">
                <thead>
                    <tr className="pointer">
                        <th>{translate.Name}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {response?.list.map(x => (
                        <>
                            <tr key={x.DashboardPanelID} onClick={() => togglePanel(x.DashboardPanelID)} className="pointer">
                                <td valign="middle">
                                    <span className="arrowDown mb-2 me-2">
                                        {showingIds.includes(x.DashboardPanelID) &&
                                            <i className="fa fa-chevron-down"></i>
                                        }
                                        {!showingIds.includes(x.DashboardPanelID) &&
                                            <i className="fa fa-chevron-right"></i>
                                        }
                                    </span>
                                    <span className="folderName">
                                        {x.Name}
                                    </span>
                                </td>
                                <td className="actions">
                                    <span>
                                        <button type="button">
                                            <i className="far fa-fw pointer fa-pen mx-2" onClick={(event) => {
                                                showDashboardPanelEditModal(x); event.stopPropagation();
                                            }}>
                                            </i>
                                        </button>
                                    </span>
                                </td>
                            </tr>
                            {
                                showingIds.includes(x.DashboardPanelID) &&
                                <tr className="no-hover">
                                    <td colSpan={2} >
                                        <div className="card" style={{ backgroundColor: "#fcfcfc", border: 0 }}>
                                            <div className="card-body">
                                                <OrderableTable headers={dashboardHeader} tableClass="dashboard-panel" data={x.Dashboards} RowComponent={DashboardItem} getKey={x => x.DashboardID.toString()} onSort={onSort} reload={requestDataCallback}></OrderableTable>
                                                <button onClick={() => createDashboard(x.DashboardPanelID)} className="btn btn-link"><i className="fa fa-plus"></i> {translate.NewDashboard}</button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            }
                        </>
                    ))}
                </tbody>
            </table>
        </div>

    )

}

export default DashboardConfiguration;