import { useState } from "react";
import Dropdown from "../../../../shared/components/Dropdown";
import { TextEditor } from "../../../../shared/components/Editors";

const DropdownWithOptionalText = ({ onChange, items, toTextValue, defaultValue }: { onChange: (value: string) => void, items: { value: string; text: string; }[], toTextValue: string, defaultValue?: string }) => {

    const [showTextbox, setShowTextbox] = useState(getDropdownDefaultValue(defaultValue, items, toTextValue) === toTextValue);
    const dropdownOnChange = (value: string) => {
        if (value === toTextValue) {
            setShowTextbox(true);
        } else {
            onChange(value);
            setShowTextbox(false);
        }
    }
    const inputOnChange = (value: string) => {
        if (showTextbox) {
            onChange(value);
        }
    }
    return (
        <div className="">
            <div className="col w-lg mb-3">
                <Dropdown onChange={dropdownOnChange} items={items} defaultValue={getDropdownDefaultValue(defaultValue, items, toTextValue)} />
            </div>
            <div className="col w-xl mb-3">
                {showTextbox && <TextEditor onChange={inputOnChange} defaultValue={getTextEditorDefaultValue(defaultValue, items)} />}
            </div>
        </div>
    )
}

const getTextEditorDefaultValue = (value: string | undefined, items: { value: string | number; text: string; }[]) => {
    if (value === undefined || value === null || value.length < 0) {
        return ""
    }
    return items.findIndex(x => x.value === value) >= 0 ? "" : value;
}

const getDropdownDefaultValue = (value: string | undefined, items: { value: string | number; text: string; }[], toTextValue: string) => {
    if (value === undefined || value === null || value.length < 0) {
        return ""
    }
    return items.findIndex(x => x.value === value) >= 0 ? value : toTextValue;
}

export default DropdownWithOptionalText;