export class InvoiceEditMarkAsPayed {
    additionals: AdditionalFieldValue[] = [];
    selPayDate?: string;
    transactiontype?: string;
    comments?: string;
    personId: string;

    constructor(personId: string) {
        this.personId = personId;
    }
}

class AdditionalFieldValue {
    Id: number = 0;
    Value: string = '';
}

export enum Receipt {
    Download = 1,
    SendViaEmail = 2,
}