import CompanyService from "../../services/CompanyService";
import { TranslationService } from "../../services/TranslationService";
import VerticalMenu from "../shared/components/VerticalMenu";
import TabPosition from "../shared/TabPosition";
import { DataPersonal } from "./components/DataPersonal";
import { DataPlan } from "./components/DataPlan";
import { CompanyConfiguration } from "./components/CompanyConfiguration";


const Profile: React.FC = () => {
    const tabs = [
        { title: TranslationService.translate.DataPlan, component: DataPlan, },
        { title: TranslationService.translate.DataPersonal, component: DataPersonal, },
        { title: TranslationService.translate.CompanySettingsProfile.replace("{1}", CompanyService.getCompanyName()), component: CompanyConfiguration, },
    ];
    return (
        <TabPosition module="Profile">
            <VerticalMenu items={tabs} />
        </TabPosition>
    );
};

export default Profile;
