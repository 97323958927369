import { useState } from "react";
import TaskService from "../../../services/TaskSerivce";

const TaskStar = ({ Fav, TaskID, className = "" }: { TaskID: number; Fav?: number; className?: string }) => {
    const [fav, setFav] = useState(Fav);
    let starClass = "favstar0";
    switch (fav) {
        case 1:
            starClass = "favstar1";
            break;
        case 2:
            starClass = "favstar2";
            break;
        case 3:
            starClass = "favstar3";
            break;
    }
    const onClick = async () => {
        let next: number | undefined = (fav ?? 0) + 1;
        next = next === 4 ? undefined : next;
        Fav = next;
        setFav(next);
        const result = await TaskService.fav(TaskID);
        if (result instanceof Error) {
            return;
        }
        if (next !== result.fav) {
            setFav(result.fav);
            Fav = result.fav;
        }
    };
    return (
        <span className={starClass + " " + className} onClick={onClick}></span>
    );
}
export default TaskStar;    