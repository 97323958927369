import { useEffect, useState } from "react";

export const AwaitValue = ({ awaitable, defaultValue = "" }: { awaitable: Promise<string>, defaultValue?: string }) => {
    const [displayValue, setDisplayValue] = useState(defaultValue);
    useEffect(() => {
        const getData = async () => {
            setDisplayValue(await awaitable);
        };
        getData();
    }, [awaitable]);
    return <>{displayValue}</>;
};