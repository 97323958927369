import { ToggleEnableActionWrapper, convertBool } from './OtherConfigurationItems/ToggleEnableActionWrapper';
import { DropdownSelectWrapper } from './OtherConfigurationItems/DropdownSelectWrapper';
import { TranslationService } from '../../../services/TranslationService';
import FilterService, { FilterCollection } from '../../../services/FilterService';
import CompanyService from '../../../services/CompanyService';
import ConfigurationContext from '../ConfigurationContext';
import { useContext, useState } from 'react';

type GroupInvoicesinEmailsandLettersType = {
    alloweditgroupmailby: 'False' | 'True',
    groupmailby: 'False' | 'True',
    groupbyorderdesc: 'False' | 'True',
}

export const GroupInvoicesinEmailsandLetters = ({ alloweditgroupmailby, groupmailby, groupbyorderdesc }: GroupInvoicesinEmailsandLettersType) => {
    const { refreshConfigValues } = useContext(ConfigurationContext);
    const [showValues, setShowValues] = useState(convertBool[alloweditgroupmailby] || groupmailby.length > 1)
    const listItemsFields = FilterService.GetFiltersForPage(FilterCollection.GroupInvoicesEmailsAndLetters)
        .flatMap(({ definitions }) => definitions.map(x => ({ value: x.Field.replace("additional-", ""), text: x.Name ?? '' })))

    const handleChangeValue = (
        valUpdate: boolean,
        changeValueUpdate: (val: boolean) => void,
        changeLoadingListener: (val: boolean) => void) => {

        changeLoadingListener(true)

        if (!valUpdate) {
            CompanyService.setConfigCompany({ 'alloweditgroupmailby': true })
                .then(refreshConfigValues)
                .finally(() => {
                    changeValueUpdate(!valUpdate)
                    setShowValues(true)
                    changeLoadingListener(false)
                })
        } else {
            CompanyService.setConfigCompany({ 'groupmailby': '', 'alloweditgroupmailby': false })
                .then(refreshConfigValues)
                .finally(() => {
                    changeValueUpdate && changeValueUpdate(!valUpdate)
                    setShowValues(false)
                    changeLoadingListener(false)
                })
        }
    }

    return (
        <div className='configuration-display-item'>
            <div className="mb-3">
                <ToggleEnableActionWrapper
                    title="GroupInvoicesinEmailsandLetters"
                    initialValue={alloweditgroupmailby}
                    onChangeCustom={handleChangeValue}
                    loadingListener
                />
            </div>

            {showValues &&
                <div className="col-auto" style={{ marginRight: 50 }}>
                    <div className="mb-2">
                        <ToggleEnableActionWrapper
                            title="AllowManualGroupingEdit"
                            action="alloweditgroupmailby"
                            initialValue={alloweditgroupmailby}
                        />
                    </div>
                    <div className="mb-2">
                        <DropdownSelectWrapper
                            initialValue={groupmailby}
                            items={[{ value: '', text: TranslationService.translate.NoneValue }, ...listItemsFields]}
                            title="DefaultGrouping"
                            action="groupmailby" />
                    </div>
                    <div className="mb-2">
                        <ToggleEnableActionWrapper
                            title="DescendingOrderZA"
                            action="groupbyorderdesc"
                            initialValue={groupbyorderdesc} />
                    </div>
                </div>}
        </div >
    )
}
