import React, { useEffect, useMemo, useState } from "react";
import ActionService from "../../../services/ActionService";
import TableContext, { TableContextValues } from "../../task/TableContext";
import { ActionDetailResponse } from "../entities/ActionDetailResponse";
import { useRequestAborter, handleError } from "../../../utils/RequestUtils";

export const ActionDetailTableProvider = (props: React.PropsWithChildren<{ id: number }>) => {
    const [response, setResponse] = useState<ActionDetailResponse>();
    const [actResponse, setActResponse] = useState<ActionDetailResponse>({} as ActionDetailResponse);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [prevPage, setPrevPage] = useState(0);
    const requestAborter = useRequestAborter();

    useEffect(() => {
        const requestData = async () => {
            setLoading(true);
            const result = await requestAborter.next(handleError(ActionService.details(props.id, prevPage), () => { setError(true); setLoading(false); }));
            if (!result) {
                return;
            }
            setResponse(result);
            setLoading(false);
            setError(false);
        }
        requestData();
    }, [prevPage, props.id, requestAborter]);

    const value = useMemo(() => {
        const val = new TableContextValues<ActionDetailResponse>();
        val.loading = loading;
        val.error = error;
        val.response = actResponse;
        return val;
    }, [actResponse, error, loading]);

    const [prevResponses, setPrevResponses] = useState<ActionDetailResponse[]>([]);
    const [actualPage, setActualPage] = useState(0);

    value.pageCount = value.response?.itemCount ? (value.response.itemCount / 10) : undefined;
    value.currentPage = actualPage;
    value.response = actResponse;

    useEffect(() => {
        if (!value.loading && !value.error && response?.list && prevResponses[prevPage] === undefined && response.page === prevPage) {
            prevResponses[prevPage] = response;
            setPrevResponses([...prevResponses]);
            const actual = { ...response };
            actual.list = (response.list ?? []).slice(10 * (actualPage % 3), 10 + 10 * (actualPage % 3));
            actual.pageSize = 10;
            actual.page = actualPage;
            value.pageCount = (value.response?.itemCount ?? 0) / 10;
            value.currentPage = actualPage;
            setPrevResponses([...prevResponses]);
            setActResponse(actual);
        }
    }, [actualPage, prevPage, prevResponses, value, value.error, value.loading, response])

    value.setCurrentPage = (page: number) => {
        if (value.pageCount === undefined) {
            return;
        }
        if (page < 0 || (page) >= (value.pageCount)) {
            return;
        }
        const newPrevPage = Math.floor(page / 3);
        setPrevPage(newPrevPage);
        setActualPage(page);
        if (prevResponses[newPrevPage] === undefined) {
            return;
        }
        const actual = { ...prevResponses[newPrevPage] };
        actual.list = prevResponses[newPrevPage].list.slice(10 * (page % 3), 10 + 10 * (page % 3));
        actual.pageSize = 10;
        actual.page = page;
        value.pageCount = (value.response?.itemCount ?? 0) / 10;
        value.currentPage = actualPage;
        actResponse.list = actual.list;
        actResponse.pageSize = actual.pageSize;
        actResponse.page = actual.page;
        setActResponse(actual);
    }
    return (
        <TableContext.Provider value={value}>
            {props.children}
        </TableContext.Provider>
    )
}
