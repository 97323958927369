import React from "react";
import { TranslationService } from "../../services/TranslationService";
import ErrorCatcher from "./ErrorCatcher";

class ErrorBoundary extends React.Component<{ location: string }, { hasError: boolean, error: string | undefined }> {
  
  componentDidUpdate(prevProps: { location: string }) {
    if (this.props.location !== prevProps.location) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state?.hasError) {
      return <h3>
        <div style={{ display: "none" }}>{this.state.error}</div>
        {TranslationService.translate.ErrorProcessingRequest}
      </h3>;
    }

    return (
      <ErrorCatcher onError={(errorMessage: string) => { this.setState({ hasError: true, error: errorMessage }) }}>
        {this.props.children}
      </ErrorCatcher>);
  }
}

export default ErrorBoundary;