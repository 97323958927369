import { EditProps } from "./EditAction";
import { ActivityType } from "./components/ActivityType";
import { EmailAditionalContent, AttachFile } from "./components/EmailAdditionalContent";
import { EmailMinimumInterval, EmailContent, EmailDesings, Subject, EmailOrFtp, Templates } from "./components/EmailComponents";
import { InsertFieldsOnly, InsertFieldsSignature } from "./components/EmailInsertFields";
import { EmailSimpleContent } from "./components/EmailSimpleContent";
import { EmailToClient } from "./components/EmailToClient";
import { InvoiceRequirements, IncludeCustomerSheet } from "./components/InvoiceComponents";
import { InvoiceFilters } from "./components/InvoiceFilters";
import { LanguageSelector } from "./components/LanguageSelector";
import { LetterFormat } from "./components/LetterFormat";
import { LetterFrom } from "./components/LetterFrom";
import { LetterSendMethod } from "./components/LetterSendMethod";
import { NotifyTo } from "./components/NotifyTo";
import { Segments } from "./components/Segments";
import { SelectWhatsappTemplate } from "./components/SelectWhatsappTemplate";
import { SendTime } from "./components/SendTime";
import { SMSNumberChoose } from "./components/SmsNumberChoose";
import { TaskDescription, TaskDue, TaskResponsableValidator } from "./components/TaskComponents";
import { Title } from "./components/Title";
import { WhatsAppTemplateContent } from "./components/WhatsAppTemplateComponents";
import { WordTemplate } from "./components/WordTemplate";

export const DefaultSections = [
    { name: "Title", Component: Title },
];

export const InvoiceEmailSections = [
    { name: "Title", Component: Title },
    { name: undefined, Component: InvoiceRequirements },
    { name: undefined, Component: EmailMinimumInterval },
    { name: "Email", Component: EmailToClient },
    { name: undefined, Component: NotifyTo },
    { name: undefined, Component: Subject },
    { name: undefined, Component: InsertFieldsSignature },
    { name: undefined, Component: EmailContent },
    { name: undefined, Component: EmailAditionalContent },
    { name: undefined, Component: EmailDesings },
    { name: undefined, Component: ActivityType },
    { name: undefined, Component: AttachFile },
];

export const CustomEmailSections = [
    { name: "Title", Component: Title },
    { name: "Segment", Component: Segments },
    { name: undefined, Component: LanguageSelector },
    { name: undefined, Component: SendTime },
    { name: undefined, Component: EmailMinimumInterval },
    { name: "Email", Component: EmailToClient },
    { name: undefined, Component: NotifyTo },
    { name: undefined, Component: Subject },
    { name: undefined, Component: InsertFieldsOnly },
    { name: undefined, Component: EmailContent },
    { name: undefined, Component: EmailAditionalContent },
    { name: undefined, Component: EmailDesings },
    { name: undefined, Component: ActivityType },
];

export const TaskSections = [
    { name: "Title", Component: Title },
    { name: "Segment", Component: Segments },
    { name: "Task", Component: TaskDescription },
    { name: undefined, Component: TaskDue },
    { name: undefined, Component: TaskResponsableValidator },
    { name: undefined, Component: ActivityType },
];

export const SmsSections = [
    { name: "Title", Component: Title },
    { name: "Segment", Component: Segments },
    { name: undefined, Component: SendTime },
    { name: undefined, Component: EmailMinimumInterval },
    { name: undefined, Component: SMSNumberChoose },
    { name: "SMS", Component: InsertFieldsOnly },
    { name: undefined, Component: EmailContent },
    { name: undefined, Component: ActivityType },
];

export const ClientListSections = [
    { name: "Title", Component: Title },
    { name: "Segment", Component: Segments },
    { name: undefined, Component: SendTime },
    { name: undefined, Component: EmailOrFtp },
    { name: "Content", Component: EmailSimpleContent },
    { name: undefined, Component: Templates },
];

export const InvoiceListSections = [
    { name: "Title", Component: Title },
    { name: undefined, Component: SendTime },
    { name: undefined, Component: EmailOrFtp },
    { name: "Content", Component: EmailSimpleContent },
    { name: undefined, Component: InvoiceFilters },
    { name: undefined, Component: Templates },
    { name: undefined, Component: IncludeCustomerSheet },
];

export const LetterSections = [
    { name: "Title", Component: Title },
    { name: "Segment", Component: Segments },
    { name: undefined, Component: SendTime },
    { name: undefined, Component: EmailMinimumInterval },
    { name: "Message", Component: LetterFrom },
    { name: undefined, Component: LetterSendMethod },
    { name: undefined, Component: NotifyTo },
    { name: undefined, Component: LetterFormat },
    { name: undefined, Component: Subject },
    { name: undefined, Component: InsertFieldsOnly },
    { name: undefined, Component: EmailContent },
    { name: undefined, Component: EmailAditionalContent },
    { name: undefined, Component: ActivityType },
    { name: undefined, Component: WordTemplate },
];

export const WhatsAppSections = [
    { name: "Title", Component: Title },
    { name: "Segment", Component: Segments },
    { name: undefined, Component: SendTime },
    { name: undefined, Component: (props: EditProps) => EmailMinimumInterval({ ...props, whatsappSectionEmail: true }) },
    { name: undefined, Component: SMSNumberChoose },
    { name: "WhatsApp", Component: SelectWhatsappTemplate },
    { name: undefined, Component: WhatsAppTemplateContent },
];