import { ReactElement, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import axios from "axios";
import * as yup from "yup";

import ErrorContext from "../shared/ErrorContext";
import { TranslationService } from "../../services/TranslationService";
import SubmitButton from "./components/SubmitButton";
import {
  ChangePasswordParamsType,
  ChangePasswordResponse,
} from "../../services/AuthenticationService";
import { BackButton } from "./components/BackButton";

const defaultValues = {
  password: "",
  confirmPassword: "",
  token: "",
};

function ChangePassword(): ReactElement {
  const [formData, setFormData] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [passwordUnchanged, setPasswordUnchanged] = useState(false);
  const [passwordMsgBlocker, setPasswordMsgBlocker] = useState("");

  const { translate, currentLanguage } = TranslationService;
  const { setCurrentError } = useContext(ErrorContext);

  const formDataSchema = yup.object({
    password: yup
      .string()
      .required(translate.LoginPasswordMissing)
      .min(8, translate.PasswordMinimunLength.replace('{{p1}}', formData.password)),
    confirmPassword: yup
      .string()
      .required(translate.ConfirmPasswordMissing)
      .min(8, translate.PasswordMinimunLength.replace('{{p1}}', formData.password))
      .oneOf([yup.ref("password"), ""], translate.PasswordNotMatch),
  });
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get("email") || "";
  const token = params.get("validationtoken") || "";

  const handleChangePassword = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setCurrentError(undefined);
    setPasswordUnchanged(false);
    setPasswordMsgBlocker("");

    formDataSchema
      .validate(formData)
      .then(async () => {
        setLoading(true);

        const userData = await axios
          .post<ChangePasswordParamsType, ChangePasswordResponse>(
            process.env.REACT_APP_INTIZA_AUTH + "/auth/setpassword",
            {
              email,
              password: formData.password,
              token,
            }
          )
          .catch(console.error)
          .finally(() => setLoading(false));

        if (userData) {
          if (userData.data.response === "Ok") {
            const queryParams = new URLSearchParams();
            queryParams.append("msg", translate.PasswordChanged);
            history.replace(`/${currentLanguage}/logon?${queryParams.toString()}`);
          } else {
            setPasswordUnchanged(true);
            switch (userData.data.response) {
              case "invalidtoken":
                setPasswordMsgBlocker(translate.TokenInvalidPassword);
                break;
              case "weak-password":
                setPasswordUnchanged(false);
                setCurrentError(translate.PasswordWeakMsg);
                break;
              case "userdelegated":
                setPasswordMsgBlocker(translate.PasswordUserDelegatedMsg);
                break;
              case "old-password":
                // TODO: Validar textos y procesos asociados a este punto
                setPasswordMsgBlocker(translate.PasswordOldMsg);
                break;
              case "wait-password":
                // TODO: Validar textos y procesos asociados a este punto
                setPasswordMsgBlocker(translate.PasswordWaitMsg);
                break;
              default:
                setPasswordMsgBlocker(translate.Network);
                break;
            }
          }
        } else {
          setCurrentError(translate.Network);
        }
      })
      .catch(({ errors }) => setCurrentError(errors[0]));

    // TODO: implementacion con la actual arquitectura.
    // Al no poder aplicar el prevent default se actualiza la pag y no se aplican validaciones ni mensajes de error
    // formDataSchema
    //   .validate(formData)
    //   .then(async () => {
    //     setLoading(true);
    //     const userData = await AuthenticationService.changePassword({
    //       email,
    //       password: formData.password,
    //       token,
    //     });
    //     if (userData instanceof Error) {
    //       setCurrentError(translate.Network);
    //     } else if (userData.response === "OK") {
    //       const queryParams = new URLSearchParams();
    //       queryParams.append("msg", translate.PasswordChanged);
    //       history.push(`/${currentLanguage}/logon?${queryParams.toString()}`);
    //     } else if (userData.response === "Blocked") {
    //       setCurrentError(translate.Blocked);
    //     }
    //   })
    //   .catch((err) => {
    //     setCurrentError(err.errors[0]);
    //     setLoading(false);
    //   })
    //   .finally(() => setLoading(false));
  };

  const handleVisiblePassword = () =>
    setVisiblePassword((prevState) => !prevState);

  const handleInputChange = ({ target: { name, value, checked, type } }: React.ChangeEvent<HTMLInputElement>) =>
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));

  useEffect(() => {
    if (!(email && token)) {
      history.push(`${currentLanguage}/logon`);
    }
  }, [email, token, currentLanguage, history]);

  return (
    <form onSubmit={handleChangePassword} noValidate>
      <div className="box">
        {!passwordUnchanged ? (
          <>
            <h1>{translate.UpdatePassword}</h1>
            <small>{translate.ChangePasswordMsg}</small>
            <div className="input-group">
              <input
                type={visiblePassword ? "text" : "password"}
                id="password"
                name="password"
                className="contrasena"
                placeholder={translate.NewPassword}
                onChange={handleInputChange}
              />
              <i
                onClick={handleVisiblePassword}
                className={`fas fa-${visiblePassword ? "un" : ""
                  }lock-alt icono-input`}
              />
            </div>
            <div className="input-group">
              <input
                type={visiblePassword ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                className="contrasena"
                placeholder={translate.RepeatPassword}
                onChange={handleInputChange}
              />
              <i
                onClick={handleVisiblePassword}
                className={`fas fa-${visiblePassword ? "un" : ""
                  }lock-alt icono-input`}
              />
            </div>
            <div className="login-btn" id="recover">
              {loading ? (
                <SubmitButton
                  text={translate.Send.toUpperCase()}
                  loading={loading}
                />
              ) : (
                <input type="submit" value={translate.Send.toUpperCase()} />
              )}
            </div>
          </>
        ) : (
          <>
            <h5 style={{ textAlign: "justify" }}>{passwordMsgBlocker}</h5>
            <p style={{ marginBottom: 15 }}></p>
          </>
        )}

        <BackButton />
      </div>
    </form>
  );
}

export default ChangePassword;
