import { useCallback, useEffect, useState } from "react";
import CompanyService from "../../../services/CompanyService";
import CurrencyService, { CurrencyListResponse, CurrencyListResponseItem } from "../../../services/CurrencyService";
import ErrorMessage from "../../shared/components/Error";
import Loading from "../../shared/components/Loading";
import FloatingPanelService from "../../shared/FloatingPanel";
import { TranslationService } from '../../../services/TranslationService';
import CurrencyEdit from "./CurrencyEdit";
import { formatNumberFractions } from "../../../utils/FormatUtils";

const CurrencyConfiguration = () => {
    const { translate } = TranslationService;
    const [response, setResponse] = useState<CurrencyListResponse>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const requestData = async () => {
        const result = await CurrencyService.getList();
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        setResponse(result);
        setLoading(false);
        setError(false);
    }

    const requestDataCallback = useCallback(requestData, []);
    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    if (loading) {
        return (<Loading />)
    }

    if (error) {
        return (<ErrorMessage onRefresh={requestDataCallback} message={translate.ErrorLoadingList} />)
    }

    const showCurrencyEditModal = (currency?: CurrencyListResponseItem) => {
        FloatingPanelService.showPanel({
            children: <CurrencyEdit reload={requestDataCallback} current={currency} />,
            title: currency ? translate.EditCurrency : translate.NewCurrency,
            width: 500,
            height: 600,
            position: "center",
        });
    }

    return (
        <div className="card px-5">
            <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center my-4">
                <h2>{translate.Currencies}</h2>
                <div className="d-flex align-items-center">
                    <button className="btn btn-primary" onClick={() => showCurrencyEditModal(undefined)}><i className="fal fa-plus" /> {translate.NewCurrency}</button>
                </div>
            </div>
            <div className="col-lg-12">
                <table className="table">
                    <thead>
                        <tr>
                            <th>{translate.Name}</th>
                            <th>{translate.Symbol}</th>
                            <th>{translate.Import}</th>
                            <th>{translate.ExchangeRate}</th>
                            <th>{translate.Update}</th>
                            <th className="text-center">{translate.Main}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {response?.list.map(x => (
                            <tr key={x.Code} className="pointer" onClick={() => showCurrencyEditModal(x)}>
                                <td>{x.Name}</td>
                                <td>{x.Symbol}</td>
                                <td>{x.Code}</td>
                                <td>{formatNumberFractions(x.Rate, 5)}</td>
                                <td>{x.AutoReference}</td>
                                <td className="text-center">{x.CurrencyID === CompanyService.getDefaultCurrencyId() && <i className="fas fa-check fa-lg" />}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>         
        </div>
    )
}
export default CurrencyConfiguration;