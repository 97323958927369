import { useEffect, useRef } from 'react'
import { TranslationService } from '../../../../services/TranslationService'

type WhatsappInputSendType = {
    handleSend: () => void,
    whatsAppTemplateText: string,
    setFreeTextChanged: (data: string) => void,
    disabledInput?: boolean,
    cleanFreeText: boolean,
    setCleanFreeText: (val: boolean) => void,
    loading: boolean
}

export const WhatsappInputSend = ({
    handleSend,
    whatsAppTemplateText,
    setFreeTextChanged,
    disabledInput = true,
    cleanFreeText,
    setCleanFreeText,
    loading
}: WhatsappInputSendType) => {
    const textAreaRef = useRef<any>(null);

    const handleChange = (e: any) => {
        setFreeTextChanged(e.target.value.toString())
    }

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.focus();
        }
    }, [disabledInput]);

    useEffect(() => {
        if (cleanFreeText) {
            textAreaRef.current.value = '';
            setCleanFreeText(false)
        }
    }, [cleanFreeText, setCleanFreeText])


    return (
        <>
            <div className='d-flex align-items-center'>
                <textarea
                    ref={textAreaRef}
                    name="input"
                    className="col-1"
                    style={{ width: '90%', color: disabledInput ? 'grey' : 'black' }}
                    rows={3}
                    placeholder={disabledInput ? TranslationService.translate.SelectTemplate : TranslationService.translate.Freetext}
                    autoComplete="off"
                    disabled={disabledInput}
                    value={whatsAppTemplateText}
                    onChange={handleChange}
                />
                <div className="conversation-compose">
                    <button className="send" onClick={handleSend}>
                        <div className="circle">
                            {!loading ?
                                <i className="fas fa-send"></i> :
                                <i className="fas fa-spinner-third fa-spin" style={{ marginRight: 5 }} />}
                        </div>
                    </button>
                </div>
            </div>
        </>
    )
}
