import { useCallback, useContext, useState } from "react";
import { TranslationService } from "../../services/TranslationService";
import FloatingPanelService from "../shared/FloatingPanel";
import { WhatsappCompose } from "../client/components/WhatsappCompose";
import ClientService from "../../services/ClientService";
import TabPositionContext from "../shared/TabPositionContext";
import { QUICK_TABS } from "./InboxTabContent";
import { TableHeaderWithFieldId } from "../shared/Table";
import { formatDateDigits, formatTimeShort, isMoreThan24Hours } from "../../utils/FormatUtils";
import { InboxContext } from "./InboxTableProvider";
import { useHistory } from "react-router-dom";
import { restTimezone } from "../../utils/ParseUtils";

import TableContext from "../task/TableContext";
import ActivityService from "../../services/ActivityService";
import { ToastService } from "../shared/bootstrap/Toast";
import { EmailEdit } from "../client/activity/components/EmailEdit";
import { EmailCompose } from "../client/components/EmailCompose";
import TooltipComponent from "../shared/TooltipComponent";

export const InboxTableTableColumn = ({ data, header }: { data: any, header: TableHeaderWithFieldId }) => {
    const [loadingData, setLoadingData] = useState(false)
    const { getCounterInbox } = useContext(TabPositionContext)
    const { request } = useContext(InboxContext)
    const { reload } = useContext(TableContext);

    const getValue = () => data[header.fieldId];
    const value = getValue();
    const navigate = useHistory();

    const openNewWhatsappModal = useCallback(async () => {
        FloatingPanelService.hidePanel();
        const result = await ClientService.get(data.PersonId);
        if (result instanceof Error) {
            return;
        }
        const person = result.item
        person && FloatingPanelService.showPanel({
            title: TranslationService.translate.NewWhatsApp,
            children: <WhatsappCompose
                person={person}
                personId={data.PersonId}
                getCounterInbox={getCounterInbox} />,
            width: 800,
            height: 800,
            position: 'bottom-right'
        });
    }, [data.PersonId, getCounterInbox]);


    const replyAll = () => {
        openEmailModal("RE: " + data.Subject, data.From! + "," + data.To, data.groupId, data.groupName);
    };

    const openEmailModal = (subject: string, to?: string, groupId?: string, groupName?: string) => {
        const onSubmit = () => reload();
        FloatingPanelService.showPanel({
            title: TranslationService.translate.EmailToClient,
            children:
                <EmailCompose
                    personId={data.PersonId.toString()}
                    defaultSubject={subject}
                    defaultTo={to}
                    defaultGroupId={groupId}
                    defaultGroupLabel={groupName}
                    prevMessageId={data.ID.toString()}
                    onSubmit={onSubmit} />,
            width: 900,
            height: 669,
        });
    };

    const editEmailModal = useCallback(async () => {
        setLoadingData(true)
        const emailRequest = await ActivityService.get(data.ID).finally(() => setLoadingData(false));
        if (emailRequest instanceof Error) {
            ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
        await ActivityService.setRead({ ids: [data.ID], read: '1' }).finally(() => {
            getCounterInbox()
            !data.Read && reload()
        })
        FloatingPanelService.showPanel({
            title: TranslationService.translate.EmailToClient,
            children: <EmailEdit email={emailRequest.item} />,
            width: 600,
            height: 569,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const replyAllAction = () => ({
        icon: "fa-reply-all",
        onClick: replyAll,
        tooltip: TranslationService.translate.ReplyAll,
    });

    const openWatchAction = () => ({
        icon: "fa-search",
        onClick: editEmailModal,
        tooltip: TranslationService.translate.SeeMore,
    });

    const actions = [
        replyAllAction(),
        openWatchAction(),
    ];

    const handleOpenModal = () => {
        switch (request.quickfilter) {
            case QUICK_TABS.WHATSAPP:
                openNewWhatsappModal()
                break;
            case QUICK_TABS.EMAIL:
                editEmailModal()
                // replyAll()
                break;
        }
    }

    const handleNavigateClient = async () => {
        setLoadingData(true)
        const result = await ClientService.get(data.PersonId).finally(() => setLoadingData(false));
        if (result instanceof Error) {
            return;
        }
        const person = result.item
        await navigate.replace(`client/details?id=${person.PersonId.toString()}`
            //    &dataID=${data.ID}&openModalDefault=${quickfilter === 8 ? 1 : quickfilter}`
        )
    }

    function decodeHTMLEntities(text: string) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');
        return doc.documentElement.textContent;
    }

    function truncateText(htmlText: string, maxLength: number) {
        const decodedText = decodeHTMLEntities(htmlText); // Decodificar las entidades HTML
        return (decodedText && decodedText?.length > maxLength) ? decodedText.substring(0, maxLength) + '...' : decodedText;
    }

    switch (header.fieldId) {
        case "date":
            const dateFormated = !isMoreThan24Hours(data?.date) ? formatTimeShort(restTimezone(new Date(data?.date))) : formatDateDigits(new Date(data?.date));
            return <td onClick={handleOpenModal}>{!data.Read ? <strong>{dateFormated}</strong> : dateFormated}</td>;
        case "personName":
            return <td onClick={handleNavigateClient}>{!data.Read ? <strong>{data.personName}</strong> : data.personName}</td >;
        case "FromName":
            return (
                <TooltipComponent title={data.From}>
                    <td onClick={handleNavigateClient}>{!data.Read ? <strong>{data.FromName}</strong> : data.FromName}</td >
                </TooltipComponent>
            )
        case "Content":
            return <td onClick={handleOpenModal}>{!data.Read ? <strong>{data.Content}</strong> : data.Content}</td >;
        case "Subject":
            const content = truncateText(data.Content, 120)
            const dataContent = <><strong>{data.Subject}</strong> - {!data.Read ? <strong><span>{content}</span></strong> : <span>{content}</span>} </>;
            return <td onClick={handleOpenModal}>{dataContent}</td>;
        case "actions":
            return <td>
                <div className="row justify-content-end">
                    <div className="actionsInbox justify-content-end d-flex gap-3 ms-3 mt-f">
                        {loadingData ? (
                            <button>
                                <i className="fas fa-spinner-third fa-spin third ms-2"></i>
                            </button>
                        ) :
                            <>
                                {actions.map((action, index) =>
                                    <TooltipComponent title={action.tooltip ?? false} key={index}>
                                        <button>
                                            <i className={"far " + action.icon} onClick={() => {
                                                action.onClick();
                                            }} />
                                        </button>
                                    </TooltipComponent>
                                )}
                            </>
                        }

                    </div>
                </div>
            </td>
        default:
            return <td onClick={handleOpenModal}>{!data.Read ? <strong>{value}</strong> : value}</td>
    }
};
