import { PropsWithChildren } from "react";
import Label from "../../../shared/form/Label";

export const Field = ({ title, children, colClass = "col-4" }: PropsWithChildren<{ title?: string | JSX.Element, colClass?: string }>) =>
    <div className={colClass}>
        <Label label={title}>
            {children}
        </Label>
    </div>;

export const TextWithField = ({ text, fieldTitle, children }: PropsWithChildren<{ text: string, fieldTitle: string }>) =>
    <div className="row">
        <div className="col-4 d-flex align-items-center">
            <strong>{text}</strong>
        </div>
        <Field title={fieldTitle}>
            {children}
        </Field>
    </div>;

export const Checkbox = ({ text, onChange = undefined, defaultValue = undefined, value = undefined }: { text: string | JSX.Element, onChange?: (value: boolean) => void, defaultValue?: boolean, value?: boolean }) =>
    <div className="form-check pl-0">
        <label className="form-check-label font-weight-normal">
            <input className="form-check-input me-2" type="checkbox" onChange={e => onChange && onChange(e.target.checked)} defaultChecked={defaultValue} checked={value} />
            {text}
        </label>
    </div>;