import { useEffect, useMemo, useState } from "react";
import { DashboardFilterEntity } from "../../../entities/company/Dashboard/DashboardFilterEntity";
import { FilterRequest } from "../../../entities/Filter/FilterRequest";
import Highcharts from "highcharts";
import { TranslationService } from "../../../services/TranslationService";
import ClientService from "../../../services/ClientService";
import { DashboardEntity } from "../../../entities/company/Dashboard/DashboardEntity";
import AgingClientChart from "./AgingClientChart";

const DashboardClientPanel = ({ clientId, }: { clientId: number, }) => {
    const [dashboardFilters, setDashboardFilters] = useState<DashboardFilterEntity[]>([]);
    const [dashboardAging, setDashboardAging] = useState<{ dashboard: DashboardEntity, optionextend: Highcharts.Options }>();

    Highcharts.setOptions({
        lang: {
            viewFullscreen: TranslationService.translate.HighchartsFullScreen,
            exitFullscreen: TranslationService.translate.HighChartsExitFullScreen,
            printChart: TranslationService.translate.HighchartsPrint,
            downloadPNG: TranslationService.translate.HighchartsDownloadPNG,
            downloadPDF: TranslationService.translate.HighchartsDownloadPDF
        }
    });

    useEffect(() => {
        const loadDashboardAging = async () => {
            const result = await ClientService.getClientAging({ id: clientId });
            if (result instanceof Error) {
                setDashboardAging(undefined);
                return;
            }
            if (result.optionextend.series[0].data.some((x: { [1]: boolean }) => x[1])) {
                setDashboardAging(result);
            }
        };
        clientId && loadDashboardAging();
        setDashboardFilters(x => x.length === 0 ? x : []);
    }, [clientId]);

    const filterCallback = (dashboardFilter: DashboardFilterEntity) => {
        setDashboardFilters(dashboardFilters => {
            if (dashboardFilters.find(x => x.dashboard.DashboardID === dashboardFilter.dashboard.DashboardID)) {
                return dashboardFilters;
            }
            dashboardFilters.push(dashboardFilter);
            return ([...dashboardFilters]);
        });
    };

    const filters: FilterRequest[] = useMemo(() =>
        dashboardFilters.map(({ filterEntity, value, operator }) => ({ field: filterEntity, value: value, operator: parseInt(operator) })), [dashboardFilters]);

    return <>
        {dashboardAging ? (
            <div className="mt-3">
                <section className="p-0">
                    {dashboardAging?.dashboard &&
                        <AgingClientChart
                            dashboard={dashboardAging.dashboard}
                            dashboardFilters={filters}
                            filterCallback={filterCallback}
                            optionextend={dashboardAging.optionextend}
                        />}
                </section>
            </div>
        ) : null}
    </>;
};

export default DashboardClientPanel;