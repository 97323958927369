import { ReactNode, useState } from "react";
import Collapsable from "../bootstrap/Collapsable";

export const CollapsableWithTtitle = ({ title, showDefault = true, children }: { title: string, showDefault?: boolean, children: ReactNode }) => {
    const [show, setShow] = useState(showDefault);
    return (
        <>
            <div className="d-flex pointer" onClick={() => setShow(x => !x)}>
                <h6>
                    {title}
                </h6>
                <i className={"ms-auto far fa-" + (show ? "chevron-up" : "chevron-down")}></i>
            </div>
            <hr></hr>
            <Collapsable show={show}>
                {children}
                <hr></hr>
            </Collapsable>
        </>
    );
};