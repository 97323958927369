import React from "react";
import { TranslationService } from "../../../services/TranslationService";

const ReportsCard = (props: React.PropsWithChildren<{ action?: any, background?: "blue" | "red", title: string, customers: string | number, type: "PaymentAnalysis" | "ClientMap", total?: string | number }>) => {
    const translate = TranslationService.translate
    return (
        <div className={"card " + (props.background)}>
            <div className="row">
                <div className="col-auto">
                    {props.title}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {translate.ClientQty}
                </div>
                <div onClick={props.action} className="col" style={{ textAlign: 'end', cursor: props.action ? 'pointer' : 'default' }}>
                    {props.customers}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {props.type === "PaymentAnalysis" ? translate.TotalPayments : translate.TotalReceivables}
                </div>
                <div className="col" style={{ textAlign: 'end' }}>
                    {props.total || '-'}
                </div>
            </div>
        </div >
    )

}

export default ReportsCard;