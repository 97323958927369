import { MutableRefObject, useContext, useEffect, useMemo, useRef, useState } from "react";
import { FilterCollection } from "../../../services/FilterService";
import InvoiceService from "../../../services/InvoiceService";
import ModalService from "../../shared/bootstrap/Modal";
import AdvancedFilters, { AdvancedFiltersContext } from "../../shared/components/AdvancedFilters";
import FloatingPanelService from "../../shared/FloatingPanel";
import { TranslationService } from "../../../services/TranslationService";
import Table, { TableHeader, TableHeaderWithFieldId } from "../../shared/Table";
import ClientDetailContext, { ClientDetailProvider } from "../ClientDetailContext";
import { TableColumn } from "../invoice/InvoiceTableItem";
import { FloatingPanelFooter } from "../../shared/components/FloatingPanelFooter";
import { formatIntizaDate } from "../../../utils/FormatUtils";
import { ToastService } from "../../shared/bootstrap/Toast";
import FileService from "../../../services/FileService";
import TransactionTableProvider from "../../transaction/TransactionTableProvider";
import CompanyService, { DataType, Entities } from "../../../services/CompanyService";
import ClientService from "../../../services/ClientService";
import { ActivityListResponse } from "../activity/entities/AcitivityListResponse";
import { Cast } from "../../../utils/Utils";
import { InvoiceList } from "../invoice/entities/InvoiceListResponse";
import { TransactionListResponse } from "../../transaction/entities/TransactionListResponse";
import InvoiceDetail from "../invoice/InvoiceDetail";
import { ReportPaymentTotal } from "../../reports/ReportPayment";
import TableContext from "../../task/TableContext";

export const NewReciept = ({ personId, invoiceIds = [] }: { personId: number, invoiceIds?: number[] }) => {
    const [isSending, setIsSending] = useState<string>("");
    const ids = useRef<number[]>([]);
    const { translate } = TranslationService;

    const submitAndSendMail = () => {
        if (isSending !== "") {
            return;
        }
        setIsSending("SendMail");
        alert("Not implemented");
        FloatingPanelService.hidePanel();
        ModalService.hideModal();
        setIsSending("");
    };

    const submitAndDownload = async () => {
        if (isSending !== "") {
            return;
        }
        setIsSending("Download");
        const result = await InvoiceService.togglePay({
            pay: true,
            invoiceIds: ids.current,
            all: false,
            filters: "",
            personId: personId.toString(),
            comments: undefined,
            date: new Date(),
            transactiontype: undefined,
            additional: [],
            receipt: true,
        });
        setIsSending("");
        if (result instanceof Error) {
            ToastService.showToast(translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
        await FileService.downloadReceipt(result.receiptId.toString());
        FloatingPanelService.hidePanel();
        ModalService.hideModal();
    };
    const defaultFilter = [`selDate=${formatIntizaDate(new Date())}&selFields=-303`];

    return (
        <>
            <div className="floatingBody p-4">
                <h6 className="mb-2">
                    {translate.SelectReceipts}
                </h6>
                <ClientDetailProvider>
                    <TransactionTableProvider defaultFilter={defaultFilter}>
                        <List ids={ids} />
                    </TransactionTableProvider>
                </ClientDetailProvider>
            </div>
            <FloatingPanelFooter>
                <button className="btn btn-primary" onClick={submitAndSendMail}>
                    {translate.IssueAndSendByMail}
                    {isSending === "SendMail" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
                <button className="btn btn-primary" onClick={submitAndDownload}>
                    {translate.IssueAndDownload}
                    {isSending === "Download" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </FloatingPanelFooter>
        </>
    );
};

function getTableHeaders() {
    const clientFields = ClientService.getClientHeaders();
    clientFields.forEach(x => x.sorteable = x.fieldId === "Name");
    const translate = TranslationService.translate;
    // const additionalDefinitionsIds = [22962 /*Cuit*/, 22963 /*Categoria*/, 22969 /*Ciudad*/, 22968 /*Tipo Cliente*/, 94806 /*Texto60*/, 113367 /*asdasdasd*/];
    const paymentFixedFields: TableHeaderWithFieldId[] = [{
        ...new TableHeader(
            "checkbox",
            "",
            false,
            false
        ),
        fieldId: "checkbox",
        type: DataType.CurrencyWithCurrencyType,
    },
    {
        ...new TableHeader(
            "date",
            translate.Date,
            false,
            true
        ),
        fieldId: "Date",
        type: DataType.Date,
    },
    {
        ...new TableHeader(
            "amount",
            translate.Amount,
            true,
            true
        ),
        fieldId: "Amount",
        type: DataType.Currency,
    },
    {
        ...new TableHeader(
            "io",
            translate.Invoice,
            false,
            true,
            "long-text"
        ),
        fieldId: "IOId",
        type: DataType.Text,
    },
    {
        ...new TableHeader(
            "iostatus",
            translate.IOStatus,
            false,
            true
        ),
        fieldId: "iostatus",
        type: DataType.Text,
    },
    {
        ...new TableHeader(
            "lastname",
            translate.User,
            false,
            true
        ),
        fieldId: "Fullname",
        type: DataType.Text,
    },
    {
        ...new TableHeader(
            "transactiontype",
            translate.Receipt,
            false,
            false,
        ),
        fieldId: "ReceiptNumber",
        type: DataType.Text,
    },
    ];
    const additionalFields = CompanyService.getAdditionalDefinitions()
        .filter(x => x.Entity === Entities.Payment)
        .map(x => ({
            ...new TableHeaderWithFieldId(
                "ioadditional." + (x.Type === DataType.List ? "list" : "text") + "field" + x.Field,
                x.Name,
                [DataType.Currency, DataType.Number].includes(x.Type),
                true,
                x.AdditionalDefinitionID.toString(),
                x.Type
            ),
        }));
    const activityFixedFields: TableHeaderWithFieldId[] = [
        {
            ...new TableHeader(
                "comments",
                translate.Observations,
                false,
                false,
            ),
            fieldId: "Comments",
            type: DataType.Text,
        }
    ];
    return [...paymentFixedFields, ...clientFields, ...additionalFields, ...activityFixedFields];
}

const List = ({ ids }: { ids: MutableRefObject<number[]> }) => {
    const tableContext = useContext(TableContext);
    const advancedFiltersContext = useContext(AdvancedFiltersContext);
    const onFilterApply = (filters: string[]) => {
        tableContext.applyFilters({ extraFilters: filters });
        ids.current = [];
    };

    useEffect(() => {
        advancedFiltersContext.setShow(true);
    }, [advancedFiltersContext]);

    const toggleOneCheckbox = (invoiceId: number, checked: boolean) => {
        let newIds: number[] = [];
        if (checked) {
            newIds = [...ids.current, invoiceId];
        }
        else {
            newIds = ids.current.filter(x => x !== invoiceId);
        }
        ids.current = newIds;
    };
    const tableHeaders = useMemo(getTableHeaders, []);

    return (<>
        <AdvancedFilters page={FilterCollection.Payment} defaultValue={`filter0=selDate%3D${formatIntizaDate(new Date())}%26selFields%3D-303`} onFilterApply={onFilterApply} />
        <Table headers={tableHeaders} item={ReportActivityItem(toggleOneCheckbox, ids.current)} stickyHeader>
            <ReportPaymentTotal tableHeaders={tableHeaders}></ReportPaymentTotal>
        </Table>
    </>
    );
};

const ReportActivityItem = (toggleCheckbox: (invoiceId: number, checked: boolean) => void, ids: number[]) => function ListItem({ data }: { data: ActivityListResponse.Item }) {
    const tableHeaders = getTableHeaders();
    const { reloadExtraData } = useContext(ClientDetailContext);
    const specialCases = {
        "IOId": (x: TransactionListResponse.Item) => (<td className="is-link">
            <span className="w-md btn btn-link" onClick={() => FloatingPanelService.showPanel({
                children: <InvoiceDetail invoiceId={x.IOId} reload={() => reloadExtraData()} />,
                title: "",
                width: 750,
                height: 800,
            })}>
                {x.io.referenceNumber}
            </span>
        </td>),
        "checkbox": (x: TransactionListResponse.Item) => <td className="w-30px" onClick={(event) => event.stopPropagation()}>
            <input type="checkbox" onChange={(e) => toggleCheckbox(x.IOId, e.target.checked)} defaultChecked={ids.includes(x.IOId)} />
        </td>,
    };
    return (<tr key={data.ID}>
        {tableHeaders.map(header => <TableColumn key={header.fieldId} data={Cast<InvoiceList.Item>(data)} header={header} specialCases={specialCases} />)}
    </tr>);
};
