import React, { useCallback, useEffect, useState } from 'react'
import { ToggleEnableActionWrapper, convertBool } from './OtherConfigurationItems/ToggleEnableActionWrapper'
import { DropdownSelectWrapper } from './OtherConfigurationItems/DropdownSelectWrapper'
import * as yup from "yup";
import { TranslationService } from '../../../services/TranslationService';
import ButtonPrimary from '../../account/components/ButtonPrimary';
import CompanyService from '../../../services/CompanyService';
import { TitleConfig } from './components/TitleConfig';

type EnableCustomerMessagesType = {
    customerMessages: 'False' | 'True',
    customerMessagesToAddress: string,
    customermessageactivitytypeid: number,
    customerMessagesToAdditional: number,
    customerMessagesTo: number | string,
    fullSize?: boolean
}

export const EnableCustomerMessages = ({
    customerMessages,
    customermessageactivitytypeid,
    customerMessagesTo,
    customerMessagesToAddress,
    customerMessagesToAdditional,
    fullSize = false
}: EnableCustomerMessagesType) => {
    const [error, setError] = useState('')
    const [saveData, setSaveData] = useState(false)
    const [formData, setFormData] = useState({ customerMessagesToAddress: customerMessagesToAddress });

    const listItems = CompanyService.getActivityTypes().map(x => ({ text: x.ActivityTypeName, value: x.ActivityTypeID }))
    const listItemsFields = CompanyService.getAdditionalDefinitions().filter(x => x.TypeName === 'Free text').map(x => ({ text: x.Name, value: x.AdditionalDefinitionID }))

    const formDataSchema = yup.object({
        customerMessagesToAddress: yup
            .string()
            .email(TranslationService.translate.LoginEmailIncorrect)
            .required(TranslationService.translate.LoginEmailMissing),
    });

    const handleInputChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prevState) => ({ ...prevState, [name]: value }));
        setSaveData(true)
    }

    const saveValues = useCallback(() => {
        setSaveData(false)
        CompanyService.setConfigCompany({ customerMessagesToAddress: formData.customerMessagesToAddress })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    useEffect(() => {
        formDataSchema.validate(formData)
            .then(() => {
                setError('')
                setSaveData(true)
            })
            .catch((err) => setError(err.errors[0]))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    return (
        <div className={`${fullSize ? '' : 'col-md-8'}`}>
            <div className="mt-3">
                <ToggleEnableActionWrapper
                    title="EnableCustomerMessages"
                    action="customerMessages"
                    initialValue={customerMessages}
                    loadingListener
                    fullSize />
            </div>
            {convertBool[customerMessages] &&
                <>
                    {listItems &&
                        <div className="my-3">
                            <DropdownSelectWrapper
                                initialValue={customermessageactivitytypeid}
                                items={[{ value: 0, text: TranslationService.translate.None }, ...listItems]}
                                title="CustomerMessagesActivityType"
                                action="customermessageactivitytypeid"
                                fullSize />
                        </div>}

                    <div className="col my-3">
                        <TitleConfig title={TranslationService.getTranslation('SendMessagesTo')} className='mx-3' />
                        <div className='d-flex justify-between align-items-center'>
                            <div className='col'>
                                <DropdownSelectWrapper
                                    initialValue={customerMessagesTo}
                                    items={[
                                        { value: 0, text: TranslationService.translate.FollowingAddress },
                                        { value: 1, text: TranslationService.translate.ReadFromField },
                                    ]}
                                    action="customerMessagesTo"
                                    fullSize />
                            </div>
                            <div className='col'>
                                {Boolean(parseInt(customerMessagesTo as string)) ?
                                    <DropdownSelectWrapper
                                        initialValue={customerMessagesToAdditional}
                                        items={listItemsFields}
                                        action="customerMessagesToAdditional"
                                        fullSize />
                                    :
                                    <input
                                        name='customerMessagesToAddress'
                                        className="form-control form-control-sm"
                                        defaultValue={formData.customerMessagesToAddress}
                                        onChange={handleInputChange} />}
                            </div>
                        </div>
                    </div>
                </>}

            <div className='d-flex justify-content-between align-items-center my-2'>
                <div id="errorMsj" className=" mx-3 incorrect" style={{ color: "red", fontSize: "14px", marginTop: "10px" }}>{error}</div>
                {saveData && !error && <ButtonPrimary onClick={saveValues}>{TranslationService.translate.Save}</ButtonPrimary>}
            </div>
        </div>
    )
}