import { SavedFilter } from "../pages/shared/components/AdvancedFilters";
import { getCache, setCache } from "../utils/PersistenceUtils";
import { Cast } from "../utils/Utils";
import CompanyService from "./CompanyService";

type UserPrefs = {
    clientDetailsCollapsed: boolean,
    pageSizePreference: Record<string, number>,
    rememberUser: boolean,
    userEmailCache: string,
    filters: Record<string,SavedFilter[]>,
};
type Writeable<T> = { -readonly [P in keyof T]: T[P] };
export enum CacheEntity {
    GroupName = 1,
    Client
}
export class StorageService {
    public static getPreferences() {
        const prefs = getCache<Partial<UserPrefs>>(getPrefsKey());
        return prefs ?? {};
    }
    public static setPreference<TKey extends keyof UserPrefs>(prefKey: TKey, value: UserPrefs[TKey]) {
        const prefs = (getCache<Partial<UserPrefs>>(getPrefsKey()) ?? {}) as Writeable<Partial<UserPrefs>>;
        prefs[prefKey] = value;
        setCache(getPrefsKey(), prefs);
    }
    private static entityCacheStorage: Record<string, unknown> = {};
    private static generateKey(entity: CacheEntity, id: string | number) {
        return entity.toString() + "-" + id.toString();
    }
    public static getCache<T>(entity: CacheEntity, id: string | number) {
        return this.entityCacheStorage[this.generateKey(entity, id)] as T | undefined;
    }
    public static setCache<T>(entity: CacheEntity, id: string | number, value: T) {
        this.entityCacheStorage[this.generateKey(entity, id)] = value;
    }
}
function getPrefsKey() {
    return Cast<"company_auth">("user_prefs_" + CompanyService.getUserid());
}
