import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Cast } from "../../utils/Utils";
import { Path } from "./Breadcrumb";
import { PathHistory } from "./BreadcrumbHistory";

export type NavigationHistoryValues = {
    pathHistory?: readonly Path[];
    conserveHistory: () => void;
    setCurrentPath:(val: Path) => void;
}
export class NavigationHistory implements NavigationHistoryValues {
    pathHistory?: Path[] = undefined;
    conserveHistory = () => this.shouldConserveHistory = true;
    setCurrentPath = (val: Path) => this.currentPath = val;
    currentPath?: Path = undefined;
    shouldConserveHistory = false;
}
export const NavigationHistoryProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const history = useHistory<PathHistory>();
    const [navigationHistory, setNavigationHistory] = useState(new NavigationHistory());
    
    useEffect(() => {
        return history.listen(() => {            
            setNavigationHistory(oldHistory => {
                const newNavigationHistory = new NavigationHistory();
                if (oldHistory.shouldConserveHistory) {
                    newNavigationHistory.pathHistory = oldHistory.pathHistory;
                    if (oldHistory.currentPath) {
                        newNavigationHistory.pathHistory = newNavigationHistory.pathHistory ?? [];
                        newNavigationHistory.pathHistory.push(oldHistory.currentPath);
                    }
                }
                return newNavigationHistory;
            });
        });
    }, [history]);
    return (
        <NavigationHistoryContext.Provider value={navigationHistory}>
            {children}
        </NavigationHistoryContext.Provider>
    );
}
export const NavigationHistoryContext = React.createContext(Cast<NavigationHistoryValues>(new Error("No NavigationHistoryContext")));