import { useEffect, useRef, useState } from 'react';
import { TranslationService } from '../../../../services/TranslationService';
import { formatDateTimeDigits } from '../../../../utils/FormatUtils';
import { restTimezone } from '../../../../utils/ParseUtils';
import { ActivityListResponse } from '../../../client/activity/entities/AcitivityListResponse';
import FileService from '../../../../services/FileService';
import './styles.css';

type WhatsappChatType = {
    noMoreResults: boolean,
    getMoreMessages: () => void,
    isFetching: boolean,
    matrizResults: any[][]
}

export const WhatsappChat = ({ noMoreResults, getMoreMessages, isFetching = false, matrizResults }: WhatsappChatType) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [firtsLoadList, setFirtsLoadList] = useState(true);
    const matriz = matrizResults.reduce((acumulador, arrayActual) => acumulador.concat(arrayActual), []);

    const scrollToBottom = () => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        if ((firtsLoadList || matriz.length > 0) && !isFetching) {
            scrollToBottom();
            setFirtsLoadList(false);
        }
    }, [firtsLoadList, isFetching, matriz]);

    return (
        <div className="page flex-1">
            <div className="screen">
                <div className="screen-container">
                    <div className="chat">
                        <div className="chat-container">
                            <div className="conversation">
                                <div className="conversation-container" ref={containerRef}>
                                    {isFetching ? <i className="fas fa-spinner-third fa-spin third ms-2 text-center w-100 my-4"></i> :
                                        !noMoreResults &&
                                        <span className="linkAction" onClick={getMoreMessages}>
                                            {TranslationService.translate.MessagesLoadMore}
                                        </span>
                                    }

                                    {matriz.length > 0 ?
                                        matriz
                                            .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
                                            .map((msg, index) => <Message key={index} message={msg} />)
                                        :
                                        (firtsLoadList && !isFetching) && <h5 className='m-2 text-center'>{TranslationService.translate.NoResults}</h5>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Message = ({ message }: { message: ActivityListResponse.Item }) => {
    return (
        <div className={`message ${!message.Inbound ? 'sent' : 'received'}`}>
            <MessageContent message={message} />

            <span className="metadata">
                <span className="time">{formatDateTimeDigits(restTimezone(new Date((message.date))))}</span>
                <span className="time"></span>
                {message?.Status && <StatusMessage status={message.Status} />}
            </span>
        </div>
    )
}

const MessageContent = ({ message }: { message: ActivityListResponse.Item }) => {
    const regex = /\.(jpg|jpeg|png|gif|bmp|tiff|webp|pdf|mp3|wav|flac|aac|ogg)$/i;
    const fileDetected = message.files[0];
    const match = fileDetected?.fileName?.toString().match(regex);

    const downloadFile = () => FileService.downloadFileMessage(fileDetected.id, message.ID, fileDetected.fileName);

    if (match) {
        switch (match[1]) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
            case 'bmp':
            case 'tiff':
            case 'webp':
                return (
                    <>
                        <img src={FileService.getThumbSrcUrl(parseInt(fileDetected.id), message.ID)} alt={message.Content} />
                        <br />
                    </>
                )
            case 'pdf':
                return <>
                    <button className="btn btn-link p-0 m-0 ms-2" onClick={downloadFile}>
                        {fileDetected.fileName}
                    </button>
                    <br />
                </>
            case 'ogg':
            case 'wav':
            case 'flac':
            case 'aac':
            case 'mp3':
                return <>
                    <audio src={FileService.getSrcUrl(parseInt(fileDetected.id), message.ID)} controls />
                    <br />
                </>
        }
    }

    return <>
        {message.Content}
        <br />
    </>
}

export enum WhatsappStatus {
    failed = 1,
    enqueued = 2,
    delivered = 3,
    sent = 4,
    received = 5,
    read = 6
}

export const StatusMessage = ({ status }: { status: WhatsappStatus }) => {
    if (status === WhatsappStatus.failed) {
        return (
            <span className="tick">
                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 512 512" version="1.1">
                    <title>warning</title>
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
                        <g id="add" fill="red" transform="translate(32.000000, 42.666667)">
                            <path d="M246.312928,5.62892705 C252.927596,9.40873724 258.409564,14.8907053 262.189374,21.5053731 L444.667042,340.84129 C456.358134,361.300701 449.250007,387.363834 428.790595,399.054926 C422.34376,402.738832 415.04715,404.676552 407.622001,404.676552 L42.6666667,404.676552 C19.1025173,404.676552 7.10542736e-15,385.574034 7.10542736e-15,362.009885 C7.10542736e-15,354.584736 1.93772021,347.288125 5.62162594,340.84129 L188.099293,21.5053731 C199.790385,1.04596203 225.853517,-6.06216498 246.312928,5.62892705 Z M225.144334,42.6739678 L42.6666667,362.009885 L407.622001,362.009885 L225.144334,42.6739678 Z M224,272 C239.238095,272 250.666667,283.264 250.666667,298.624 C250.666667,313.984 239.238095,325.248 224,325.248 C208.415584,325.248 197.333333,313.984 197.333333,298.282667 C197.333333,283.264 208.761905,272 224,272 Z M245.333333,106.666667 L245.333333,234.666667 L202.666667,234.666667 L202.666667,106.666667 L245.333333,106.666667 Z" id="Combined-Shape">

                            </path>
                        </g>
                    </g>
                </svg>
            </span>
        )
    }

    if (status === WhatsappStatus.enqueued) {
        return <span className="tick">
            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none">
                <path d="M12 7V12H15M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="grey" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </span>
    }

    if (status === WhatsappStatus.read) {
        return (
            <span className="tick">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076">
                    <path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#4fc3f7" />
                </svg>
            </span>
        )
    }

    if (status === WhatsappStatus.received) {
        return (
            <span className="tick">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076">
                    <path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                        fill="grey" />
                </svg>
            </span>
        )
    }

    return (
        <span className="tick">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076">
                <path d="M15.01 3.316l-.478-.372a.365.365 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                    fill="grey" />
            </svg>
        </span>
    )
}