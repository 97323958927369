import { useMemo } from "react"
import { TranslationService } from "../../../../services/TranslationService"
import { getEnumArray } from "../../../../utils/EnumUtils"
import { daysOfTheWeek } from "../../../../utils/Utils"
import Dropdown from "../../../shared/components/Dropdown"
import { EditProps, FieldKey, numEvent } from "../EditAction"
import { Field, Checkbox } from "./FieldHelpers"
import CompanyService from "../../../../services/CompanyService"
import { ActionType } from "../../../../services/ActionService"

const dayKeys = Array.from({ length: 7 }).map((x, i) => "Day" + i.toString() as FieldKey<Boolean>);
export const SendTime = ({ setField, data, propGen }: EditProps) => {
    enum SendOptions {
        Once,
        After,
        Every,
        Weekly,
        Monthly,
    }
    const days = useMemo(() => daysOfTheWeek.map((x, i) => ({
        key: i === 6 ? dayKeys[0] : dayKeys[i + 1],
        text: TranslationService.getTranslation(x)
    })), []);

    const hoursProps = propGen.str.notRequired("Hour");
    if (!hoursProps.defaultValue) {
        hoursProps.defaultValue = "08:00:00";
    }
    return (<>
        <div className="row">
            <Field title={TranslationService.translate.SendOption}>
                <Dropdown items={getEnumArray(SendOptions).slice(data.Type === ActionType.ActionSMSSend || data.Type === ActionType.MailSend || data.Type === ActionType.ActionSendLetter ? 0 : 2)}
                    {...propGen.num.notRequired("Mail_Period")} />
            </Field>
            {[SendOptions.After, SendOptions.Every].includes(data.Mail_Period!) &&
                <Field title={TranslationService.translate.Days}>
                    <input className="form-control"
                        {...numEvent(propGen.num.notRequired("Mail_Frequency"))} />
                </Field>}
            {data.Mail_Period === SendOptions.Weekly &&
                <Field title={TranslationService.translate.Days}>
                    <div className="weekday-buttons-list">
                        {days.map(x =>
                            <span key={x.key} className={data[x.key] ? "active" : ""}
                                onClick={() => setField(x.key)(!Boolean(data[x.key]))}>{x.text[0]}
                            </span>)}
                    </div>
                </Field >}
            {data.Mail_Period === SendOptions.Monthly &&
                <Field title={TranslationService.translate.On}>
                    <Dropdown items={Array.from({ length: 31 }).map((x, i) => ({
                        text: (i + 1).toString().padStart(2, "0"),
                        value: (i + 1),
                    }))} {...propGen.num.notRequired("Mail_Frequency")} />
                </Field>}
            <Field title={TranslationService.translate.SendTime}>
                <Dropdown items={Array.from({ length: 24 }).map((x, i) => ({
                    text: i.toString().padStart(2, "0") + ":00",
                    value: ((i + CompanyService.getTimezone() / 60) % 24).toString().padStart(2, "0") + ":00:00",
                }))} {...hoursProps} />
            </Field>
            {(data.Type === ActionType.MailSend || data.Type === ActionType.ActionSMSSend) && data.Mail_Period !== SendOptions.Weekly &&
                <Field>
                    <Checkbox text={TranslationService.translate.ExcludeSaturday}
                        defaultValue={!data.Mail_Saturday} onChange={x => setField("Mail_Saturday")(!x)} />
                    <Checkbox text={TranslationService.translate.ExcludeSunday}
                        defaultValue={!data.Mail_Sunday} onChange={x => setField("Mail_Sunday")(!x)} />
                </Field>}
        </div >
    </>)
}