import { useContext } from "react";
import { CurrencyTotal } from "../../../entities/shared/CurrencyTotal";
import { DataType } from "../../../services/CompanyService";
import InvoiceService, { InvoiceFields } from "../../../services/InvoiceService";
import { formatCurrency, formatNumber } from "../../../utils/FormatUtils";
import { TableHeaderWithFieldId } from "../../shared/Table";
import TableContext from "../../task/TableContext";
import { InvoiceListResponse } from "./entities/InvoiceListResponse";

const InvoiceTableTotal = ({ tableHeaders = undefined }: { tableHeaders?: TableHeaderWithFieldId[] }) => {
    const headers = tableHeaders ?? InvoiceService.getTableHeaders();
    const response = useContext(TableContext).response as InvoiceListResponse | undefined;
    return (
        <>
            {response && response.currencyTotals.map((totals, index) =>
                <tr key={index} className={"font-weight-bold"} style={{ backgroundColor: (index % 2 === 0) ? "#ddedf9" : "#e4f4ff", }}>
                    {headers.map(header => <TableColumn key={header.fieldId} currencyTotals={response.currencyTotals} header={header} index={index} />)}
                </tr>
            )}
        </>);
};

const TableColumn = ({ currencyTotals, header, index }: { currencyTotals: CurrencyTotal[][], header: TableHeaderWithFieldId, index: number }) => {
    const data = currencyTotals[index];
    const getValue = () => {
        switch (header.fieldId) {
            case "pending":
                return data.find(x => x.id === InvoiceFields.pending);
            case "amount":
                return data.find(x => x.id === InvoiceFields.amount);
        }
        return data.find(x => x.id.toString() === header.fieldId);
    };
    const value = getValue();
    if (value === undefined) {
        return <td></td>;
    }
    switch (header.type) {
        case DataType.Currency:
            return <td className={"text-end"}>{formatCurrency(value.value, value.currencyid)}</td>;
        case DataType.Number:
            if (index > 0) {
                return <td></td>;
            }
            {
                const sum = currencyTotals.flat().filter(x => x.id === value.id).map(x => x.value).reduce((x, y) => x + y, 0);
                return <td className={"text-end"}>{formatNumber(sum)}</td>;
            }
        default:
            return <td>{value} {value.currencyid}</td>;
    }
};

export default InvoiceTableTotal;

