import React, { useCallback, useState } from "react";
import TableContext, { TableContextValues } from "../../task/TableContext";
import { applyFiltersHelper, calculatePageCount, setCurrentPageHelper, sortHelper, useEndpointData } from "../../../utils/TableUtils";
import { TagReportRequest, TagReportResponse } from "../../../entities/company/Tag/TagListResponse";
import TagService from "../../../services/TagService";


export class ReportTagListTableContextValues extends TableContextValues<TagReportResponse, { extraFilters: string[], pagesize: any; }> {
    request: TagReportRequest = new TagReportRequest();
    setRequest: (request: TagReportRequest) => void = () => { };
}

const ReportTagListProvider = ({ children, prevRequest = undefined }: React.PropsWithChildren<{ prevRequest?: TagReportRequest }>) => {
    const [request, setRequest] = useState(prevRequest ?? new TagReportRequest());
    const endpoint = useCallback(() => TagService.getTagReport(request?.filter), [request?.filter]);
    const { loading, error, response, reload } = useEndpointData(endpoint);
    const pageCount = calculatePageCount(response);
    const { sort, setSortColumn } = sortHelper({ ...request, descending: 0, sortColumn: 'date' }, setRequest);

    const applySearch = (keyword: any) => {
        setRequest({ ...request, text: keyword })
    };

    const value: ReportTagListTableContextValues =
    {
        request,
        setRequest,
        loading,
        error,
        response,
        reload,
        pageCount,
        sort,
        setSortColumn,
        setCurrentPage: setCurrentPageHelper(setRequest, pageCount),
        pageSize: request.pageSize,
        applyFilters: applyFiltersHelper(setRequest),
        applySearch,
    }

    return (
        <TableContext.Provider value={value}>
            {children}
        </TableContext.Provider>
    )
}

export default ReportTagListProvider; 