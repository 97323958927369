import React from "react";
import { Link } from "react-router-dom";
import CompanyService, { DataType } from "../../../services/CompanyService";
import InvoiceService from "../../../services/InvoiceService";
import { formatCurrency, formatDateShort, formatNumber, unbreak } from "../../../utils/FormatUtils";
import { TranslationService } from "../../../services/TranslationService";
import TableContext from "../../task/TableContext";
import ClientDetailContext from "../ClientDetailContext";
import { InvoiceList } from "./entities/InvoiceListResponse";
import { TableHeaderWithFieldId } from "../../shared/Table";
import { Cast, OptionalMap } from "../../../utils/Utils";
import { handleErrorWithToast } from "../../../utils/RequestUtils";
import { CacheEntity, StorageService } from "../../../services/StorageService";
import { percentFormatCollection } from "../../../utils/PercentCollection";
import { parseDate } from "../../../utils/ParseUtils";

const InvoiceTableItemReadOnly = (onCheckboxChange?: (invoiceId: number, checked: boolean) => void, headers?: TableHeaderWithFieldId[]) =>
    function InvoiceTableItemImpl({ data }: { data: InvoiceList.Item }) {
        const specialCases = {
            "download": () => !data.HasFiles ? <td></td> : <td><i className="fas fa-fw fa-download pointer" onClick={(e) => { e.stopPropagation(); handleErrorWithToast(InvoiceService.downloadAllFilesFromInvoice(data.IOID)); }}></i></td>,
            "group": () => <td>{data.GroupID ? StorageService.getCache(CacheEntity.GroupName, data.GroupID) : ""}</td>
        };
        return (
            <ClientDetailContext.Consumer>
                {({ invoiceIds, invoiceAll }) =>
                    <TableContext.Consumer>
                        {() => {
                            if (!headers) {
                                headers = InvoiceService.getTableHeaders();
                            }
                            return <tr key={data.IOID}>
                                {onCheckboxChange &&
                                    <td onClick={event => event.stopPropagation()} width={40}>
                                        <input
                                            type="checkbox"
                                            className="checkbox-invoice"
                                            data-invoiceselected={data.IOID}
                                            defaultChecked={invoiceAll || invoiceIds.includes(data.IOID)}
                                            onChange={event => onCheckboxChange(data.IOID, (event.target as HTMLInputElement).checked)} />
                                    </td>}
                                {headers.filter(x => x.fieldId !== "checkbox").map(header => <TableColumn key={header.fieldId} data={data} header={header} specialCases={specialCases} />)}
                            </tr>;
                        }}
                    </TableContext.Consumer>
                }
            </ClientDetailContext.Consumer>
        );
    };

export const TableColumn = <T,>({ data, header, specialCases = {} }: { data: InvoiceList.Item, header: TableHeaderWithFieldId, specialCases?: { [key: string]: undefined | ((x: T) => (undefined | React.ReactElement)) } }) => {
    const { translate, currentLanguage } = TranslationService;
    // const { reload } = useContext(TableContext);
    // const { reloadExtraData } = useContext(ClientDetailContext);
    const getValue = () => {
        if (header.key.startsWith("ioadditional.")) {
            return data.additionals.find(x => x.Id.toString() === header.fieldId)?.Value ??
                Cast<{ transactionadditionals?: typeof data.additionals }>(data).transactionadditionals?.find(x => x.Id.toString() === header.fieldId)?.Value;
        }
        return data[header.fieldId as keyof InvoiceList.Item];
    };

    const negativeValueClass = CompanyService.getSetting("iosnegativeamountinred") ? "text-danger" : "";
    const value = getValue();
    const specialCase = specialCases[header.fieldId];
    if (specialCase) {
        const caseResult = specialCase(Cast<T>(data));
        if (caseResult) {
            return caseResult;
        }
    }

    switch (header.fieldId) {
        case "StatusIcon":
            return <td>{data.pending === 0 ? <i className="far fa-square-check"></i> : ""}</td>;
        case "dueDate":
            if (data.dueDays === 0) {
                return <td>{data.dueDate ? formatDateShort(new Date(data.dueDate)) : ""}</td>;
            }
            if (data.dueDays > 0) {
                return <td className="text-danger">{formatDateShort(new Date(data.dueDate))}<small className="ps-1">({data.dueDays} {translate.Days})</small></td>;
            }
            return <td>{unbreak(formatDateShort(new Date(data.dueDate)))}</td>;
        case "status":
            {
                const status = CompanyService.getIoStatus().find(x => x.IOStatusID === data.IOStatusID)?.Value ?? CompanyService.getNormalIOStatusName();
                return <td>{unbreak(status)}</td>;
            }
        case "percentDue":
            {
                return <td className={"text-end "}>{percentFormatCollection(data.overdueAmount, data.amount)}</td>;
            }
        case "pending2":
            {
                const defaultCurrencyId = CompanyService.getDefaultCurrencyId();
                if (data.CurrencyID === defaultCurrencyId) {
                    return <td className={"text-end"}></td>;
                }
                const currencies = CompanyService.getCurrencies();
                const rate = currencies.find(x => x.CurrencyId === data.CurrencyID)!.Rate;
                return <td className={"text-end " + (data.pending < 0 ? negativeValueClass : "")}>{formatCurrency(data.pending * rate, defaultCurrencyId, formatNumber)}</td>;
            }
        case "Name":
            return (<td className="is-link long-text">
                <span className="w-md">
                    <Link to={`/${currentLanguage}/client/details?id=${data.personId}`}>
                        {Cast<{ Fullname: string }>(data).Fullname}
                    </Link>
                </span>
            </td>);
    }
    if (value === undefined || value === null || value === "") {
        return <td></td>;
    }
    switch (header.type) {
        case DataType.Currency:
            return <td className={"text-end " + (parseFloat(value) < 0 ? negativeValueClass : "")}>{formatCurrency(parseFloat(value), data.CurrencyID, formatNumber)}</td>;
        case DataType.Number:
            return <td className={"text-end"}>{formatNumber(value)}</td>;
        case DataType.Date:
            return <td>{OptionalMap(parseDate(value), x => formatDateShort(x))}</td>;
        // return <td>{OptionalMap(parseDate(value), x => <TooltipComponent title={(x.getHours() | x.getMinutes()) ? formatDateTimeDigits(x) : ""}>{formatDateShort(x)}</TooltipComponent>)}</td>;
        case DataType.List:
            {
                const additionalId = header.fieldId;
                const additionalDefinition = CompanyService.getAdditionalDefinitions()
                    .find(x => x.AdditionalDefinitionID.toString() === additionalId);
                const definition = additionalDefinition?.AdditionalDefinitionItems.find(x => x.AdditionalDefinitionItemID.toString() === value);
                return <td>{definition?.Value}</td>;
            }
        default:
            return <td>{value}</td>;
    }
};

export default InvoiceTableItemReadOnly;

