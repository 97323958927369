import FloatingPanelService from "../../shared/FloatingPanel";
import { TranslationService } from '../../../services/TranslationService';
import { TagEntity } from "../../../entities/company/Tag/TagEntity";
import { useCallback, useContext, useEffect, useState } from "react";
import { RequiredManager, ValidationMessage } from "../../shared/RequieredManager";
import ModalService from "../../shared/bootstrap/Modal";
import ToastContext from "../../shared/bootstrap/Toast";
import { TextEditor } from "../../shared/components/Editors";
import { FloatingPanelFooter } from "../../shared/components/FloatingPanelFooter";
import TagService from "../../../services/TagService";
import ErrorMessage from "../../shared/components/Error";
import Loading from "../../shared/components/Loading";
import { TagListResponse } from "../../../entities/company/Tag/TagListResponse";

const TagsConfiguration = () => {
    const { translate } = TranslationService;
    const [response, setResponse] = useState<TagListResponse>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const requestData = async () => {
        setLoading(true);
        const result = await TagService.getList();
        if (result instanceof Error) {
            setError(true);
            setLoading(false);
            return;
        }
        setResponse(result);
        setLoading(false);
        setError(false);
    }

    const requestDataCallback = useCallback(requestData, []);

    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    if (loading) {
        return (<Loading />)
    }

    if (error) {
        return (<ErrorMessage onRefresh={requestDataCallback} message={translate.ErrorLoadingList} />)
    }

    const showTagEdit = (tag?: TagEntity) => {
        FloatingPanelService.showPanel({
            children: <TagEdit reload={requestDataCallback} tag={tag} />,
            title: tag ? translate.TagEdit : translate.NewTag,
            width: 600,
            height: 250,
            position: "center",
        });
    }
    return (
        <div className="card px-5">
            <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center my-4">
                <h2>{TranslationService.translate.Tags}</h2>
                <div className="d-flex align-items-center">
                    <button className="btn btn-primary" onClick={() => showTagEdit(undefined)}><i className="fal fa-plus" /> {translate.NewTag}</button>
                </div>
            </div>

            <table className="table table shadow-hover">
                <thead>
                    <tr>
                        <th>{translate.Value}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {response?.list.map(x => (
                        <tr key={x.TagID} className="pointer show-child-on-hover" onClick={(event) => {
                            showTagEdit(x); event.stopPropagation();
                        }}>
                            <td>
                                {x.Name}
                            </td>
                            <td width={200}> </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}



const TagEdit = ({ reload, tag }: { reload: () => void, tag?: TagEntity }) => {

    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);
    const [submiting, setSubmiting] = useState<"set" | "del" | null>(null);
    const [value, setValue] = useState(tag?.Name ?? "");

    const requiredManager = new RequiredManager();
    const onValueChanged = requiredManager.makeRequired(setValue);

    const onSubmit = async () => {
        if (submiting !== null) {
            return;
        }
        if (!requiredManager.validate()) {
            showToast(translate.MissingRequiredFields);
            return;
        }
        setSubmiting("set");
        const result = await TagService.set(value, tag?.TagID);
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
            setSubmiting(null);
            return;
        }
        setSubmiting(null);
        reload();
        FloatingPanelService.hidePanel();
        showToast(translate.TagSaved, undefined, "success");
    }

    const onDelete = () => {
        const deleteTag = async () => {
            if (submiting !== null) {
                return;
            }
            setSubmiting("del");
            const result = await TagService.delete(tag?.TagID!);
            if (result instanceof Error) {
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
                setSubmiting(null);
                return;
            }
            setSubmiting(null);
            reload();
            FloatingPanelService.hidePanel();
            showToast(translate.TagDeleted, undefined, "success");
        }
        ModalService.showDefaultModal({
            acceptButtonLabel: translate.Delete,
            message: () => <>{translate.AreYouSureDeleteTag.split("\n").map(x => (
                <><span>{x}</span><br /></>
            ))}</>,
            onAcceptClick: deleteTag,
        });
    }


    return (
        <>
            <div className="floatingBody p-4">
                <div className="card-body">
                    <div className="d-flex flex-column">
                        <div className="row mb-1">
                            <label className="col-3 font-weight-normal col-form-label">{translate.Name}:</label>
                            <div className="col-8">
                                <TextEditor onChange={onValueChanged} defaultValue={value} />
                                {<ValidationMessage onChange={onValueChanged} defaultValue={value} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FloatingPanelFooter>

                {tag &&
                    <button type="button" className="btn btn-danger btn-sm" onClick={onDelete}>
                        {translate.Delete}
                        {submiting === "del" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                    </button>}

                <button className="btn btn-primary btn-sm" onClick={onSubmit}>
                    {translate.Save}
                    {submiting === "set" && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </FloatingPanelFooter>
        </>
    )
}

export default TagsConfiguration;