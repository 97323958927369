import SearchInput from "../../shared/components/SearchInput";

type SearchBarType = {
    onSearch: (value: string) => void,
    labelSearch?: string
}

export const SearchBar = ({ onSearch, labelSearch = "Buscar configuración..." }: SearchBarType) => {
    const handleInputChange = (value: string) => {
        onSearch(value);
    };

    return (
        <div className="mb-3 row align-items-center show-child-on-hover">
            <div className="col">
                <div className="searcherFilterHeader" style={{ height: 40 }}>
                    <SearchInput onSearch={handleInputChange} placeholder={labelSearch} divClass="max-width-100"/>
                </div>
            </div>
        </div>
    );
};
