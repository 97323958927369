interface CustomButtonHTMLAttributes extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    onClick?: () => void | undefined,
    // children: any,
}

const ButtonPrimary: React.FC<CustomButtonHTMLAttributes> = (props: CustomButtonHTMLAttributes) => {
    const onClick = props.onClick || undefined;
    const handleOnClick = (e: any) => {
        e.preventDefault();
        if (onClick) {
            onClick();
        }
    }
    return (
        <button onClick={handleOnClick} disabled={props.disabled} className="btn btn-primary rounded-pill px-4" type="submit">
            {props.children}
        </button>
    )
}

export default ButtonPrimary;
