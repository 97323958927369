
import { TranslationService } from "../../../services/TranslationService";
import { CheckBoxEditor } from "../../shared/components/Editors";
import { Field } from "../../action/Edit/components/FieldHelpers";
import Dropdown from "../../shared/components/Dropdown";

const SMSConfigurationEdit = () => {
    return (
        <>
            <div className="floatingBody p-4">
                <h5>{TranslationService.translate.SMSSetup}</h5>
                <div className="row my-4">
                    <div className="col-12 ">
                        <div className="mb-3">
                            <Field title={TranslationService.translate.SendMethod}>
                                <Dropdown onChange={() => { }} items={[{ value: "", text: "Manual" }, { value: "", text: "Altiria" }, { value: "", text: "Concepto Móvil" }]} />
                            </Field>
                        </div>
                        <div className="mb-3">
                            <CheckBoxEditor message={"Registrar en historial del cliente"} onChange={() => { }} />
                        </div>
                        <div className="mb-3">
                            <Field title="label del input" colClass="col-12">
                                <input className="form-control w-100"></input>
                            </Field>
                            <Field title="label del input" colClass="col-12">
                                <input className="form-control w-100"></input>
                            </Field>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}




export default SMSConfigurationEdit;