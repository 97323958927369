import { useCallback, useEffect, useState } from "react";
import { DashboardDebt, DashboardDebtResponse } from "../../../entities/company/Dashboard/DashboardDebtResponse";
import { DashboardEntity } from "../../../entities/company/Dashboard/DashboardEntity";
import { FilterRequest } from "../../../entities/Filter/FilterRequest";
import DashboardService from "../../../services/DashboardService";
import Loading from "../../shared/components/Loading";
import DashboardSales from "../../client/components/DashboardSales";


const DebtChart = ({ dashboard, dashboardFilters }: { dashboard: DashboardEntity, dashboardFilters: FilterRequest[] }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [overdue, setOverdue] = useState<DashboardDebt>();
    const [notDue, setNotDue] = useState<DashboardDebt>();

    const requestData = async () => {
        setLoading(true);
        const result = await DashboardService.calculate(dashboard.DashboardID, dashboardFilters);
        setLoading(false);
        if (result instanceof Error) {
            setError(true);
            return;
        }
        const dashboardDebt = result as DashboardDebtResponse;

        let notDueInfo: DashboardDebt | undefined;
        let overdueInfo: DashboardDebt | undefined;
        if (!dashboardDebt.list) {
            setError(false);
        }
        else {
            [notDueInfo, overdueInfo] = dashboardDebt.list.sort((a, b) => Number(a.GroupBy) - Number(b.GroupBy));
        }

        if (!overdueInfo) {
            overdueInfo = {
                color: "rgb(236, 4, 35)",
                GroupBy: "",
                name: "",
                Other: true,
                y: 0,
                ydue: 0
            };
        }

        if (!notDueInfo) {
            notDueInfo = {
                color: "rgb(22, 71, 181)",
                GroupBy: "",
                name: "",
                Other: true,
                y: 0,
                ydue: 0
            };
        }
        setOverdue(overdueInfo);
        setNotDue(notDueInfo);
        setError(false);
    }

    const requestDataCallback = useCallback(requestData, [dashboard.DashboardID, dashboardFilters]);
    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    if (loading) {
        return (<Loading />)
    }

    if (error || !notDue) {
        return (<></>)
    }
    let total = notDue!.y + overdue!.y;

    return (
        <DashboardSales noDue={notDue!.y} overdue={overdue!.y} totalAmount={total} />
    )
}
export default DebtChart;