import { CreditReportResponse } from "../pages/reports/entities/CreditReportResponse";
import CompanyService from "./CompanyService";
import ExportService from "./ExportService";
import RequestService from "./RequestService";

export class CreditReportService {
    public static getList(request: CreditReportListRequest): Promise<CreditReportResponse | Error> {
        return RequestService.post("/client/creditlist", { ...request, additionaldefinitions: CompanyService.getAdditionalDefinitions() });
    }
    public static export(request: CreditReportListRequest, itemCount: number) {
        return ExportService.requestExport("/client/exportcreditlist", request, itemCount);
    }
}
export type CreditReportListRequest = {
    page: number,
    sortColumn: string,
    descending: number,
    pagesize: number,
    filter: string,
}