import { useCallback, useEffect, useMemo, useState } from 'react'
import { DropdownSelectWrapper } from './OtherConfigurationItems/DropdownSelectWrapper'
import ActivityTypeService from '../../../services/ActivityTypeService';
import { ActivityTypeListResponse } from '../../../entities/company/ActivityType/ActivityTypeListResponse';
import { TranslationService } from '../../../services/TranslationService';

type InboundMailsActivityTypeTypo = {
    initialValue?: number | string;
}

export const InboundMailsActivityType = ({ initialValue }: InboundMailsActivityTypeTypo) => {
    const [response, setResponse] = useState<ActivityTypeListResponse>();
    const listItems = useMemo(() => response?.list.map(el => ({ value: el.ActivityTypeID, text: el.ActivityTypeName })), [response])

    const requestData = async () => {
        const result = await ActivityTypeService.getList();
        if (result instanceof Error) { return; }
        setResponse(result);
    }

    const requestDataCallback = useCallback(requestData, []);

    useEffect(() => {
        requestDataCallback();
    }, [requestDataCallback]);

    return <>
        {listItems ? (
            <DropdownSelectWrapper
                initialValue={initialValue}
                items={[{ value: '', text: TranslationService.translate.NoneValue }, ...listItems]}
                title="InboundMailsActivityType"
                action="inboundactivitytypeid" />
        ) : null}
    </>
}
