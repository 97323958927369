import React, { ErrorInfo, GetDerivedStateFromError } from "react";
import { RequestError } from "../../utils/RequestUtils";

class ErrorCatcher extends React.Component<{ onError: (error: string) => void }, { hasError: boolean, shownError: boolean, error: string | undefined }> {
  static getDerivedStateFromError(error: GetDerivedStateFromError<{}, { hasError: boolean }>) {
    if (error instanceof RequestError) {
    } else {
      return { hasError: true, shownError: false, error: undefined };
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const errorMessage = error.message + " \n " + JSON.stringify(errorInfo);
    this.setState({ hasError: true, shownError: true, error: errorMessage });
    this.props.onError(errorMessage);
  }

  render() {
    if (this.state?.hasError && !this.state.shownError) {
      return (<></>);
    }
    return this.props.children;
  }
}

export default ErrorCatcher;
