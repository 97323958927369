import { useEffect, useMemo, useState } from "react";
import { TranslationService } from "../../../../services/TranslationService";
import { EditProps } from "../EditAction";
import { Field } from "./FieldHelpers";
import Dropdown from "../../../shared/components/Dropdown";
import WhatsappService, { WhatsappTemplateListType } from "../../../../services/WhatsappService";
import { ToastService } from "../../../shared/bootstrap/Toast";
import CompanyService, { Entities } from "../../../../services/CompanyService";
import { EntityField, sortEntityFields } from "../../../shared/entities/EntityField";
import { ClientFields, getClientFieldTitle } from "../../../../services/ClientService";

export const SelectWhatsappTemplate = (props: EditProps) => {
    const [data, setData] = useState<WhatsappTemplateListType>();
    const hasDefaultValue = ((props.data as Object).hasOwnProperty('WATemplateID') && (props.data as Object).hasOwnProperty('Content'));
    const selectOptionTranslate = TranslationService.translate.Select + ' ' + TranslationService.translate.WhatsAppTemplate;

    const fixedFieldsCustomer = useMemo(() => {
        const clientFieldOptions = [
            ...CompanyService.getAdditionalDefinitionsFiltered(Entities.Client).map(x => new EntityField(x)),
        ]
            .sort(sortEntityFields(CompanyService.getPersonSortedFields().split(",")))
            .map(x => ({ value: x.id.toString(), text: x?.title ?? '' }));

        const insertFieldOptions = [
            { text: TranslationService.translate.InsertField, value: "" },
            ...WhatsappService.getConfigItemsByWhatsApp().map(cl => ({ value: cl.toString(), text: getClientFieldTitle(cl as ClientFields) })),
            ...clientFieldOptions,
        ];
        return insertFieldOptions;
    }, []);

    const onChange = (value: string) => {
        let valueAux: string | string[] = value;
        if (value === selectOptionTranslate) {
            valueAux = '';
            props.setData((old: any) => {
                delete old.Content;
                delete old.WATemplateID;
                return ({ ...old, Type: 7 })
            })
        } else if (value?.length > 0) {
            valueAux = value.split('---');
            props.setData((old: any) => ({ ...old, Content: valueAux[1], WATemplateID: parseInt(valueAux[0]), Type: 7 }))
        }
    }

    const handleSelectedChange = (value: any) => {
        const valueSelected = value.split('---')
        const item = data?.list && data.list.find(el => el.WATemplateID.toString() === valueSelected[0])
        const dataParameters = item?.Parameters;
        const elements = Array.from({ length: dataParameters || 0 }, (_, index) => index + 1);

        let texto = valueSelected[1] || '';

        if (item?.FieldList) {
            (item?.FieldList?.split(',').map((f, index) => ({ param: index + 1, data: `${f}`, valueText: `${f}` })) ||
                elements.map((elIndex) => ({ param: elIndex, data: `{{${elIndex}}}`, valueText: `{{${elIndex}}}` }))
            ).forEach(({ param, valueText }) => {
                texto = texto.replace(`{{${param}}}`, `{{${fixedFieldsCustomer.find(f => f.value === valueText)?.text || valueText}}}`)
            });
        }

        onChange(value);
    }

    useEffect(() => {
        const getWhatsappTemplateList = async () => {
            const result = await WhatsappService.getList()
            if (result instanceof Error) {
                ToastService.showToast('Error al obtener templates', undefined, "danger");
                return;
            } else {
                setData(result);
            }
        }
        getWhatsappTemplateList();
    }, []);

    const fixedFields = useMemo(() => {
        const templateFieldOptions = data?.list.filter(f => f.FieldList).map(x => ({ value: `${x.WATemplateID}---${x.Structure}`, text: x.Name ?? '' }));
        templateFieldOptions?.unshift({
            text: TranslationService.translate.Select + ' ' + TranslationService.translate.WhatsAppTemplate,
            value: TranslationService.translate.Select + ' ' + TranslationService.translate.WhatsAppTemplate
        })
        return templateFieldOptions || [];
    }, [data?.list]);

    const insertFieldOptions = useMemo(() => {
        const itemsFields = fixedFields;
        return itemsFields;
    }, [fixedFields]);

    return (
        <div className="row">
            <div className={"col-6"}>
                <Field colClass={'col'} title={TranslationService.translate.WhatsAppTemplate}>
                    {
                        data &&
                        <Dropdown
                            defaultValue={hasDefaultValue ? `${props.data.WATemplateID}---${props.data.Content}` : selectOptionTranslate}
                            onChange={handleSelectedChange}
                            placeholder={selectOptionTranslate}
                            items={insertFieldOptions} />
                    }
                </Field >
            </div >
        </div >
    )
}   
