import TabPosition from "../shared/TabPosition";

const GroupedList: React.FC = () => {

    return (
        <TabPosition module="client">
            <p>Company</p>
        </TabPosition>
    )
}

export default GroupedList;