import React, { useCallback, useState } from "react";
import TableContext from "../../task/TableContext";
import { applyFiltersHelper, setCurrentPageHelper, setPageSizeHelper, sortHelper, useEndpointData } from "../../../utils/TableUtils";
import InvoiceService from "../../../services/InvoiceService";
import { InvoiceHistoryListRequest } from "../../client/invoice/entities/InvoiceHistoryListRequest";

const ReportHistoryIoListTableProvider = ({ children, prevRequest = undefined }: React.PropsWithChildren<{ prevRequest?: InvoiceHistoryListRequest }>) => {
    const [request, setRequest] = useState(prevRequest ?? new InvoiceHistoryListRequest());
    const endpoint = useCallback(() => InvoiceService.getIoHistoryList(request), [request]);
    const context = useEndpointData(endpoint);

    context.pageCount = context.response ? Math.ceil(context.response.clientCount / context.response.pageSize) : 0;
    const { sort, setSortColumn } = sortHelper(request, setRequest);
    context.sort = sort;
    context.setSortColumn = setSortColumn;
    const applySearch = (keyword: string) => {
        setRequest({ ...request, filter: keyword });
    };
    context.setCurrentPage = setCurrentPageHelper(setRequest, context.pageCount);
    context.pageSize = request.pagesize;
    context.setPageSize = setPageSizeHelper(setRequest);
    context.applyFilters = applyFiltersHelper(setRequest);
    context.applySearch = applySearch;
    context.currentPage = request.page;
    return (
        <TableContext.Provider value={context}>
            {children}
        </TableContext.Provider>
    );
};

export default ReportHistoryIoListTableProvider; 