import React from "react";
import CompanyService from "../../../services/CompanyService";
import { TranslationService } from "../../../services/TranslationService";
import TooltipComponent from "../../shared/bootstrap/Tooltip";
import ButtonDropdown, { DropdownItems } from "../../shared/components/ButtonDropdown";
import Dropdown from "../../shared/components/Dropdown";

const DashboardHeader = (props: React.PropsWithChildren<{ title: string, tooltip?: string, onUserSelected?: (selectedUserId: string) => void, userId?: string, viewAll?: () => void, dropdownItems?: DropdownItems, onDropdownSelected?: (selected: string) => void }>) => {
    return (
        <div className="card-header">
            <div className="menu">
                <h2>{props.title}</h2>
                {props.tooltip &&
                    <TooltipComponent placement="right" title={props.tooltip}>
                        <i className="far fa-info-circle information-icon"></i>
                    </TooltipComponent>
                }
                {props.onUserSelected &&
                    <Dropdown items={CompanyService.getUsers().map(x => ({ text: x.Value, value: x.Id, key: x.Id }))} onChange={props.onUserSelected} defaultValue={props.userId ?? CompanyService.getUserid()} />
                }
            </div>
            {props.viewAll &&
                <div className="view-all" onClick={props.viewAll}>
                    <span>{TranslationService.translate.SeeAll}</span><i className="fas fa-arrow-right ps-2"></i>
                </div>
            }
            {props.dropdownItems && props.onDropdownSelected &&
                <ButtonDropdown items={props.dropdownItems} onChange={(selected) => props.onDropdownSelected && props.onDropdownSelected(selected!)}>
                    <i className="fas fa-ellipsis-h"></i>
                </ButtonDropdown>
            }
        </div>
    );
}

export default DashboardHeader;