
interface SelectProps<T> {
    options: { text: string, value: T }[];
    extraClass?: string;
    onChange: (value: T) => void;
    defaultValue?: T;
    value?: T;
}

const Select = <T extends object,>({ options, extraClass, onChange, defaultValue, value }: SelectProps<T>) => {
    const isSelected = (option: SelectProps<T>["options"][number]) => {
        if (defaultValue === undefined || defaultValue === null) {
            return false;
        }
        return defaultValue === option.value;
    };
    return (
        <select className={extraClass} onChange={x => onChange(options[parseInt(x.target.value)].value)} value={value && options.findIndex(x => x.value === value)}>
            {options.map((option, index) => (
                <option key={index + option.text} value={index} selected={isSelected(option)}>
                    {option.text}
                </option>
            ))}
        </select>
    );
};

export default Select;