import CompanyService from "../../../services/CompanyService";
import { TranslationService } from "../../../services/TranslationService";
import { formatDateShort } from "../../../utils/FormatUtils";
import TooltipComponent from "../../shared/TooltipComponent";
import { TaskEntities } from "../entities/TaskListResponse";
import { TaskStatus } from "../entities/TaskStatus";

export const StatusText = ({ data }: { data: TaskEntities.ListItem }) => {
    let statusElem: React.ReactNode = undefined;
    let statusDate: string | undefined = undefined;
    let statusUserId: number | undefined = undefined;
    switch (data.Status as TaskStatus) {
        case TaskStatus.Fulfilled:
            statusDate = data.Done;
            statusUserId = data.DoneUserID;
            statusElem =
                <span className="alert-sm alert-sm-success">
                    {TranslationService.translate.StatusFulfilled}
                </span>
            break;
        case TaskStatus.Validated:
            statusDate = data.Done;
            statusUserId = data.DoneUserID;
            statusElem =
                <span className="alert-sm alert-sm-success">
                    {TranslationService.translate.StatusValidated}
                </span>
            break;
        case TaskStatus.Canceled:
            statusDate = data.Done;
            statusUserId = data.DoneUserID;
            statusElem =
                <span className="alert-sm alert-sm-warning">
                    {TranslationService.translate.StatusCanceled}
                </span>
            break;
        case TaskStatus.Pending:
            statusDate = data.Done;
            statusUserId = data.DoneUserID;
            // if (data.dueDays > 0) {
            //     statusElem = <span className="alert-sm alert-sm-danger">
            //         {TranslationService.translate.StatusExpired}
            //     </span>
            // }
            // else {
            statusElem =
                <span className="alert-sm alert-sm-warning">
                    {TranslationService.translate.StatusPending}
                </span>
            // }
            break;
        // case TaskStatus.Expired:
        //     statusDate = data.Done;
        //     statusUserId = data.DoneUserID;
        //     statusElem =
        //         <span className="alert-sm alert-sm-danger">
        //             {TranslationService.translate.StatusExpired}
        //         </span>
        //     break;
    }

    const dateStr = statusDate ? formatDateShort(new Date(statusDate)) : "";
    const userStr = statusUserId ? (CompanyService.getUsers().find(x => x.Id === statusUserId?.toString())?.Value ?? '') : "";
    return (
        <TooltipComponent title={Boolean(statusDate && statusUserId) && `${dateStr}. ${userStr}`}>
            {statusElem}
        </TooltipComponent>
    )
}