

const IconButtonDebts = ({ onClick, children }: React.PropsWithChildren<{ onClick: () => void }>) => {
    return (
        <button onClick={onClick} className="actionButton rounded-circle" style={{backgroundColor: "#EAF8FA", height: 40, width: 40, color: "#0858B7"}}>
            {children}
        </button>
    );
}

export default IconButtonDebts;