type InfoMessageType = {
  currentInfoMsg: string;
};

export const InfoMessage = ({ currentInfoMsg }: InfoMessageType) => {
  return (
    <div
      style={{
        color: "green",
        fontSize: "14px",
        textAlign: "center",
        margin: "0px 0px 10px",
      }}
    >
      {currentInfoMsg}&nbsp;
    </div>
  );
};
