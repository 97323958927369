import { useState } from "react";
import { TranslationService } from "../../../../services/TranslationService";
import { FileApi, FileSingleEditor } from "../../../shared/components/Editors";
import { EditProps } from "../EditAction";
import { Field } from "./FieldHelpers";
import FileService from "../../../../services/FileService";

export const WordTemplate = ({ setField, data, propGen }: EditProps) => {
    const [file, setFile] = useState<FileApi | undefined>(data.Letter_HasFile ? { id: "1", name: "" } : undefined);
    const onFileChanged = (file: FileApi | undefined) => {
        setField("Letter_HasFile")(true);
        setField("file")(file);
        setFile(file);
    }
    return (
        <div className="row">
            <Field title={TranslationService.translate.Template} colClass="col">
                <div className="w-100">
                    <FileSingleEditor 
                    onFileChange={onFileChanged} file={file} canEdit={true} 
                    downloadFile={()=>FileService.downloadActionLetterTemplate(data.ActionID?.toString() ?? "")}
                    invalidExtentionMessage={TranslationService.translate.WarningTheFileHasToBeDOCType} 
                    validExtensions={["doc", "docx"]} 
                    buttonText={<><i className="far fa-file-word me-2"></i> {TranslationService.translate.UploadWordTemplate}</>}></FileSingleEditor>
                </div>
            </Field>
        </div>
    )
}