import { TranslationService } from "../../../../services/TranslationService";
import { EditProps, strEvent } from "../EditAction";
import { EmailFrom, EmailTo, EmailContent, Subject } from "./EmailComponents";
import { Field } from "./FieldHelpers";

export const EmailSimpleContent = (props: EditProps) => {
    const { data } = props;
    if (data.Mail_ToType === 3) {
        return (<>
            <div className="row">
                <Field title={TranslationService.translate.FileName} colClass="col-8">
                    <input className="form-control"
                        {...strEvent(props.propGen.str.notRequired("FTP_Filename"))} />
                </Field>
            </div>
        </>);
    }
    return (
        <>
            <EmailFrom {...props} />
            <EmailTo {...props} />
            <Subject {...props} />
            <EmailContent {...props} />
        </>);
}