import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { TranslationService } from "../../../services/TranslationService";

const Empty = () => <></>;
export type RrsBaseStructureProps = {
    highchartsOptions: Highcharts.Options,
    PreGraphic?: typeof Empty,
    PostGraphic?: typeof Empty,
    UnderTitle?: typeof Empty,
}
export const RrsBaseStructure = ({ highchartsOptions, PreGraphic = Empty, PostGraphic = Empty, UnderTitle = Empty }: RrsBaseStructureProps) => {
    Highcharts.setOptions({
        lang: {
            viewFullscreen: TranslationService.translate.HighchartsFullScreen,
            exitFullscreen: TranslationService.translate.HighChartsExitFullScreen,
            printChart: TranslationService.translate.HighchartsPrint,
            downloadPNG: TranslationService.translate.HighchartsDownloadPNG,
            downloadPDF: TranslationService.translate.HighchartsDownloadPDF
        }
    });
    return (
        <div className="card mh-100 mb-2 pb-3">
            <div className="row">
                <div className="col-xxl-3 col-4">
                    <UnderTitle />
                </div>
                <div className="col-xxl-9 col-8">
                    <PreGraphic />
                    <HighchartsReact highcharts={Highcharts} options={highchartsOptions} />
                    <PostGraphic />
                </div>
            </div>
        </div>);
};
