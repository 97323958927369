import React, { useContext, useEffect, useRef, useState } from "react";
import { FC } from "react";
import { Toast as ToastBootstrap } from 'bootstrap';

export type ToastType = "danger" | "success" | "warning" | "info" | "";

class ToastParams {
    static lastId = 1;
    id = ++ToastParams.lastId;
    constructor(
        public body: string | FC,
        public clear: (toast: ToastParams) => void,
        public title?: string,
        public type: ToastType = "",
    ) { }
}
type ToastContextValues = {
    showToast: (body: string | FC, title?: string, type?: ToastType) => void,
    toasts: ToastParams[],
}
const ToastContext = React.createContext({} as ToastContextValues);

export class ToastService {
    public static showToast(body: string | FC, title?: string, type?: ToastType) {

    }
}

export const ToastProvider: FC = (props) => {
    const removeToast = (toast: ToastParams) => {
        setToasts(toasts => toasts.filter(x => x.id !== toast.id));
    }
    const [toasts, setToasts] = useState<ToastParams[]>([]);
    const showToast = (body: string | FC, title?: string, type: ToastType = "") => {
        const params = new ToastParams(body, removeToast, title, type);
        setToasts(toasts => {
            if (toasts.every(x => x.id !== params.id)) {
                toasts.push(params);
            }
            return [...toasts];
        });
    };
    useEffect(() => {
        ToastService.showToast = showToast;
    })
    return (
        <ToastContext.Provider value={{ toasts, showToast }}>
            {props.children}
            <Toasts />
        </ToastContext.Provider>
    )
}

const Toasts = () => {
    const { toasts } = useContext(ToastContext);
    return (
        <div aria-live="polite" role="alert" aria-atomic="true" style={{ position: "fixed", top: 20, right: 20, zIndex: 7000 }}>
            {toasts.map(x => <Toast key={x.id} {...x} />)}
        </div>
    );
}

const Toast = (params: ToastParams) => {
    const ref = useRef(null);
    useEffect(() => {
        if (ref.current) {
            new ToastBootstrap(ref.current, {
                delay: 6000,
            }).show();
        }
    }, []);
    const { title, type } = params;
    const showHeader = title !== undefined && title !== "";
    const Body = typeof params.body === "string" ?
        () => (<>{params.body}</>) : params.body;
    return (
        <div ref={ref} className="toast fade" role="alert" aria-live="assertive" aria-atomic="true">
            {showHeader &&
                <div className={"toast-header bg-opacity-10 bg-" + type}>
                    <strong className="me-auto">{title}</strong>
                    <button type="button" className={"ms-2 btn-close"} data-bs-dismiss="toast" aria-label="Close">
                        <span className="d-none" aria-hidden="true">×</span>
                    </button>
                </div>}
            <div className={"toast-body d-flex text-bg-" + type}>
                <div className="me-auto">
                    <Body />
                </div>
                {!showHeader &&
                    <button type="button" className={"ms-2 btn-close" + (type !== "" ? " btn-close-white" : "")} data-bs-dismiss="toast" aria-label="Close">
                        <span className="d-none" aria-hidden="true">×</span>
                    </button>}
            </div>
        </div>
    );
}

export default ToastContext;