
import { CheckBoxEditor, TextEditor } from "../../../shared/components/Editors";
import { TranslationService } from "../../../../services/TranslationService";
import { CompanyAuth } from "../../../../entities/login/CompanyAuth";
import { FloatingPanelFooter } from "../../../shared/components/FloatingPanelFooter";
import CompanyService, { Entities } from "../../../../services/CompanyService";
import TooltipComponent from "../../../shared/TooltipComponent";
import { useState } from "react";
import { negate } from "../../../../utils/FuncUtils";
import FieldConfigurationService from "../../../../services/FieldConfigurationService";
import FloatingPanelService from "../../../shared/FloatingPanel";
import { handleErrorWithToast } from "../../../../utils/RequestUtils";
import { ToastService } from "../../../shared/bootstrap/Toast";
import { InvoiceFields } from "../../../../services/InvoiceService";
import ModalService from "../../../shared/bootstrap/Modal";

const FixedFieldEdit = ({ data, reload, field: original }: { data: CompanyAuth.ConfigItem, reload: () => void, field: CompanyAuth.ConfigItem }) => {
    const [field, setField] = useState<CompanyAuth.ConfigItem>({ ...original });
    const title = data.name ?? CompanyService.getEntityFieldTitle(data.Entity, data.field);
    const isNameField = TranslationService.translate.Name === title;

    const updateField = <TKey extends keyof CompanyAuth.ConfigItem,>(propName: TKey) => (value: CompanyAuth.ConfigItem[TKey]) => {
        setField(field => ({ ...field, [propName]: value }) as CompanyAuth.ConfigItem);
    };
    const submitSave = async () => {
        await handleErrorWithToast(FieldConfigurationService.setFixedDefinition(field),
            (error) => {
                if (error.message === "Fail-PersonKey") {
                    ModalService.showDefaultModal({
                        message: TranslationService.translate.CantSelectThisFieldAsIdentifierBecauseThereAreCustomersWithTheValueOfThisFieldRepeated,
                    });
                }
            });

        FloatingPanelService.hidePanel();
        ToastService.showToast(TranslationService.translate.SavedChanges, undefined, "success");
        window.location.reload();
    };
    const IncludeInList = () => <div className="d-flex align-items-center">
        <CheckBoxEditor onChange={updateField("IncludeInList")} defaultValue={field.IncludeInList} message={TranslationService.translate.IncludeInReports} />
        <TooltipComponent title={TranslationService.translate.IncludeInvoicesPaymentsActivitiesTags}>
            <i className="ms-2 fal fa-question-circle"></i>
        </TooltipComponent>
    </div>;
    if (field.Entity === Entities.Invoice) {
        return (
            <>
                <div className="floatingBody p-4">
                    {field.field === -4 /* Identificador */ ?
                        <div className="form-group mb-3">
                            <label>{TranslationService.translate.Name}</label>
                            <TextEditor onChange={updateField("name")} value={field?.name} />
                        </div> :
                        <h5 className="mb-3">{title}</h5>}
                    <section>
                        {field.field !== InvoiceFields.amount ? <>
                            <CheckBoxEditor onChange={updateField("IncludeInList")} defaultValue={data.IncludeInList} message={TranslationService.translate.IncludeInLists} />
                            <CheckBoxEditor onChange={updateField("IncludeInMail")} defaultValue={data.IncludeInMail} message={TranslationService.translate.IncludeInEmailsToClientsAndPortal} />
                            {field.field === InvoiceFields.dueDate &&
                                <CheckBoxEditor onChange={updateField("ShowDueDays")} defaultValue={data.ShowDueDays} message={TranslationService.translate.ShowDueDays} />
                            }
                        </> :
                            <>
                                <CheckBoxEditor onChange={updateField("IncludeInList")} defaultValue={data.IncludeInList} message={TranslationService.translate.IncludeTotalAmountInListings} />
                                <CheckBoxEditor onChange={updateField("IncludeInMail")} defaultValue={data.IncludeInMail} message={TranslationService.translate.IncludeTotalAmountInMailsToCustomers} />
                                <CheckBoxEditor onChange={updateField("IncludeMainCurrency")} defaultValue={data.IncludeMainCurrency} message={TranslationService.translate.IncludeOutstandingAmountInPrincipalCurrency} />
                            </>
                        }
                        <CheckBoxEditor onChange={updateField("ManualEdit")} defaultValue={data.ManualEdit} message={TranslationService.translate.AllowManualEdit} />
                    </section>
                </div >
                <FloatingPanelFooter>
                    <button className="btn btn-primary" onClick={submitSave}>{TranslationService.translate.Save}</button>
                </FloatingPanelFooter>
            </>
        );
    }
    return (
        <>
            <div className="floatingBody p-4">
                <h5 className="mb-3">{title}</h5>
                <section>
                    {!isNameField && <>
                        <CheckBoxEditor onChange={updateField("IncludeInClientExport")} defaultValue={data.IncludeInClientExport} message={TranslationService.translate.IncludeWhenExportingTheCustomersList} />
                        <IncludeInList />
                    </>}
                    <CheckBoxEditor onChange={negate(updateField("ExcludeInFilters"))} defaultValue={!data.ExcludeInFilters} message={TranslationService.translate.IncludeInFilters} />
                    <CheckBoxEditor onChange={updateField("ManualEdit")} defaultValue={data.ManualEdit} message={TranslationService.translate.AllowManualEdit} />
                    {!isNameField && <CheckBoxEditor onChange={updateField("IncludeInCustomerPortal")} defaultValue={data.IncludeInCustomerPortal} message={TranslationService.translate.IncludeInCustomerPortal} />}
                </section>
            </div >
            <FloatingPanelFooter>
                <button className="btn btn-primary" onClick={submitSave}>{TranslationService.translate.Save}</button>
            </FloatingPanelFooter>
        </>
    );
};


export default FixedFieldEdit;