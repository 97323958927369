
import { GetSignatureResponse } from "../entities/user/GetSignatureResponse";
import { UserGetResponse } from "../pages/user/entities/UserGetResponse";
import { UserListGetResponse } from "../pages/user/entities/UserListGetResponse";
import { UserEditModel } from "../pages/user/UserEdit";
import CompanyService from "./CompanyService";
import RequestService from "./RequestService";

export enum UserRole {
    Admin = 0,
    Guest = 1,
    QueriesOnly = 2,
}

export class UserGetListRequest {
    page: number = 0;
    text: string | null = null;
    pagesize: number = 20;
}

class UserService {

    public static height = 0;
    public static setHeight(_height: number) {
        this.height = _height;
    }

    public static getHeight() {
        return this.height;
    }

    public static getUserName(userId: number): string | undefined {
        return CompanyService.getUsers()?.find(x => x.Id === userId.toString())?.Value;
    }
    public static async get(userId: string): Promise<UserGetResponse | undefined> {
        const result: UserGetResponse | Error = await RequestService.post("/user/get", { id: userId });
        if (result instanceof Error) { return undefined; }
        return result;
    }
    public static getList(request: UserGetListRequest): Promise<UserListGetResponse | Error> {
        return RequestService.post("/user/list", request);
    }
    public static set(user: UserEditModel): Promise<{ response: string, Id: number } | Error> {
        const request = {
            ...user,
            segments: user.segments.map(x => parseInt(x)),
            Admin: user.role === UserRole.Admin,
            ReadOnly: user.role === UserRole.QueriesOnly,
        }
        return RequestService.post("/user/set", request);
    }
    public static delete(userId: number): Promise<{ response: string, Id: number } | Error> {
        return RequestService.post("/user/delete", { id: userId });
    }
    public static reinvite(email: string): Promise<{ response: string, Id: number } | Error> {
        return RequestService.post("/user/reinvite", { email });
    }

    public static getRole(user: {CompanyUserID?: number | null | undefined, UserID?: number | null | undefined, Admin: boolean, ReadOnly?: boolean}): string {
        if (user.CompanyUserID === user.UserID) {
            return "Creator";
        }
        if (user.Admin) {
            return "Administrator";
        }
        if (user.ReadOnly) {
            return "Readonly";
        }
        return "Guest";
    }

    public static getSignatures(): Promise<GetSignatureResponse[] | Error> {
        return RequestService.post("/user/getsignatures", {});
    }
}

export default UserService;