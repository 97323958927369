import { useContext, useMemo, useState } from "react";
import Table, { TableHeader } from "../shared/Table";
import { TranslationService } from "../../services/TranslationService";
import ReportTagListProvider, { ReportTagListTableContextValues } from "./providers/ReportTagListProvider";
import { TagReportResponse } from "../../entities/company/Tag/TagListResponse";
import AdvancedFilters, { AdvancedFiltersButton } from "../shared/components/AdvancedFilters";
import FilterService, { FilterCollection } from "../../services/FilterService";
import TableContext from "../task/TableContext";
import { useHistory } from "react-router-dom";

export const ReportTagList = () => {
    return (
        <ReportTagListProvider>
            <ReportTagListComponent />
        </ReportTagListProvider>
    );
};

const ReportTagListComponent = () => {
    const tableHead = useMemo(() => [
        new TableHeader("tag", TranslationService.translate.Tag, false, false),
        new TableHeader("invoices", TranslationService.translate.Invoices, false, false),
        new TableHeader("emails", TranslationService.translate.Emails, false, false),
        new TableHeader("comments", TranslationService.translate.Comments, false, false),
        new TableHeader("tasks", TranslationService.translate.Tasks, false, false),
        new TableHeader("total", TranslationService.translate.Total, false, false),
    ], []);

    const { setRequest, request, } = useContext(TableContext) as ReportTagListTableContextValues;
    const [extraFilters, setExtraFilters] = useState<string[]>([]);

    const onFilterApply = (filters: string[]) => {
        const prepared = filters
            .filter(x => x !== undefined && x.length > 0)
            .map((value, index) => "filter" + index + "=" + encodeURIComponent(value)).join("&");
        setExtraFilters(filters);
        setRequest({ ...request, filter: prepared });
    };

    return (
        <div className="container-fluid padding">
            <div className="d-flex align-items-center col-8">
                <h2 className="mb-3">{TranslationService.translate.TagsReport}</h2>
            </div>
            <div className="card mh-100 p-0">
                <div className="genericHeader row justify-content-start">
                    <div className="col-auto">
                        <div className="d-flex align-items-center">
                            <AdvancedFiltersButton />
                        </div>
                    </div>
                </div>
                <div className="pt-0">
                    <AdvancedFilters page={FilterCollection.Tag} onFilterApply={onFilterApply} />
                </div>
                <div className="p-4">
                    <Table stickyHeader={true} headers={tableHead} item={({ data }) => ReportLogItemMain({ data, extraFilters })} />
                </div>
            </div>
        </div>
    );
};

const orderHeader = ['Name', 'io', 'mail', 'comment', 'task', 'total'];
enum headersTypes {
    'Name',
    'mail',
    'comment',
    'task',
    'io',
    'total',
}

const ReportLogItemMain = ({ data, extraFilters }: { data: TagReportResponse.Item, extraFilters: string[] }) => {
    const { currentLanguage } = TranslationService;
    const history = useHistory();
    const nameFilter = 'tagidsFilter={tagId}&selFields=-106';
    const categoryFilter = 'typeFilter={typeId}&selFields=-103'

    const handleNavigate = (dvKey: any) => {
        const typeId = parseInt(headersTypes[dvKey]);
        const dataValues = [orderHeader[0]].map((el: string) => ({
            id: el,
            value: (data as any)[el],
            name: (data as any)['Name'],
            data
        }));

        const extraFiltersFormated: string[] = [];
        if (extraFilters) {
            for (const exFilter of extraFilters) {
                let filterAux = exFilter;
                if (exFilter.includes('selFields=2')) {
                    filterAux = filterAux.replace('selFields=2', 'selFields=-100')
                } else if (exFilter.includes('selFields=4')) {
                    filterAux = filterAux.replace('selFields=4', 'selFields=-7')
                }
                else if (exFilter.includes('selFields=1')) {
                    filterAux = filterAux.replace('selFields=4', 'selFields=-102')
                }
                extraFiltersFormated.push(filterAux);
            }
        }

        let stringFilters: string[] = [];
        switch (typeId) {
            case 0:
                break;
            case 4:
                history.push("/" + currentLanguage + "/report/iolist?filter=" + encodeURIComponent(FilterService.GetExtraFiltersRequestString([...stringFilters, ...extraFiltersFormated]) || ""));
                break;
            case 5:
                stringFilters.push(nameFilter.replace('{tagId}', dataValues[0].data.TagID.toString()));
                history.push("/" + currentLanguage + "/report/reportactivity?filter=" + encodeURIComponent(FilterService.GetExtraFiltersRequestString([...stringFilters, ...extraFiltersFormated]) || ""));
                break;
            default:
                stringFilters.push(nameFilter.replace('{tagId}', dataValues[0].data.TagID.toString()));
                stringFilters.push(categoryFilter.replace('{typeId}', typeId.toString()));
                history.push("/" + currentLanguage + "/report/reportactivity?filter=" + encodeURIComponent(FilterService.GetExtraFiltersRequestString([...stringFilters, ...extraFiltersFormated]) || ""));
                break;
        }
    }

    return (
        <tr>
            {orderHeader.map((dv, index) =>
                <td
                    key={index}
                    className="pointer"
                    onClick={() => handleNavigate(dv)}
                >
                    {(data as any)[dv]}
                </td>)
            }
        </tr>
    );
};