import { Entity } from "draft-js";
import { useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";
import CompanyService, { Entities } from "../../../../services/CompanyService";
import { TranslationService } from "../../../../services/TranslationService";
import Dropdown from "../../../shared/components/Dropdown";
import { ActionGetResponse } from "../../entities/ActionGetResponse";
import { EditProps, strEvent } from "../EditAction";
import FilterService, { FilterDefinition, FilterEntity } from "../../../../services/FilterService";
import { ActionType } from "../../../../services/ActionService";

type AddDef = Partial<ActionGetResponse.ActionAdditionalDefinition> & { _id: string, entity?: Entity };

const addId = (item: {}) => ({ ...item, _id: v4() });
const iconStyle = { height: 40, display: "flex", alignItems: "center" };

export const AdditionalDefinitions = ({ setField, data, propGen }: EditProps) => {
    const showInvoiceFilterTypes = useMemo(() => ([ActionType.MailSend, ActionType.ActionSendIOList, ActionType.ActionSendLetter]), []);
    const filtersOptions = useMemo(() => (
        [FilterService.GetFiltersForEntity(FilterEntity.Client)]
    ), []);
    // FilterService.GetFiltersFor(FilterCollection.Client);
    //const [filtersOptions, setFilterOptions] = useState(FilterService.GetFiltersFor([ActionType.MailSend, ActionType.ActionSendIOList, ActionType.ActionSendLetter].indexOf(data.Type ?? ActionType.ActionSMSSend) > -1 ? FilterCollection.Client : FilterCollection.OnlyClient));
    const formatDefinitionKey = (definition: FilterDefinition) => {
        return definition.Entity + "%" + definition.Field
    };

    const mainDropdownItems = filtersOptions
        .flatMap(x => [({ text: x.title, isTitle: true }),
        ...x.definitions.map(y => ({ text: y.Name, value: y.Field.replaceAll("additional-", ""), key: formatDefinitionKey(y) }))])
        .filter(x => !("value" in x) || !x.value.startsWith("-"));

    const toItems = (items: ActionGetResponse.ActionAdditionalDefinition[] = []): AddDef[] =>
        items.map(x => ("_id" in x ? x : addId(x)) as AddDef)
            .map(x => {
                for (let index = 0; index < filtersOptions.length; index++) {
                    const filterOption = filtersOptions[index];
                    const entity = filterOption.definitions.find(z => z.Field.replaceAll("additional-", "") === x.AdditionalDefinitionID?.toString())?.Entity;
                    if (entity !== undefined) {
                        x.entity = entity;
                    }
                }
                return x;
            });

    const [items, setItems] = useState<AddDef[]>(toItems(data.Action_AdditionalDefinition));
    const newItem = () => {
        const x = mainDropdownItems.filter(x => "value" in x)[0];
        let val = 0;
        if ("value" in x) {
            val = parseInt(x.value);
        }
        return addId({
            ActionID: data.ActionID,
            entity: 0,
            AdditionalDefinitionID: val
        })
    };

    useEffect(() => {
        if (showInvoiceFilterTypes.indexOf(data.Type ?? ActionType.ActionSMSSend) === -1) {
            setItems(items => items.filter(x => x.entity === 0));
        }
    }, [data.Type, showInvoiceFilterTypes])

    const onChange = <T extends keyof AddDef,>(_id: string, field: T, value: AddDef[T]) => {
        const item = items.find(x => x._id === _id)!;
        item[field] = value;
        const newItems = [...items];
        setItems(newItems);
        setField("Action_AdditionalDefinition")
            (newItems.filter(x => x.AdditionalDefinitionID) as
                ActionGetResponse.ActionAdditionalDefinition[]);
    };

    if (!CompanyService.getSetting("allowactionadditionaldefinition")) {
        return <></>
    }

    return (
        <div className="row mb-3">
            <div className="col-10">
                <button className="btn btn-link px-0 pt-0 pb-2 text-start" onClick={
                    () => setItems(x => [...x, newItem()])}>
                    + {TranslationService.translate.AddAdditionalEdit}
                </button>
            </div>
            <div className="col-10">
                {items.map(x => (
                    <div className="row mb-2" key={x._id}>
                        <div className="col-auto">
                            <span style={iconStyle}>
                                <i className={"ps-3 fas fa-fw " + (x.entity === Entities.Invoice ?
                                    "fa-list" : "fa-user")} />
                            </span>
                        </div>
                        <div className="col-4">
                            <Dropdown items={mainDropdownItems}
                                defaultValue={x.AdditionalDefinitionID?.toString()}
                                onChange={val => onChange(x._id, "AdditionalDefinitionID", parseInt(val))} />
                        </div>
                        <div className="col-4">
                            <input className="form-control" {...strEvent({
                                onChange: value => onChange(x._id, "Value", value),
                                defaultValue: x.Value ?? "",
                            })} />
                        </div>
                        <div className="col-2">
                            <span style={iconStyle}>
                                <i className="fa-solid fa-times text-danger pointer" onClick={
                                    () => setItems(y => y.filter(z => z._id !== x._id))} />
                            </span>
                        </div>
                    </div>))}
            </div>
        </div>);
}