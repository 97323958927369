import { useRef } from "react";
import { formatCurrency, formatInteger } from "../../../utils/FormatUtils";
import CompanyService from "../../../services/CompanyService";
import TooltipComponent from "../../shared/TooltipComponent";
import { TooltipBarClient } from "./ClientTooltip";
import { endpointRequest } from "../../../utils/RequestUtils";
import ClientService from "../../../services/ClientService";
import Loading from "../../shared/components/Loading";
import { renderToStaticMarkup } from "react-dom/server";
import { useHistory } from "react-router-dom";
import { TranslationService } from "../../../services/TranslationService";

const ClientHistoryTableItem = (props: any) => {
    const { currentLanguage } = TranslationService;
    const aging = useRef<string[]>([]);
    const history = useHistory()

    const setTooltipBarData = async () => {
        const tooltipElement = document.getElementsByClassName("tooltip-aging-" + props.data.PersonId)[0];

        if (aging.current[props.data.PersonId]) {
            tooltipElement.outerHTML = aging.current[props.data.PersonId];
            return;
        }

        endpointRequest(() => ClientService.getAging(props.data.PersonId), x => {
            if (!tooltipElement) {
                return;
            }
            aging.current[props.data.PersonId] = renderToStaticMarkup(<TooltipBarClient ageing={x} amount={props.data.amount ?? 0} overdueAmount={props.data.overdueAmount ?? 0} noDueAmount={props.data.noDueAmount ?? 0}></TooltipBarClient>);
            tooltipElement.outerHTML = aging.current[props.data.PersonId];
        });
    };

    const handleNavigate = () => history.push(`/${currentLanguage}/client/details?id=${props.data.PersonId}`)

    return (
        <tr role="button">
            <td className="fw-normal w-xl" onClick={handleNavigate}>
                <span className="w-md">{props.data.personName ?? 0}</span>
            </td>

            <td className="fw-normal text-end w-xl">
                <span className="w-md">{props.data.ioCount ?? 0}</span>
            </td>
            <td className="fw-normal text-end w-xl">
                <span className="w-md">
                    {formatCurrency(props.data.amount ?? 0, CompanyService.getDefaultCurrencyId(), formatInteger)}
                </span>
            </td>
            <td className="bar-progress">
                <TooltipComponent title={<div className={"tooltip-aging-" + props.data.PersonId}><Loading></Loading></div>} onInserted={() => setTooltipBarData()}>
                    <div className="bar">
                        {(props.data.overdueAmount > 0 || props.data.noDueAmount > 0) && <>
                            <div className="expired" style={{ width: `${(((props.data.overdueAmount * 100) / props.data.maxAmount))}%` }}></div>
                            <div className="not-expired" style={{ width: `${(((props.data.noDueAmount * 100) / props.data.maxAmount))}%` }}></div>
                        </>}
                    </div>
                </TooltipComponent>
            </td>
        </tr>
    );
};

export default ClientHistoryTableItem;